import { render, staticRenderFns } from "./DeclineButton.vue?vue&type=template&id=050d9d21&scoped=true&"
import script from "./DeclineButton.vue?vue&type=script&lang=js&"
export * from "./DeclineButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "050d9d21",
  null
  
)

export default component.exports