<template>
  <HorizontalScrollablePage @get:negativeMargin="getNegativeMargin">
    <template #scrollableContent>
      <ScrollingCardsView
        v-if="collectionCreatedByMe"
        row="createdbyme"
        heading="Created by me"
        :count="collectionCreatedByMe.meta?.count"
        :resources="collectionCreatedByMe.resources.slice(0, 8)"
        :loading="loading"
        :negativeMargin="negativeMargin"
        :displayCreateButton="$vuetify.breakpoint.smAndUp"
        :displaySeeAll="collectionCreatedByMe.meta?.count > 8"
        v-on="$listeners"
        @see:all="handleSeeAll"
      />
      <ScrollingCardsView
        v-if="collectionFollowedByMe"
        row="followedbyme"
        heading="Followed Collections"
        :count="collectionFollowedByMe.meta?.count"
        :resources="collectionFollowedByMe.resources.slice(0, 8)"
        :loading="loading"
        :negativeMargin="negativeMargin"
        :displayCreateButton="$vuetify.breakpoint.smAndUp"
        :displaySeeAll="collectionFollowedByMe.meta?.count > 8"
        v-on="$listeners"
        @see:all="handleSeeAll"
      />
      <ScrollingCardsView
        v-if="collectionSharedWithMe"
        row="sharedwithme"
        heading="Shared with me"
        :count="collectionSharedWithMe.meta?.count"
        :resources="collectionSharedWithMe.resources.slice(0, 8)"
        :loading="loading"
        :negativeMargin="negativeMargin"
        :displaySeeAll="collectionSharedWithMe.meta?.count > 8"
        v-on="$listeners"
        @see:all="handleSeeAll"
      />
      <ScrollingCardsView
        v-if="collectionSharedByMe"
        row="sharedbyme"
        heading="Shared by me"
        :count="collectionSharedByMe.meta?.count"
        :resources="collectionSharedByMe.resources.slice(0, 8)"
        :loading="loading"
        :negativeMargin="negativeMargin"
        :displaySeeAll="collectionSharedByMe.meta?.count > 8"
        v-on="$listeners"
        @see:all="handleSeeAll"
      />
    </template>
  </HorizontalScrollablePage>
</template>

<script>
import ScrollingCardsView from '@/views/MyCollectionsPage/ScrollingCardsView';
import HorizontalScrollablePage from '@/components/HorizontalScrollablePage';

export default {
  name: 'LandingView',
  components: {
    HorizontalScrollablePage,
    ScrollingCardsView,
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    collectionCreatedByMe:{
      type: Object,
      default: () => {}
    },
    collectionFollowedByMe:{
      type: Object,
      default: () => {}
    },
    collectionSharedWithMe: {
      type: Object,
      default: () => {}
    },
    collectionSharedByMe: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      negativeMargin: ''
    }
  },
  methods: {
    getNegativeMargin(val) {
      this.negativeMargin = val;
    },
    async handleSeeAll(row) {
      console.log('Row click from landing view..', row)
      await this.$store.commit('myCollections/SET_SEARCH_MODE', true);
      await this.$store.commit('myCollections/SET_QUERY_ACTION', row);
      // page will be 1 for seeAll link click by default
      await this.$store.dispatch('myCollections/fetchCollections', {
        page: 1,
        perPage: 12,
        access: row
      });
    }
  }
}
</script>
