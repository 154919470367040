<template>
  <div
    v-if="searchContextIsHub"
    v-infinite-scroll="loadMoreHubResources"
    v-resize="loadMoreOnResize"
    infinite-scroll-distance="50"
    class="mb-15"
  >
    <v-row
      v-if="gridView || $vuetify.breakpoint.xsOnly"
      :class="{'ma-0': $vuetify.breakpoint.xsOnly}"
      align="center"
      justify="start"
    >
      <v-col
        v-for="(item, index) in resources"
        :key="index"
        cols="auto"
        :class="{'px-0': $vuetify.breakpoint.width < 620}"
      >
        <v-responsive
          v-if="$store.state.isLoading && !$store.state.userScroll"
          :min-width="cardSize"
          :min-height="cardSize"
        >
          <v-skeleton-loader
            boilerplate
            type="image, list-item-three-line, actions"
          />
        </v-responsive>
        <ResourceCard
          v-else
          :num="index"
          :item="item"
          :size="cardSize"
          :height="380"
          :rounded="$vuetify.breakpoint.width >= 620"
        />
      </v-col>
    </v-row>

    <v-row v-if="!gridView && $vuetify.breakpoint.smAndUp">
      <v-col
        v-for="(item, index) in resources"
        :key="index"
        cols="12"
        class="py-0 my-3"
      >
        <ResourceCardList :item="item" />
      </v-col>
    </v-row>
  </div>

  <div
    v-else
    v-infinite-scroll="loadMoreMyResources"
    v-resize="loadMoreOnResize"
    :infinite-scroll-disabled="infiniteScrollBusy"
    infinite-scroll-distance="50"
    class="mb-15"
  >
    <v-row
      v-if="gridView || $vuetify.breakpoint.xsOnly"
      :class="{'ma-0': $vuetify.breakpoint.xsOnly}"
      align="center"
      justify="start"
    >
      <v-col
        v-for="(item, index) in resources"
        :key="index"
        cols="auto"
        :class="{'px-0': $vuetify.breakpoint.width < 620}"
      >
        <v-responsive
          v-if="$store.state.isLoading && !$store.state.userScroll"
          :min-width="cardSize"
          :min-height="cardSize"
        >
          <v-skeleton-loader
            boilerplate
            type="image, list-item-three-line, actions"
          />
        </v-responsive>
        <ResourceCard
          v-else
          :num="index"
          :item="item"
          :size="cardSize"
          :height="380"
          :rounded="$vuetify.breakpoint.width >= 620"
        />
      </v-col>
    </v-row>

    <v-row v-if="!gridView && $vuetify.breakpoint.smAndUp">
      <v-col
        cols="12"
        class="py-0 my-3"
      >
        <MyResourcesListView :resources="resources" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ResourceCard from '@/views/Help&About/TutorialsPage/ResourceCard';
import ResourceCardList from '@/views/HubResourcesPage/ResourceCardList';
import MyResourcesListView from '@/views/Help&About/TutorialsPage/MyResourcesListView';
import {SEARCH_CONTEXT} from '@/constants';

export default {
  name: 'ResourcesResults',
  components: {
    ResourceCard,
    ResourceCardList,
    MyResourcesListView
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    resources: {
      type: Array,
      default: () => []
    },
    gridView: {
      type: Boolean,
      default: true
    },
    cardSize: {
      type: Number,
      default: 350
    },
    isMyResources: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      infiniteScrollBusy: false,
      viewportHeight: null,
    }
  },
  computed: {
    searchContextIsHub() {
      return this.$store.state.searchContext === SEARCH_CONTEXT.HUB;
    },
  },
  methods: {
    loadMoreOnResize() {
      if (!this.viewportHeight) {
        this.viewportHeight = window.innerHeight;
      } else {
        if (this.viewportHeight < window.innerHeight) {
          this.loadMore();
          this.viewportHeight = window.innerHeight;
        }
      }
    },
    loadMore() {
      if (this.searchContextIsHub) {
        this.loadMoreMyResources();
      } else {
        this.loadMoreHubResources()
      }
    },
    async loadMoreMyResources() {
      const myResources = this.$store.state.myResources;
      this.infiniteScrollBusy = true;
      if (myResources.pageNum < myResources.searchResults.metadata.totalPages) {
        await this.$store.commit('myResources/SET_USER_SCROLL');
        await this.$store.commit('myResources/INCREASE_PAGE_NUM');
        await this.$store.dispatch('myResources/fetchResources');
      }
      this.infiniteScrollBusy = false;
    },
    async loadMoreHubResources() {
      const searchResults = this.$store.state.resources;
      if (searchResults.items.length < 16 ||
        this.$store.state.pageNum >= searchResults.metadata.totalPages ||
        !this.searchContextIsHub) {
        return false;
      }
      this.infiniteScrollBusy = true;
      this.$store.commit('SET_USER_SCROLL');
      this.$store.commit('SET_PAGE_NUM', this.$store.state.pageNum + 1);
      await this.$store.dispatch('fetchResources');
      this.infiniteScrollBusy = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-skeleton-loader {
  height: 100%;

  ::v-deep.v-skeleton-loader__image {
    height: 50% !important;
  }
}
</style>
