import { render, staticRenderFns } from "./UpdateStatusDialog.vue?vue&type=template&id=37989287&scoped=true&"
import script from "./UpdateStatusDialog.vue?vue&type=script&lang=js&"
export * from "./UpdateStatusDialog.vue?vue&type=script&lang=js&"
import style0 from "./UpdateStatusDialog.vue?vue&type=style&index=0&id=37989287&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37989287",
  null
  
)

export default component.exports