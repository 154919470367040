import {createApi} from 'unsplash-js';

const moduleUnsplash = {
  namespaced: true,
  state: {
    photos: [],
    total: 0,
    page: 1,
    perPage: 6,
  },
  getters: {
    photos: (state) => state.photos,
  },
  mutations: {
    SET_PHOTOS(state, photos) {
      state.photos = photos;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_PER_PAGE(state, perPage) {
      state.perPage = perPage;
    }
  },
  actions: {
    /**
     * unsplash api Access Key for DLRH:
       k2di8zU9ntaL6aKsg53Vp92fJBJCr9-LemdGsS7w1kI
     */
    getPhotos({state}, searchString) {
      if (!searchString) {
        searchString = 'wallpaper'
      }
      const unsplash = createApi({
        accessKey: 'k2di8zU9ntaL6aKsg53Vp92fJBJCr9-LemdGsS7w1kI',
      });
      return unsplash.search.getPhotos({
        query: searchString,
        page: state.page,
        perPage: state.perPage,
        orientation: 'portrait',
        // client_id: 'k2di8zU9ntaL6aKsg53Vp92fJBJCr9-LemdGsS7w1kI'
      });
    }
  }
}

export default moduleUnsplash;
