<template>
  <Dialog
    v-bind="$attrs"
    icon="mdi-account-multiple-plus-outline"
    title="Invite collaborators"
    :width="$vuetify.breakpoint.mdAndUp ? 950 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        class="mt-0 pb-0"
        :style="{'color': ADS_Colors.Dark}"
      >
        <span>{{ heading }}</span>
        <span v-if="subHeading"><strong>Note: </strong>{{ subHeading }} </span>
      </v-card-text>

      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="10"
            sm="8"
          >
            <SearchDoeStaff
              v-model="recipients"
              display-details
              counter="30"
              :notAllowed="[...editors, ...owners]"
              @getRule="handleRule"
              @getError="handleError"
            />
          </v-col>

          <v-col
            cols="12"
            md="2"
            sm="4"
            mb="2"
          >
            <AdsButton
              button-text="Send invite"
              :disabled="recipients.length === 0 || rule[0] !== true"
              @click="handleSendInvite('addaccess','editor')"
            />
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="mt-3 font-weight-bold header-container">
          <v-col
            cols="4"
            md="5"
            sm="4"
          >
            Users ({{ numUsers }})
          </v-col>
          <v-col
            cols="4"
            md="5"
            sm="4"
          >
            Permission level
          </v-col>
          <v-col
            cols="4"
            md="5"
            sm="4"
          />
        </v-row>
        <v-row class="scroll-container">
          <v-list-item
            v-if="owners"
          >
            <v-col
              cols="4"
              md="5"
              sm="4"
            >
              <span
                v-for="owner in owners"
                :key="owner"
              >
                {{ owner }}
              </span>
            </v-col>
            <v-col
              cols="6"
              md="5"
              sm="4"
            >
              Creator
            </v-col>
          </v-list-item>
          <template v-if="editors.length">
            <v-list-item
              v-for="(editor, i) in editors"
              :key="'editor_'+ i"
            >
              <v-col
                cols="4"
                md="5"
                sm="4"
              >
                <span>
                  {{ editor }}
                </span>
              </v-col>
              <v-col
                cols="6"
                md="5"
                sm="4"
              >
                Editor
              </v-col>
              <v-col
                cols="2"
                md="5"
                sm="4"
              >
                <v-icon @click="removeCollaborator(editor, 'removeaccess','editor')">
                  mdi-minus-circle-outline
                </v-icon>
              </v-col>
            </v-list-item>
          </template>

          <!-- <template v-if="viewers">
            <v-list-item
              v-for="(viewer,i) in viewers"
              :key="'viewer_'+ i"
            >
              <v-col
                cols="4"
                md="5"
                sm="4"
              >
                <span>
                  {{ viewer }}
                </span>
              </v-col>
              <v-col
                cols="6"
                md="5"
                sm="4"
              >
                Viewer
              </v-col>
              <v-col
                cols="2"
                md="5"
                sm="4"
              >
                <v-icon @click="removeCollaborator(viewer, 'removeaccess','viewer')">
                  mdi-minus-circle-outline
                </v-icon>
              </v-col>
            </v-list-item>
          </template> -->
        </v-row>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';
import SearchDoeStaff from '../SearchDoeStaff/SearchDoeStaff.vue'
import {mapState} from 'vuex';
import {apiBaseUrl} from '@/store/config';
export default {
  name: 'CollaboratorsDialog',
  components: {
    Dialog,
    SearchDoeStaff,
    AdsButton
  },
  props:{
    heading: {
      type: String,
      default: 'Who else can edit this resource card? You can update this at any time.'
    },
    subHeading: {
      type: String,
      default: 'Anyone invited to collaborate will have editor permissions. They will be able to edit the resource card.'
    },
  },
  data() {
    return {
      ADS_Colors,
      recipients: [],
      rule: [true],
      senderEmail: this.$OAuth.profile.email,
      senderName: this.$OAuth.profile.givenName + ' ' + this.$OAuth.data.profile.sn,
      emailBody: '',
      error: '',
    };
  },
  methods: {
    handleRule(val) {
      this.rule = val;
    },
    handleError(val) {
      this.error = val;
    },
    async sendEmail(message) {
      this.emailBody = (
        `<p>This resource was sent from the <a href="https://resources.education.nsw.gov.au/">DLRH</a> by: ${this.senderName} (${this.senderEmail})</p><p>Resource card: <a href="${this.resourceUrl}">${this.resourceName}</a></p>`
      );
      //compose email header and body
      const data = {
        to: this.recipients,
        from: this.senderEmail,
        subject: `${this.senderName} ${message}`,
        body: this.emailBody,
      };
      try {
        await this.$store.dispatch('sendResourceByEmail', data);
        this.recipients = [];
        //this.$downwardBroadcast('close:dialog');
      } catch (e) {
        console.error(e);
        this.error = 'There was an issue with your action. Try again or contact support.';
      }
    },
    async removeCollaborator(collaboratorId, action = 'removeaccess', accessScope = null) {
      const payload = {
        action,
        resourceName: this.resourceName,
        userAccess: {
          accessFor: collaboratorId,
          accessScope
        }
      };
      await this.$store.dispatch('myResources/shareThisResource', payload);
      await this.$store.dispatch('myResources/fetchResourceDetails', {id: this.resourceDetails.record?.id});
    },
    async handleSendInvite(action = 'addaccess', accessScope = null) {
      /**
       * dispatch action for sharethiscard endpoint.
       */

      // get user email and access scope
      let userAccess = this.recipients.map(item => {
          let userId = item.split('@')[0].toLowerCase();
          return {accessFor: userId, accessScope}
        });

      /**
       * since the alert is variable depending on dialog, we will compose alert message here and send it as a payload
       */
      let alert;
      if( action === 'addaccess'){
        alert= {
          type: 'success',
          text: 'Invitation has been sent'
        }
      }
      // prepare payload
      let payload = {
        action,
        alert,
        resourceName: this.resourceName,
        userAccess
      };

      await this.$store.dispatch('myResources/shareThisResource', payload);
      this.sendEmail('shared a resource with you as editor');
      await this.$store.dispatch('myResources/fetchResourceDetails', {id: this.resourceDetails.record?.id});
      //this updates the activitiy under inforfation drawer
      await this.$store.dispatch('myResources/getResourceNotifications', this.resourceDetails.record?.id);
    }
  },
  computed: {
    ...mapState({
      hubResourceDetails: state => state.itemDetail,
      resourceDetails: state => state.myResources.resourceDetails
    }),
    editors() {
      return this.resourceDetails?.userAccess?.editor ? this.resourceDetails.userAccess.editor : [];
    },
    owners() {
      return this.resourceDetails?.userAccess?.owner ? this.resourceDetails.userAccess.owner : [];
    },
    // viewers() {
    //   return this.resourceDetails?.userAccess.viewer ? this.resourceDetails?.userAccess.viewer : [];
    // },
    numUsers() {
      let usersLength = this.editors?.length + this.owners?.length  /*+ this.viewers?.length */;
      return usersLength ? usersLength : 0;
    },
    users(){
      return this.resourceDetails.userAccess
    },
    resourceName(){
      return (this.resourceDetails?.document.Name || this.hubResourceDetails?.Name || '' )
    },
    resourceUrl(){
      let url = apiBaseUrl.replace('/api/v1', this.$route.path)
      return (url || '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.v-dialog{
  overflow: hidden;
}
.v-list-item {
  padding: 0 !important;
}

.row {
  width: 100%;
  overflow: hidden;

  &.header-container{
    padding: 10px 10px 0 10px;
  }

  &.scroll-container {
    display: block;
    margin: 0 auto 20px auto;
    min-height: 200px;
    max-height: 200px;
    overflow-y: auto;
    /* Works on Firefox */
    & {
      scrollbar-width: thin!important;
      scrollbar-color: $ads-light-40!important;
    }

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $ads-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $ads-light-40;
      border-radius: 20px;
    }
  }
}

.v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
}

button {
  @media only screen and (max-width: 600px) {
    &.v-btn {
      display: block !important;
    }
  }
}
</style>
