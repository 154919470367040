<template>
  <div class="d-flex flex-column custom-checkbox-label">
    <h2 class="code">
      {{ item.name }}
    </h2>
    <p
      v-if="item.description && !open"
      class="description"
    >
      {{ item.description }}
    </p>
    <ShowMore
      v-if="item.description && open"
      :text="item.description"
      :max-chars="$vuetify.breakpoint.xsOnly ? 70 : 196"
    />
  </div>
</template>
<script>
import ShowMore from '@/components/ShowMore'

export default {
  name: 'ProgressionLabel',
  components: {
    ShowMore,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {

    }
  },
  computed:{
    progressionVersion(){
      return this.item
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../../../../../../../scss/variables";

.custom-checkbox-label {
  .code {
    font-size: 16px;
    font-weight: normal;
  }

  .description {
    text-wrap: normal;
    font-size: 16px;
    color: $ads-dark-70;
    margin-bottom: 0;
    line-height: 24px;
    height: 46px;
    display: -webkit-box;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  ::v-deep.startParagraph, ::v-deep.newContent {
    p {
      color: $ads-dark-70 !important;
      line-height: 23px !important;
    }
  }
}
</style>
