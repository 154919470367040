<template>
  <div class="add-image-dialog__content">
    <v-card-actions>
      <v-row
        v-if="$store.getters['metadata/resourceImages']"
        ref="images-container"
        class="images__container"
      >
        <v-col
          v-for="(photo, index) in $store.getters['metadata/resourceImages'].resourceImageUrl"
          :key="'photo_' + photo.id + index"
          class="pa-2"
          :cols="colSize.xs"
          :lg="colSize.lg"
          :md="colSize.md"
          :sm="colSize.sm"
        >
          <v-btn
            v-if="!loading"
            :ripple="false"
            class="image__wrapper"
            :aria-label="`${photo.description} - ${selectedPhoto && selectedPhoto.id === photo.id ? 'selected' : 'not selected'}`"
            @click="selectImage(photo)"
            @keyup.enter="selectImage(photo)"
          >
            <v-img
              :aspect-ratio="1.85"
              width="100%"
              :src="photo.urls.full"
            >
              <v-icon
                v-if="selectedPhoto && selectedPhoto.id === photo.id"
                class="selected-photo--icon"
              >
                check
              </v-icon>
            </v-img>
          </v-btn>
          <v-responsive
            v-else
            :aspect-ratio="1.85"
          >
            <v-skeleton-loader
              type="image"
            />
          </v-responsive>
        </v-col>
      </v-row>
      <span v-else>No results.</span>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: 'UnsplashDialogContent',
  data() {
    return {
      loading: false,
      photosPerPage: [6, 12],
      perPage: 12,
      unsplashQuery: '',
      selectedPhoto: '',
      colSize: {
        lg: '4',
        md: '4',
        sm: '6',
        xs: '12'
      }
    }
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.commit('metadata/SET_PER_PAGE', val);
          switch (val) {
            case 6:
              this.colSize = {
                lg: '4',
                md: '4',
                sm: '6',
                xs: '12'
              }
              break;
            case 12:
              this.colSize = {
                lg: '3',
                md: '3',
                sm: '4',
                xs: '12'
              }
              break;
          }
          this.$store.dispatch('metadata/fetchResourceImages')
        }
      }
    }
  },
  methods: {
    selectImage(photo) {
      this.selectedPhoto = photo;
/*      const resourceImage = {
        author: 'Departmnet of Education',
        altDescription: this.selectedPhoto.description,
        resourceImage: this.selectedPhoto.urls.full,
        thumbnailUrl: this.selectedPhoto.urls.full
      }*/
      this.$listeners.input(this.selectedPhoto.urls.full);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.add-image-dialog__content {
  padding: 0 40px 24px 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-height: 600px;
  min-height: 500px;
  overflow-y: hidden;

  .heading {
    color: $ads-dark;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 8px 0;

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .sub-title {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .images__container {
    padding: 25px 0 0;
    margin: 0 -8px;
    overflow-y: scroll;
    max-height: 500px;

    /* Works on Firefox */
    & {
      scrollbar-width: thin !important;
      scrollbar-color: $ads-light-40 !important;
    }

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $ads-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $ads-light-40;
      border-radius: 20px;
    }

    .image__wrapper {
      background: transparent;
      width: 100%;
      height: fit-content;
      padding: 0;

      &:focus {
        outline: none !important;
      }

      &:focus-visible {
        outline: 3px solid $ads-navy !important;
      }

      .v-image {
        border-radius: 4px;

        .v-responsive__content {
          position: relative;

          .selected-photo--icon {
            position: absolute;
            bottom: 6px;
            right: 6px;
            padding: 6px;
            background-color: $ads-white;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .images__footer {
    margin-bottom: 12px;
    max-height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .pagination-count {
      span {
        width: 140px;
        margin-right: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .per-page__label {
      padding-right: 12px;
    }

    .v-select {
      max-width: 50px;
      padding-top: 0;
      padding-bottom: 4px;
    }

    ::v-deep.v-text-field > {
      .v-input__control > {

        .v-input__slot {
          &:before {
            border-color: transparent;
          }
        }
      }
    }

    .v-input {
      input {
        max-height: 20px;
      }
    }
  }
}

.v-skeleton-loader {
  height: 100%;
}

@media only screen and (max-width: 700px) {
  .add-image-dialog__content {
    padding: 0 12px;

    .images__footer {
      font-size: 12px;

      .per-page__label {
        padding-top: 4px;
      }

      .pagination-count {
        span {
          width: 100px;
        }
      }

      .v-select {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .add-image-dialog__content {
    //height: 100% !important;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    position: relative;

    .heading {
      padding: 0 0 12px 0;

      .title {
        font-size: 20px;
      }

      .sub-title {
        font-size: 14px;
      }
    }

    .images__container {
      max-height: calc(100vh - 257px);
      padding-bottom: 130px;
    }

    .actions {
      background-color: $ads-white;
      width: 100%;
      padding: 12px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .images__footer {
      width: 100%;
      font-size: 14px;

      .pagination-count {
        span {
          width: 100px;
        }
      }

      .v-select {
        font-size: 14px;
      }
    }

    .search-button {
      min-width: 40px !important;
      max-width: 40px !important;

      ::v-deep.v-btn__content {
        .buttonText {
          display: none !important;
        }
      }
    }
  }
}
</style>
