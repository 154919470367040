<template>
  <v-treeview
    selected-color="#041E42"
    :items="items"
    open-on-click
    @input="handleChange"
    @click.stop.native
  >
    <template #prepend="{item, open, leaf}">
      <v-checkbox
        :indeterminate="!isAllSelected(item) && !isAllUnselected(item)"
        :input-value="isAllSelected(item)"
        class="treeview-checkbox"
        dense
        hide-details
        :aria-label="item.name"
        @click="selectItem(item, open, $event)"
      />
    </template>
    <template
      v-if="customArrow"
      #append="{ item, leaf, open, active }"
    >
      <v-btn
        v-if="!leaf"
        icon
        small
        :aria-label="`Expand ${item.name} menu`"
        :aria-expanded="open ? 'true' : 'false'"
      >
        <v-icon>
          {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </template>
  </v-treeview>
</template>
<script>
export default {
  name: 'Treeview',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    customArrow: {
      type: Boolean,
      default: true,
    }
  },
  data: function () {
    return {
      valueInternal: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.valueInternal = val;
      }
    },
    valueInternal: function (val) {
      this.$emit('input', val);
    }
  },
  methods: {
    handleChange: function (val) {
      this.valueInternal = val;
    },
    selectItem(item, open, e) {
      if (this.isAllSelected(item)) {
        this.unselectAll(item);
      } else {
        this.selectAll(item);
      }
      if (open) {
        e.stopPropagation();
      }
    },
    selectAll(item) {
      if (item.children) {
        item.children.forEach(c => this.selectAll(c));
      } else {
        if (!this.valueInternal.includes(item.id)) {
          this.valueInternal.push(item.id);
        }
      }
    },
    unselectAll(item) {
      if (item.children) {
        item.children.forEach(c => this.unselectAll(c));
      } else {
        if (this.valueInternal.includes(item.id)) {
          this.valueInternal = this.valueInternal.filter(i => i !== item.id);
        }
      }
    },
    isAllSelected(item) {
      if (item.children) {
        return item.children.every(c => this.isAllSelected(c));
      } else {
        return this.valueInternal.includes(item.id);
      }
    },
    isAllUnselected(item) {
      if (item.children) {
        return item.children.every(c => this.isAllUnselected(c));
      } else {
        return !this.valueInternal.includes(item.id);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/scss/variables";

.v-treeview {
  width: 100%;
  padding-bottom: 12px;

  .v-btn {
    &:focus {
      outline: none !important;
      border: 2px solid $ads-navy;
      border-radius: 50%;
    }
  }

  ::v-deep.v-input--selection-controls {
    &.v-input--is-focused {
      .v-input--selection-controls__ripple {
        &:before {
          border: 2px solid $ads-navy;
          background-color: rgba(0, 0, 0, 0.2);
          opacity: 1;
        }
      }
    }
  }

  .treeview-checkbox {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  ::v-deep.v-treeview-node__root {
    min-height: 42px;

    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }

    .v-treeview-node__label{
      display: block;
      width: 100%;
      text-overflow: clip;
      font-size: 16px;
    }

    .mdi-checkbox-marked, .mdi-minus-box {
      color: $ads-navy !important;
    }

    .v-treeview-node__toggle {
      display: none;
    }

    .v-treeview-node__level {
      width: 0;
    }

    .v-treeview-node__content {
      .v-treeview-node__prepend {
        margin-bottom: 6px;
      }
    }
  }

  ::v-deep.v-treeview-node {
    &.v-treeview-node--click {

      ::v-deep.v-treeview-node {
        &.v-treeview-node--click {

          .v-treeview-node__root {
            margin-left: 12px;
          }
        }
      }
    }
  }

  ::v-deep.v-treeview-node__children {
    .v-treeview-node {
      .v-treeview-node__level {
        width: 12px;
      }

      &.v-treeview-node--click {
        &:not(.v-treeview-node--leaf) {
          margin-left: 12px !important;
        }
      }
    }
  }

  // add focus state in treeview checkboxes
  ::v-deep.v-icon {
    &.v-icon {
      font-size: 20px;
      height: 24px;

      &:focus {
        &:before {
          border: 2px solid $ads-navy !important;
          border-radius: 50% !important;
          padding: 4px !important;
        }
      }
    }
  }
}
</style>
