<template>
  <Dialog
    v-bind="$attrs"
    icon="delete_outline"
    title="Confirm deletion"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="new-collection-content">
        <v-card-text
          class="mt-3 pb-0 data-content"
          :style="{'color': ADS_Colors.Dark}"
        >
          <p class="mb-2">
            Are you sure you want to delete this collection card <strong>{{ collectionName }}</strong>?
          </p>
          <p class="mb-1">
            You will not be able to undo this action.
          </p>
          <p>
            <strong>Note:</strong> If you are the creator, this action will delete the collection for every user that
            it was shared with.
          </p>
        </v-card-text>
        <v-divider v-if="!$vuetify.breakpoint.smAndUp" />
        <v-card-actions :class="{'d-flex justify-space-between px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <AdsButton
            button-text="Cancel"
            @click="$downwardBroadcast('close:dialog')"
            @keyup.enter="$downwardBroadcast('close:dialog')"
          />
          <AdsButton
            :loading="loading"
            :color="ADS_Colors.Red_Dark"
            secondary
            button-text="Delete"
            @click="handleDelete"
            @keyup.enter="handleDelete"
          />
        </v-card-actions>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'DeleteCollectionDialog',
  components: {
    Dialog,
    AdsButton
  },
  props: {
    collectionId: {
      type: String,
      default: ''
    },
    collectionName: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
      loading: false
    }
  },
  methods: {
    handleDelete: async function () {
      this.$emit('confirmDeletion', this.resource)
      this.$downwardBroadcast('close:dialog');
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/mixins";

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 80vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }
}
</style>
