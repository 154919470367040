<template>
  <div
    class="carousel-item__wrapper"
    aria-live="polite"
  >
    <v-row class="ma-0">
      <v-col
        cols="12"
        :class="{'px-6': $vuetify.breakpoint.width >= 740, 'px-3': $vuetify.breakpoint.width < 740 }"
      >
        <h1
          v-if="$vuetify.breakpoint.width >= 740"
          class="mb-3"
        >
          Teaching and learning
        </h1>
        <p :class="{'body-1': $vuetify.breakpoint.width >= 740, 'body-2': $vuetify.breakpoint.width < 740}">
          {{ subtitle }}
        </p>
      </v-col>
    </v-row>
    <v-row class="ma-0 preferences__container">
      <v-col
        cols="12"
        lg="6"
        md="6"
        sm="6"
        :class="{'pl-6 pr-0 py-0': $vuetify.breakpoint.width >= 740, 'px-3': $vuetify.breakpoint.width < 740}"
      >
        <PreferencesCheckboxGroup
          v-if="$vuetify.breakpoint.width >= 740 || carousel === 2"
          v-model="learningAreas"
          :heading="$vuetify.breakpoint.width >= 740 ? 'Learning area' : null"
          parent-checkbox-label="All"
          :items="$store.getters['metadata/klas']"
        />
        <PreferencesCheckboxGroup
          v-else
          v-model="years"
          parent-checkbox-label="All"
          treeview
          :items="$store.getters['metadata/stagesAndYears']"
        />
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.width >= 740"
        class="pa-0 stages-preferences"
        cols="12"
        lg="6"
        md="6"
        sm="6"
      >
        <PreferencesCheckboxGroup
          v-model="years"
          heading="Stage"
          parent-checkbox-label="All"
          treeview
          :items="$store.getters['metadata/stagesAndYears']"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PreferencesCheckboxGroup from '@/components/Dialogs/Preferences/PreferencesCheckboxGroup';
import {mapGetters} from 'vuex';

export default {
  name: 'TeachingAndLearning',
  components: {PreferencesCheckboxGroup},
  props: {
    carousel: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      preferences: 'users/preferences',
    }),
    years: {
      get() {
        return this.$attrs.value.years;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input({
          years: value,
          learningAreas: this.learningAreas,
        });
      }
    },
    learningAreas: {
      get() {
        return this.$attrs.value.learningAreas;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input({
          years: this.years,
          learningAreas: value,
        });
      }
    },
    subtitle() {
      if (this.$vuetify.breakpoint.width >= 740) {
        return 'Choose your learning area(s) and teaching stage(s).';
      } else if (this.carousel === 2) {
        return 'Choose your learning area(s)'
      } else {
        return 'Choose your teaching stage(s)';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-item__wrapper {
  height: 100%;

  .preferences__container {
    height: 100%;

    .stages-preferences {
      height: calc(100% - 124px);
      overflow: auto;
    }
  }
}

@media only screen and (max-width: 740px) {
  .carousel-item__wrapper {
    overflow: auto;
    height: calc(100vh - 182px);
    margin: 56px 0 126px 0;
  }
}
</style>
