<template>
  <v-menu
    offset-y
    :nudge-bottom="10"
    transition="slide-y-transition"
    eager
    v-bind="$attrs"
    rounded="4"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        large
        text
        v-bind="attrs"
        aria-label="User Account"
        class="profile--menu"
        v-on="on"
      >
        <InitialsIcon
          size="40px"
          :given-name="givenName"
          :surname="surname"
          class="initial--icon"
        />
      </v-btn>
    </template>

    <v-list :aria-hidden="$store.getters.dialogReportIssue || $store.getters.dialogCopyLink || $store.getters.dialogEmbedCode || $store.getters.dialogEmail">
      <v-list-item
        class="px-6 py-3"
        tabindex="0"
        aria-describedby="profileInfo-description"
        @click="suppressClick"
      >
        <InitialsIcon
          size="40px"
          :given-name="givenName"
          :surname="surname"
          aria-hidden
          tabindex="-1"
        />

        <div class="ml-4">
          <v-list-item-subtitle class="profile-title-font profile--name font-weight-bold">
            {{ givenName || "Unknown" }} {{ surname || "User" }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="profile-subtitle-font">
            {{ jobTitle || "None" }}
          </v-list-item-subtitle>
        </div>
        <div
          id="profileInfo-description"
          class="visuallyhidden"
        >
          User Profile information
        </div>
      </v-list-item>

      <v-divider v-if="updateProfile || changePassword || updateSecretQuestion || portalSettings" />

      <v-list-item
        v-if="updateProfile"
        class="pl-8"
        @click="updateProfileHandler"
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil-outline</v-icon>
        </v-list-item-icon>
        <div>
          <v-list-item-title class="profile-title-font">
            Update profile
          </v-list-item-title>
        </div>
      </v-list-item>

      <v-list-item
        v-if="changePassword"
        class="pl-8"
        @click="changePasswordHandler"
      >
        <v-list-item-icon>
          <v-icon>mdi-lock-outline</v-icon>
        </v-list-item-icon>
        <div>
          <v-list-item-title class="profile-title-font">
            Change password
          </v-list-item-title>
        </div>
      </v-list-item>

      <v-list-item
        v-if="updateSecretQuestion"
        class="pl-8"
        @click="updateSecretQuestionHandler"
      >
        <v-list-item-icon>
          <v-icon>mdi-security</v-icon>
        </v-list-item-icon>
        <div>
          <v-list-item-title class="profile-title-font">
            Update secret questions
          </v-list-item-title>
        </div>
      </v-list-item>

      <v-list-item
        v-if="portalSettings"
        class="pl-8"
        @click="portalSettingsHandler"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <div>
          <v-list-item-title class="profile-title-font">
            Portal settings
          </v-list-item-title>
        </div>
      </v-list-item>

      <v-divider v-if="logoutHandler" />

      <v-list-item
        v-if="logoutHandler"
        class="pl-8 logout--class"
        @click="logoutHandler"
      >
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <div>
          <v-list-item-title class="profile-title-font">
            Log out
          </v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {InitialsIcon} from '@nswdoe/doe-ui-core';

export default {
  name: 'Profile',
  components: {
    InitialsIcon
  },
  props: {
    givenName: {
      type: String,
      default: '',
    },
    surname: {
      type: String,
      default: '',
    },
    jobTitle: {
      type: String,
      default: '',
    },
    updateProfile: {
      type: Boolean,
      default: true,
    },
    changePassword: {
      type: Boolean,
      default: true,
    },
    updateSecretQuestion: {
      type: Boolean,
      default: true,
    },
    portalSettings: {
      type: Boolean,
      default: true,
    },
    logout: {
      type: Boolean,
      default: true,
    },
    updateProfileHandler: {
      type: Function,
      default: () => {
      },
    },
    changePasswordHandler: {
      type: Function,
      default: () => {
      },
    },
    updateSecretQuestionHandler: {
      type: Function,
      default: () => {
      },
    },
    portalSettingsHandler: {
      type: Function,
      default: () => {
      },
    },
    logoutHandler: {
      type: Function,
      default: () => {
      },
    },
  },
  methods: {
    suppressClick(e) {
      e.stopPropagation();
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.profile-title-font {
  color: #041E42 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.profile-subtitle-font {
  color: #666666 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

/*force width to avoid jank in IE11*/
.v-list-item__icon .v-icon {
  width: 24px;
  color: #041E42;
}

.theme--dark {
  .profile-title-font:not(.profile--name), .profile-subtitle-font {
    color: $ads-light-10 !important;
  }

  .v-list-item__icon .v-icon {
    color: $ads-navy-dark;
  }

  .profile--name {
    color: $ads-navy-dark !important;
  }

  .logout--class {
    .v-list-item__icon .v-icon, .profile-title-font {
      color: $ads-red-2-dark !important;
    }
  }
}

.profile--menu {
  &:focus {
    outline: 2px solid #FFFFFF !important;
  }
}
</style>
