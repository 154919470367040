<template>
  <div class="content">
    <v-form>
      <AddNewImage v-model="document.ResourceImage" />

      <div class="mb-5">
        All fields marked with * are required
      </div>
      <UploadFileOrLink
        :value="fileOrLink"
        @fileOrLink="getFileOrLink"
        @exceedFileSize="handleExceedFileSize"
      />
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="document.ResourceType"
            class="resource-type__selector"
            outlined
            aria-label="Resource type"
            aria-required="true"
            item-text="name"
            item-value="id"
            label="Resource type*"
            :items="$store.getters['metadata/myResourcesTypes']"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="document.Format"
            class="resource-type__selector"
            outlined
            label="Resource format*"
            aria-label="Resource format"
            aria-required="true"
            item-text="name"
            item-value="id"
            :items="$store.getters['metadata/formats']"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <AdsTextField
            v-model="document.Name"
            label="Resource title*"
            aria-label="Resource title"
            aria-required="true"
            counter="200"
            maxlength="200"
            :rules="[rules.specialChars]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <v-combobox
            id="stages-field"
            v-model="stages"
            aria-label="stages"
            aria-required="true"
            label="Stages*"
            multiple
            outlined
            chips
            deletable-chips
            :items="allStages"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="4"
          class="autocomplete__wrapper"
        >
          <v-combobox
            id="years-field"
            v-model="years"
            :items="scholasticYears"
            multiple
            outlined
            chips
            deletable-chips
            label="Years*"
            placeholder="Years"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="4"
          class="autocomplete__wrapper"
        >
          <v-combobox
            id="kla-field"
            v-model="kla"
            :items="klas"
            item-value="id"
            multiple
            outlined
            chips
            deletable-chips
            label="Learning areas*"
            placeholder="Learning areas"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <AdsTextArea
            id="description"
            v-model="document.Description"
            label="Description"
            rows="4"
            row-height="20"
            :counter="false"
          />
        </v-col>
      </v-row>
      <AdsExpansionPanel
        v-model="panel"
        :items="expansionPanelItems"
        multiple
      >
        <template #contentOverview>
          <v-row :class="{'mt-6 mx-6': $vuetify.breakpoint.lgAndUp, 'mt-6 mx-3': $vuetify.breakpoint.mdAndDown}">
            <v-col>
              <v-row>
                <v-col
                  cols="12"
                  class="px-0"
                >
                  <Autocomplete
                    v-model="document.Topic"
                    label="Topics"
                    :items="$store.getters['metadata/randntopics'].map(el => el.name)"
                  />
                </v-col>
              </v-row>
              <Curriculum
                :syllabus="syllabus"
                :outcomes="outcomes"
                :literacyV3="document.LitLearnProgLevelV3"
                :numeracyV3="document.NumLearnProgLevelV3"
                :literacyV2="document.LitLearnProgLevel"
                :numeracyV2="document.NumLearnProgLevel"
                :curriculum-input="curriculumInput"
                @curriculumSelection="handleCurriculumSelections"
                @confirmProgressions="handleProgressions"
              />
              <RemoveOrRetainProgressionsDialog />
              <v-row>
                <v-col
                  cols="12"
                  class="px-0"
                >
                  <AdsTextField
                    v-model="professionalLearningLink"
                    label="Professional learning"
                    placeholder="Paste link to any relevant PL"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="px-0"
                >
                  <AdsTextField
                    v-model="document.Publisher"
                    label="Publisher"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="px-0"
                >
                  <AdsTextField
                    v-model="document.Author"
                    label="Author"
                  />
                </v-col>
              </v-row>
              <CopyrightSelector
                :statement="document.Rights"
                :copyrightTitle="copyrightTitle"
                :copyrightRadioGroupLabel="copyrightRadioGroupLabel"
                :copyrightBody="copyrightBody"
                :copyrightItems="copyrightItems"
                @change="handleCopyright"
              />
              <v-row>
                <v-col
                  cols="12"
                  class="px-0"
                >
                  <Autocomplete
                    v-model="document.Keywords"
                    allow-user-input
                    label="keywords"
                    persistent-hint
                    hint="Use comma or enter to add new keyword"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </AdsExpansionPanel>
    </v-form>
  </div>
</template>

<script>
import {AdsExpansionPanel, AdsTextArea, AdsTextField} from '@nswdoe/doe-ui-core';
import {CHECKBOX_YEARS, MY_RESOURCES_RESOURCE_DETAILS_STRUCTURE} from '@/constants';
import AddNewImage from '@/views/MyResourceDetailsPage/Edit/AddNewImage';
import UploadFileOrLink from '@/views/MyResourceDetailsPage/Edit/UploadFileOrLink';
import Autocomplete from '@/views/MyResourceDetailsPage/Edit/Autocomplete';
import Curriculum from '@/views/ResourceEditorDetailsPage/Edit/Curriculum';
import CopyrightSelector from '@/views/MyResourceDetailsPage/Edit/CopyrightSelector';

import RemoveOrRetainProgressionsDialog from '@/views/MyResourceDetailsPage/Edit/Curriculum/RemoveOrRetainProgressionsDialog';
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Edit',
  components: {
    CopyrightSelector,
    Autocomplete,
    UploadFileOrLink,
    AddNewImage,
    AdsTextField,
    AdsTextArea,
    AdsExpansionPanel,
    Curriculum,
    RemoveOrRetainProgressionsDialog
  },
  props: {
    isNewResource: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // years/stages options
      // stages: CHECKBOX_YEARS,
      // upload file or resource url, used in <UploadFileOrLink>
      file: null,
      fileName: '',
      link: '',
      // match resourceDetails.document object, commit to Vuex when toggling editMode to false to show Preview
      document: {
        ResourceImage: {
          author: null,
          altDescription: null,
          regularUrl: null,
          thumbnailUrl: null
        },
        ResourceType: '',
        ResourceURL: '',
        Format: '',
        Name: '', // resource title
        Stage: [],
        Year: [],
        KLA: [],
        Description: '',
        Topic: [],
        NSWSyllabus: [],
        NumLearnProgLevel: [],
        LitLearnProgLevel: [],
        PLLinks: [],
        Publisher: '',
        Author: '',
        Rights: '', //copyright
        Keywords: [],
        Source: 'myresource',
        draft: null
      },
      rules: {
        specialChars: value => {
          const pattern = /^[a-zA-Z0-9_/+=&' .-]*$/
          const testResult = pattern.test(value)
          if (testResult) {
            this.containSpecialChars = false;
            return testResult;
          } else {
            this.containSpecialChars = true;
            return 'Card title cannot include the special characters used.';
          }
        },
      },
      containSpecialChars: false,
      exceedFileSize: false,
      expansionPanelItems: [
        {
          id: 'Overview',
          title: '<span role="heading" aria-level="3">Overview (optional)</span>',
        }
      ],
      panel: [0],
      copyrightTitle: 'Copyright statement',
      copyrightRadioGroupLabel: 'Please select from the following statements:',
      copyrightBody: `The attached resource must not infringe copyright laws. Users must identify any third-party copyright material embedded in their resource before uploading.

The Department retains copyright in all resources created by its staff. All resources wholly or partially created by staff must be marked “© 202X NSW Department of Education: No reproduction permitted without express permission.”
      `,
      copyrightItems: [{
          'id': 0,
          'text': 'This resource was wholly created by myself or other departmental staff.',
          'value': '© State of New South Wales (Department of Education).'
        },
        {
          'id': 1,
          'text': 'I am not sure of the copyright status of the resource or its elements.',
          'value': 'Rights not known.'
        },
        {
          'id': 2,
          'text': 'There are elements contained in this resource which were created by, or whose copyright is owned by, third parties but are available for departmental use due to:',
          'value': 'Other',
          'children': [
            {
              'text': 'The elements are only accessed via linking to an external website.',
              'value': 'The elements are only accessed via linking to an external website.'
            },
            {
              'text': 'This resource was wholly created by myself or other departmental staff.',
              'value': 'This resource was wholly created by myself or other departmental staff.'
            },
            {
              'text': 'The elements do not exceed the limits imposed by the Department’s Education Copyright Licence.',
              'value': 'The elements do not exceed the limits imposed by the Department’s Education Copyright Licence.'
            },
            {
              'text': 'Creative Commons or other Open Education Resources.',
              'value': 'Creative Commons or other Open Education Resources.'
            },
            {
              'text': 'Express permission has been obtained from the author/owner.',
              'value': 'Express permission has been obtained from the author/owner.'
            },
            {
              'text': 'Permission has been obtained from the traditional owners in accordance with the principles of Indigenous Cultural Intellectual Property.',
              'value': 'Permission has been obtained from the traditional owners in accordance with the principles of Indigenous Cultural Intellectual Property.'
            },
            {
              'text': 'Public Domain.',
              'value': 'Public Domain.'
            }
          ]
        }
      ],
    }
  },
  /*
    Example resourceDetails.document object:
    document: {
      author: "test"
      creator: {creatorId: "kiran.test", name: "Kiran-Test Test"}
      description: "1111111"
      keywords: ["test"]
      learningArea: ["Mathematics"]
      name: "Copy of test"
      outcomes: [,…]
      professionalLearningLink: "test"
      progressions: [{level: "UnT1", subelement: "Understanding texts",…}]
      publisher: "Kiran"
      resourceFormat: "Document"
      resourceImage: {author: "Pawel Czerwinski", altDescription: "red and blue wallpaper",…}
      resourceType: "School planning resource"
      rights: {option: 0, subOption: -1, statement: "© State of New South Wales (Department of Education)."}
      source: "myresource"
      stage: ["Stage 5"]
      syllabus: ["Mathematics K-10"]
      topics: ["Geometry"]
      year: ["Year 9", "Year 10"]
    }
 */
  mounted() {
    if (this.editMode && !this.isNewResource) {
      this.document = this.resourceDetails?.document;
      this.link = this.resourceDetails.document?.ResourceURL;
      this.fileName = this.resourceDetails?.files[0]?.FileName;
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.myResources.resourceDetails,
      editMode: state => state.myResources.editMode,
    }),
    ...mapGetters({
      stagesAndYears: 'metadata/mappedStagesAndYears'
    }),
    professionalLearningLink: {
      get() {
        return this.document.PLLinks[0]?.url || ''
      },
      set(val) {
        this.document.PLLinks[0].url = val
      }
    },
    // computed object for all inputs on Edit form, partly match resourceDetails for API needed fields
    formInputs() {
      return {
        document: this.document,
        link: this.link,
        file: this.file,
        fileName: this.fileName,
        rules: this.groupedRules
      };
    },
    fileOrLink() {
      return {
        file: this.fileName,
        link: this.link
      }
    },
    syllabus() {
      return this.document?.NSWSyllabus?.map(el => el.name) || []
    },
    outcomes() {
      return this.document?.NSWSyllabus?.reduce(function (outcomes, syllabus) {
        return [...outcomes, ...syllabus.outcomes]
      }, [])
    },
    curriculumInput() {
      // unfortunately nSyllabus requires Stage values not the ids so lets use the local stages form field
      const stageValues = this.stages.map(s => s.text)
      return {
        kla: this.document.KLA,
        stage: stageValues,
      }
    },
    groupedRules() {
      return [this.containSpecialChars, this.exceedFileSize];
    },
    kla: {
      get() {
        return this.document.KLA?.map(y => {
          return {
            text: this.klas.find(stage => stage.value === y).text,
            value: y
          }
        }) || []
      },
      set(value) {
        this.document.KLA = value.map(kla => kla.value);
      }
    },
    stages: {
      get() {
        return this.document.Stage?.map(y => {
          return {
            text: this.allStages.find(stage => stage.value === y).text,
            value: y
          }
        }) || []
      },
      set(value) {
        this.document.Stage = value.map(stage => stage.value);
      }
    },
    years: {
      get() {
        return this.document.Year?.map(y => {
          return {
            text: this.scholasticYears.find(sy => sy.value === y).text,
            value: y
          }
        }) || []
      },
      set(value) {
        this.document.Year = value.map(y => y.value).sort(function (a, b) {
          return a - b
        });
      },
    },
    klas() {
      return this.$store.getters['metadata/klas']?.map(y => {
        return {
          value: y.id,
          text: y.name
        }
      }) || []
    },
    scholasticYears() {
      return this.$store.getters['metadata/years']?.map(y => {
        return {
          value: y.shortName,
          text: y.name
        }
      }) || []
    },
    allStages() {
      return this.$store.getters['metadata/stages'].map(s => {
        return {
          value: s.name === 'Early Stage 1' ? 'ES1' : s.name.replace('Stage ', ''),
          text: s.name
        }
      })
    },
  },
  watch: {
    link(val) {
      this.document.ResourceURL = val;
    },
    'document.Year': {
      immediate: true,
      handler(val) {
        // when element is active or Stage is not defined (to support old resources) need to re calculate stages
        if (document.activeElement && document.activeElement.id === 'years-field' || (val && !this.document.Stage)) {

          const stages = val ? this.stagesAndYears.filter(sy => {
            return val.includes(sy.year)
          }).map(s => s.stage) : []

          // just removing duplicates
          this.document.Stage = [...new Set(stages)];
        }
      }
    },
    'document.Stage': {
      handler(selectedStages) {
        if (document.activeElement && document.activeElement.id === 'stages-field') {

          const years = selectedStages ? this.stagesAndYears.filter(sy => {
            return selectedStages.includes(sy.stage)
          }).map(s => s.year) : []

          this.document.Year = years;
        }
      }
    },
    'document.ResourceImage': {
      immediate: true,
      handler(val) {
        if (val) {
          this.document.ResourceImage = val
          this.document.ThumbnailImage = val.thumbnailUrl
        }
      }
    },
    editMode: {
      handler(newVal) {
        if (!newVal) { // show Preview, so commit form data in Edit to Vuex for Preview to read
          if (this.isNewResource && !this.resourceDetails) {
            // when creating a new resource, store.resourceDetails is null
            const resourceDetails = {...MY_RESOURCES_RESOURCE_DETAILS_STRUCTURE};
            resourceDetails.document = this.document;
            resourceDetails.link = this.link;
            resourceDetails.fileName = this.fileName;
            this.$store.commit('myResources/SET_RESOURCE_DETAILS', resourceDetails);
          }

          if (this.resourceDetails) {
            // assign new form data to this local computed property, then commit to Vuex
            // not sure why this was not using the value from store originally as id and date fields will not be available in this.document
            this.resourceDetails.document = { ...this.resourceDetails.document, ...this.document };
            this.resourceDetails.link = this.link;
            this.resourceDetails.fileName = this.fileName;
          }
        } else {       // show Edit, so this.document will get the latest data from Vuex
          this.document = this.resourceDetails?.document;
          this.link = this.resourceDetails.document.ResourceURL;
          this.fileName = this.resourceDetails?.files[0]?.FileName;
        }
      }
    },
    formInputs: { // when the form's inputs change, set the data to the parent for use
      deep: true,
      handler(formInputs) {
        this.$emit('get:formInputs', formInputs);
      }
    },
    curriculumInput: {
      immediate: true,
      async handler(val) {
        const payload = {
          kla: val?.kla,
          stage: val?.stage
        }
        if (val?.kla && val?.kla.length > 0 && val?.stage && val?.stage.length > 0) {
          await this.$store.dispatch('metadata/fetchNsyllabus', payload);
        }
      }
    },
  },
  methods: {
    handleCopyright(statement) {
      this.document.Rights = statement
    },
    handleCurriculumSelections(curriculum) {
      if (curriculum.syllabus) {
        this.document.NSWSyllabus = curriculum.syllabus
      }
    },
    handleProgressions(progressions) {
      console.log('updated progressions', progressions)
      if (progressions.literacyV3) {
        this.document.LitLearnProgLevelV3 = progressions.literacyV3
      }
      if (progressions.numeracyV3) {
        this.document.NumLearnProgLevelV3 = progressions.numeracyV3
      }
      if (progressions.literacyV2) {
        this.document.LitLearnProgLevel = progressions.literacyV2
      }
      if (progressions.numeracyV2) {
        this.document.NumLearnProgLevel = progressions.numeracyV2
      }
    },
    getFileOrLink(val) {
      this.link = val.link;
      this.file = val.file;
      this.fileName = val.file ? val.file.name : '';
      if (val.file && !this.document.Name) {
        this.document.Name = val.file.name.split('.')[0];
      }
    },
    // handleCurriculumSelections(curriculum) {
    //   if (curriculum.syllabus) {
    //     this.document.syllabus = curriculum.syllabus;
    //   }
    //   if (curriculum.outcomes) {
    //     this.document.outcomes = curriculum.outcomes;
    //   }
    //   if (curriculum.progressions) {
    //     this.document.progressions = curriculum.progressions;
    //   }
    // },
    handleExceedFileSize(val) {
      this.exceedFileSize = val;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.row {
  margin-top: 0;
  margin-bottom: 0;

  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.content {
  width: 80%;
}

.copyright__container {
  border: 1px solid $ads-light-40;
  box-sizing: border-box;
  border-radius: 4px;

  .body-1 {
    letter-spacing: normal !important;
  }

  ::v-deep label.v-label {
    color: $ads-dark;
  }
}

::v-deep.theme--light {
  .v-btn {
    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }
}

.v-application .error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep div.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep label.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep button.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep i.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

@media only screen and (max-width: 960px) {
  .row {
    margin-bottom: 0;
  }

  .content {
    width: 100%;

    .autocomplete__wrapper {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
}
</style>
