<template>
  <AdsButton
    v-bind="$attrs"
    :loading="$store.state.isLoading"
    secondary
    button-text="Submit for review"
    v-on="$listeners"
  />
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'SubmitForReviewButton',
  components: {AdsButton}
}
</script>

<style lang="scss" scoped>

</style>
