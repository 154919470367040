<template>
  <v-row class="resource-info__wrapper">
    <v-col
      cols="12"
      lg="11"
      md="11"
      sm="12"
    >
      <p class="resource-type">
        {{ resourceType }}
      </p>
      <h2 class="page-title">
        {{ name }}
      </h2>
      <p class="updated-date">
        <span v-if="isOwner && !isNewResource">Created by me&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
        <span v-if="!isOwner && !isNewResource">Created by {{ creatorName }}&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
        <span v-if="!isNewResource">
          Version {{
            currentVersion
          }}.0&nbsp;&nbsp;&#8226;&nbsp;&nbsp;
        </span>
        Updated: {{ updated }}
      </p>
      <div :class="{'mb-3': true, 'd-flex flex-column': $vuetify.breakpoint.xsOnly}">
        <span class="label">Format: </span>
        <span>
          {{ format }}
        </span>
      </div>
      <div class="tags__wrapper">
        <div class="tag--group__wrapper">
          <span class="label">Stage: </span>
          <span>
            {{ stage }}
          </span>
        </div>
        <div class="tag--group__wrapper">
          <span class="label">Year: </span>
          <span>
            {{ year }}
          </span>
        </div>
        <div class="tag--group__wrapper">
          <span class="label">Learning Area: </span>
          <span>
            {{ learningArea }}
          </span>
        </div>
      </div>
      <div class="label">
        Description:
      </div>
      <ShowMore
        :key="ckey"
        :text="description"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';
import ShowMore from '@/components/ShowMore'
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'PreviewResourceInfo',
  components: {
    ShowMore
  },
  props: {
    isNewResource: {     // prop used to differentiate when the component is used to create a resource or edit one
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moment,
      ckey: 0
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.myResources.resourceDetails,
      document: state => state.myResources.resourceDetails?.document
    }),
    ...mapGetters({
      stagesAndYears: 'metadata/mappedStagesAndYears',
      allResourceTypes: 'metadata/myResourcesTypes',
      formats: 'metadata/formats'
    }),
    resourceType() {
      const type = this.allResourceTypes.filter(el => el.id === this.document?.ResourceType)
      return this.document?.ResourceType.length > 0 ? type[0].name : 'Resource Type'
    },
    currentVersion() {
      if (this.$route.query.version) {
        const versionHistory = this.$store.getters['myResources/versionHistory'];
        const index = versionHistory.findIndex(i => i._id === this.$route.query.version);
        return versionHistory.length - index;
      } else {
        return this.$store.getters['myResources/currentVersion'];
      }
    },
    isOwner() {
      return this.resourceDetails?.userAccess?.owner?.includes(this.$store.state.uid);
    },
    name() {
      return this.document?.Name ? this.document.Name : 'No title created';
    },
    updated() {
      if (this.resourceDetails?.record) {
        return this.moment(this.resourceDetails.record.modified).format('DD MMM YYYY');
      } else {
        return this.moment(new Date()).format('DD MMM YYYY');
      }
    },
    format() {
      return this.formats.find(el => el.id === this.document?.Format)?.name || 'None'
    },
    stage() {
      if (this.document?.Year && !this.document?.Stage) {
        const years = this.document?.Year
        const stages = years ? this.stagesAndYears.filter(sy => {
          return years.includes(sy.year)
        }).map(s => s.stage) : []

        // just removing duplicates
        return [...new Set(stages)].join(', ');
      }
      return this.document?.Stage?.join(', ') || 'none'
    },
    year() {
      // return this.document?.Year?.length > 0 ? this.parseAndSortYearsOrStages(this.document.Year, 'Kindergarten').join(', ') : 'None';
      return this.document?.Year?.join(', ') || 'none'
    },
    learningArea() {
      return this.document?.KLA?.length > 0 ? this.document.KLA.join(', ') : 'None';
    },
    description() {
      return this.document?.Description ? this.document.Description : 'None';
    },
    creatorName() {
      return this.document?.Owner ? this.document.Owner : '';
    }
  },
  watch: {
    description: {
      immediate: true,
      handler() {
        this.ckey++;
      }
    }
  },
  methods: {
    parseAndSortYearsOrStages(arr, str) {
      let arrModified = [];
      let includeString = false;
      if (arr.includes(str)) {
        includeString = true;
        arrModified = arr.filter(s => s !== str);
        arrModified = arrModified.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      } else {
        arrModified = arr.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      }
      if (includeString) {
        arrModified.unshift('K');
      }
      return arrModified;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.resource-info__wrapper {
  margin: 0 0 24px 0 !important;

  .col-12 {
    padding-left: 0;
    padding-right: 0;

    .resource-type {
      font-size: 16px;
      font-weight: bold;
      color: $ads-blue-1;
      margin-bottom: 8px;
    }

    .page-title {
      font-size: 34px;
      font-weight: bold;
      color: $ads-navy;
      word-break: break-word;
      line-height: 40px;
      margin-bottom: 8px;
    }

    .updated-date {
      color: $ads-dark-60;
    }

    .tags__wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 28px;

      .tag--group__wrapper {
        display: flex;
        min-width: 120px;
        margin-right: 24px;
      }
    }

    .label {
      color: $ads-navy;
      margin-right: 8px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 669px) {
  .resource-info__wrapper {
    .col-12 {
      .page-title {
        font-size: 20px;
        line-height: 28px;
      }

      .tags__wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        .tag--group__wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
