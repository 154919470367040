<template>
  <div :class="{'px-0 pt-2 pb-4': $vuetify.breakpoint.xs, 'pa-3': $vuetify.breakpoint.smAndUp } ">
    <v-row class="ma-0">
      <v-col cols="12">
        <h3 style="font-size: 16px;">
          NSW Syllabus
        </h3>
      </v-col>
      <v-list
        v-if="syllabus && syllabus.length > 0"
        class="px-0 pb-0 pt-3"
      >
        <v-list-item
          v-for="(syllabus, i) in syllabus"
          :key="i"
          class="d-flex flex-column align-start px-3"
        >
          <h4
            style="font-size: 16px;"
            class="mb-2"
          >
            {{ syllabus.name }}
          </h4>
          <v-list
            v-if="syllabus.outcomes.length > 0"
            class="py-0"
          >
            <v-list-item
              v-for="outcome in syllabus.outcomes"
              :key="outcome.id"
              class="d-flex flex-column align-start px-0"
            >
              <p class="mb-0 d-flex flex-row">
                <a
                  target="_blank"
                  :href="outcome.url"
                  class="mr-1 font-weight-bold"
                >
                  {{ outcome.id }}
                </a>
                <v-icon
                  class="pr-2"
                  alt="opens in a new window"
                  :color="ADS_Colors.Blue_1"
                  small
                >
                  open_in_new
                </v-icon>
              </p>
              <p>{{ outcome.name ? capitalizeFirstLetter(outcome.name) : outcome.name }}</p>
            </v-list-item>
          </v-list>
          <span
            v-else
            class="mb-4"
          >
            No outcomes
          </span>
        </v-list-item>
      </v-list>
      <v-col
        v-else
        cols="12"
        class="pt-0"
      >
        <span>None selected</span>
      </v-col>
    </v-row>

    <v-row
      align="center"
      class="ma-0 pt-3"
    >
      <v-col cols="12">
        <h3 style="font-size: 16px;">
          Australian Curriculum content descriptions
        </h3>
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
      >
        <span>Not available</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import {mapState} from 'vuex';

export default {
  name: 'PreviewCardCurriculumAlignment',
  data() {
    return {
      ADS_Colors
    };
  },
  computed: {
    ...mapState({
      document: state => state.myResources.resourceDetails?.document
    }),
    outcomes() {
      return this.document?.NSWSyllabus?.reduce(function (outcomes, syllabus) {
        return [...outcomes, ...syllabus.outcomes?.map(outcome => outcome.id)]
      }, []);
    },
    syllabus() {
      return this.document?.NSWSyllabus?.length > 0 ? this.document.NSWSyllabus : [];
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1) + '.';
    },
    getOutcomeUrl(code) {
      return `https://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=${code}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

a {
  color: $ads-blue-1 !important;
}

.v-list-item {
  min-height: 0 !important;
}
</style>
