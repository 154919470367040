<template>
  <Dialog
    v-bind="$attrs"
    icon="info_outline"
    title="Copyright status is not confirmed"
    :hideCloseButton="true"
    max-width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        :class="{'mt-0 pb-0': true, 'content-mobile': $vuetify.breakpoint.xsOnly}"
        :style="{'color': ADS_Colors.Dark}"
      >
        <p class="mb-1">
          You cannot share this resource until you have provided a known copyright status.
        </p>
        <CopyrightSelector
          :statement="copyRights"
          :copyrightTitle="copyrightTitle"
          :copyrightRadioGroupLabel="copyrightRadioGroupLabel"
          :copyrightBody="copyrightBody"
          :copyrightItems="copyrightItems"
          @change="handleCopyright"
        />
      </v-card-text>
      <v-card-actions :class="{'d-flex justify-space-between px-6 pb-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
        <v-spacer />
        <AdsButton
          secondary
          button-text="Cancel"
          @click="$downwardBroadcast('close:dialog')"
          @keyup.enter="$downwardBroadcast('close:dialog')"
        />
        <AdsButton
          :disabled="disableConfirm"
          :loading="$store.getters['myResources/loading']"
          :tertiary="disableConfirm"
          button-text="Confirm"
          @click="handleConfirm"
          @keyup.enter="handleConfirm"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';
import CopyrightSelector from '@/views/MyResourceDetailsPage/Edit/CopyrightSelector';
import {mapState} from 'vuex';

export default {
  name: 'CopyrightDialog',
  components: {
    CopyrightSelector,
    Dialog,
    AdsButton
  },
  data() {
    return {
      ADS_Colors,
      loading: false,
      copyRights: null,
      // copyright: {option: null, subOption: null, statement: ''},
      copyrightTitle: 'Copyright statement',
      copyrightRadioGroupLabel: 'Please select from the following statements:',
      copyrightBody: `The attached resource must not infringe copyright laws. Users must identify any third-party copyright material embedded in their resource before uploading.

The Department retains copyright in all resources created by its staff. All resources wholly or partially created by staff must be marked “© 202X NSW Department of Education: No reproduction permitted without express permission.”
      `,
      copyrightItems: [{
        'id': 0,
        'text': 'This resource was wholly created by myself or other departmental staff.',
        'value': '© State of New South Wales (Department of Education).'
      },
        {
          'id': 1,
          'text': 'I am not sure of the copyright status of the resource or its elements.',
          'value': 'Rights not known.'
        },
        {
          'id': 2,
          'text': 'There are elements contained in this resource which were created by, or whose copyright is owned by, third parties but are available for departmental use due to:',
          'value': 'Other',
          'children': [
            {
              'text': 'The elements are only accessed via linking to an external website.',
              'value': 'The elements are only accessed via linking to an external website.'
            },
            {
              'text': 'This resource was wholly created by myself or other departmental staff.',
              'value': 'This resource was wholly created by myself or other departmental staff.'
            },
            {
              'text': 'The elements do not exceed the limits imposed by the Department’s Education Copyright Licence.',
              'value': 'The elements do not exceed the limits imposed by the Department’s Education Copyright Licence.'
            },
            {
              'text': 'Creative Commons or other Open Education Resources.',
              'value': 'Creative Commons or other Open Education Resources.'
            },
            {
              'text': 'Express permission has been obtained from the author/owner.',
              'value': 'Express permission has been obtained from the author/owner.'
            },
            {
              'text': 'Permission has been obtained from the traditional owners in accordance with the principles of Indigenous Cultural Intellectual Property.',
              'value': 'Permission has been obtained from the traditional owners in accordance with the principles of Indigenous Cultural Intellectual Property.'
            },
            {
              'text': 'Public Domain.',
              'value': 'Public Domain.'
            }
          ]
        }
      ],
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.myResources.resourceDetails
    }),
    disableConfirm() {
      console.log(!this.copyRights || this.copyRights === 'Rights not known.')
      return (!this.copyRights || this.copyRights === 'Rights not known.') ? true : false;
    },
  },
  watch: {
    resourceDetails(newVal) {
      this.copyRights = newVal.document.Rights
    }
  },
  methods: {
    handleCopyright(statement) {
      this.copyRights = statement
    },
    handleConfirm() {
      let document = {...this.resourceDetails.document};
      document.Rights = this.copyRights;

      let requestPayload = {
        newDocument: document
      };

      let payload = {
        requestPayload
      };

      this.$eventHub.$emit('update:copyright', payload);
      this.$downwardBroadcast('close:dialog');
      if (this.$attrs.args === 'shareCard') {
        this.$eventHub.$emit('show:shareThisCardDialog');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-mobile {
  height: calc(100vh - 72px);
  overflow: auto;
}

.footer-mobile {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 12px !important;
}
</style>
