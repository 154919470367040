<template>
  <div class="faq-page__wrapper">
    <Banner
      v-if="$vuetify.breakpoint.mdAndUp"
      id="main-content"
      heading="Help & About - FAQs"
      subtitle="Answers to some of the more commonly identified questions for the Resource Hub"
      icon="help_outline"
      :icon-size="bannerIconSize"
      tabindex="-1"
    />
    <div class="faq-content__container">
      <AdsExpansionPanel
        :items="items"
      />
    </div>
  </div>
</template>

<script>
import {Banner, AdsExpansionPanel} from '@nswdoe/doe-ui-core';

export default {
  name: 'FAQPage',
  components: {
    Banner,
    AdsExpansionPanel
  },
  data() {
    return {
      bannerIconSize: '3.5rem',
      items: [
        {
          title: 'Who has access to the <i>Resources Hub</i>?',
          content: 'Any staff member can access the resource cards. The resources themselves can be shared via MS Teams, Google Classroom, email and embedded links.'
        },
        {
          title: 'What web browsers are supported by the <i>Resources Hub</i>?',
          content: 'Google Chrome or Safari are the recommended browsers. MS Edge Chromium can be used. Internet Explorer or other browsers are not supported.'
        },
        {
          title: 'What resources are available in the <i>Resources Hub</i>?',
          content: `<ul>
<li>The <i>Universal Resources Hub</i> includes resources from the following repositories:
<ul>
  <li><strong>Aboriginal Education</strong> resources which will provide guidance to teachers and school leaders in how to support Aboriginal students in attaining the HSC whilst maintaining their cultural identity.</li>
  <li><strong>Attendance</strong> resources which will support schools to plan and implement attendance strategies that create a positive environment for engagement and learning.</li>
  <li><strong>Curriculum</strong> resources are quality assured learning resources produced by the Curriculum, Secondary Learners and Curriculum, Early Years and Primary Learners teams. Nine resources have been created, with more to come.</li>
  <li><strong>Financial Management</strong>  resources will support school leaders and administrative staff to implement financial processes and practices within their school context as part of the School Excellence in Action cycle.</li>
  <li><strong>Inclusive Practice</strong>  includes evidence-based practice resources for schools to support students with disability and additional needs.</li>
  <li><strong>Literacy & Numeracy</strong> resources can be used by teachers to embed effective practices within their teaching and learning cycle.</li>
  <li><strong>School Excellence in Action</strong> resources will support school leaders to implement and embed key aspects of the School Excellence in Action cycle within their school context.</li>
  <li><strong>stem.T4L Learning Library</strong> includes teaching & learning resources from T4L.schools.nsw.gov.au.</li>
</ul>
</li>
</ul>`
        },
        {
          title: 'What if I find a problem with a resource or the data in a resource card?',
          content: 'If there is a problem, please click on the “Flag/Report” button within each resource card and provide as much detail as you can.'
        },
        {
          title: 'Who can I contact to ask further questions about the <i>Resources Hub</i>?',
          content: `<ul>
<li>Follow the <i>Resources Hub</i> Yammer group, select the “Community” tab on the left side navigation bar and click on the Yammer button.</li>
<li>Contact EdConnect for any problems with resources or access to the Hub.</li>
</ul>`
        },
        {
          title: 'Is there a plan to roll out new features?',
          content: 'New features and functionality will be released in the future.'
        },
        {
          title: 'How can I share resources and to whom?',
          content: 'Resources can be shared with students. Resource cards can be sent to other teachers for them to use or work together on, once edit permissions have been provided.<br>' +
            'For Students: Within each resource “card” there is a “share” icon. Links and/or embed codes are available to share the resource.<br>' +
            'In “More actions” you are able to add collaborators to resources you have created in “My Resources”.'
        },
        /*        {
                  title: 'Can I save resources for later?',
                  content: 'Yes, as of Release 3.0 of the Hub, you are now able to save searches and bookmark / save resources to the new “My Resources” section, please visit the tutorials for more information.'
                },*/
        {
          title: 'What is new in the <i>Resources Hub</i> as part of the latest release?',
          content: 'To see all the changes pertaining to the current, previous and planned releases, please go to "Road Map" menu item.'
        },
        {
          title: 'Why has the Digital Learning Resources Hub changed its name?',
          content:
            'The <i>Digital Learning Resources Hub</i> has been rebranded to the <i>Universal Resources Hub</i> as it now includes school improvement resources alongside teaching and learning resources. "School improvement" resources focus on the more holistic school planning activities that schools undertake. Therefore, the name “Universal Resources Hub” is representative of all possible resource “categories” available.'
        },
        {
          title: 'Where can I get more information about the teaching & learning or school improvement cycles?',
          content:
            'All resources on the Universal Resources Hub enable the Teaching and Learning cycle or the School Improvement cycle and will be mapped to the relevant phases of these cycles. This allows users to understand which phase of the specific cycle resources fall into, for ease of searching and to ensure resources can be utilised and incorporated into the daily practices of teachers and school leaders. Read more about the Universal Resources Hub <b><a href="https://education.nsw.gov.au/inside-the-department/teaching-and-learning/universal-resources-hub" target="_blank" style="text-decoration: none; color: black;">here.</a></b>'
        }
      ],
      isFAQ: true,
      isRoadmap: false,
    };
  },
  methods: {
    showFAQContent: function (event) {
      if (event.target.innerText == 'FREQUENTLY ASKED QUESTIONS') {
        this.isFAQ = true;
        this.isRoadmap = false;
      }
    },
    showRoadmapContent: function (event) {
      if (event.target.innerText == 'ROAD MAP') {
        this.isRoadmap = true;
        this.isFAQ = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.faq-page__wrapper {
  min-height: 100%;
  margin-top: 12px;

  .faq-content__container {
    display: flex;
    justify-content: center;
    margin: 60px;
  }
}

@media only screen and (max-width: 960px) and (min-height: 600px) {
  .faq-page__wrapper {
    margin-top: 0;

    .faq-content__container {
      margin: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .faq-page__wrapper {
    margin-top: 0;

    .faq-content__container {
      margin: 12px;
    }
  }
}
</style>
