<template>
  <div class="report-menu__wrapper">
    <v-card-actions>
      <v-text-field
        :value="path"
        hide-details
        outlined
        readonly
        label="Resource URL"
        class="mr-3"
      />
      <AdsButton
        v-clipboard:copy="path"
        v-clipboard:success="() => copySucceeded = true"
        class="copy-button"
        secondary
        :buttonText="copySucceeded ? 'Copied!' : 'Copy link'"
        :icon="copySucceeded ? 'check' : ''"
        :ariaLabel="copySucceeded ? 'copied' : 'copy to clipboard'"
      />
    </v-card-actions>
    <v-card-text>
      <p>Please copy and paste the URL and email it to us:</p>
      <div class="email">
        <v-icon
          class="material-icons-outlined"
          aria-hidden="true"
        >
          email
        </v-icon>
        <a
          href="mailto:URHSupport@det.nsw.edu.au"
          aria-describedby="link to send email to URH"
        >URHSupport@det.nsw.edu.au</a>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'ReportMenu',
  components: {
    AdsButton
  },
  data() {
    return {
      copySucceeded: false,
      path: window.location.href
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.report-menu__wrapper {
  .v-card__actions {
    padding: 12px 24px;

    .v-text-field {
      &.v-text-field--enclosed {
      }
    }

    .copy-button {
      margin-left: 12px;
      height: 56px;
      min-width: 120px;
    }
  }

  .v-card__text {
    padding: 12px 24px 24px 24px;
    font-size: 16px;

    .email {
      font-weight: bold;

      .v-icon {
        margin-right: 8px;
      }

      a {
        color: $ads-blue-1;
      }
    }
  }
}
</style>
