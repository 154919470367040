<template>
  <AdsButton
    v-bind="$attrs"
    buttonText="Cancel"
    tertiary
    v-on="$listeners"
  />
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'CancelButton',
  components: {AdsButton}
}
</script>

<style lang="scss" scoped>

</style>
