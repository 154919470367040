<template>
  <v-menu
    v-model="showMenu"
    offset-y
    bottom
    :left="alignRight"
    :right="alignLeft"
    :nudge-bottom="8"
    transition="slide-y-transition"
    origin="top right"
    attach
  >
    <template #activator="{ on, attrs }">
      <AdsButton
        v-bind="attrs"
        secondary
        buttonText="Share via"
        icon="share"
        :color="ADS_Colors.Navy"
        ariaLabel="Share"
        :class="{'share-button pl-0 pr-1': true, 'share-button__mobile': $vuetify.breakpoint.xsOnly}"
        v-on="on"
        @click="showMenu = true"
      />
    </template>
    <ShareList :item="item" />
  </v-menu>
</template>

<script>
import ShareList from './ShareList';
import {AdsButton, ADS_Colors} from '@nswdoe/doe-ui-core';

export default {
  name: 'Share',
  components: {
    ShareList,
    AdsButton
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    alignLeft: {
      type: Boolean,
      default: true
    },
    alignRight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false,
      ADS_Colors
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.share-button__mobile {
  width: 100% !important;
}

.share-button {
  width: 100%;
  min-width: 160px !important;

  &:focus-visible {
    background-color: $ads-navy !important;
    border: 2px solid $ads-navy;
    color: $ads-white !important;
    outline: none !important;
  }
}

.theme--light {
  &.v-btn {
    &.v-btn--outlined {
      border-width: 2px !important;

      &:focus-visible {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }
}
</style>
