<template>
  <v-combobox
    id="search-staff"
    v-bind="$attrs"
    :search-input.sync="searchInput"
    v-model="selections"
    class="recipients-text-field"
    hide-no-data
    outlined
    prepend-inner-icon="search"
    append-icon=""
    chips
    deletable-chips
    multiple
    clearable
    label="Recipient’s email address"
    placeholder="Search staff"
    :delimiters="[' ', ';', ',']"
    :counter="counter"
    :rules="rules"
    v-on="$listeners"
    @update:list-index="$emit('onKeyboardNavigation')"
  >
    <template #item="{ item, on, attrs }">
      <v-list-item
        :id="item"
        v-bind="attrs"
        v-on="on"
        @mouseover="onHover(item, $event)"
      >
        {{ item }}
        <div
          v-if="highlightedItem"
          class="sr-only"
          :aria-hidden="$vuetify.breakpoint.mdAndUp || displayDetails ? 'false' : 'true'"
        >
          <span>
            {{ highlightedItem.name }}
          </span>
          <span>
            {{ highlightedItem.email }}
          </span>
          <span v-if="highlightedItem.phone">
            P: {{ highlightedItem.phone }}
          </span>
          <span v-if="highlightedItem.title">
            {{ highlightedItem.title }}
          </span>
          <span v-if="highlightedItem.school">
            {{ highlightedItem.school }}
          </span>
        </div>
      </v-list-item>
    </template>
  </v-combobox>
</template>
<script>
import {validate} from 'email-validator';

export default {
  name: 'SearchField',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    results: {
      type: Array,
      default: () => []
    },
    highlightedItem: {
      type: Object,
      default: null
    },
    displayDetails: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, Number, String],
      default: false
    }
  },
  data: function () {
    return {
      searchInput: '',
      loadingCount: 0,
    }
  },
  computed: {
    selections: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    rules() {
      if (this.selections.some(email => !validate(email))) {
        this.$emit('getRule', ['Enter only valid email addresses']);
        return ['Enter only valid email addresses'];
      }
      if (this.counter && this.selections.length > this.counter) {
        this.$emit('getRule', [`Maximum ${this.counter} recipients`]);
        return [`Maximum ${this.counter} recipients`];
      }
      this.$emit('getRule', [true]);
      return [true];
    }
  },
  watch: {
    searchInput: function (val) {
      this.$emit('searchInput', val);
    },
  },
  methods: {
    onHover(item, event) {
      let itemEl = event.srcElement;
      const rect = itemEl.getBoundingClientRect();
      const hoverPosition =  {x: rect.x + rect.width, y: rect.top};
      this.$emit('onHover', {item, hoverPosition});
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/variables";

.v-autocomplete {
  ::v-deep &.v-select {
    &.v-select--chips {
      .v-select__selections {
        min-height: 56px !important;
        max-height: 120px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

::v-deep.v-select {
  &.v-select--chips {
    &:not(.v-text-field--single-line) {
      &.v-text-field--enclosed  {
        .v-select__selections {
          min-height: 56px !important;
        }
      }
    }
  }
}


  .v-menu__content {
    &.v-autocomplete__content {
      .v-list {
        .v-list-item--link {
          &.v-list-item {
            &.theme--light {
              &:hover {
                &::before {
                  opacity: 0;
                }
              }
            }
          }

          .recipients--item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          &.v-list-item--highlighted {
            background-color: $ads-navy;
            color: $ads-white !important;
            outline: none !important;

            &:focus {
              outline: none;
            }

            .recipients--item {
              .v-btn {
                color: $ads-white;
              }
            }
          }
        }
      }
    }
  }
  .theme--light {
    ::v-deep.v-icon {
      &:focus {
        border: 2px solid $ads-navy;
        border-radius: 50%;

        &::after {
          opacity: 0;
        }
      }
    }

    ::v-deep.v-chip {
      background: $ads-white;
      border: 1px solid $ads-navy;

      .v-chip__content {
        button {
          &:focus {
            border: none !important;
            outline: 2px auto $ads-navy;
          }
        }
      }
    }
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

</style>
