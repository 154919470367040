import { render, staticRenderFns } from "./ResultsHeader.vue?vue&type=template&id=5733b600&scoped=true&"
import script from "./ResultsHeader.vue?vue&type=script&lang=js&"
export * from "./ResultsHeader.vue?vue&type=script&lang=js&"
import style0 from "./ResultsHeader.vue?vue&type=style&index=0&id=5733b600&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5733b600",
  null
  
)

export default component.exports