<template>
  <div
    v-if="displayList.length > 0"
    class="avatar--list"
  >
    <v-avatar
      v-for="(item, index) in displayList"
      :key="index"
      :class="{ 'mr-2': stacked, 'ml-n5': index !== 0}"
      size="40"
      :aria-label="item.replace('.', ' ')"
    >
      {{ index < showOnly? getInitials(item):'' }}
    </v-avatar>
    <v-avatar
      v-if="additionalItems > 0"
      class="mr-2 ml-n5"
      size="40"
    >
      +{{ additionalItems }}
    </v-avatar>
  </div>
  <div
    v-else
    class="mr-2"
  >
    0 shares
  </div>
</template>
<script>
export default {
  name: 'AvatarList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    showOnly: {
      type: Number,
      default: 3
    },
    stacked: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    displayList() {
      return [...this.items].splice(0, this.showOnly) // make a copy before splicing
    },
    additionalItems() {
      return this.items.length - this.showOnly
    }
  },
  methods: {
    getInitials(name) {
      return name.split('.').map(item => item.charAt(0).toUpperCase()).join('')
    },
  }
}
</script>
