<template>
  <v-dialog
    v-bind="$attrs"
    v-model="isExpanded"
    attach
    left
    :rounded="'0'"
    v-on="$listeners"
  >
    <template #activator="{on, attrs}">
      <v-btn
        class="mx-2 floating-action-menu"
        fab
        small
        @click="isExpanded = !isExpanded"
      >
        <v-icon>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <Menu
      :items="items"
      @clickedAction="handleClickedAction"
      @close="isExpanded = false"
    />
  </v-dialog>
</template>

<script>
import Menu from './Menu.vue';

export default {
  name: 'ActionsMenu',
  components: {
    Menu
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    button: {
      type: Object,
      default: () => ({
        text: 'More actions',
        icon: 'mdi-dots-vertical',
        secondary: true,
        tertiary: false,
        disabled: false
      })
    },
    icon: {
      type: [Boolean, String],
      default: false
    },
    externalData: {
      type: [String, Number, Object, Array],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    handleClickedAction(id) {
      if (this.externalData) {
        this.$emit('clickedAction', {externalData: this.externalData, action: id});
      } else {
        this.$emit('clickedAction', id);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

// dialog open at the top, full screen width on mobile portrait
::v-deep.v-dialog__content {
  align-items: start;

  &.v-dialog__content--active {
    .v-dialog {
      &.v-dialog--active {
        margin: 0;
        border-radius: 0;
      }
    }
  }
}

.v-btn {
  padding: 0;

  &.v-btn--icon.v-size--default {
    height: 42px !important;
    width: 42px !important;
  }

  &:focus {
    outline: none !important;

    &::before {
      opacity: 0 !important;
    }
  }

  &:focus-visible {
    border: 2px solid $ads-navy;
  }

  &:hover {
    &::before {
      opacity: 0 !important;
    }
  }
}

.floating-action-menu{
  position: absolute;
  top: 30px;
  right: 15px;
  background: transparent!important;
  border: 2px solid $ads-blue-3;

  .v-icon{
    color: $ads-blue-3;
  }
}
</style>
