<template>
  <AdsButton
    v-bind="$attrs"
    :loading="$store.state.isLoading"
    secondary
    outlined
    class="mr-3"
    color="warning"
    icon="mdi-content-save-outline"
    button-text="Decline"
    v-on="$listeners"
  />
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'DeclineButton',
  components: {AdsButton}
}
</script>

<style lang="scss" scoped>

</style>
