<template>
  <v-container class="source-files-and-links">
    <v-row class="pb-9">
      <v-col>
        <div class="title">
          Source *
        </div>
      </v-col>
    </v-row>
    <v-row v-if="filesAndLinks.length === 0">
      <v-col class="pt-0">
        <div class="body-1">
          Attach a link or upload a file
        </div>
      </v-col>
    </v-row>
    <v-row v-if="filesAndLinks.length > 1">
      <v-col class="pt-0">
        <div class="primary-label subtitle-2">
          Primary
        </div>
      </v-col>
    </v-row>
    <draggable
      v-model="filesAndLinks"
      class="draggable-container"
      ghost-class="dragging-ghost"
      @start="dragging = true"
      @end="dragging = false"
    >
      <v-row
        v-for="({ type, rules, data }, sourceIndex) in filesAndLinks"
        :key="`sourceInput${sourceIndex}`"
      >
        <v-col
          v-if="type === SourceType.File"
          cols="10"
        >
          <v-file-input
            v-model="filesAndLinks[sourceIndex].data"
            prepend-icon=""
            prepend-inner-icon="$file"
            placeholder="Upload"
            multiple
            outlined
            counter
            :clearable="false"
            label="Upload"
            :error="hasError(rules)"
            :error-count="rules?.length"
            :rules="formatInvalidRules(rules)"
            :success-messages="successMessages(rules, data)"
            @change="handleFileUpload($event,filesAndLinks, sourceIndex)"
          >
            <template #selection="{text, index}">
              <v-chip
                label
                small
                dark
                :color="ADS_Colors.Info_Blue"
                @click:close="handleRemoveFile(sourceIndex, index)"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col
          v-if="type === SourceType.Link"
          cols="5"
        >
          <AdsTextField
            v-model="filesAndLinks[sourceIndex].data.url"
            label="Link"
            aria-label="Link"
            placeholder="Paste here"
            clearable
            :rules="rules"
            @input="handleLinkInputChange(filesAndLinks, sourceIndex, $event)"
          />
        </v-col>
        <v-col
          v-if="type === SourceType.Link"
          cols="5"
        >
          <AdsTextField
            v-model="filesAndLinks[sourceIndex].data.label"
            label="Text"
            aria-label="Link"
            placeholder="Display name"
            clearable
          />
        </v-col>
        <v-col cols="2">
          <v-icon
            v-if="filesAndLinks.length > 1"
            class="drag-icon"
          >
            mdi-drag-horizontal-variant
          </v-icon>
          <v-btn
            icon
            color="warning"
            aria-label="remove button"
            @click="handleRemoveInput(sourceIndex)"
            @keyup.enter="handleRemoveInput(sourceIndex)"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </draggable>
    <v-row v-if="filesAndLinks.length > 1">
      <v-col class="pt-0">
        <div class="drag-message body-2">
          Drag to reorder
        </div>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col>
        <AdsButton
          tertiary
          icon="link"
          button-text="Link"
          @click="handleAddInput(SourceType.Link)"
          @keyup.enter="handleAddInput(SourceType.Link)"
        />
        <AdsButton
          tertiary
          icon="upload"
          button-text="File"
          @click="handleAddInput(SourceType.File)"
          @keyup.enter="handleAddInput(SourceType.File)"
        />
      </v-col>
    </v-row>

    <UnzipDialog
      v-model="unzipDialog"
      :name="zipFile.name"
      :size="zipFile.size"
      :zipFileContents="zipFileContents"
      :isScorm="isScormOrSite"
    />
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import {ADS_Colors, AdsButton, AdsTextField} from '@nswdoe/doe-ui-core';
import {SourceType} from '@/constants';
import {getFilesOrLinks, getOperatingSystem} from '@/transforms';
import JSZip from 'jszip';
import UnzipDialog from '@/components/Dialogs/Editor/UnzipDialog.vue';
import {humanReadableFileSize} from 'vuetify/src/util/helpers';

const MAX_FILE_SIZE_IN_MEGABYTES = 2000;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MEGABYTES * 1024 * 1024;

export default {
  name: 'SourceFilesAndLinks',
  components: {
    UnzipDialog,
    AdsButton,
    AdsTextField,
    draggable
  },
  data() {
    return {
      ADS_Colors,
      SourceType,
      valid: true,
      dragging: false,
      unzipDialog: false,
      zipFile: {
        name: '',
        size: '',
      },
      zipFileContents: []
    }
  },
  computed: {
    filesAndLinks: {
      get() {
        return this.$attrs.value;
      },
      set(val) {
        // get the latest array, and split it right away
        const files = getFilesOrLinks(this.filesAndLinks, SourceType.File);
        const links = getFilesOrLinks(this.filesAndLinks, SourceType.Link);
        this.$emit('get:files', files);
        this.$emit('get:links', links);
        this.$listeners && this.$listeners.input(val);
      }
    },
    isScormOrSite() {
      return this.zipFileContents.some(c => {
        if (this.isPotentialWebsitePackage(c)) {
          return true;
        } else if (c.includes('scormcontent/') || c.includes('scormdriver/')) {
          return true;
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    filesAndLinks: {
      deep: true,
      handler(val) {
        const files = getFilesOrLinks(this.filesAndLinks, SourceType.File);
        const links = getFilesOrLinks(this.filesAndLinks, SourceType.Link);
        this.$emit('get:files', files);
        this.$emit('get:links', links);
        this.$listeners && this.$listeners.input(val);
      }
    }
  },
  methods: {
    isPotentialWebsitePackage(fileName) {
      // using same logic as BE to determine website package
      if (fileName.match(/index(\.html?)/gi)) {// if it's an index of some sort, grab it
        return true
      }
      if (fileName.match(/default(\.html?)/gi)) {
        return true
      }
      if (fileName.match(/(default|index)(\.php)/gi)) {
        return true
      }
      if (fileName.match(/.html/gi)) {
        return true
      }
      return false
    },
    validateUrl(value) {
      // Does not start nor end with a . but contains at least one . and not adjacent
      const urlPattern = /^[^.]+(\.[^.]+)+$/;
      return !value || value.length === 0 || urlPattern.test(value) || 'Invalid url';
    },
    hasError(rules) {
      return rules?.some(rule => rule !== true);
    },
    checkValidity() {
      const invalid = this.filesAndLinks.some(({rules}) => this.hasError(rules));

      if (this.valid === invalid) {
        this.valid = !invalid;
        this.$emit('validityChange', !invalid);
      }
    },
    successMessages(rules, data) {
      // Ensure all rules are valid, if so return their file names
      return !rules || this.hasError(rules) ? null : data.map(({name}) => name);
    },
    formatInvalidRules(rules) {
      return rules?.reduce((invalidRules, name) => [
        ...invalidRules,
        ...(name === true
            ? []
            : [`${name} is over the maximum file size of ${MAX_FILE_SIZE_IN_MEGABYTES}MB`]
        )
      ], []);
    },
    expandFilesAt(index) {
      const {data} = this.filesAndLinks[index];

      if (data.length > 1) {
        const extract = data.splice(1);
        extract.reverse().forEach(file => {
          this.filesAndLinks.splice(index + 1, 0, {type: SourceType.File, data: [file], rules: [true]})
        });
      }
    },
    handleAddInput(type) {
      this.filesAndLinks.push({
        type,
        ...(type === SourceType.Link && {
          data: {}
        })
      });
    },
    handleRemoveInput(inputIndex) {
      this.filesAndLinks.splice(inputIndex, 1);

      if (!this.valid) {
        this.checkValidity();
      }
    },
    handleRemoveFile(inputIndex, fileIndex) {
      this.filesAndLinks[inputIndex].data.splice(fileIndex, 1);
      this.filesAndLinks[inputIndex].rules.splice(fileIndex, 1);

      if (!this.valid) {
        this.checkValidity();

        if (this.valid) {
          this.expandFilesAt(inputIndex);
        }
      }
    },
    handleFileUpload(event, filesAndLinks, sourceIndex) {
      console.log(event, filesAndLinks, sourceIndex)
      this.filesAndLinks[sourceIndex].rules = filesAndLinks[sourceIndex].data.map(({size, name}) =>
        size < MAX_FILE_SIZE_IN_BYTES || name
      );

      this.checkValidity();

      if (this.valid) {
        this.expandFilesAt(sourceIndex);
      }

      // 4.3 - only show the dialog for a zip file
      if (event && (event[0].type === 'application/x-zip-compressed' || event[0].type === 'application/zip')) {
        this.zipFile.name = event[0].name;
        this.zipFile.size = humanReadableFileSize(event[0].size, false);
        this.zipFile.type = event[0].type;
        this.unzipDialog = true;
        const zip = new JSZip();
        zip.loadAsync(event[0])
          .then(zipData => {
            console.log(zipData.files)
            const contents = Object.keys(zipData.files);
            const os = getOperatingSystem();
            console.log(os)
            if (os === 'MacOS' || os === 'Linux' || os === 'UNIX') {
              this.zipFileContents = contents.filter(item => item.slice(-1) !== '/');
            } else if (os === 'Windows') {
              this.zipFileContents = contents.filter(item => item.slice(-1) !== '\\');
            } else {
              this.zipFileContents = contents.filter(item => item.slice(-1) !== '/');
			}
          });
      }

    },
    handleLinkInputChange(filesAndLinks, sourceIndex, value) {
      this.filesAndLinks[sourceIndex].rules = [this.validateUrl(value)];

      this.checkValidity();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/ads";

.source-files-and-links {
  .title {
    color: $ads-grey-01;
  }

  .primary-label,
  .drag-message {
    color: $ads-grey-02;
  }

  .draggable-container {
    .drag-icon:hover {
      cursor: pointer;
    }

    & > div:nth-child(2) {
      &::before {
        content: 'Additional files';
        width: 100%;
        padding-left: 12px;
        @extend .subtitle-2;
        @extend .primary-label;
      }
    }

    .dragging-ghost {
      opacity: 0.5;
      background: #c8ebfb;
    }
  }
}
</style>
