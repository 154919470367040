import {apiBaseUrl} from '@/store/config'
import Vue from 'vue'
import http from '@/api/http'

const CONFIG = (window as any).dlrhConfig;
/*
A dedicated Vuex module for Editor.
 */
const moduleEditor = {
  namespaced: true,
  state: {
    errorState: false,
    filters: {
      owner: '',
      role: ''
    },
    filtersSelection: {
      checkboxOwner: [],
      checkboxEditorRole: []
    },
    users: {
      items: [],
      metadata: {}
    },
    usersGroupBy: {
      items: [],
      metadata: {}
    },
    usersGroupByContentArea: {
      items: [],
      metadata: {}
    },
    userAccesses: [],
    editMode: false,
    currentVersion: 1,
    applicationUserSelected: '',
    perPage: 16,
    pageNum: 1,
    keywords: '',
    // sort: 'default:asc',
    sourceIsEmpty: false,
  },
  getters: {
    urhUsers: state => state.users,
    urhUserAccesses: state => state.userAccesses,
    count: state => state.users?.metadata?.count ? state.users?.metadata?.count : 0,
  },
  mutations: {
    SET_URH_USERS(state, data) {
      state.users = {
        items: data.result,
        metadata: {
          count:  data.total,
          totalPages: Math.ceil(data.total / data.pageSize),
          page: data.currentPage,
          currentPage: data.currentPage
        }
      }
    },
    SET_URH_USER_ACCESS(state, accesses) {
      state.userAccesses = accesses
    },
    SET_FILTERS(state, filters) {
      state.filters = Object.assign(state.filters, filters);
    },
    SET_FILTERS_SELECTION(state, value) {
      state.pageNum = 1;
      state.filtersSelection = value;
    },
    SET_KEYWORDS(state, keywords) {
      state.keywords = keywords;
      state.pageNum = 1;
    },
    SET_STATUS(state, status) {
      state.status = status;
      state.pageNum = 1;
    },
    SET_PAGE_NUM(state, page) {
      state.pageNum = page
    }
  },
  actions: {
    async fetchUsersByGroup({commit, state, rootState, rootGetters}, payload) {
      // check loading status
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, {root: true});
      }

      const { filtersSelection, keywords } = state
      const { checkboxOwner, checkboxEditorRole } = filtersSelection

      let url = `${apiBaseUrl}/accesses/groupby/userId?pageSize=${ payload.pagination?.itemsPerPage || state.perPage }&pageNum=${payload?.pagination?.page || 1}`;

      if (checkboxEditorRole?.length > 0) {
        url += `&roles=${checkboxEditorRole}`
      }

      if (checkboxOwner?.length > 0) {
        url += `&content=${checkboxOwner}`
      }

      if (keywords) {
        url += `&searchStr=${keywords}`
      }

      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            uid: rootState.uid
          })
        }
      };
      try {
        const response: any = await http.get(`${url}`, config)
        const users = response?.data;
        commit('SET_URH_USERS', users);
      }  catch (err) {
        err && console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error loading user details.'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, {root: true});
        }
      }
    },
    async fetchUser({commit, state, rootState, rootGetters}, payload) {
      // check loading status
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, {root: true});
      }
      const url = `${apiBaseUrl}/accesses/${payload.userId}?pageSize=500`;
      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            uid: rootState.uid
          })
        }
      };
      try {
        const response: any = await http.get(`${url}`, config)
        const accesses = response?.data?.result;
        commit('SET_URH_USER_ACCESS', accesses);
        return response;
      }  catch (err) {
        err && console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error loading user details.'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, {root: true});
        }
      }
    },
    async createUserAccess({commit, state, rootState}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const data = {
          users: payload.users,
          contentGroup: payload.owners,
          roles: payload.roles
        }
        const newdata = {
          users: payload.users,
          accesses: payload.accesses
        }
        const url = `${apiBaseUrl}/accesses`;
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              Accept: 'application/json',
              'Content-Type': 'application/json',
              uid: rootState.uid
            })
          }
        };
        console.log(newdata, config)
        // const resource = await http.post(url, data, config)?.finally(() => {
        //   if (rootState.isLoading) {
        //     commit('SET_IS_LOADING', false, {root: true});
        //   }
        // });
        // return resource?.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async patchUserAccess({commit, state, rootState}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const data = {
          accesses: payload.accesses
        }
        const url = `${apiBaseUrl}/accesses`;
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              Accept: 'application/json',
              'Content-Type': 'application/json',
              uid: rootState.uid
            })
          }
        };
        console.log('patchUserAccess', config, data)
        const resource = await http.patch(url, data, config)?.finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
        });
        return resource?.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    }
  },
};

export default moduleEditor;
