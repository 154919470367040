<template>
  <Loader
    :loading="loading"
    type="image"
    width="396px"
    height="128px"
  >
    <div class="status-counts">
      <div
        v-for="({status, count}) in statusCounts"
        :key="status"
        class="status-count"
      >
        <div class="count">
          {{ count }}
        </div>
        <div class="status body-2">
          {{ status }}
        </div>
      </div>
    </div>
  </Loader>
</template>

<script>
import {mapGetters} from 'vuex';
import {Loader} from '@nswdoe/doe-ui-core';

export default {
  name: 'StatusCounts',
  components: {
    Loader
  },
  computed: mapGetters({
    loading: 'editor/loadingStatusCounts',
    statusCounts: 'editor/statusCounts',
  })
}
</script>

<style scoped lang="scss">
.status-counts {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;

  .status-count {
    width: 186px;
    height: 128px;
    display: grid;
    justify-items: center;
    align-content: center;
    background-color: rgba(red($ads-blue-2), green($ads-blue-2), blue($ads-blue-2), 0.3);
    border-radius: 4px;

    .count {
      font-weight: 700;
      font-size: 40px;
      line-height: 36px;
    }

    .status {
      color: $ads-blue-3;
      line-height: 36px;
    }
  }
}
</style>
