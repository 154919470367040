import {get, post} from '@/api/http';
import Vue from 'vue';

export async function getFiltersMetadata(baseUrl) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await get(`${baseUrl}/metadata-service/filters`, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getStatusesMetadata(baseUrl) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await get(`${baseUrl}/metadata-service/statuses`, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getSyllabuses(baseUrl, payload) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await post(`${baseUrl}/metadata-service/syllabus`, payload, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getOutcomes(baseUrl, payload) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await post(`${baseUrl}/metadata-service/outcomes`, payload, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getProgressions(baseUrl) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await get(`${baseUrl}/metadata-service/progressionlevel`, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getProgressionsV3(baseUrl) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await get(`${baseUrl}/metadata-service/progressionlevelV3`, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getResourcesImages(baseUrl){
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    };
    const res = await get(`${baseUrl}/metadata-service/resourceimages`, config);
    return res.data;
  } catch (error) {
      return null;
  }
}

export async function getNsyllabus(baseUrl){
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    };
    const res = await post(`${baseUrl}/metadata-service/nsyllabus`, config);
    return res.data;
  } catch (error) {
      return null;
  }
}
