import { render, staticRenderFns } from "./SetAccess.vue?vue&type=template&id=7495fdf4&scoped=true&"
import script from "./SetAccess.vue?vue&type=script&lang=js&"
export * from "./SetAccess.vue?vue&type=script&lang=js&"
import style0 from "./SetAccess.vue?vue&type=style&index=0&id=7495fdf4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7495fdf4",
  null
  
)

export default component.exports