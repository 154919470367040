<template>
  <v-card
    v-bind="$attrs"
    outlined
    tile
    class="collection--item"
    role="button"
    v-on="$listeners"
    @click="cardClickHandler"
    @keyup.enter="cardClickHandler"
  >
    <v-row
      class="ma-0"
      align="center"
    >
      <v-col cols="1">
        <div
          class="selection-wrapper"
        >
          <v-checkbox
            v-model="selected"
            class="mt-0 ml-1"
            tabindex="-1"
            hide-details
            readonly
            @click="cardClickHandler"
            @click.stop
          />
        </div>
      </v-col>
      <v-col
        v-if="imageCollage"
        :cols="imageCollage ? '3' : ''"
        class="image__container"
      >
        <v-img
          v-if="item.ThumbnailImage"
          :src="item.ThumbnailImage"
          :lazy-src="item.ThumbnailImage"
          height="100%"
          position="center"
        />
        <ImageCollage
          v-else
          :images="images"
          rounded
        />
      </v-col>
      <v-col>
        <v-card-title class="pb-0 pt-2">
          <h4 class="subtitle--text">
            {{ item.Name }}
          </h4>
        </v-card-title>
        <v-card-title class="pt-0 pb-0 primary-control">
          <div class="subtitle-2 text--secondary  font-weight-regular">
            {{ item.KLA?.join(', ') }}
            <template v-if="item.Stages?.length && item.KLA?.length">
              |
            </template>
            <template v-if="stages && stages?.length">
              Stage {{ stages.sort().join().replace('Early Stage','ES').replace(/Stage/g, '').replace('Early', '') }}
            </template>
          </div>
        </v-card-title>
        <v-card-text
          v-if="hubCollection"
          class="pt-2 pb-4 resources"
        >
          {{ totalResourcesMessage() }}
        </v-card-text>
        <v-card-text
          v-else
          class="pt-2 pb-4"
        >
          <AvatarList
            :items="item.Members"
            :show-only="3"
          />
        </v-card-text>
      </v-col>
      <v-col cols="2">
        <Chip
          v-if="item.isNew"
          class="status-chip"
          text="NEW"
          :color="ADS_Colors.PRIMARY_BLUE"
          :textColor="ADS_Colors.PRIMARY_BLUE"
          outlined
        />
        <Chip
          v-else-if="hubCollection"
          chipClass="status-chip"
          :text="item.status?.toUpperCase()"
          :color="statusChipColor(item.status)"
          :outlined="statusChipOutlined(item.status)"
          :textColor="statusChipTextColor(item.status)"
        />
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import {Chip, ADS_Colors} from '@nswdoe/doe-ui-core';
import statusChipMixin from '@/mixins/statusChipMixin';
import {pluralise} from '@/transforms';
import AvatarList from '@/components/AvatarList'
import ImageCollage from '@/components/ImageCollage'
import {mapGetters} from 'vuex';

export default {
  name: 'CollectionList',
  components: {
    AvatarList,
    ImageCollage,
    Chip
  },
  mixins: [statusChipMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    hubCollection: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    imageCollage:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      ADS_Colors,
      selected: this.isSelected
    }
  },
  watch: {
    isSelected(val) {
      this.selected = val
    }
  },
  computed: {
    ...mapGetters({
      allStages: 'metadata/stages'
    }),
    images(){
      return this.item.subResources?.map( item => item.image) || []
    },
    stages() {
      return this.item.Stages?.map(stage => this.allStages.find(el => el.id === stage)?.name) || []
    }
  },
  methods: {
    totalResourcesMessage() {
      return `${this.item.subResources.length} resource${pluralise(this.item.subResources.length)}`;
    },
    cardClickHandler() {
      this.$emit('onCollectionSelect', { select: !this.selected, item: this.item });
    },
  }

}
</script>
<style lang="scss" scoped>
.collection--item {
  border-top: none;
  .image__container {
    height: 125px;
  }

  .status-chip {
    width: 100%;
    justify-content: center;
  }

  .resources {
    color: $ads-grey-02;
  }
}
</style>
