<template>
  <div>
    <v-card
      :width="size"
      :height="cardHeight"
      :class="{'rounded-0': !rounded}"
      :title="resource.name"
    >
      <v-img
        :src="imageUrl"
        :lazy-src="resourceDefaultImage"
        :width="size"
        :aspect-ratio="1.85"
        :contain="false"
        :eager="true"
        :alt="altDescription"
      >
        <v-chip
          v-if="isEditor"
          small
          label
          class="editor-chip"
        >
          EDITOR
        </v-chip>
      </v-img>
      <div class="card-info">
        <div class="card-info--heading">
          <v-card-title
            :class="{
              'd-flex justify-space-between': true,
              'px-2 pt-0 pb-0 mt-0': size < 350,
              'pb-0 pt-1 mt-0' : size >= 350
            }"
          >
            <span
              :class="{
                'resource-type': true,
                'resource-type-xs': size < 280
              }"
              :aria-label="'resource type: '+ resourceType"
            >
              {{ resourceType }}
            </span>
            <span
              v-if="!isMyResources"
              class="d-flex"
            >
              <FeatureIcon
                v-for="cat in resourceCategory"
                :key="cat.id"
                size="20"
                :aria-label="cat.label"
                :color="cat.iconColor"
                :backgroundColor="cat.iconBg"
                :icon="cat.icon"
                class="ml-2"
              />
            </span>

            <v-chip
              v-if="isDraft && (isOwner || isEditor)"
              small
              class="draft-chip"
            >
              DRAFT
            </v-chip>
          </v-card-title>
          <v-card-title
            v-if="isDraft"
            class="pt-0 pb-0"
            :class="{'px-2': size < 350 }"
          >
            <a
              :id="itemId"
              tabindex="0"
              class="primary-control"
              @click="openDetailsPage"
              @keyup.enter="openDetailsPage"
            >
              <h3
                class="card-title"
                :class="{'card-title-xl': size > 450, 'card-title-md': size < 320}"
              >
                {{ resource.Name ? resource.Name : 'No title created' }}
              </h3>
            </a>
          </v-card-title>
          <v-card-title
            v-else
            class="pt-0 pb-0"
            :class="{'px-2': size < 350 }"
          >
            <a
              :id="itemId"
              tabindex="0"
              class="primary-control"
              @click="openDetailsPage"
              @keyup.enter="openDetailsPage"
            >
              <h3
                class="card-title"
                :class="{'card-title-xl': size > 450, 'card-title-md': size < 320}"
              >
                {{ resource.Name ? resource.Name : 'No title created' }}
              </h3>
            </a>
          </v-card-title>
        </div>
        <v-card-actions :class="{ 'd-flex align-end': !isMyResources }">
          <v-card-subtitle
            :class="{'pt-1 pr-6': size >= 350, 'px-2 pt-0 pb-2': size < 350, 'pb-1' : !isMyResources}"
            class="card-footer"
          >
            <div v-if="isSIRCategory">
              Focus Area:
              <span
                :class="{
                  'years-sm': size < 300,
                  'years-xs': size < 280
                }"
              >
                {{ focusAreas ? focusAreas : 'None' }}
              </span>
            </div>
            <div v-else>
              Years:
              <span
                :class="{
                  'years-sm': size < 300,
                  'years-xs': size < 280
                }"
              >
                {{ years ? years : 'None selected' }}
              </span>
            </div>
            <span>Updated: {{ moment(updatedDate).format('DD MMM YYYY') }}</span>
          </v-card-subtitle>
          <v-spacer />

          <template v-if="isMyResources">
            <ActionsMenu
              icon
              top
              offset-y
              left
              :items="moreActionsItems"
              @clickedAction="handleAction"
            />
            <DeleteResourceDialog
              v-model="deleteDialog"
              :resourceId="itemId"
              :resource-name="resource.name"
              :is-owner="isOwner"
              :is-editor="isEditor"
            />
          </template>
          <template v-else>
            <div
              class="d-flex mb-1"
            >
              <div>
                <Dialog
                  v-model="dialogCopyLink"
                  title="Copy this link"
                  dark-heading
                  :max-width="$vuetify.breakpoint.smAndUp ? 500 : 360"
                >
                  <template #activator="{ on, attrs}">
                    <button
                      aria-label="copy content"
                      class="button-icon copy-button"
                      @click.stop="dialogCopyLink = true"
                    >
                      <v-icon>content_copy</v-icon>
                    </button>
                  </template>
                  <template #content>
                    <CopyClipboardMenu
                      :is-open="dialogCopyLink"
                      description="Copy and paste this link:"
                      :text-to-copy="itemUrl"
                    />
                  </template>
                </Dialog>
              </div>

              <button
                aria-label="go to resource"
                class="button-icon go-to-resource-button"
                @click.stop="handleGoToResource"
              >
                <v-icon>open_in_new</v-icon>
              </button>
            </div>
          </template>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import Dialog from '@/components/Dialogs/Dialog';
import CopyClipboardMenu from '@/components/CopyClipboardMenu';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu';
import DeleteResourceDialog from '@/components/Dialogs/DeleteResourceDialog';
import {FeatureIcon} from '@nswdoe/doe-ui-core';
import {RESOURCE_CATEGORIES} from '@/constants';
import {mapGetters} from 'vuex';

export default {
  name: 'ResourceCard',
  components: {
    Dialog,
    CopyClipboardMenu,
    ActionsMenu,
    DeleteResourceDialog,
    FeatureIcon
  },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    size: {
      type: [Number, String],
      default: 350
    },
    height: {
      type: [Number, String],
      default: 350
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moment,
      dialogCopyLink: false,
      deleteDialog: false,
      resourceDefaultImage,
    }
  },
  computed: {
    ...mapGetters({
      allHubResourceTypes: 'metadata/allHubResourceTypes',
      allResourceTypes: 'metadata/myResourcesTypes'
    }),
    isMyResources() {
      if (this.item.document) {
        return true;
      }
      return this.resource.Source === 'myresource' ?? false;
    },
    resourceType() {
      const type = this.allResourceTypes.filter(el => el.id === this.resource?.ResourceType)
      return type[0] ? type[0].name : this.resource?.ResourceType || 'None'
    },
    isSIRCategory() {
      return this.resource.ResourceCategory?.includes('sir') || false;
    },
    focusAreas() {
      return this.resource.FocusArea?.map(e => e.focusArea).join(', ');
    },
    resource() {
      if (this.item.document) {
        if (this.isDraft && !this.isEditor && !this.isOwner) {
          const reversedArchive = this.item.archive.slice().reverse();
          const beforeDraft = reversedArchive.find(resource => !resource.document.draft);
          return beforeDraft.document;
        }
        return this.item.document;
      } /*else {
        return this.item;
      }*/
    },
    resourceCategory() {
      return this.resource.ResourceCategory?.map(id => this.getCategoryDetails(id));
    },
    moreActionsItems() {
      let items = [];
      if (this.isEditor || this.isOwner) {
        items = [
          {
            id: 'goToResource',
            name: 'Go to resource',
            tag: 'a',
            icon: 'mdi-open-in-new'
          },
          {
            id: 'editCard',
            name: 'Edit this resource',
            tag: 'a',
            icon: 'mdi-pencil-outline'
          },
          {
            id: 'delete',
            name: 'Delete',
            tag: 'button',
            icon: 'mdi-delete-outline',
          }
        ];
      } else {
        items = [
          {
            id: 'goToResource',
            name: 'Go to resource',
            tag: 'a',
            icon: 'mdi-open-in-new'
          },
          {
            id: 'delete',
            name: 'Delete',
            tag: 'button',
            icon: 'mdi-delete-outline',
          }
        ]
      }
      if (this.isDraft && (this.isOwner || this.isEditor)) {
        items = items.filter(item => item.id !== 'shareCard' && item.id !== 'goToResource');
      }
      return items;
    },
    imageUrl() {
      if (this.resource.Source === 'myresource') {
        if (this.resource.ResourceImage?.thumbnailUrl) {
          return this.resource.ResourceImage?.thumbnailUrl;
        } else {
          return resourceDefaultImage;
        }
      } else {
        if (this.resource.cardImageUrl) {
          return this.resource.cardImageUrl;
        } else {
          return resourceDefaultImage;
        }
      }
    },
    altDescription() {
      return this.resource.ResourceImage?.altDescription ? this.resource.ResourceImage?.altDescription : 'alt description not available';
    },
    updatedDate() {
      return this.resource.Source === 'myresource' ? this.item.record.modified : this.resource.updated;
    },
    itemUrl() {
      return this.item?.url || this.item?.links?.path || this.item?.files[0]?.DownloadUrl || '';
    },
    itemId() {
      return this.item.record.id;
    },
    years() {
      return this.resource.Source === 'myresource' ? this.parseAndSortYearsOrStages(this.resource.Year, 'K').join(' ') : this.resource.Years?.join(' ');
    },
    isOwner() {
      if (this.item.document?.Source === 'myresource') {
        const userId = this.$store.getters['users/userProfile'].userId.toLowerCase();
        const ownerCaseInsensitive = this.item?.userAccess?.owner.map(owner => owner.toLowerCase());
        return ownerCaseInsensitive?.includes(userId);
      } else {
        return false;
      }
    },
    isEditor() {
      if (this.item.document?.Source === 'myresource') {
        const userId = this.$store.getters['users/userProfile'].userId.toLowerCase();
        const editorCaseInsensitive = this.item?.userAccess?.editor.map(editor => editor.toLowerCase());
        return editorCaseInsensitive?.includes(userId);
      } else {
        return false;
      }
    },
    isViewer() {
      if (this.item.document?.Source === 'myresource') {
        const userId = this.$store.getters['users/userProfile'].userId.toLowerCase();
        const viewerCaseInsensitive = this.item?.userAccess?.viewer.map(viewer => viewer.toLowerCase());
        return viewerCaseInsensitive?.includes(userId);
      } else {
        return false;
      }
    },
    isDraft() {
      return !this.item.record.published;
    },
    cardHeight() {
      return this.$vuetify.breakpoint.mdAndDown ? this.height + 20 : this.height;
    }
  },
  methods: {
    handleGoToResource() {
      this.$store.dispatch('captureGoToResource', this.itemId);
      window.open(this.itemUrl, '_blank');
    },
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    async openDetailsPage() {
      try {
        let res = null;
        // fetch resource details (conditional to dispatch correct action based on the correct action
        if (this.resource.Source === 'myresource') {
          res = await this.$store.dispatch('myResources/fetchResourceDetails', {id: this.itemId});
        }
        if (!res) {
          this.$store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
          await this.$router.push('/notfound');
          return;
        }
        if (this.resource.Source === 'myresource') {
          if (this.isDraft && !this.isViewer) {
            this.$store.commit('myResources/SET_EDIT_MODE', true);
          }
          await this.$router.push('/myresources/' + this.itemId).catch(err => {
            console.log(err)
          }); // MyResources details page
        }
      } catch (e) {
        if (e.name === 'NavigationDuplicated') {
          return;
        }
        this.$store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
        await this.$router.push('/notfound');
      }
    },
    parseAndSortYearsOrStages(arr, str) {
      let arrModified = [];
      let includeString = false;
      if (arr.includes(str)) {
        includeString = true;
        arrModified = arr.filter(s => s !== str);
        arrModified = arrModified.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      } else {
        arrModified = arr.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      }
      if (includeString) {
        arrModified.unshift('K');
      }
      return arrModified;
    },
    handleAction(actionId) {
      switch (actionId) {
        case 'goToResource':
          this.handleGoToResource();
          break;
        case 'editCard':
          this.$store.commit('myResources/SET_EDIT_MODE', true);
          this.$router.push(`/myresources/${this.itemId}`);
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.v-card {
  display: flex;
  flex-direction: column;

  .v-image {
    position: relative;

    &.v-responsive {
      flex: none;
    }

    .editor-chip {
      background-color: $ads-dark-70;
      font-weight: bold;
      color: $ads-white;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }

  .card-footer {
    max-width: 90%;
  }

  .card-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .resource-type {
      font-size: 14px;
      font-weight: bold;
      display: block;
      color: $ads-blue-1;
      line-height: 28px;

      &.resource-type-xs {
        line-height: 24px;
      }
    }

    .draft-chip {
      font-weight: bold;
      background-color: $ads-light-blue;
    }

    .primary-control {
      text-decoration: none;

      &:focus {
        outline-color: $ads-navy;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .card-title {
        font-size: 18px;
        color: $ads-navy;
        font-weight: bold;
        line-height: 23px;
        height: 69px;
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.card-title-md {
          font-size: 16px;
          line-height: 23px;
          height: 46px;
          -webkit-line-clamp: 2;
        }

        &.card-title-xl {
          font-size: 20px;
          line-height: 24px;
          height: 48px;
          -webkit-line-clamp: 2;
        }
      }
    }

    span {
      &.years-sm {
        letter-spacing: -0.5px;
      }

      &.years-xs {
        letter-spacing: -1px;
      }
    }

    .v-card__actions {
      padding: 0;
      position: relative;

      .button-icon {
        box-sizing: border-box;
        border: 2px solid transparent;
        border-radius: 50%;
        padding: 4px;

        &:focus {
          border-color: $ads-navy;
          outline: none;
        }
      }
    }
  }
}
</style>
