<template>
  <v-row>
    <!--    <v-col-->
    <!--      cols="12"-->
    <!--      class="pa-0"-->
    <!--    >-->
    <!--      <v-tabs-->
    <!--        slider-color="#CE0037"-->
    <!--        color="#121212"-->
    <!--        background-color="transparent"-->
    <!--      >-->
    <!--        <v-tab-->
    <!--          @click="showCollection"-->
    <!--          class="page&#45;&#45;tab"-->
    <!--          :style="[isCollection ? {'border-bottom': '4px solid #CE0037 !important', 'width': '100%'} : {'border': 'none'} ]"-->
    <!--        >-->
    <!--          COLLECTION-->
    <!--        </v-tab>-->
    <!--        <v-tab-->
    <!--          @click="showDiscussion"-->
    <!--          class="page&#45;&#45;tab"-->
    <!--          :style="[isDiscussion ? {'border-bottom': '4px solid #CE0037 !important'} : {'border': 'none'} ]"-->
    <!--        >-->
    <!--          DISCUSSION-->
    <!--        </v-tab>-->
    <!--      </v-tabs>-->
    <!--    </v-col>-->
    <v-col
      v-show="isCollection"
      cols="12"
    >
      <v-row class="pa-4">
        <v-col
          cols="12"
          class="d-flex"
        >
          <AdsButton
            secondary
            icon="mdi-arrow-left"
            aria-label="go back"
            button-text="Back"
            class="mr-auto"
            @click="$router.go(-1)"
          />
          <!-- Action Menu -->
          <ActionsMenu
            left
            offset-y
            :nudge-bottom="4"
            :items="collectionActions"
            :button="editModeButton"
            :disabled="selectedCards && selectedCards.length === 0"
            :style="{'float': 'right'}"
            @clickedAction="handleCollectionActionsMenu"
          />
        </v-col>
        <v-col cols="12">
          <AdsTextField
            v-model="searchString"
            solo
            prepend-inner-icon="search"
            clearable
            flat
            class="search-bar"
            placeholder="Search within the collection"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <AdsSelect
            v-model="resourceSort"
            aria-label="Sort by"
            :items="sortOptions"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="content__wrapper"
        >
          <!-- resource cards in grid view -->
          <ResourceGrid
            v-if="isCollection"
            :items="searchResultsSorted"
            :selectedCards="selectedCards"
            @onSelect="onCardSelectHandler"
          />
        </v-col>
      </v-row>
    </v-col>

    <!-- disable it till we have api endpoint ready for discussion...-->
    <!--    <v-col-->
    <!--      v-show="isDiscussion"-->
    <!--    >-->
    <!--      <DiscussionDrawer-->
    <!--        v-if="$vuetify.breakpoint.xsOnly && isDiscussion"-->
    <!--        :members="collection.members"-->
    <!--      />-->
    <!--    </v-col>-->

    <!-- Remove from collection dialog -->
    <RemoveResources
      v-model="showRemoveFromCollectionDialog"
      :items="resources"
      :selectedCards="selectedCards"
      @onSelect="onCardSelectHandler"
    />

    <!-- Copy resources to collection dialog -->
    <CopyToCollection
      v-model="showCopyCollectionDialog"
      :items="resources"
      :selectedCards="selectedCards"
      @onSelect="onCardSelectHandler"
    />

    <!-- Move resources to collection dialog -->
    <MoveToCollection
      v-model="showMoveToCollectionDialog"
      :items="resources"
      :selectedCards="selectedCards"
      @onSelect="onCardSelectHandler"
    />
  </v-row>
</template>
<script>
import { AdsTextField, AdsButton, AdsSelect } from '@nswdoe/doe-ui-core'
import ResourceGrid from '@/components/Resource/ResourceGrid';
import ActionsMenu from '@/views/ResourceEditorDetailsPage/ActionsMenu/ActionsMenu'
import DiscussionDrawer from '@/views/CollectionBoardPage/DiscussionDrawer/DiscussionDrawer.vue';
import LearningSequence from '@/components/Collection/LearningSequence/LearningSequence.vue'
import {
    CopyToCollection,
    MoveToCollection,
    RemoveResources,

  } from '@/components/Dialogs/Collections';

export default {
  name: 'MobileView',
  components:{
    ResourceGrid,
    AdsSelect,
    DiscussionDrawer,
    AdsTextField,
    AdsButton,
    ActionsMenu,
    CopyToCollection,
    MoveToCollection,
    RemoveResources,
    LearningSequence
  },
  data(){
    return{
      showSequence: false,
      isCollection: true,
      isDiscussion: false,
      showCopyCollectionDialog: false,
      showRemoveFromCollectionDialog: false,
      showMoveToCollectionDialog: false,
      searchString: '',
      resourceSort: 'added:asc',
      selectedCards: [],
      editModeButton: {
        'text': 'Actions',
        'icon': 'mdi-dots-vertical',
        'small': this.$vuetify.breakpoint.mdAndDown,
        'secondary': false,
        'tertiary': false
      },
      moreActionsButton: {
        'text': 'Options',
        'color': '#2196f3',
        'icon': 'arrow_drop_down',
        'small': this.$vuetify.breakpoint.mdAndDown,
        'secondary': true,
        'tertiary': false
      },
      sortOptions: [{
        text: 'Date added: Newest to oldest',
        value: 'added:asc'
      }, {
        text: 'Date added: Oldest to newest',
        value: 'added:desc'
      }, {
        text: 'Last updated: Newest to oldest',
        value: 'updated:asc'
      }, {
        text: 'Last updated: Oldest to newest',
        value: 'updated:desc'
      }, {
        text: 'Sequence order',
        value: 'sequence'
      }],
      viewerActions: [
        {
          id: 'copyToAnother',
          name: 'Copy to another collection',
          tag: 'button',
          icon: 'mdi-content-copy',
        },
      ],
      editorActions: [
        {
          id: 'moveToAnother',
          name: 'Move to another collection',
          tag: 'button',
          icon: 'input',
        },
        {
          id: 'delete',
          name: 'Remove from collection',
          tag: 'button',
          icon: 'remove_circle_outline',
        }
      ]
    }
  },
  computed:{
    collectionActions() {
      return this.collection.isOwner? [...this.viewerActions, ...this.editorActions]: [...this.viewerActions]
    },
    editCollectionActions() {
      return this.collection.isOwner?this.ownerEditActions:this.collection.isEditor?this.editorEditActions:this.viewerEditActions
    },
    userId() {
      return this.$store.getters['users/userProfile']?.userId?.toLowerCase() || '';
    },
    collection() {
      const collection = this.$store.state.myCollections.collectionDetails
      const isOwner = collection.userAccess.owner.includes(this.userId)
      const isEditor = collection.userAccess.editor.includes(this.userId)
      return {
        ...collection.document,
        isOwner,
        isEditor,
        userAccess: collection.userAccess,
        resources: collection.subResources,
        members: [ ...collection.userAccess.owner, ...collection.userAccess.editor, ...collection.userAccess.viewer]
      }
    },
    subResources () {
      return this.$store.state.myCollections.subResources
    },
    resources() {
      return this.collection.resources?.map(item => {
        const resourceDetails = this.getSubResource(item.resourceId.id)
        return {
          resourceId: item.resourceId,
          ThumbnailImage: item.image,
          DateAdded: item.dateAdded,
          ...resourceDetails?.document,
          file: resourceDetails?.files?.[0]
        }
      })
    },
    searchResults() {
      if (this.searchString ==='' || !this.searchString ) {
        return this.resources
      } else {
        return this.resources?.filter(resource => resource.Name.toLowerCase().includes(this.searchString.toLowerCase()) || resource.Description.toLowerCase().includes(this.searchString.toLowerCase()))
      }
    },
    searchResultsSorted() {
      let results = [ ...this.searchResults]

      switch (this.resourceSort) {
        case 'added:asc':
          results = results.sort((a, b) => {
            return new Date(a.DateAdded) < new Date(b.DateAdded) ? 1: -1
          })
          break;
        case 'added:desc':
          results = results.sort((a, b) => {
            return new Date(a.DateAdded) < new Date(b.DateAdded) ? -1: 1
          })
          break;
        case 'updated:asc':
          results = results.sort((a, b) => {
            console.log(a.DateModified, b.DateModified)
            return new Date(a.DateModified) < new Date(b.DateModified) ? 1: -1
          })
          break;
        case 'updated:desc':
          results = results.sort((a, b) => {
            console.log(a.DateModified, b.DateModified)
            return new Date(a.DateModified) < new Date(b.DateModified) ? -1: 1
          })
          break;
        case 'sequence':
          break;
        default:
          results = results.sort((a, b) => {
            return new Date(a.DateAdded) < new Date(b.DateAdded) ? 1: -1
          })
          break;
      }
      return results
    },
  },
  watch: {
    userId: {
      immediate: true,
      async handler() {
        await this.$store.dispatch('myCollections/fetchMyEditableCollections');
      }
    }
  },
  methods:{
    handleBack(){
      this.showSequence = false;
    },
    onCardSelectHandler(val) {
      /**
       * val: {
       *   select: type Boolean, // selected or not
       *   item: type Object<ResourceItem>
       * }
       *
       * **/
      if(val.select) {
        // add object to array
        this.selectedCards.push(val.item)
      } else {
        //delete from list
        this.selectedCards = this.selectedCards.filter(card => card.ItemID !== val.item.ItemID)
      }

      console.log(this.selectedCards.length + ' selected' + val.select)
    },
    showCollection(event){
      if(event.target.innerText === 'COLLECTION'){
        this.isCollection = true;
        this.isDiscussion = false;
      }
    },
    showDiscussion(event){
      if(event.target.innerText === 'DISCUSSION'){
        this.isCollection = false;
        this.isDiscussion = true;
      }
    },
    getSubResource(id) {
      return this.subResources.find(resource => resource.document.ItemID === id)
    },
    async fetchCollection() {
      await this.$store.dispatch('myCollections/fetchCollection', {id: this.collection.ItemID, rce: true});
    },
    clearSelection() {
      this.selectedCards = []
    },
    handleCollectionActionsMenu(action) {
      console.log(action)
      switch (action) {
        case 'copyToAnother':
          this.showCopyCollectionDialog = true
          break;
        case 'moveToAnother':
          this.showMoveToCollectionDialog = true
          break;
        case 'delete':
          this.showRemoveFromCollectionDialog = true
          break;
        case 'info':
          this.informationDrawer = true;
          break;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/variables";

::v-deep .collection-metadata {
  .right-panel {
    min-width: 30%;
  }
}

.v-tab.page--tab{
  flex: 1;
}

.action-container:last-child{
  float: right;
}
</style>
