<template>
  <Dialog
    v-bind="$attrs"
    :hide-heading="false"
    :width="$vuetify.breakpoint.width > 1700 ? 800 : 600"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="alert-dialog-container">
        <v-card-text>
          <div class="alert-dialog-container__icon">
            <v-icon size="100">
              mdi_error_outline
            </v-icon>
          </div>
        </v-card-text>
        <v-card-text>
          <h2>
            {{ title }}
          </h2>
          <slot name="message" />
        </v-card-text>
        <v-card-text v-if="resourceIdExist">
          <slot name="existingResourceId" />
        </v-card-text>
        <v-card-actions
          :class="{'d-flex justify-center px-6 py-6 mt-10': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}"
        >
          <slot name="buttons" />
        </v-card-actions>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Illustration from '@/assets/illustrations.svg'
import Dialog from '@/components/Dialogs/Dialog';

export default {
  name: 'ErrorDialog',
  components:{
    Dialog,
  },
  props:{
    title:{
      type: String,
      default: ''
    },
    resourceIdExist:{
      type: Boolean,
      default: false
    }
  },
  data: () =>({
    Illustration,
    showOverWriteAlert: false
  }),
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";
@import "../../../scss/mixins";
.alert-dialog{
  &-container {
    text-align: center;
    margin-top: 24px;

    @include respond(phone) {

      display: flex;
      flex-flow: column;
      position: relative;
      min-height: calc(100vh - 200px);

      .footer-mobile{
        width: 100%;
      }
    }

    &__icon{
      width: 140px;
      height: 140px;
      border-radius: 100%;
      background-color: $ads-blue-3-dark;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px auto;
      padding: 20px;
      text-align: center;
      position: relative;
      & i {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}
.create-success{
  &-container {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include respond(phone) {

      display: flex;
      flex-flow: column;
      position: relative;
      min-height: calc(100vh - 200px);

      .footer-mobile{
        width: 100%;
      }
    }
    &__svg{
      margin-bottom: 10px;

      .v-image {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.create-success-container,
::v-deep.alert-dialog-container {
  & h2 {
    font-size: 22px;
    color: $ads-navy;
  }

  & p {
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
  }

  & span {
    font-size: 16px;
  }
}
</style>
