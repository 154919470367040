import {ADS_Colors} from '@nswdoe/doe-ui-core';
import {ELASTIC_STATUS} from '@/constants';

const statusChipMixin = {
  methods: {
    statusChipOutlined(itemStatus, darkMode = false) {
      // only draft and pending are outlined
      if (!darkMode && (itemStatus.toLowerCase() === ELASTIC_STATUS.PENDING || itemStatus.toLowerCase() === ELASTIC_STATUS.DRAFT)) {
        return true;
      }
      return false;
    },
    statusChipColor(itemStatus, darkMode = false) {
      let color;
      switch (itemStatus.toLowerCase()) {
        case ELASTIC_STATUS.DRAFT:
        case ELASTIC_STATUS.PENDING:
          color = darkMode? ADS_Colors.Light_20 :ADS_Colors.PRIMARY_BLUE;
          break;
        case ELASTIC_STATUS.PUBLISHED:
          color = ADS_Colors.Blue_2;
          break;
        case ELASTIC_STATUS.DECLINED:
          color = ADS_Colors.PRIMARY_RED;
          break;
        case ELASTIC_STATUS.ENDORSED:
          color = ADS_Colors.PRIMARY_TEAL;
          break;
        case ELASTIC_STATUS.ARCHIVED:
          color = ADS_Colors.SECONDARY_GREY;
          break;
      }
      return color;
    },
    statusChipTextColor(itemStatus) {
      let textColor;
      switch (itemStatus.toLowerCase()) {
        case ELASTIC_STATUS.ENDORSED:
        case ELASTIC_STATUS.PENDING:
          textColor = ADS_Colors.PRIMARY_BLUE;
          break;
        case ELASTIC_STATUS.PUBLISHED:
        case ELASTIC_STATUS.DECLINED:
          textColor = ADS_Colors.White;
          break;
        case ELASTIC_STATUS.DRAFT:
        case ELASTIC_STATUS.ARCHIVED:
          textColor = ADS_Colors.GREY_02;
          break;
      }
      return textColor;
    }
  }
};

export default statusChipMixin;
