<template>
  <v-row align="start">
    <v-col
      cols="12"
      sm="3"
      lg="3"
      md="3"
      class="pl-3"
      :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
    >
      <span class="font-weight-bold">{{ label }}:</span>
      <div v-if="text">
        {{ text }}
      </div>
    </v-col>
    <v-col
      v-if="items.length > 0"
      cols="12"
      sm="9"
      lg="9"
      md="9"
      class="pt-1"
      :class="{'pt-0': $vuetify.breakpoint.xsOnly, 'pl-1': true}"
    >
      <v-chip
        v-for="(item, itemIndex) in items"
        :key="item.replace(/\s+/g, '') + itemIndex"
        tabindex="0"
        color="#041E42"
        outlined
        text-color="#041E42"
        class="chip ma-2"
        role="button"
        :title="title"
        @click="$emit('chipSelected', item)"
        @keyup.enter="$emit('chipSelected', item)"
      >
        {{ item }}
      </v-chip>
    </v-col>
    <v-col
      v-else
      cols="12"
      sm="9"
      md="9"
      lg="9"
      :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
    >
      <span>None</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FieldRowWithChips',
  props: {
    label: {
      type: String
    },
    text: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    title:{
      type: String,
      default: ''
    }
  }
}
</script>
