<template>
  <li class="listItem">
    <a
      style="font-weight: bold; font-size: 14px; text-decoration: none;"
      :href="url"
      target="_blank"
      v-html="text"
    />
  </li>
</template>

<script>
export default {
  name: 'StaffRoomItem',
  props: {
    color: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    url: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.listItem {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
</style>
