<template>
  <div>
    <Dialog
      v-bind="$attrs"
      :hide-heading="false"
      :max-width="$vuetify.breakpoint.mdAndUp ? 800 : '100%'"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #content>
        <v-container
          class="create-success-container"
        >
          <v-card-text class="create-success-container__svg">
            <v-img
              :src="Illustration"
              width="200"
            />
          </v-card-text>
          <v-card-text>
            <h2>
              Success! Do you want to invite members to your collection?
            </h2>
            <p>Create a space where you and your colleagues can contribute to this collection</p>
          </v-card-text>
          <v-card-actions :class="{'d-flex justify-center px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
            <AdsButton
              secondary
              button-text="Close"
              @click="$downwardBroadcast('close:dialog');"
              @keyup.enter="$downwardBroadcast('close:dialog');"
            />
            <!-- if no selected cards, disable the Save button -->
            <AdsButton
              primary
              button-text="Invite Members"
              icon="person_add_alt"
              @click="openInviteMemberDilaog"
              @keyup.enter="openInviteMemberDilaog"
            />
          </v-card-actions>
        </v-container>
      </template>
    </Dialog>
    <InviteMembers
      v-model="showInviteMember"
      :collection="newCreatedCollections"
    />
  </div>
</template>

<script>
import Illustration from '@/assets/illustrations.svg'
import Dialog from '@/components/Dialogs/Dialog';
import { AdsButton} from '@nswdoe/doe-ui-core';
import { InviteMembers } from '@/components/Dialogs/Collections'
import {mapGetters} from 'vuex';


export default {
  name: 'SuccessDialog',
  components:{
    InviteMembers,
    Dialog,
    AdsButton,
  },
  props:{
    newCollections:{
      type: Object,
      default: () => {}
    }
  },
  data: () =>({
    Illustration,
    showInviteMember: false
  }),
  computed:{
    ...mapGetters({
      newCreatedCollections: 'myCollections/newCreatedCollection',
    }),
  },
  methods:{
    openInviteMemberDilaog(){
      //this.$emit('handle:action', this.collection);
      this.showInviteMember = true;
      //this.$downwardBroadcast('close:dialog');
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";
@import "../../../scss/mixins";
  .alert-dialog{
    &-container {
      text-align: center;
      margin-top: 24px;

      @include respond(phone) {

        display: flex;
        flex-flow: column;
        position: relative;
        min-height: calc(100vh - 200px);

        .footer-mobile{
          width: 100%;
        }
      }

      &__icon{
        width: 140px;
        height: 140px;
        border-radius: 100%;
        background-color: $ads-blue-3-dark;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto 20px auto;
        padding: 20px;
        text-align: center;
        position: relative;
        & i {
          display: block;
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
  .create-success{
    &-container {
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      @include respond(phone) {

        display: flex;
        flex-flow: column;
        position: relative;
        min-height: calc(100vh - 200px);

        .footer-mobile{
          width: 100%;
        }
      }
      &__svg{
        margin-bottom: 10px;

        .v-image {
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .create-success-container,
  .alert-dialog-container {
    & h2 {
      font-size: 20px;
      color: $ads-navy;
    }

    & p {
      padding: 10px;
      font-size: 16px;
    }
  }
</style>
