<template>
  <Dialog
    v-bind="$attrs"
    icon="share"
    :title="title"
    :hide-heading="false"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 760"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="new-collection-content">
        <v-sheet>
          <v-list class="d-flex">
            <v-list-item>
              <Dialog
                v-model="dialogEmbedCode"
                title="Embed this code"
                dark-heading
                :width="$vuetify.breakpoint.smAndUp ? 500 : 360"
              >
                <template #activator>
                  <div class="d-flex flex-column align-center">
                    <div class="button-icon">
                      <v-btn
                        icon
                        :color="ADS_Colors.Light_40"
                        @click.stop.prevent="handleDialog('EmbedCode')"
                      >
                        <v-icon size="48">
                          code
                        </v-icon>
                      </v-btn>
                    </div>
                    <span>Embed Code</span>
                  </div>
                </template>
                <template #content>
                  <CopyClipboardMenu
                    :is-open="dialogEmbedCode"
                    description="Copy and paste this code to embed on your platform to use this:"
                    :text-to-copy="embedCode"
                  />
                </template>
              </Dialog>
            </v-list-item>
            <v-list-item>
              <Dialog
                v-model="dialogEmail"
                title="Email this resource"
                dark-heading
                :width="$vuetify.breakpoint.smAndUp ? 500 : 360"
              >
                <template #activator>
                  <div class="d-flex flex-column align-center">
                    <div class="button-icon">
                      <v-btn
                        icon
                        :color="ADS_Colors.Light_40"
                        @click.stop.prevent="handleDialog('Email')"
                      >
                        <v-icon
                          size="48"
                          class="material-icons-outlined"
                        >
                          email
                        </v-icon>
                      </v-btn>
                    </div>
                    <span>Email</span>
                  </div>
                </template>
                <template #content>
                  <ShareEmailMenu
                    v-if="dialogEmail"
                    v-model="dialogEmail"
                    :resource-links="[{ url: item.url, name: item.id }]"
                    context="editor"
                    @displayDetails="getResultDetails"
                  />
                  <RecipientDetails
                    v-if="resultDetails && $vuetify.breakpoint.mdAndUp"
                    :details="resultDetails"
                    :position="hoverPosition"
                  />
                </template>
              </Dialog>
            </v-list-item>
            <v-list-item>
              <div class="d-flex flex-column align-center">
                <div class="button-icon">
                  <span
                    id="googleClassroom-trigger"
                    class="google-classroom-span mr-3"
                    @click="googleClassroomClick"
                  />
                </div>
                <div class="">
                  Google Classroom
                </div>
              </div>
            </v-list-item>
            <v-list-item class="d-flex align-center">
              <div class="d-flex flex-column align-center">
                <div class="button-icon">
                  <span
                    :id="`msTeams-trigger`"
                    class="teams-share-button mr-3"
                    data-icon-px-size="48"
                    :data-href="item.url"
                    @click="msTeamsClick"
                  />
                </div>
                <div>Microsoft Teams</div>
              </div>
            </v-list-item>
          </v-list>
          <v-container>
            <v-divider />
            <v-card-title>
              <h3>Copy URL</h3>
            </v-card-title>
            <v-container class="d-flex flex-row">
              <div
                class="mx-3"
                style="max-width: 80%;"
              >
                <code>{{ item.url }}</code>
              </div>
              <AdsButton
                v-clipboard:copy="item.url"
                v-clipboard:success="() => copySucceeded = true"
                :button-text="copySucceeded ? 'Copied!' : 'Copy'"
                :icon="copySucceeded ? 'check' : ''"
                :aria-label="copySucceeded ? 'copied' : 'copy to clipboard'"
              />
            </v-container>
          </v-container>
        </v-sheet>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import { ADS_Colors, AdsButton } from '@nswdoe/doe-ui-core';
import ShareEmailMenu from '@/components/ShareEmailMenu';
import CopyClipboardMenu from '@/components/CopyClipboardMenu';
import RecipientDetails from '@/components/SearchDoeStaff/RecipientDetails.vue';
import { mapState } from 'vuex';

export default {
  name: 'ShareListDialog',
  components: {
    AdsButton,
    CopyClipboardMenu,
    ShareEmailMenu,
    Dialog,
    RecipientDetails
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Share'
    }
  },
  data() {
    return {
      ADS_Colors,
      dialogCopyLink: false,
      dialogEmail: false,
      dialogEmbedCode: false,
      resultDetails: null,
      hoverPosition: null,
      name: '',
      copySucceeded: false,
    }
  },
  mounted() {
    this.$eventHub.$on('show:dialog', this.showDialog);
    // try to use updated()?
    // this time delay is required as the js files of teams/google are not loaded yet after the component's rendering
    setTimeout(() => {
      this.displayGoogleClassroomShare();
      this.displayMsTeamsShare();
    }, 400);
  },
  beforeUnmount() {
    this.$eventHub.$off('show:dialog');
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.myResources.resourceDetails
    }),
    url() {
      return this.item.url || '';
    },
    resourceName() {
      return this.item?.name || ''
    },
    embedCode() {
      return `<iframe frameborder="0" style="overflow: hidden; height: 100%; width: 100%;" height="100%" width="100%" src=${this.url}></iframe>`;
    }
  },
  methods: {
    showDialog() {
      switch (this.name) {
        case 'Email':
          this.dialogEmail = true;
          this.name = '';
          break;
        case 'EmbedCode':
          this.dialogEmbedCode = true;
          this.name = '';
          break;
      }
    },
    handleDialog(name) {
      this.name = name;
      if (this.resourceDetails?.document.Rights === 'Rights not known.' || this.resourceDetails?.document.Rights === '') {
        this.$eventHub.$emit('show:copyright');
      } else {
        this.showDialog();
      }
    },
    getResultDetails(details, position) {
      this.resultDetails = details;
      this.hoverPosition = position;
    },
    displayGoogleClassroomShare() {
      const gapi = window['gapi'];  // included in index.html
      if (gapi === undefined) {
        console.error('DetailCardDialog - window.gapi is undefined');
      } else {
        gapi.sharetoclassroom.render('googleClassroom-trigger', {
          size: '48',
          url: this.url, // this was missed in one of the previous releases, I think Vish made this change to cater for files sharing and changed it to return in this.url. Issue reported in JIRA-1755
          title: 'Google Classroom',
          onsharestart: this.googleClassroomClick
        });
      }
    },
    displayMsTeamsShare() {
      const shareToMicrosoftTeams = window['shareToMicrosoftTeams'];
      if (shareToMicrosoftTeams === undefined) {
        console.error('DetailCardDialog - window.shareToMicrosoftTeams is undefined');
      } else {
        shareToMicrosoftTeams.renderButtons({
          elements: [document.getElementById('msTeams-trigger')]
        });
      }
    },
    msTeamsClick() {
      this.$store.dispatch('captureTeamsShare', {
        id: this.item.id,
        name: this.item.Name
      });
    },
    googleClassroomClick() {
      this.$store.dispatch('captureGoogleClassroomShare', {
        id: this.item.id,
        name: this.item.Name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.v-list {
  justify-content: space-between;
  align-items: center;
  .v-list-item {
    display: block;
    width: 100%;
    padding: 0 8px;
  }
}

.button-icon {
  margin-top: 20px;
  height: 80px;
}
</style>
