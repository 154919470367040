import api from '@/api';
import Vue from 'vue';
import {apiBaseUrl, apiBaseUrlV2} from '@/store/config';
import {delay} from '@/store/helpers';

const CONFIG = (window as any).dlrhConfig;

let librariesArr;

export function generateQueriesFromFilter(filters) {
  const queries: string[] = [];
  if (filters.myResourcesLibraries) {
    librariesArr = filters.myResourcesLibraries.split(',');
    // queries.push(`action=${libraries}`);
  }
  if (filters.years) { // MyResource page
    queries.push(`Year=${filters.years}`);
  }
  if (filters.klas) {  // MyResource page
    queries.push(`KLA=${filters.klas}`);
  }
  if (filters.types) {  // MyResource page
    queries.push(`ResourceType=${filters.types}`);
  }
  if (filters.formats) { // MyResource page & Hub page
    queries.push(`Format=${filters.formats}`);
  }

  if (queries.length > 0) {
    return queries.join('&'); // array to string
  } else {
    return '';
  }
}

const moduleMyResources = {
  namespaced: true,
  state: {
    sorting: 'updated:desc',
    resourcesCreatedByMe: {
      items: [],
      metadata: {
        count: 0
      }
    },
    resourcesSharedWithMe: {
      items: [],
      metadata: {
        count: 0
      }
    },
    resourcesSharedByMe: {
      items: [],
      metadata: {
        count: 0
      }
    },
    searchResults: {
      items: [],
      metadata: {
        count: 0
      },
    },
    searchMode: false,
    filters: { // for My Resources search API calls in Vuex action, used to generate URL queries, filters -> queries
      myResourcesLibraries: '',
      years: '',
      klas: '',
      types: '',
      formats: '',
    },
    filtersSelection: { // filters selection by the user, source of v-model on <ChipFilters>.
      checkboxMyResourcesLibraries: [],
      checkboxYears: [],
      checkboxKLAs: [],
      checkboxResourceType: [],
      checkboxFormat: []
    },
    /*
    The resourceDetails object structure matches the api response.data object, e.g.:
    {
      archive: [,…]
      audit: {iss: "dlrh", sub: "kiran.test"}
      curriculum: {}
      document: {resourceImage: {author: "Pawel Czerwinski", altDescription: "red and blue wallpaper",…},…}
      draft: {}
      files: []
      links: {path: "https:google.com"}
      misc: {subSource: "", likes: 0, likedBy: [], hasLiked: false, views: 35}
      professionalLearning: []
      record: {created: "2022-05-31T06:48:55Z", collection: "myresource", originalId: "Gqqnq4AB3E-CD-QpFIH9",…}
      userAccess: {owner: ["kiran.test"], scope: 0, editor: [], viewer: [], schools: []}
    }
     */
    resourceDetails: null, // the whole object's initial value is null for easy nullable checking
    pageNum: 1,
    keywords: '',
    editMode: false,
    removeProgressionsDialog: false,
    clearProgressions: false,
    lastDeletedResource: '',
    versionHistory: [],
    currentVersion: 1,
    notifications: [],
    resourceNotifications: [],
    tokenWillExpire: false
  },
  getters: {
    sorting: state => state.sorting,
    searchResults: state => state.searchResults.items,
    searchResultsCount: state => state.searchResults.metadata.count,
    resourcesCreatedByMe: state => state.resourcesCreatedByMe.items,
    createdByMeCount: state => state.resourcesCreatedByMe.metadata.count,
    resourcesSharedWithMe: state => state.resourcesSharedWithMe.items,
    sharedWithMeCount: state => state.resourcesSharedWithMe.metadata.count,
    resourcesSharedByMe: state => state.resourcesSharedByMe.items,
    sharedByMeCount: state => state.resourcesSharedByMe.metadata.count,
    filters: state => state.filters,
    removeProgressionsDialog: state => state.removeProgressionsDialog,
    clearProgressions: state => state.clearProgressions,
    currentVersion: state => state.currentVersion,
    resourceNotifications: state => state.resourceNotifications,
    isFiltersEmpty: state => {
      for (const filter in state.filters) {
        if (state.filters[filter] !== '') {
          return false;
        }
      }
      return true;
    }
  },
  mutations: {
    SET_RESOURCE_DETAILS(state, resource) { // called in fetchResources() when calling the api to get data
      state.resourceDetails = resource;
    },
    SET_DOCUMENT(state, document) {
      if (state.resourceDetails) { // initial value is null
        state.resourceDetails.document = document;
      }
    },
    SET_LINK(state, link) {
      if (state.resourceDetails) {
        state.resourceDetails.links.path = link;
      }
    },
    SET_FILE_NAME(state, fileName) {
      if (state.resourceDetails?.files?.length > 0) {
        state.resourceDetails.files[0].FileName = fileName;
      }
    },
    SET_FILTERS_SELECTION(state, value) {
      state.filtersSelection = value;
    },
    SET_SORTING(state, val) {
      state.sorting = val;
    },
    SET_SEARCH_RESULTS(state, val) {
      state.searchResults.metadata = val.metadata;
      if (state.userScroll) {
        state.searchResults.items = state.searchResults.items.concat(val.items);
      } else {
        state.searchResults.items = val.items;
      }
    },
    SET_RESOURCE_CREATED_BY_ME(state, val) {
      state.resourcesCreatedByMe.metadata = val.metadata;
      if (state.userScroll) {
        state.resourcesCreatedByMe.items = state.resourcesCreatedByMe.items.concat(val.items);
      } else {
        state.resourcesCreatedByMe.items = val.items;
      }
    },
    SET_RESOURCE_SHARED_WITH_ME(state, val) {
      state.resourcesSharedWithMe.metadata = val.metadata;
      if (state.userScroll) {
        state.resourcesSharedWithMe.items = state.resourcesSharedWithMe.items.concat(val.items);
      } else {
        state.resourcesSharedWithMe.items = val.items;
      }
    },
    SET_RESOURCE_SHARED_BY_ME(state, val) {
      state.resourcesSharedByMe.metadata = val.metadata;
      if (state.userScroll) {
        state.resourcesSharedByMe.items = state.resourcesSharedByMe.items.concat(val.items);
      } else {
        state.resourcesSharedByMe.items = val.items;
      }
    },
    SET_HASLIKED(state, hasLiked) {
      state.resourceDetails.misc.hasLiked = hasLiked;
    },
    SET_SEARCH_MODE(state, val) {
      state.searchMode = val;
    },
    SET_KEYWORDS(state, val) {
      state.keywords = val;
    },
    SET_FILTERS(state, val) {
      state.filters = val;
    },
    SET_EDIT_MODE(state, val) {
      state.editMode = val;
    },
    SET_REMOVE_PROGRESSIONS_DIALOG(state, val) {
      state.removeProgressionsDialog = val;
    },
    SET_CLEAR_PROGRESSIONS(state, val) {
      state.clearProgressions = val;
    },
    RESET_SEARCH(state) {
      state.lastSearch = '';
      state.pageNum = 1;
      state.resourcesCreatedByMe.metadata = {};
      state.resourcesCreatedByMe.items = [];
    },
    SET_USER_SCROLL(state) {
      state.userScroll = true;
    },
    RESET_USER_SCROLL(state) {
      state.userScroll = false;
    },
    RESET_PAGE_NUM(state) {
      state.pageNum = 1;
    },
    INCREASE_PAGE_NUM(state) {
      state.pageNum += 1;
    },
    SET_LAST_DELETED_RESOURCE(state, val) {
      state.lastDeletedResource = val;
    },
    SET_VERSION_HISTORY(state, val) {
      state.versionHistory = val;
    },
    SET_CURRENT_VERSION(state, val) {
      state.currentVersion = val;
    },
    SET_NOTIFICATIONS(state, val) {
      state.notifications = val;
    },
    SET_RESOURCE_NOTIFICATIONS(state, val) {
      state.resourceNotifications = val;
    },
    SET_TOKEN_WILL_EXPIRE(state, val) {
      state.tokenWillExpire = val;
    }
  },
  actions: {
    async fetchResources({commit, state, rootState}, row) {
      commit('SET_IS_LOADING', true, {root: true});

      let resourcesPerPage = 16;
      // if screen resolution is higher than Full HD (1920 X 1080), it fetches 48 resources in the initial load
      if (state.resourcesCreatedByMe.items.length === 0 && window.innerWidth > 1920) {
        resourcesPerPage = 48;
      }
      // let resourcesURL = `?perpage=${resourcesPerPage}&sort=${state.sorting}`;
      let resourcesURL = `?size=${resourcesPerPage}&sort=${state.sorting}`;
      // search within MyResources
      let res;
      if (state.searchMode) { // searching view
        // if there is keywords, add the keywords param to the URL
        if (state.keywords && state.keywords.length >= 3) {
          // handle special characters reserved for query string parameters
          let keywords = state.keywords;
          keywords = keywords.replace(/[`~!@#$%^&*+=|<>/\\()]/g, '');
          keywords = keywords.replace(' - ', ' _ ');
          resourcesURL = `${resourcesURL}&keyword=${keywords}`;
        }
        // always generate queries from Filter, if no filter, it is also fine
        const queries = await generateQueriesFromFilter(state.filters);

        if (queries) {
          resourcesURL = `${resourcesURL}&${queries}`;
        }
        /*        if (state.filters.myResourcesLibraries === '') {
                  resourcesURL = `${resourcesURL}&userAccess.owner=${rootState.uid}`;
                }*/

        let body = {
          'customQuery': {
            'bool': {
              'minimum_should_match': 1,
              'should': [
                {
                  'terms': {
                    'userAccess.owner': [
                      rootState.uid
                    ]
                  }
                },
                {
                  'terms': {
                    'userAccess.editor': [
                      rootState.uid
                    ]
                  }
                },
                {
                  'terms': {
                    'userAccess.viewer': [
                      rootState.uid
                    ]
                  }
                }
              ]
            }
          }
        };

        let bodyLibraries: any = {
          'customQuery': {
            'bool': {
              //'minimum_should_match': 1,
              //'should': []
              'filter': {
                'bool': {
                  'should': []
                }
              }
            }
          }
        };

        const shouldCreatedByMe = {
          'terms': {
            'userAccess.owner': [
              rootState.uid
            ]
          }
        };

        const shouldSharedWithMeEditor = {
          'terms': {
            'userAccess.editor': [
              rootState.uid
            ]
          }
        };

        const shouldSharedWithMeViewer = {
          'terms': {
            'userAccess.viewer': [
              rootState.uid
            ]
          }
        };

        const mustSharedByMeUser = {
          'term': {
            'userAccess.owner': rootState.uid
          }
        };

        const mustSharedByMeScope = {
          'term': {
            'userAccess.scope': 1
          }
        };

        if (librariesArr?.length === 1) {
          console.log('Length: ' + librariesArr.length)
          switch (librariesArr[0]) {
            case 'createdbyme': {
              console.log('lib: createdbyme')
              bodyLibraries.customQuery.bool.filter.bool.should.push(shouldCreatedByMe);
              break;
            }
            case 'sharedwithme': {
              console.log('lib: sharedwithme')
              bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeEditor);
              bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeViewer);
              break;
            }
            case 'sharedbyme': {
              console.log('lib: sharedbyme')
              bodyLibraries = {
                'customQuery': {
                  'bool': {
                    'filter': {
                      'bool': {
                        'must': [] // this needs to change to a must (i.e. AND)
                      }
                    }
                  }
                }
              }

              bodyLibraries.customQuery.bool.filter.bool.must.push(mustSharedByMeUser);
              bodyLibraries.customQuery.bool.filter.bool.must.push(mustSharedByMeScope);
              break;
            }
          }
        } else if (librariesArr?.length === 2) {
          console.log('Length: ' + librariesArr?.length)
          if (librariesArr.includes('createdbyme') && librariesArr.includes('sharedwithme')) {
            console.log('lib: createdbyme:sharedwithme')
            bodyLibraries.customQuery.bool.filter.bool.should.push(shouldCreatedByMe);
            bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeEditor);
            bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeViewer);
          } else if (librariesArr.includes('createdbyme') && librariesArr.includes('sharedbyme')) {
            console.log('lib: createdbyme:sharedbyme')
            bodyLibraries = {
              'customQuery': {
                'bool': {
                  'filter': {
                    'bool': {
                      'must': {
                        'term': {
                          'userAccess.owner': rootState.uid
                        }
                      },
                      'should': [
                        {
                          'bool': {
                            'filter': {
                              'term': {
                                'userAccess.scope': 1
                              }
                            }
                          }
                        }
                      ]
                    }
                  }
                }
              }
            };
          } else if (librariesArr.includes('sharedwithme') && librariesArr.includes('sharedbyme')) {
            console.log('lib: sharedwithme:sharedbyme')
            bodyLibraries = {
              'customQuery': {
                'bool': {
                  'filter': {
                    'bool': {
                      'should': [
                        {
                          'term': {
                            'userAccess.editor': rootState.uid
                          }
                        },
                        {
                          'term': {
                            'userAccess.viewer': rootState.uid
                          }
                        },
                        {
                          'bool': {
                            'must': [
                              {
                                'term': {
                                  'userAccess.owner': rootState.uid
                                }
                              },
                              {
                                'term': {
                                  'userAccess.scope': 1
                                }
                              }
                            ]
                          }
                        }
                      ]
                    }
                  }
                }
              }
            };
          }
        } else if (librariesArr?.length === 3) {
          console.log('Length: ' + librariesArr?.length)
          bodyLibraries.customQuery.bool.filter.bool.should.push(shouldCreatedByMe);
          bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeEditor);
          bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeViewer);
        }

        console.log('librariesArr = ', librariesArr);
        console.log('bodyLibraries = ', JSON.stringify(bodyLibraries));
        if (librariesArr) { // Libraries filter is selected
          body = bodyLibraries;
        }

        res = await api.myresources.getResources(apiBaseUrlV2, {
          path: resourcesURL,
          uid: rootState.uid,
          page: state.pageNum
        }, body);

        if (res) {
          const searchResults = {
            metadata: res.meta,
            items: res.resources
          };
          commit('SET_SEARCH_RESULTS', searchResults);
          commit('SET_IS_LOADING', false, {root: true});
        }
      } else { // landing view, searchMode = false
        try {
          if (row === 'createdByMe') {
            const body = {
              'customQuery': {
                'bool': {
                  'minimum_should_match': 1,
                  'should': [
                    {
                      'terms': {
                        'userAccess.owner': [
                          rootState.uid
                        ]
                      }
                    }
                  ]
                }
              }
            };
            res = await api.myresources.getCreatedResources(apiBaseUrlV2, resourcesURL, state.pageNum, rootState.uid, body);

            if (res) {
              const resourcesCreatedByMe = {
                metadata: res.meta,
                items: res.resources
              };
              commit('SET_RESOURCE_CREATED_BY_ME', resourcesCreatedByMe);
            }
          }

          if (row === 'sharedWithMe') {
            const body = {
              'customQuery': {
                'bool': {
                  'minimum_should_match': 1,
                  'should': [
                    {
                      'terms': {
                        'userAccess.editor': [
                          rootState.uid
                        ]
                      }
                    },
                    {
                      'terms': {
                        'userAccess.viewer': [
                          rootState.uid
                        ]
                      }
                    }
                  ]
                }
              }
            };
            res = await api.myresources.getResources(apiBaseUrlV2, {path: resourcesURL, uid: rootState.uid, page: state.pageNum}, body);

            if (res) {
              const resourcesSharedWithMe = {
                metadata: res.meta,
                items: res.resources
              };
              commit('SET_RESOURCE_SHARED_WITH_ME', resourcesSharedWithMe);
            }
          }

          if (row === 'sharedByMe') {
            const body = {
              'customQuery': {
                'bool': {
                  'filter': {
                    'bool': {
                      'must': [
                        {
                          'term': {
                            'userAccess.owner': rootState.uid
                          }
                        },
                        {
                          'term': {
                            'userAccess.scope': 1
                          }
                        }
                      ]
                    }
                  }
                }
              }
            };
            res = await api.myresources.getResources(apiBaseUrlV2, {path: resourcesURL, uid: rootState.uid, page: state.pageNum}, body);

            if (res) {
              const resourcesSharedBYMe = {
                metadata: res.meta,
                items: res.resources
              };
              commit('SET_RESOURCE_SHARED_BY_ME', resourcesSharedBYMe);
            }
          }
        } catch (e) {
          console.error(e);
        } finally {
          commit('SET_IS_LOADING', false, {root: true});
        }
      }
    },
    async fetchResourceDetails({commit, rootState}, {id, fromLike = false}) {
      const payload = {
        uid: rootState.uid,
        resourceId: id
      }
      const response: any = await api.myresources.getResourceDetails(apiBaseUrlV2, payload, fromLike);
      commit('SET_RESOURCE_DETAILS', response?.data.resources[0]);
      return response?.data.resources[0];
    },
    async createResource({commit, state, rootState}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const requestData = {
          uid: rootState.uid,
          payload: payload.requestPayload
        }
        const resource: any = await api.myresources.createResource(apiBaseUrl, requestData);
        commit('SET_RESOURCE_DETAILS', resource?.data);

        if (payload.displayAlert) {
          const alert = {
            type: 'success',
            text: 'Your resource has been saved'
          }
          commit('SET_ALERT', alert, {root: true});
          commit('SET_EDIT_MODE', false);
        }
        return resource.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async updateResource({commit, state, rootState}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const requestData = {
          uid: rootState.uid,
          firstName: rootState.users.userProfile.firstName,
          lastName: rootState.users.userProfile.lastName,
          resourceId: state.resourceDetails.record.id,
          newDocument: payload.requestPayload.newDocument,
          newFiles: payload.requestPayload.newFiles,
          newLinks: payload.requestPayload.newLinks
        }
        const resource: any = await api.myresources.updateResource(apiBaseUrl, requestData);
        commit('SET_RESOURCE_DETAILS', resource?.data);

        if (payload.displayAlert) {
          const alert = {
            type: 'success',
            text: 'Your resource has been updated'
          }
          commit('SET_ALERT', alert, {root: true});
          commit('SET_EDIT_MODE', false);
        }
        return resource.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your changes'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async copyResource({commit, state, rootState, dispatch}, {action, version}) {
      const copiedResource: any = null;
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const requestData: any = {
          uid: rootState.uid,
          resourceId: state.resourceDetails.record.id,
          action,
          index: state.resourceDetails.archive.findIndex(i => Number(i.record.version) === Number(version))
        }

        const alert = {
          type: 'success',
          text: `Successfully made a copy of '${state.resourceDetails.document.Name}'`
        }

        const copiedResourceBeforeFinalise = await api.myresources.copyResource(apiBaseUrl, requestData);
        if (copiedResourceBeforeFinalise?.data?.document) {
          const originalName = copiedResourceBeforeFinalise?.data.document.Name;
          // update resource name without creating a new version and finalise copy
          copiedResourceBeforeFinalise.data.document.Name = 'Copy of ' + copiedResourceBeforeFinalise.data.document.Name;
          // requestData.modifiedDocument = copiedResourceBeforeFinalise.data.document;
          // requestData.resourceId = copiedResourceBeforeFinalise.data.record.id;
          // copiedResource = await api.myresources.finaliseCopyResource(apiBaseUrl, requestData);
          commit('SET_RESOURCE_DETAILS', copiedResourceBeforeFinalise.data);
          // update version history
          await dispatch('sendChanges', {changes: [` created resource by copying "${originalName}"`], isDraft: false});
          // set successful alert
          commit('SET_ALERT', alert, {root: true});
        }
      } catch (e) {
        console.error(e);
        const alert = {
          type: 'error',
          text: 'There was an error copying your resource'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
      return copiedResource.data;
    },
    async deleteResource({commit, state, rootState, dispatch}, resourceId) {
      try {
        const requestData = {
          uid: rootState.uid,
          firstName: rootState.users.userProfile.firstName,
          lastName: rootState.users.userProfile.lastName,
          resourceId: resourceId
        }

        const res = await api.myresources.deleteResource(apiBaseUrl, requestData);
        if (res.status === 200) {
          await delay(1000);
          // fetch resources according to the current view (search mode or not)
          if (state.searchMode) {
            await dispatch('fetchResources');
          } else {
            await dispatch('fetchResources', 'createdByMe');
            await dispatch('fetchResources', 'sharedWithMe');
            await dispatch('fetchResources', 'sharedByMe');
          }
        }
        const alert = {
          type: 'success',
          text: 'Your resource has been deleted'
        }
        commit('SET_ALERT', alert, {root: true});
        commit('SET_LAST_DELETED_RESOURCE', resourceId);
      } catch (e) {
        console.error(e);
        const alert = {
          type: 'error',
          text: 'There was an error deleting your resource'
        }
        commit('SET_ALERT', alert, {root: true});
        commit('SET_LAST_DELETED_RESOURCE', '');
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },
    async shareThisResource({commit, state, rootState}, payload) {
      const shareResourceCard = null
      //4.) Compose alert to notify user weather sharing success or failed.
      /**
       * @displayAlert is a rootState,
       * @getters displayAlert
       * @mutation SET_DISPLAY_ALERT,
       */
      commit('SET_IS_LOADING', true, {root: true});

      try {
        // 1.) get resource Id to be shared.
        const resourceId = payload.resourceId ? payload.resourceId : state.resourceDetails.record.id;
        // 2.) get first name and last name of the authenticated users
        const {firstName, lastName, userId} = rootState.users.userProfile;
        // 3.a) url needs query params, header and body so that we can make a request to share resource.
        const query = `?action=${payload.action}`
        // 3.b) header
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              accept: 'application/json',
              application: CONFIG.APPLICATION,
              source: 'myresource',
              uid: userId,
              first_name: firstName,
              last_name: lastName
            })
          }
        }
        // 4.) compose url to send a request to server.
        const url = `${apiBaseUrl}/resources/share/${resourceId}${query}`

        // 5.) send request to api/resources
        const res = await api.myresources.shareResourceCard(url, {userAccess: payload.userAccess}, config)
        // 6.) commit SET_DISPLAY_ALERT to display alert
        let alert;
        if (payload.action === 'removeaccess') {
          alert = {
            type: 'success',
            text: `Successfully removed access to ${payload.resourceName}`
          }
        } else {
          alert = payload.alert
        }
        if (res?.status === 200) {
          commit('SET_ALERT', alert, {root: true});
        }
      } catch (e: any) {
        console.log(e);
        const alert = {
          type: 'error',
          text: `Sharing ${payload.resourceName} was unsuccessful.`
        }
        commit('SET_ALERT', alert, {root: true});
        return new Error(e)
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }

      return shareResourceCard;
    },
    patchLike({state, rootState}) {
      const resourceId = state.resourceDetails.record.id;
      const url = `${apiBaseUrl}/resources/resource/${resourceId}?action=like`;

      const config = {
        headers:
          {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              accept: 'application/json',
              application: CONFIG.APPLICATION,
              source: 'myresource',
              likes: state.resourceDetails.misc.likes,
              likedby: rootState.uid,
              hasliked: state.resourceDetails.misc.hasLiked,
              uid: rootState.uid,
            })
          }
      };
      return api.myresources.patchResource(url, null, config);
    },
    async sendChanges({commit, state, rootState}, {changes, isDraft}) {
      const resourceId = state.resourceDetails.record.id;
      const {firstName, lastName, userId} = rootState.users.userProfile;
      const payload = {
        versionNum: state.resourceDetails.record.version,
        userName: firstName + ' ' + lastName,
        userId: userId,
        change: changes,
        isDraft: isDraft
      }
      try {
        await api.myresources.sendChanges(apiBaseUrl, {resourceId, payload});
        const versionHistory = await api.myresources.getVersionHistory(apiBaseUrl, resourceId);
        commit('SET_VERSION_HISTORY', versionHistory?.data);
      } catch (e) {
        console.error(e);
      }
    },
    async editChanges({commit, state}, {changes, isDraft}) {
      let updatedChanges = changes.concat(state.versionHistory[0].change);
      updatedChanges = [...new Set(updatedChanges)];
      const resourceId = state.resourceDetails.record.id;
      const payload = {
        versionNum: state.resourceDetails.record.version,
        userName: state.resourceDetails.document.creator.name,
        userId: state.resourceDetails.document.creator.creatorId,
        change: updatedChanges,
        isDraft: isDraft,
        auditId: state.versionHistory[0]._id
      }
      try {
        await api.myresources.editChanges(apiBaseUrl, {resourceId, payload});
        const versionHistory = await api.myresources.getVersionHistory(apiBaseUrl, resourceId);
        commit('SET_VERSION_HISTORY', versionHistory?.data);
      } catch (e) {
        console.error(e);
      }
    },
    async deleteChanges({state, commit, rootState, dispatch}) {
      const resourceId = state.resourceDetails.record.id;
      const requestData = {
        auditId: state.versionHistory[0]._id,
        resourceId: resourceId
      }

      try {
        // delete audit entry
        await api.myresources.deleteChanges(apiBaseUrl, requestData);
        const versionHistory = await api.myresources.getVersionHistory(apiBaseUrl, resourceId);
        commit('SET_VERSION_HISTORY', versionHistory?.data);

        // revert to previous version before draft
        // console.log(versionHistory);
        const previousVersion = versionHistory?.data[0].versionNum;
        const index = state.resourceDetails.archive.findIndex(i => Number(i.record.version) === Number(previousVersion));
        const payload = {
          index: index,
          resourceId: resourceId,
          uid: rootState.uid
        }
        await api.myresources.revertPreviousVersion(apiBaseUrl, payload);
        await dispatch('fetchResourceDetails', {id: resourceId});

        const alert = {
          type: 'success',
          text: 'Your draft has been deleted'
        }
        commit('SET_ALERT', alert, {root: true});
      } catch (err) {
        console.error(err);
      }
    },
    async getVersionHistory({commit, state, rootState}) {
      const resourceId = state.resourceDetails.record.id;
      const versionHistory = await api.myresources.getVersionHistory(apiBaseUrl, resourceId);
      const isViewer = !state.resourceDetails.userAccess.owner.includes(rootState.uid) && !state.resourceDetails.userAccess.editor.includes(rootState.uid);
      if (versionHistory.data && versionHistory.data[0]?.isDraft && isViewer) {
        versionHistory.data.shift();
      }
      commit('SET_VERSION_HISTORY', versionHistory.data);
      const currentVersion = versionHistory.data.length;
      commit('SET_CURRENT_VERSION', currentVersion);
      return versionHistory?.data;
    },
    async getNotifications({commit, state, rootState}) {
      const uid = rootState.uid;
      const notifications = await api.myresources.getNotifications(apiBaseUrl, uid);
      commit('SET_NOTIFICATIONS', notifications?.data);
      return notifications?.data;
    },
    async getResourceNotifications({commit}, payload) {
      const resourceId = payload;
      const resNotification = await api.myresources.getResourceNotification(apiBaseUrl, resourceId);
      commit('SET_RESOURCE_NOTIFICATIONS', resNotification.data);
      return resNotification?.data;
    },
    async deleteNotification({commit, rootState, dispatch}, notificationId) {
      try {
        const requestData = {
          uid: rootState.uid,
          notificationId: notificationId
        }
        const res = await api.myresources.deleteNotification(apiBaseUrl, requestData);
        if (res.status === 200) {
          await delay(1000);
          await dispatch('getNotifications');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async revertToPreviousVersion({commit, state, rootState, dispatch}, version) {
      const index = state.resourceDetails.archive.findIndex(i => Number(i.record.version) === Number(version));
      const resourceId = state.resourceDetails.record.id;
      const payload = {
        index: index,
        resourceId: resourceId,
        uid: rootState.uid
      }
      try {
        await api.myresources.revertPreviousVersion(apiBaseUrl, payload);
        await dispatch('fetchResourceDetails', {id: resourceId});
        const alert = {
          type: 'success',
          text: 'Successfully retained previous version'
        }
        commit('SET_ALERT', alert, {root: true});
        await dispatch('sendChanges', {changes: [` created this version when reverting to version ${version + 1}.0`], isDraft: false});
      } catch (e) {
        console.error(e);
        const alert = {
          type: 'error',
          text: 'There was an error retaining previous version'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    }
  }
};

export default moduleMyResources;
