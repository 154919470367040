<template>
  <div
    v-resize="handleResize"
    class="mb-15"
  >
    <slot name="scrollableContent" />
  </div>
</template>

<script>
let timeoutId = null;

export default {
  name: 'HorizontalScrollablePage',
  data() {
    return {
      negativeMargin: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateMargins();
    });
  },
  methods: {
    calculateMargins() {
      const container = document.getElementById('page-content__wrapper');
      if (container) {
        const containerStyle = window.getComputedStyle(container);
        const margin = containerStyle.marginRight;
        this.negativeMargin = `-${margin}`;
        this.$emit('getNegativeMargin', this.negativeMargin);
      }
    },
    handleResize() {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => this.calculateMargins(), 800);
      } else {
        timeoutId = setTimeout(() => this.calculateMargins(), 800);
      }
    }
  },
}
</script>
