<template>
  <div>
    <Dialog
      v-bind="$attrs"
      :title="$vuetify.breakpoint.smAndUp ? 'Copy to another collection' : 'Copy Collection'"
      :hide-heading="false"
      icon="mdi-image-plus"
      :width="$vuetify.breakpoint.smAndUp ? 1000 : 1000"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #subTitle>
        <span class="subtitle-2 font-weight-regular ">{{ selectedCards.length }} resources selected</span>
        <AdsButton
          tertiary
          dense
          small
          :button-text="showResources ? 'Hide': 'Show'"
          @click="showResources = !showResources"
        />
      </template>
      <template #content>
        <v-container class="mt-8 new-collection-content">
          <v-card-text v-if="showResources">
            <v-row>
              <v-col
                v-for="(item, index) in selectedCards"
                :key="index"
                lg="4"
                sm="12"
              >
                <ResourceCard
                  dense
                  :size="360"
                  :height="260"
                  :item="item"
                  :isSelected="true"
                  :enableNavigation="false"
                  :rounded="$vuetify.breakpoint.width >= 620"
                  v-on="$listeners"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <EditableCollections
              v-model="selectedCollections"
              :exclude="collection.record.id"
              :hubCollection="hubCollection"
              @onCollectionSelect="onCollectionSelectHandler"
            />
          </v-card-text>

          <v-card-actions :class="{'d-flex justify-space-between px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
            <AdsButton
              tertiary
              button-text="Cancel"
              @click="$downwardBroadcast('close:dialog');"
              @keyup.enter="$downwardBroadcast('close:dialog');"
            />
            <!-- if no selected cards, disable the Save button -->
            <AdsButton
              primary
              button-text="Save changes"
              :disabled="selectedCards?.length < 1 || selectedCollections.length < 1"
              @click="copyResourcesTo()"
              @keyup.enter="copyResourcesTo()"
            />
          </v-card-actions>
        </v-container>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapState} from 'vuex';
import ResourceCard from '@/components/Resource/ResourceCard'
import EditableCollections from '@/components/Collection/EditableCollections'

export default {
  name: 'CopyToCollectionDialog',
  components: {
    EditableCollections,
    AdsButton,
    Dialog,
    ResourceCard
  },
  props: {
    items: { // all resources in the collection
      type: Array,
      default: () => []
    },
    selectedCards: { // all selected resources in the collection
      type: Array,
      default: () => []
    },
    hubCollection: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      showDialog: false,
      showResources: false,
      selectedCollections: []
    };
  },
  computed: {
    ...mapState({
      collection: state => state.myCollections.collectionDetails || '',
    }),
  },
  methods: {
    async copyResourcesTo() {
      this.$downwardBroadcast('close:dialog');
      try {

        const resources = this.selectedCards.map(resource => {
          return {
            ItemID: resource.resourceId.id,
            Source: resource.resourceId.source
          }
        })

        // To validate for duplicates
        const invalidResources = []
        const validUpdates = []

        // Step 1: add resources to the collections
        const apiCallPromisses = this.selectedCollections.map(async (collection, index) => {

          // list of resources already exist in the target collection
          const collectionSubResources = collection.subResources.flatMap(subs => subs.resourceId?.id)

          const [invalid, valid] = resources.reduce(([pass, fail], elem) => {
            // push to the invalid list if it is already exist in the collection
            return collectionSubResources.includes(elem.ItemID) ? [[...pass, elem.ItemID], fail] : [pass, [...fail, elem]];
          }, [[], []]);

          invalid?.length > 0 && invalidResources.push(`<li>The resource(s) ${invalid.join(', ')} already exists in the Collection ${collection.Name}</li>`)
          valid.length > 0 && new Promise(() => setTimeout(async () => {
            validUpdates.push(collection) // saving updated collection details
            console.log(validUpdates)
            return  this.$store.dispatch('myCollections/addResources', {
              collection: collection,
              subResources: valid // only add valid resources to the collection
            })
          }, 1000 * index));
        })

        await Promise.all([apiCallPromisses]).then(async () => {

          setTimeout(() => {
            // Step 3: fetch all my collectionsx
            this.$store.dispatch('myCollections/fetchMyEditableCollections', { hubCollection: this.hubCollection }), 1000 * this.selectedCollections.length
            // Step 4: Clear selections in parent
            this.$emit('onComplete');

            const alert = validUpdates.length < 1 ? {
              type: 'error',
              text: `The following error(s) occured while trying to add resource[s]: <ul> ${invalidResources.join('')}</ul>`
            }: (invalidResources.length > 0) ?  {
              type: 'warning',
              text: `The following error(s) occured while trying to add resource[s]: <ul> ${invalidResources.join('')}</ul>`
            } : {
              type: 'success',
              text: 'Your collection is successfully updated.'
            }

            this.$store.commit('SET_ALERT', alert, {root: true});
          });

        });
      } catch (err) {
        console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error saving your resource.'
        }
        this.$store.commit('SET_ALERT', alert, {root: true});
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
        this.$store.commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
      this.selectedCollections = []
    },

    onCollectionSelectHandler(val) {
      /**
       * val: {
       *   select: type Boolean, // selected or not
       *   item: type Object<ResourceItem>
       * }
       * **/
      if(val.select) {
        // add object to array
        this.selectedCollections.push(val.item)
      } else {
        //delete from list
        this.selectedCollections = this.selectedCollections.filter(card => card.ItemID !== val.item.ItemID)
      }
      console.log(this.selectedCollections.length + ' selected ' + val.select)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../scss/mixins";

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    min-height: calc(100vh - 80px);

    .data-content{
      min-height: 100vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }
}
</style>
