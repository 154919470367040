<template>
  <div class="collection-control__wrapper">
    <FiltersForMyCollections v-on="$listeners" />
    <Controls
      v-on="$listeners"
      @toggle:view="getToggleView"
    />
  </div>
</template>
<script>
/**
 * @CollectionControls will consist of Filters for my collection on left side and to the right
 * side, it will consist of sorting, toggle view and create new collections.
 *
 */


import FiltersForMyCollections from './FiltersForMyCollections.vue';
import Controls from './Controls.vue';
export default {
  name: 'CollectionControls',
  components:{
    FiltersForMyCollections,
    Controls
  },
  data() {
    return {
      showGridView: true,
    }
  },
  methods:{
    getToggleView(val) {
      if (val === 0) {
        this.$store.commit('myCollections/SHOW_CARDS', true)
      }
      if (val === 1) {
        this.$store.commit('myCollections/SHOW_CARDS', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-control__wrapper {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px){
    flex-direction: column;
  }

  .chip-filters__wrapper{
    width: 50%;
    @media only screen and (max-width: 600px){
      width: 80%;
    }
  }
}
</style>
