<template>
  <div class="selectable-card__wrapper">
    <v-card
      v-if="!expansionPanel"
      flat
      role="button"
      :class="{'selectable-card__container': true, '--selected': selected}"
      @click="selected = !selected"
      @keyup.enter="selected = !selected"
    >
      <slot name="flexibleContainer" />
      <v-row class="ma-0">
        <v-col
          cols="1"
          class="mx-3 px-0 d-flex align-start justify-center"
        >
          <v-checkbox
            v-model="selected"
            tabindex="-1"
            class="mt-3"
            @click.prevent.stop
          />
        </v-col>
        <v-col
          cols="1"
          class="mr-3 px-0 d-flex align-start justify-center"
        >
          <v-icon
            large
            class="mt-3"
          >
            {{ icon }}
          </v-icon>
        </v-col>
        <v-col
          cols="8"
          class="pr-0 d-flex flex-column"
        >
          <h2 class="heading-text mt-2">
            {{ text }}
          </h2>
          <slot name="description" />
        </v-col>
      </v-row>
    </v-card>

    <v-expansion-panels
      v-if="expansionPanel"
      v-model="expanded"
      flat
    >
      <v-expansion-panel :class="{'bottom-border': bottomBorder}">
        <v-expansion-panel-header :class="{'pl-0 pr-2 pb-0': true, '--selected': selected}">
          <v-row class="ma-0">
            <v-col
              cols="1"
              class="mx-3 px-0 d-flex align-start justify-center"
            >
              <v-checkbox
                v-model="selected"
                class="mt-2"
                @click.stop
              />
            </v-col>
            <v-col
              cols="1"
              class="mr-3 px-0 d-flex align-start justify-center"
            >
              <v-icon
                large
                class="mt-2"
              >
                {{ icon }}
              </v-icon>
            </v-col>
            <v-col
              cols="8"
              class="pr-0 d-flex flex-column"
            >
              <h1 class="heading-text mt-2">
                {{ text }}
              </h1>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="{'--selected': selected}">
          <slot name="description" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'SelectableCard',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    expansionPanel: {
      type: Boolean,
      default: false
    },
    bottomBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: 1
    }
  },
  computed: {
    selectedCategories: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value);
      }
    },
    selected: {
      get() {
        return this.selectedCategories.includes(this.id);
      },
      set(val) {
        if (val) {
          this.selectedCategories = [...this.selectedCategories, this.id];
        } else {
          this.selectedCategories = this.selectedCategories.filter(i => i !== this.id);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.selectable-card__wrapper {
  height: 100%;
}

.selectable-card__container {
  border: 1px solid $ads-dark-60;
  min-height: 100%;
  position: relative;

  &.--selected {
    background-color: $ads-light-blue;
  }

  &:focus {
    border: 2px solid $ads-navy;
  }
}

.heading-text {
  color: $ads-navy;
  font-size: 16px;
  margin-bottom: 12px;
}

.bottom-border {
  border-radius: 0 !important;
  border-bottom: 1px solid $ads-light-40;
}

.v-expansion-panels {
  &:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .v-expansion-panel-header {
    border-radius: 0 !important;

    &.--selected {
      background-color: $ads-light-blue;
    }
  }

  .v-expansion-panel-content {
    &.--selected {
      background-color: $ads-light-blue;
    }
  }
}
</style>
