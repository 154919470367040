<template>
  <Dialog
    v-model="showDialog"
    persistent
    icon="mdi-minus-circle-outline"
    title="Remove selections"
    hide-close-button
    :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
  >
    <template #content>
      <p :class="{'pa-6': $vuetify.breakpoint.smAndUp, 'pa-3': $vuetify.breakpoint.xsOnly}">
        Removing Syllabus or Syllabus outcome selections might affect your selected progression levels. Do you want
        to remove or retain progressions already chosen?
      </p>
      <v-card-actions
        :class="{'d-flex justify-end pt-0': true, 'px-6 pb-6': $vuetify.breakpoint.smAndUp, 'px-3 pb-3': $vuetify.breakpoint.xsOnly}"
      >
        <AdsButton
          secondary
          color="#B81237"
          button-text="Remove"
          @click="handleRemove"
        />
        <AdsButton
          button-text="Retain"
          @click="showDialog = false"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'RemoveOrRetainProgressionsDialog',
  components: {
    Dialog,
    AdsButton
  },
  computed: {
    showDialog: {
      get() {
        return this.$store.state.myResources.removeProgressionsDialog;
      },
      set(val) {
        this.$store.commit('myResources/SET_REMOVE_PROGRESSIONS_DIALOG', val);
      }
    }
  },
  methods: {
    handleRemove() {
      this.$store.commit('myResources/SET_CLEAR_PROGRESSIONS', true);
      this.$store.commit('myResources/SET_REMOVE_PROGRESSIONS_DIALOG', false);
    }
  }
}
</script>
