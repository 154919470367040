<template>
  <v-row
    dense
    align="center"
    justify="start"
    class="chip-filters__wrapper"
  >
    <!--    **My Resource only Filters**   -->
    <!--    1. My Resources Libraries-->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="libraries"
        v-model="checkboxMyResourcesLibraries"
        name="My Resources Libraries"
        heading="My Resources Libraries"
        multiLevel
        :items="$store.getters['metadata/myResourcesLibraries']"
      />
    </v-col>

    <!--    2. MyResources Type-->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="Type"
        v-model="checkboxResourceType"
        heading="Resource type"
        name="Type"
        multiLevel
        :items="$store.getters['metadata/myResourcesTypes']"
      />
    </v-col>

    <!--    3. Format (this is the 'format' from metadata for MyResource page-->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="Format"
        v-model="checkboxFormat"
        heading="Format"
        name="Format"
        :items="$store.getters['metadata/formats']"
      />
    </v-col>


    <!--    **Filters on both pages**   -->
    <!--  1. Stage  -->
    <!--  Show if itStage  -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="years"
        v-model="checkboxYears"
        name="Stage"
        heading="Stage"
        multiLevel
        :items="$store.getters['metadata/stagesAndYears']"
      />
    </v-col>

    <!-- 2. Learning Area  -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="Learning Area"
        v-model="checkboxKLAs"
        name="Learning Area"
        heading="Learning Area"
        :items="$store.getters['metadata/klas']"
      />
    </v-col>

    <v-col
      cols="auto"
      align-self="center"
    >
      <AdsButton
        class="clear-all"
        tertiary
        button-text="Clear all"
        aria-label="clear all"
        @click="handleClearAllFilters"
      />
    </v-col>
  </v-row>
</template>

<script>
import {ChipFilter, AdsButton} from '@nswdoe/doe-ui-core';
import {MY_RESOURCES_FILTERS_SELECTION_STRUCTURE, MY_RESOURCES_FILTERS_STRUCTURE} from '@/constants';

export default {
  name: 'FiltersMyResources',
  components: {
    ChipFilter,
    AdsButton
  },
  data() {
    return {
      checkboxMyResourcesLibraries: [],
      checkboxYears: [],
      checkboxKLAs: [],
      checkboxResourceType: [],
      checkboxFormat: [],
    }
  },
  watch: {
    checkboxMyResourcesLibraries: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const filtersSelection = {...this.$store.state.myResources.filtersSelection};
          filtersSelection.checkboxMyResourcesLibraries = newVal;
          this.$store.commit('myResources/SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.myResources.filters};
          newFilters.myResourcesLibraries = newVal.join();
          this.$store.commit('myResources/SET_FILTERS', newFilters);

          if (!this.$store.getters['myResources/isFiltersEmpty']) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else if (this.$store.state.myResources.keywords && this.$store.state.keywords.length >= 3) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else {
            this.$store.commit('myResources/SET_SEARCH_MODE', false);
            this.$store.dispatch('myResources/fetchResources');
          }
        }
      }
    },
    checkboxYears: {
      handler(newVal, oldVal) {
        // when clearAll, newVal array is empty and oldVal array is also empty, but newVal !== oldVal is true as their references are different
        if (newVal !== oldVal) {
          const filtersSelection = {...this.$store.state.myResources.filtersSelection};
          filtersSelection.checkboxYears = newVal;
          this.$store.commit('myResources/SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.myResources.filters};
          newFilters.years = newVal.join();
          this.$store.commit('myResources/SET_FILTERS', newFilters);

          if (!this.$store.getters['myResources/isFiltersEmpty']) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else if (this.$store.state.myResources.keywords && this.$store.state.keywords.length >= 3) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else {
            this.$store.commit('myResources/SET_SEARCH_MODE', false);
            this.$store.dispatch('myResources/fetchResources');
          }
        }
      }
    },
    checkboxKLAs: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const filtersSelection = {...this.$store.state.myResources.filtersSelection};
          filtersSelection.checkboxKLAs = newVal;
          this.$store.commit('myResources/SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.myResources.filters};
          newFilters.klas = newVal.join();
          this.$store.commit('myResources/SET_FILTERS', newFilters);

          if (!this.$store.getters['myResources/isFiltersEmpty']) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else if (this.$store.state.myResources.keywords && this.$store.state.keywords.length >= 3) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else {
            this.$store.commit('myResources/SET_SEARCH_MODE', false);
            this.$store.dispatch('myResources/fetchResources');
          }
        }
      }
    },
    checkboxResourceType: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const filtersSelection = {...this.$store.state.myResources.filtersSelection};
          filtersSelection.checkboxResourceType = newVal;
          this.$store.commit('myResources/SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.myResources.filters};
          newFilters.types = newVal.join();
          this.$store.commit('myResources/SET_FILTERS', newFilters);

          if (!this.$store.getters['myResources/isFiltersEmpty']) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else if (this.$store.state.myResources.keywords && this.$store.state.keywords.length >= 3) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else {
            this.$store.commit('myResources/SET_SEARCH_MODE', false);
            this.$store.dispatch('myResources/fetchResources');
          }
        }
      }
    },
    checkboxFormat: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const filtersSelection = {...this.$store.state.myResources.filtersSelection};
          filtersSelection.checkboxFormat = newVal;
          this.$store.commit('myResources/SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.myResources.filters};
          newFilters.formats = newVal.join();
          this.$store.commit('myResources/SET_FILTERS', newFilters);

          if (!this.$store.getters['myResources/isFiltersEmpty']) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else if (this.$store.state.myResources.keywords && this.$store.state.keywords.length >= 3) {
            this.$store.commit('myResources/SET_SEARCH_MODE', true);
            this.$store.dispatch('myResources/fetchResources');
          } else {
            this.$store.commit('myResources/SET_SEARCH_MODE', false);
            this.$store.dispatch('myResources/fetchResources');
          }
        }
      }
    }
  },
  methods: {
    async getScrollableViewResources() {
      this.$store.commit('SET_IS_LOADING', true);
      let createdByMePromise = this.$store.dispatch('myResources/fetchResources', 'createdByMe');
      let sharedWithMePromise = this.$store.dispatch('myResources/fetchResources', 'sharedWithMe');
      let sharedByMePromise = this.$store.dispatch('myResources/fetchResources', 'sharedByMe');

      try {
        await Promise.all([createdByMePromise, sharedByMePromise, sharedWithMePromise]);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
      }
    },
    handleClearAllFilters() {
      // clear v-model array
      this.checkboxMyResourcesLibraries = [];
      this.checkboxYears = [];
      this.checkboxKLAs = [];
      this.checkboxResourceType = [];
      this.checkboxFormat = [];

      // commit empty filtersSelection to store
      const filtersSelection = {...MY_RESOURCES_FILTERS_SELECTION_STRUCTURE};
      this.$store.commit('myResources/SET_FILTERS_SELECTION', filtersSelection);

      // commit empty filters to store
      const newFilters = {...MY_RESOURCES_FILTERS_STRUCTURE};
      this.$store.commit('myResources/SET_FILTERS', newFilters);

      if (this.$store.state.keywords && this.$store.state.keywords.length >= 3) {
        this.$store.commit('myResources/SET_SEARCH_MODE', true);
        this.$store.dispatch('myResources/fetchResources');
      } else {
        this.$store.commit('myResources/SET_SEARCH_MODE', false);
        this.$store.dispatch('myResources/fetchResources');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.chip-filters__wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% + 12px);
  overflow-x: scroll;
}

.chip-filters__wrapper::-webkit-scrollbar {
  display: none;
}

.clear-all {
  &:hover {
    &:before {
      opacity: 0 !important;
    }
  }

  &:focus {
    outline-color: $ads-navy !important;
  }
}

.v-menu {
  &__content {
    max-width: 290px;
  }
}

::v-deep .v-card {
  &__title {
    .heading {
      width: 100px;
      word-break: keep-all;
      line-height: 1.5;
      margin-right: 5px;
    }

    button.close-button {
      align-self: flex-start;
    }
  }
}


@media only screen and (max-width: 620px) {
  .chip-filters__wrapper {
    .col {
      &:first-child {
        margin-left: 12px;
      }

      &:last-child {
        margin-right: 12px;
      }
    }
  }
}
</style>
