import {post} from '@/api/http';
import Vue from 'vue';

const CONFIG = (window as any).dlrhConfig;

export async function getTutorials(baseUrl, {path, uid}) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          application: CONFIG.APPLICATION,
          source: 'myresource',
          uid: uid
        })
      }
    };
    const res = await post(`${baseUrl}/search${path}`, {}, config);
    return res.data;
  } catch (err) {
    console.error(err);
  }
}
