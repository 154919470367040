<template>
  <div>
    <v-row class="image__row">
      <v-col
        cols="12"
        :class="{'image__col': true, 'image__col--mobile': regularUrl}"
      >
        <v-img
          v-if="regularUrl"
          :src="regularUrl"
          :aspect-ratio="1.85"
        >
          <v-btn
            icon
            class="edit-button"
            aria-label="edit image"
            @click.stop="showDialog = true"
          >
            <FeatureIcon
              :size="$vuetify.breakpoint.xsOnly ? '36' : '48'"
              icon="mdi-pencil-outline"
            />
          </v-btn>
        </v-img>
        <template v-else>
          <v-responsive :aspect-ratio="1.85">
            <v-card
              role="button"
              class="image-area"
              outlined
              @click="showDialog = true"
              @keyup.enter="showDialog = true"
            >
              <v-card-text class="card-content">
                <v-icon class="add-image--icon">
                  mdi-chart-box-plus-outline
                </v-icon>
                <p class="mt-6">
                  Click to add a cover image for your resource.
                </p>
              </v-card-text>
            </v-card>
          </v-responsive>
        </template>
      </v-col>
    </v-row>
    <Dialog
      v-model="showDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.mdAndUp ? 1200 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    >
      <template #content>
        <UnsplashDialogContent
          v-bind="$attrs"
          v-on="$listeners"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {FeatureIcon} from '@nswdoe/doe-ui-core';
import UnsplashDialogContent from '@/views/MyResourceDetailsPage/Edit/AddNewImage/UnsplashDialogContent';
import Dialog from '@/components/Dialogs/Dialog';

export default {
  name: 'AddNewImage',
  components: {
    UnsplashDialogContent,
    FeatureIcon,
    Dialog
  },
  inheritAttrs: false,
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    regularUrl() {
      return this.$attrs.value?.regularUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.image__row {
  margin-bottom: 36px;

  .image__col {
    .v-image {
      border-radius: 4px;

      .edit-button {
        margin: 12px;

        &:focus {
          outline: none !important;
        }

        &:focus-visible {
          outline: 10px auto $ads-navy !important;
        }
      }
    }

    .image-area {
      border: 2px dashed $ads-navy;
      min-height: 100%;
      display: flex;

      &:focus {
        &::before {
          opacity: 0;
        }
      }

      &:focus-visible {
        border-width: 4px;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .add-image--icon {
          font-size: 90px;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .image__row {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .image__row {
    margin-bottom: 20px;

    .image__col {
      &.image__col--mobile {
        padding: 0;
      }

      .v-image {
        border-radius: 0;

        .edit-button {
          margin: 4px;
        }
      }

      .image-area {
        .card-content {
          .add-image--icon {
            font-size: 48px;
          }
        }
      }
    }
  }
}
</style>
