const CONFIG = (window as any).dlrhConfig;

let apiBaseUrl = '';
let apiBaseUrlV2 = '';
let siteBaseUrl = '';
let collectionBaseUrl = '';
let metadataBaseUrl = '';
let searchBaseUrl = '';

if (CONFIG.envUI === 'local') {
  // local, dev, test, prod
  apiBaseUrl = CONFIG.apiBaseUrlLocal;
  siteBaseUrl = CONFIG.siteBaseUrlLocal;
  metadataBaseUrl = CONFIG.metadataBaseUrlLocal;
  apiBaseUrlV2 = CONFIG.apiBaseUrlDevV2;
  collectionBaseUrl = CONFIG.collectionBaseUrlLocal;
  searchBaseUrl = CONFIG.searchBaseUrlLocal;
} else if (CONFIG.envUI === 'dev') {
  apiBaseUrl = CONFIG.apiBaseUrlDev;
  metadataBaseUrl = CONFIG.apiBaseUrlDev;
  apiBaseUrlV2 = CONFIG.apiBaseUrlDevV2;
  siteBaseUrl = CONFIG.siteBaseUrlDev;
  collectionBaseUrl = CONFIG.apiBaseUrlDev;
  searchBaseUrl = CONFIG.apiBaseUrlDev;
} else if (CONFIG.envUI === 'test') {
  apiBaseUrl = CONFIG.apiBaseUrlTest;
  metadataBaseUrl = CONFIG.apiBaseUrlTest;
  apiBaseUrlV2 = CONFIG.apiBaseUrlTestV2;
  siteBaseUrl = CONFIG.siteBaseUrlTest;
  collectionBaseUrl = CONFIG.apiBaseUrlTest;
  searchBaseUrl = CONFIG.apiBaseUrlTest;
} else if (CONFIG.envUI === 'pre') {
  apiBaseUrl = CONFIG.apiBaseUrlPre;
  metadataBaseUrl = CONFIG.apiBaseUrlPre;
  apiBaseUrlV2 = CONFIG.apiBaseUrlPreV2;
  siteBaseUrl = CONFIG.siteBaseUrlPre;
  collectionBaseUrl = CONFIG.apiBaseUrlPre;
  searchBaseUrl = CONFIG.apiBaseUrlPre;
} else if (CONFIG.envUI === 'prod') {
  apiBaseUrl = CONFIG.apiBaseUrlProd;
  metadataBaseUrl = CONFIG.apiBaseUrlProd;
  apiBaseUrlV2 = CONFIG.apiBaseUrlProdV2;
  siteBaseUrl = CONFIG.siteBaseUrlProd;
  collectionBaseUrl = CONFIG.apiBaseUrlProd;
  searchBaseUrl = CONFIG.apiBaseUrlProd;
}

export {apiBaseUrl, siteBaseUrl, apiBaseUrlV2, metadataBaseUrl, collectionBaseUrl, searchBaseUrl};
