<template>
  <div :class="{'px-2 pt-2 pb-4': $vuetify.breakpoint.xs, 'px-6 pt-6 pb-6': $vuetify.breakpoint.smAndUp } ">
    <v-row
      v-if="itemDetail.LitLearnProgLevelV3 && Object.keys(itemDetail.LitLearnProgLevelV3).length > 0"
      align="center"
    >
      <v-col
        cols="12"
        class="pb-4"
      >
        <h3 style="font-size: 16px;">
          <span>
            <a
              :href="progressionV3Urls"
              target="_blank"
              class="font-weight-bold"
            >
              National Literacy Learning Progression V3
            </a>
            <v-icon
              class="pl-2"
              alt="opens in a new window"
              color="#1D428A"
              small
            >
              open_in_new
            </v-icon>
          </span>
        </h3>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        lg="9"
        md="9"
      >
        <ul class="pa-0">
          <li
            v-for="(elements, key) in literacyProgressionsV3"
            :key="key"
            class="px-0 mb-4 d-flex flex-column align-items-start"
          >
            <h4
              style="font-size: 16px; display: block;"
              class="mb-1"
            >
              {{ key }}
            </h4>
            <ul class="pa-0 list">
              <li
                v-for="(levels, subelement, subindex) in elements"
                :key="subindex"
              >
                <p class="mb-0">
                  {{ subelement }} - {{ levels?.map(obj => obj.level).join(", ") }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row
      v-if="itemDetail.NumLearnProgLevelV3 && Object.keys(itemDetail.NumLearnProgLevelV3).length > 0"
      align="center"
    >
      <v-col
        cols="12"
        class="pb-4"
      >
        <h3 style="font-size: 16px;">
          <span>
            <a
              :href="progressionV3Urls"
              target="_blank"
              class="font-weight-bold"
            >
              National Numeracy Learning Progression V3
            </a>
            <v-icon
              class="pl-2"
              alt="opens in a new window"
              color="#1D428A"
              small
            >
              open_in_new
            </v-icon>
          </span>
        </h3>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        lg="9"
        md="9"
      >
        <ul class="pa-0">
          <li
            v-for="(i, key) in numeracyProgressionsV3"
            :key="key"
            class="px-0 mb-4 d-flex flex-column align-items-start"
          >
            <h4
              style="font-size: 16px; display: block;"
              class="mb-1"
            >
              {{ key }}
            </h4>
            <ul class="pa-0 list">
              <li
                v-for="(levels, subelement, subindex) in i"
                :key="subindex"
              >
                <p class="mb-0">
                  {{ subelement }} - {{ levels?.map(obj => obj.level).join(", ") }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row
      v-if="literacyProgressions && Object.keys(literacyProgressions).length > 0"
      align="center"
    >
      <v-col
        cols="12"
        class="pb-4"
      >
        <h3 style="font-size: 16px;">
          <span><a
                  :href="literacyUrl"
                  target="_blank"
                  class="font-weight-bold"
                >National Literacy Learning Progression V2 (PDF)</a>
            <v-icon
              class="pl-2"
              alt="opens in a new window"
              color="#1D428A"
              small
            >open_in_new</v-icon>
          </span>
        </h3>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <ul class="pa-0">
          <li
            v-for="(subelements, element, index) in literacyProgressions"
            :key="index"
            class="px-0 mb-4 d-flex flex-column align-items-start"
          >
            <h4
              style="font-size: 16px; display: block;"
              class="mb-1"
            >
              {{ element }}
            </h4>
            <ul class="pa-0 list">
              <li
                v-for="(levels, subelement, subindex) in subelements"
                :key="subindex"
              >
                <p class="mb-0">
                  {{ subelement }} - {{ levels.map(obj => obj.level).join(", ") }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row
      v-if="numeracyProgressions && Object.keys(numeracyProgressions).length > 0"
      align="center"
    >
      <v-col
        cols="12"
        class="pb-4"
      >
        <h3 style="font-size: 16px;">
          <span>
            <a
              :href="numeracyUrl"
              target="_blank"
              class="font-weight-bold"
            >National Numeracy Learning Progression V2 (PDF)</a>
            <v-icon
              class="pl-2"
              alt="opens in a new window"
              color="#1D428A"
              small
            >open_in_new</v-icon>
          </span>
        </h3>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <ul class="pa-0">
          <li
            v-for="(subelements, element, index) in numeracyProgressions"
            :key="index"
            class="px-0 mb-4 d-flex flex-column align-items-start"
          >
            <h4
              style="font-size: 16px; display: block;"
              class="mb-1"
            >
              {{ element }}
            </h4>
            <ul class="pa-0 list">
              <li
                v-for="(levels, subelement, subindex) in subelements"
                :key="subindex"
              >
                <p class="mb-0">
                  {{ subelement }} - {{ levels.map(obj => obj.level).join(", ") }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {URLS} from '@/constants';

export default {
  name: 'Progressions',
  data() {
    return {
      numeracyProgressions: null,
      literacyProgressions: null,
      numeracyProgressionsV3: null,
      literacyProgressionsV3: null,
      numeracyUrl: 'https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=',
      literacyUrl: 'https://educationstandards.nsw.edu.au/wps/wcm/connect/32837681-1ffc-49b3-8069-c756611ff054/national-literacy-learning-progression.pdf?MOD=AJPERES&CVID='
    }
  },
  methods: {
    groupByKey(arr, key) {
      return arr.reduce((r, a) => {
        r[a[key]] = [...r[a[key]] || [], a];
        return r;
      }, {});
    }
  },
  computed: {
    ...mapState({
      itemDetail: state => state.itemDetail
    }),
    progressionV3Urls() {
      return URLS.PROGRESIONS.LITERACY_NUMERACY_V3
    },
  },
  watch: {
    itemDetail: {
      immediate: true,
      handler() {
        if (this.itemDetail.NumLearnProgLevel) {
          this.numeracyProgressions = this.groupByKey(this.itemDetail.NumLearnProgLevel, 'subElement');
          for (let element of Object.keys(this.numeracyProgressions)) {
            this.numeracyProgressions[element] = this.groupByKey(this.numeracyProgressions[element], 'subElement');
          }
        }
        if (this.itemDetail.LitLearnProgLevel) {
          this.literacyProgressions = this.groupByKey(this.itemDetail.LitLearnProgLevel, 'subElement');
          for (let element of Object.keys(this.literacyProgressions)) {
            this.literacyProgressions[element] = this.groupByKey(this.literacyProgressions[element], 'subElement');
          }
        }
        if (this.itemDetail.NumLearnProgLevelV3) {
          this.numeracyProgressionsV3 = this.groupByKey(this.itemDetail.NumLearnProgLevelV3, 'element');
          for (let element of Object.keys(this.numeracyProgressionsV3)) {
            this.numeracyProgressionsV3[element] = this.groupByKey(this.numeracyProgressionsV3[element], 'subElement');
          }
        }
        if (this.itemDetail.LitLearnProgLevelV3) {
          this.literacyProgressionsV3 = this.groupByKey(this.itemDetail.LitLearnProgLevelV3, 'element');
          for (let element of Object.keys(this.literacyProgressionsV3)) {
            this.literacyProgressionsV3[element] = this.groupByKey(this.literacyProgressionsV3[element], 'subElement');
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

a {
  color: $ads-blue-1 !important;
}

.list {
  list-style: none;
}
</style>
