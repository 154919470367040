<template>
  <v-row
    v-bind="{'justify': centered ? 'center' : 'start'}"
    :class="'banner ml-0 pb-6 ' + bannerClass"
    :style="{
      backgroundImage: overrideBackgroundImage
        ? 'url(' + backgroundImageUrl + ')' /* image will be served from the 'public' folder */
        : overrideBackgroundColour ? 'none' : '',
      backgroundColor: overrideBackgroundColour ? backgroundColour : ''
    }"
  >
    <v-col class="pl-0">
      <slot name="content">
        <v-container
          :fluid="$slots.rightPannel ? true : false"
          :class="contentClass"
        >
          <div
            class="d-flex justify-space-between "
            :class="$vuetify.breakpoint.mdAndDown ? 'flex-column':'flex-row'"
          >
            <div class="d-flex align-start left-panel">
              <div class="pt-1">
                <slot name="icon">
                  <v-icon
                    v-if="icon"
                    alt=""
                    :size="$vuetify.breakpoint.mdAndUp ? iconSize: 30"
                    class="material-icons-outlined"
                  >
                    {{ icon }}
                  </v-icon>
                </slot>
              </div>
              <div class="d-flex flex-column">
                <slot name="title">
                  <h1 class="appTitle">
                    {{ heading }}
                  </h1>
                </slot>
                <slot name="subtitle">
                  <p
                    v-if="subtitle"
                    class="appSubtitle"
                  >
                    {{ subtitle }}
                  </p>
                </slot>
                <div
                  v-if="$slots.bottomPanel"
                  class="bottom-panel"
                >
                  <slot name="bottomPanel" />
                </div>
              </div>
              <div class="align-self-center info-icon">
                <slot name="info">
                  <v-tooltip
                    bottom
                    color="primary"
                  >
                    <template #activator="{ on, attrs}">
                      <v-icon
                        v-if="info"
                        alt=""
                        :size="infoSize"
                        class="material-icons-outlined"
                        v-bind="attrs"
                        color="blue"
                        v-on="on"
                      >
                        {{ info }}
                      </v-icon>
                    </template>
                    <span>{{ infoMessage }}</span>
                  </v-tooltip>
                </slot>
              </div>
            </div>
            <div class="right-panel">
              <slot name="rightPannel" />
            </div>
          </div>
        </v-container>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    icon: {
      type: String,
      default: null
    },
    info:{
      type: String,
      default: null
    },
    infoSize:{
      type: String,
      default: '30'
    },
    infoMessage:{
      type: String,
      default: 'This is a tooltip with default message. Please include your conveying message.'
    },
    iconSize: {
      type: String,
      default: '50'
    },
    heading: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    defaultBackground1: {
      type: Boolean,
      default: false
    },
    defaultBackground2: {
      type: Boolean,
      default: false
    },
    bgPositionLeft: {
      type: Boolean,
      default: false
    },
    backgroundColour: {
      type: String,
      default: null
    },
    bgPositionRight: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    backgroundImageUrl: {
      type: String,
      default: null
    },
    contentClass: {
      type: String,
      default: 'pl-6'
    }
  },
  data() {
    return {
      overrideBackgroundImage: this.backgroundImageUrl != null,
      overrideBackgroundColour: this.backgroundImageUrl == null && this.backgroundColour
    }
  },
  computed: {
    bannerClass() {
      let className = '';
      if (this.defaultBackground1) {
        className = ' banner1';
      }

      if (this.defaultBackground2) {
        className = 'banner2'
      }

      if (this.bgPositionRight) {
        className += ' background--right';
      }

      if (this.bgPositionLeft) {
        className += ' background--left';
      }

      return className;
    }
  }
};
</script>

<style lang='scss' scoped>
@import "src/scss/variables";

.background--right {
  background-position: right;
}

.background--left {
  background-position: left;
}

.banner {
  background-image: url(./banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $ads-navy;
  color: $ads-white;
}

.banner1 {
  background-image: url(./banner2.png);
  background-position: right;
}

.banner2 {
  background-image: url(./banner3.png);
  background-position: right;
}

.banner .col {
  padding-top: 30px;
  padding-bottom: 0px;
}

.appSubtitle {
  font-size: 16px;
  padding: 0 14px;
  margin-bottom: 0;
}

.appTitle {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  vertical-align: bottom;
}

i.v-icon {
  color: $ads-white;
}

.info-icon {
  padding-bottom: 12px;

  i{
    font-size: 24px;
  }
}
  @media only screen and (max-width: 600px) {
    .appTitle{
      font-size: 24px;
      margin-bottom: 12px;
    }
    .appSubtitle{
      font-size: 14px;
      margin-bottom: 12px;
    }
  }


</style>
