<template>
  <Dialog
    v-bind="$attrs"
    icon="mdi-pencil-box-outline"
    :title="successfulResources?.length > 0 ? 'Success' : 'Failed'"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <div
        class="share-card__wrapper mt-6"
      >
        <v-card-text v-if="successfulResources?.length > 0">
          <v-row class="ml-5">
            <h3>The following resource(s) were {{ action === 'publish' ? 'PUBLISHED': 'UNPUBLISHED' }} successfully:</h3>
            <v-list-item
              v-for="s in successfulResources"
              :key="s.ItemID"
            >
              {{ s.Name }}
            </v-list-item>
          </v-row>
        </v-card-text>
        <v-card-text v-if="failedResources?.length > 0">
          <v-row class="ml-5">
            <h3>The following {{ failedResources.length }} resource(s) could NOT be {{ action === 'publish' ? 'published': 'unpublished' }} as you do not have the appropriate permission: </h3>
            <v-list-item
              v-for="s in failedResources"
              :key="s.ItemID"
            >
              {{ s.Name }}
            </v-list-item>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-10 mr-4">
          <div class="d-flex justify-end" />
          <v-row
            justify="end"
          >
            <AdsButton
              class="clear-all mt-2 pl-5 mr-8"
              primary
              button-text="Close"
              aria-label="close"
              @click="closeDialog"
            />
          </v-row>
        </v-card-actions>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import submitForReviewVector from '@/assets/workflow_illustration.svg';

export default {
  name: 'UpdateStatusDialog',
  components: {
    AdsButton,
    Dialog
  },
  props: {
    successfulResources: {
      default: () => [],
      type: Array
    },
    failedResources: {
      default: () => [],
      type: Array
    },
    action: {
      type: String,
      default: 'publish'
    }
  },
  methods: {
    closeDialog() {
      this.$downwardBroadcast('close:dialog');
    }

  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.share-card__wrapper {
  display: block;
  min-width: 240px;

  .v-divider {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 43.75em) {
    // 700px === 43.75em
    & {
      width: 100%;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  & .v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .v-btn {
    min-height: 2.5rem;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    border-color: $ads-light-40;
    background-color: $ads-white;
    color: $ads-white;

    &:focus {
      border: 2px solid $ads-navy;
      outline: none !important;
    }
  }

  button.v-btn > .v-btn__content {
    justify-content: center;
  }

  .btnText {
    color: white;
  }

  .v-divider {
    border-color: $ads-light-40;
  }
}
</style>
