<template>
  <v-row>
    <v-col
      cols="12"
      class="pa-0"
    >
      <h5 class="syllabus-item__title">
        {{ title }}
      </h5>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-row
        v-if="valueInternal.length > 0"
        class="chips__row"
      >
        <v-col
          v-for="(item, i) of valueInternal"
          :key="i"
          cols="auto"
          class="chip__col"
        >
          <v-chip
            close
            color="primary"
            outlined
            @click:close="handleClear(item)"
          >
            {{ item }}
          </v-chip>
        </v-col>
      </v-row>
      <p
        v-else
        class="paragraph"
      >
        None selected
      </p>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'SyllabusItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      valueInternal: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.valueInternal = val;
      }
    },
    valueInternal: {
      handler: function (val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleClear: function (item) {
      this.valueInternal = this.valueInternal.filter(i => i !== item);
    }
  }
}

</script>
<style lang="scss" scoped>
@import "../../../../scss/variables";

.syllabus-item__title {
  font-size: 16px;
  color: $ads-navy;
  font-weight: bold;
  margin: 4px 0;
}

.chips__row {
  margin: 0 0;
  min-height: 56px;

  .chip__col {
    padding-left: 0;
    padding-top: 4px;
  }
}

.paragraph {
  margin-bottom: 0;
  min-height: 56px;
}
</style>
