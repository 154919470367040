<template>
  <div
    v-if="searchMode"
    class="resources-heading__wrapper"
  >
    <div class="resources-heading__content">
      <template>
        <div class="results__container">
          <template v-if="isLoading">
            <v-skeleton-loader
              class="results__skeleton"
              type="text"
              :min-width="78"
            />
          </template>
          <template v-else-if="count === 0">
            No results.
          </template>
          <template v-else>
            <p>
              <strong>{{ count }} </strong>
              <span v-if="searchMode">
                {{ count !== 1 ? 'results' : 'result' }}
              </span>
              <span v-else>{{ count !== 1 ? 'resource collections' : 'resource collection' }}</span>
            </p>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ResultsHeader',
  props: {
    count: {
      type: [Number, String],
      default: ''
    },
  },
  computed:{
    ...mapState({
      searchMode: state => state.myCollections.searchMode,
      isLoading : state => state.isLoading
    }),
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.resources-heading__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 0;
  min-height: 76px;

  .resources-heading__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .results__container {
      min-width: 90px;

      .results__skeleton {
        &.v-skeleton-loader {
          ::v-deep.v-skeleton-loader__heading {
            width: 100%;
          }
        }
      }

      p {
        margin-bottom: 0;
        margin-right: 8px;

        .redirect-search-link {
          color: $ads-blue-1;
        }
      }
    }

    .v-btn {
      font-size: 16px;

      &:hover {
        &:before {
          opacity: 0 !important;
        }
      }
    }
  }

}

::v-deep.v-list {
  .v-list-item {
    transition: background-color 0.3s ease-out;
  }

  .v-list-item.v-list-item--highlighted {
    border-collapse: separate; /* for ie 9-11 */
    box-shadow: 0 0 0 3 $ads-blue-3-dark inset;

    &:before {
      opacity: 0;
    }
  }

  &.theme--light {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy;
      color: $ads-white !important;
    }
  }

  &.theme--dark {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy-dark;
      color: $ads-01dp !important;

      &:before {
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .resources-heading__wrapper {
    align-items: center;
    min-height: 40px;

    .resources-heading__left-content {
      padding-left: 12px;

      .results__container {
        min-width: 80px;

        p {
          max-width: 200px;
        }
      }
    }

    .resources-heading__right-content {
      padding-right: 12px;
      align-items: flex-end;

      .sorting {
        .sorting-menu {
          padding-right: 0;
          max-width: 140px;
        }
      }
    }
  }
}
</style>
