/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import resources from './modules/resources';
import staff from './modules/staff';
import * as users from './modules/users';
import saveSearches from './modules/saveSearches';
import * as myresources from './modules/myresources';
import * as tutorials from './modules/tutorials';
import * as metadata from './modules/metadata';
import * as mycollecions from './modules/mycollections'

export default {
  resources,
  staff,
  users,
  saveSearches,
  myresources,
  tutorials,
  metadata,
  mycollecions
}
