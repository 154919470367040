<template>
  <div
    v-if="$store.state.itemDetail"
    :class="{'px-2 pt-2 pb-4': $vuetify.breakpoint.xs, 'px-6 pt-6 pb-6': $vuetify.breakpoint.smAndUp } "
  >
    <FieldRowWithChips
      label="Topics"
      :items="topics"
      title="Click to search"
      @chipSelected="searchOnClick"
    />
    <FieldRowWithChips
      label="L&N Topics"
      :items="lnnTopics"
      title="Click to search"
      @chipSelected="searchOnClick"
    />
    <FieldRowWithChips
      label="Owner"
      :items="[capitalize(source)]"
      title="Click to search"
      @chipSelected="searchOnClick"
    />
    <template v-if="isLearningResource">
      <FieldRowWithChips
        label="NSW Syllabus"
        :items="nswSyllabuses"
        title="Click to search"
        @chipSelected="searchOnClick"
      />
      <FieldRowWithChips
        label="Syllabus Outcome(s)"
        :items="syllabusOutcomes"
        title="Click to search"
        @chipSelected="searchOnClick"
      />
      <FieldRowWithChips
        label="Progression V2"
        :items="progressionLevels"
        title="Click to search"
        @chipSelected="searchOnClick"
      />
      <FieldRowWithChips
        label="Progression V3"
        :items="v3progressionLevels"
        title="Click to search"
        @chipSelected="searchOnClick"
      />
    </template>
    <FieldRowWithChips
      v-if="itemDetail.SchoolType && itemDetail.SchoolType.length > 0"
      label="School type"
      :items="itemDetail.SchoolType"
      title="Click to search"
      @chipSelected="searchOnClick"
    />
    <FieldRowWithChips
      v-if="sefAlignment && sefAlignment.length > 0"
      label="SEF Alignment"
      :items="sefAlignment"
      title="Click to search"
      @chipSelected="searchOnClick"
    />
    <FieldRowWithChips
      v-if="itemDetail.SIPTerms && itemDetail.SIPTerms.length > 0"
      label="SIP Terms"
      :items="itemDetail.SIPTerms"
      title="Click to search"
      @chipSelected="searchOnClick"
    />
    <FieldRow
      class="pl-2"
      label="Publisher"
      :value="itemDetail.Publisher"
    />
    <FieldRow
      class="pl-2"
      label="Author"
      :value="authors"
    />
    <FieldRow
      class="pl-2"
      label="Resource"
      :value="capitalize(resourceType)"
    />
    <FieldRow
      label="Format"
      class="pl-2"
      :value="capitalize(itemDetail.Format)"
    />

    <FieldRowWithLinks
      label="Related Resources"
      :items="itemDetail.RelatedResources"
    />

    <FieldRowWithLinks
      label="Professional Learning"
      :items="itemDetail.PLLinks"
    />
    <!-- @TODO update FieldRowWithLinks with foot notes -->

    <v-row
      v-if="isLearningResource"
      class="pl-2"
    >
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        class="pl-1"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span class="font-weight-bold">Curriculum planning for every student:</span>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <ul
          id="unified_design"
          class="linkList"
        >
          <li>
            <a
              href="https://education.nsw.gov.au/teaching-and-learning/learning-from-home/teaching-at-home/teaching-and-learning-resources/universal-design-for-learning"
              target="_blank"
              style="color: #1D428A;"
            >Curriculum planning for every student</a>
            <v-icon
              class="pl-1"
              alt="opens in a new window"
              color="#1D428A"
              small
            >
              open_in_new
            </v-icon>
          </li>
          <li>
            Explore evidence-based strategies to optimise learning for the full range of students.
          </li>
        </ul>
      </v-col>
    </v-row>

    <FieldRow
      class="pl-2"
      label="Created Date"
      :value="creationDate()"
    />
    <FieldRow
      class="pl-2"
      label="Updated Date"
      :value="moment(resourceDetails.record.modified).format('DD MMM YYYY')"
    />
    <FieldRow
      class="pl-2"
      label="Rights"
      :value="itemDetail.Rights || 'No Rights Provided'"
    />
  </div>
</template>

<script>
import moment from 'moment';
import FieldRowWithChips from '@/components/FieldRowWithChips';
import FieldRowWithLinks from '@/components/FieldRowWithLinks';
import FieldRow from '@/components/FieldRow';
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'CardOverview',
  components: {
    FieldRow,
    FieldRowWithChips,
    FieldRowWithLinks
  },
  props: {
    isLearningResource: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moment
    }
  },
  computed: {
    ...mapState({
      itemDetail: state => state.itemDetail,
      resourceDetails: state => state.resourceDetails
    }),
    ...mapGetters({
      HubResourceSources: 'metadata/HubResourceSources',
      allHubResourceTypes: 'metadata/allHubResourceTypes'
    }),
    topics() {
      if (this.itemDetail.Topic && this.itemDetail.Topic.length > 0) {
        // ["Earth movements", "Coasts", "Erosion", ""]
        return this.itemDetail.Topic.filter(topic => topic);
      } else {
        return [];
      }
    },
    lnnTopics() {
      if (['readingandnumeracy','literacyandnumeracypackages'].includes(this.itemDetail?.Source)) {
        return this.itemDetail?.RandNTopic?.map(topic => {
          return this.$store.getters['metadata/randntopics']?.find(type => {
            return type.id === topic
          })?.name || null;
        }).filter(Boolean) || []
      } else {
        return []
      }
    },
    source() {
      // Lookup in metadata for the source name
      return this.HubResourceSources.length > 0 && this.HubResourceSources?.find(source => {
        // console.log(source);
        return source.id === this.itemDetail.Source
      }).name || '';
    },
    resourceType() {
      // Lookup in metadata for the resourceType name
      return this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.find(type => {
        return type.id === this.itemDetail.ResourceType
      }).name || 'Resource';
    },
    nswSyllabuses() {
      return this.itemDetail.NSWSyllabus.map(syllabus => syllabus.name) || []
    },
    syllabusOutcomes() {
      return this.itemDetail.NSWSyllabus.reduce(function (outcomes, syllabus) {
        return [...outcomes, ...syllabus.outcomes?.map(outcome => outcome.id)]
      }, [])
    },
    progressionLevels() {
      const literacyLevels = this.itemDetail.LitLearnProgLevel?.map(progression => progression.level) || [];
      const numeracyLevels = this.itemDetail.NumLearnProgLevel?.map(progression => progression.level) || [];
      return literacyLevels.concat(numeracyLevels);
    },
    v3progressionLevels() {
      const literacyLevels = this.itemDetail.LitLearnProgLevelV3?.map(progression => progression.level) || [];
      const numeracyLevels = this.itemDetail.NumLearnProgLevelV3?.map(progression => progression.level) || [];
      return literacyLevels.concat(numeracyLevels);
    },
    sefAlignment() {
      return this.itemDetail.SEFAlignment.map(alignment => alignment.element) || []
    },
    authors() {
      // @TODO check with BE why this is String for School Improvement Resources
      return Array.isArray(this.itemDetail.Author) && this.itemDetail.Author.join(', ')
        || this.itemDetail.Author;
    }
  },
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    showDetailCardDialogPage(id) {
      this.$router.push({name: 'DetailCardDialogPage', params: {id: id}});
    },
    creationDate() {
      if (this.resourceDetails.record.created === '0001-01-01T00:00:00') {
        return 'Not Available';
      } else {
        return this.moment(this.resourceDetails.record.created).format('DD MMM YYYY');
      }
    },
    async searchOnClick(value) {
      // value = `"${value}"`; // treat as one string in quotes

      if (!this.$store.state.searchMode) {
        this.$store.commit('SET_SEARCH_MODE', true);
      }

      // just clear resources and set keywords. Sort and filters will be added onto URL based on Vuex
      this.$store.commit('CLEAR_SEARCH');
      this.$store.commit('SET_KEYWORDS', value);
      await this.$store.dispatch('fetchResourcesWithClient');
      await this.$router.push({name: 'HubResourcesPage'});
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.linkList {
  padding: 0 !important;

  li {
    list-style: none;
  }
}

.chip:focus {
  color: $ads-white !important;
}

::v-deep.v-chip.v-chip--outlined.v-chip.v-chip:focus {
  background-color: $ads-navy !important;
}
</style>
