<template>
  <div
    class="progressions__wrapper"
    v-bind="$attrs"
  >
    <ProgressionsSearchInput
      v-model="searchProgressionsInput"
    />

    <ProgressionsTree
      id="progressions__results_v2"
      :items="filteredProgressions"
      :selected="v2"
      :searchInput="searchProgressionsInput"
      @updateProgressions="updateV2Progressions"
      v-on="$listeners"
    />
    <ProgressionsTree
      id="progressions__results_v3"
      :items="v3FilteredProgressions"
      :selected="v3"
      :searchInput="searchProgressionsInput"
      @updateProgressions="updateV3Progressions"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import ProgressionsSearchInput
  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Progressions/ProgressionsSearchInput.vue';
import ProgressionsTree  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Progressions/ProgressionsTree';

export default {
  name: 'Progressions',
  components: {
    ProgressionsTree,
    ProgressionsSearchInput,
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    v2: {
      type: Array,
      default: () => []
    },
    v3: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      searchProgressionsInput: '',
    }
  },
  computed: {
    ...mapGetters({
      storeProgressions: 'metadata/progressions',
      v3Progressions: 'metadata/progressionsV3'
    }),
    searchMode() {
      return this.searchProgressionsInput !== ''
    },
    filteredProgressions: function () {
      return this.storeProgressions//filterProgressions(clone(this.storeProgressions));
    },
    v3FilteredProgressions() {
      return this.v3Progressions
    }
  },
  methods: {
    updateV2Progressions(val) {
      // This will be handled in the Curriculum.vue
      console.log('v2 progressions updated', val)
      this.$emit('confirmV2Progressions', {
        literacy: val.filter(progression => progression.type === 'literacy' ),
        numeracy: val.filter(progression => progression.type === 'numeracy' )
      })
    },
    updateV3Progressions(val) {
      // This will be handled in the Curriculum.vue
      console.log('v3 progressions updated', val)
      this.$emit('confirmV3Progressions', {
        literacy: val.filter(progression => progression.type === 'literacy' ),
        numeracy: val.filter(progression => progression.type === 'numeracy' )
      })
    }
  },
}
</script>
