type Resource = {
  draft: {
    ItemID: string;
    DateCreated: string;
  }
}

type Data = {
  resources: Resource[]
}

export const setNewCollectionFlag = (data: Data, newCollectionId?: string) => {
  const index = data.resources.findIndex(({ draft: { ItemID }}) => ItemID === newCollectionId);

  return  {
    ...data,
    ...(index >= 0 && {
      resources: [
        ...data.resources.slice(0, index),
        {
          ...data.resources[index],
          draft: {
            ...data.resources[index].draft,
            isNew: true
          }
        },
        ...data.resources.slice(index + 1),
      ]
    })
  };
};

const compareDateStrings = (a: string, b: string) => new Date(b).valueOf() - new Date(a).valueOf();

const sortCollectionByDateCreated = (a: Resource, b: Resource) =>
  compareDateStrings(a.draft.DateCreated, b.draft.DateCreated);

export const orderCollections = (data: Data) => ({
  ...data,
  resources: data.resources.sort(sortCollectionByDateCreated)
});
