<template>
  <Dialog
    v-bind="$attrs"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    :hideCloseButton="true"
    v-on="$listeners"
  >
    <template #content>
      <div
        class="share-card__wrapper mt-6"
      >
        <v-card-text>
          <v-row justify="center">
            <v-img
              :src="submitForReviewVector"
              max-width="200px"
            />
          </v-row>
          <v-row justify="center">
            <v-card-title>This resource has been successfully published.</v-card-title>
            <v-card-subtitle>
              <!--              Publishers in your approval group will be alerted to its endorsement and they will be able to publish as necessary.-->
            </v-card-subtitle>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-10 mt-4">
          <v-row
            justify="center"
          >
            <CloseDialogButton @click="closeDialog" />
          </v-row>
        </v-card-actions>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog.vue';
import submitForReviewVector from '@/assets/workflow_illustration.svg';
import CloseDialogButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/CloseDialogButton.vue';

export default {
  name: 'PublishedDialog',
  components: {
    CloseDialogButton,
    Dialog
  },
  data() {
    return {
      submitForReviewVector
    };
  },
  methods: {
    closeDialog() {
      this.$store.commit('editor/SET_PUBLISH_SUBMITTED', false);
      this.$downwardBroadcast('close:dialog');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.share-card__wrapper {
  display: block;
  min-width: 240px;

  .v-divider {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 43.75em) {
    // 700px === 43.75em
    & {
      width: 100%;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  & .v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .v-btn {
    min-height: 2.5rem;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    border-color: $ads-light-40;
    background-color: $ads-white;
    color: $ads-white;

    &:focus {
      border: 2px solid $ads-navy;
      outline: none !important;
    }
  }

  button.v-btn > .v-btn__content {
    justify-content: center;
  }

  .btnText {
    color: white;
  }

  .v-divider {
    border-color: $ads-light-40;
  }
}
</style>
