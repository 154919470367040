<template>
  <Stepper
    :next-disabled="syllabusInternal.length === 0"
    has-fixed-content
    :steps="steps"
    @cancel="handleCancel"
    @confirm="handleConfirm"
    @changedStep="handleChangedStep"
  >
    <template #fixedContent>
      <v-row class="pl-14">
        <v-col
          cols="12"
          :class="{'chips-preview__wrapper': true, '--mobile': $vuetify.breakpoint.mdAndDown}"
          class="d-flex flex-nowrap"
        >
          <FeatureIcon
            size="40"
            icon="mdi-link"
            class="icon"
          />
          <div class="d-flex flex-column ml-2">
            <h1
              v-if="$vuetify.breakpoint.smAndUp"
              class="title font-weight-bold"
            >
              Link syllabus outcome
            </h1>
            <span>Select the syllabus outcomes related to your project. These outcomes will be linked to your project file</span>
            <h4
              v-if="$vuetify.breakpoint.smAndUp"
            >
              Outcomes added:
            </h4>
          </div>
        </v-col>
      </v-row>
      <v-row class="ml-15 mb-2">
        <v-col>
          <ChipsPreview
            v-model="previewValues"
            :is-allowed-to-clear="allowedToClear"
          />
          <ProgressionItem
            :is-allowed-to-clear="currentStep === 3"
            display-inline
            :items="v2Progressions"
            @confirmProgressions="$emit('confirmV2Progressions', $event)"
          />
          <ProgressionItem
            version="V3"
            :is-allowed-to-clear="currentStep === 3"
            display-inline
            :items="v3Progressions"
            @confirmProgressions="$emit('confirmV3Progressions', $event)"
          />
        </v-col>
      </v-row>
    </template>
    <template
      #step1
    >
      <Syllabus
        v-model="syllabusInternal"
      />
    </template>
    <!-- <template slot="step2">
      <Substrand
        v-model="outcomesInternal"
        :key="ckey"
      />
    </template> -->
    <template #step2>
      <Outcomes
        :key="ckey"
        v-model="outcomesInternal"
      />
    </template>
    <template #step3>
      <Progressions
        :v3="v3Progressions"
        :v2="v2Progressions"
        v-on="$listeners"
      />
    </template>
  </Stepper>
</template>
<script>
import Stepper from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/Stepper';
import Progressions
  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Progressions';
import Outcomes from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Outcomes';
import ChipsPreview
  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/ChipsPreview';
import Syllabus from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Syllabus';
import Substrand from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Substrand.vue'
import {clone} from '@/plugins/utils';
import {mapGetters} from 'vuex';
import ProgressionItem
  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Progressions/ProgressionItem'
import {FeatureIcon} from '@nswdoe/doe-ui-core';

export default {
  name: 'CurriculumSteps',
  components: {
    FeatureIcon,
    ProgressionItem,
    Syllabus,
    ChipsPreview,
    Outcomes,
    Progressions,
    Stepper,
    // Substrand
  },
  props: {
    syllabus: {
      type: Array,
      default: () => []
    },
    outcomes: {
      type: Array,
      default: () => []
    },
    v2Progressions: {
      type: Array,
      default: () => []
    },
    v3Progressions: {
      type: Array,
      default: () => []
    },
    curriculumInput: {
      type: Object,
      default: () => {
      }
    }
  },
  data: function () {
    return {
      syllabusInternal: [],
      outcomesInternal: [],
      steps: [
        {
          id: 'step1',
          number: 1,
          name: 'Syllabus'
        },
        // {
        //   id: 'step2',
        //   number: 2,
        //   name: 'Substrand'
        // },
        {
          id: 'step2',
          number: 2,
          name: 'Syllabus outcome'
        },
        {
          id: 'step3',
          number: 3,
          name: 'Progression'
        }
      ],
      currentStep: 1,
      ckey: 1
    }
  },
  computed: {
    ...mapGetters({
      clearProgressions: 'editor/clearProgressions',
      removeProgressionsDialog: 'editor/removeProgressionsDialog'
    }),
    previewValues: {
      get: function () {
        const outcomes = this.outcomesInternal.map(o => o.id);
        return [...this.curriculumInput.kla, ...this.curriculumInput.stage, ...this.syllabusInternal, ...outcomes];
      },
      set: function (val) {
        const removedItem = this.previewValues.find(i => !val.includes(i));
        if (removedItem === -1) {
          return;
        }
        if (this.syllabusInternal.includes(removedItem)) {
          if (this.progressionsInternal.length > 0) {
            this.$store.commit('editor/SET_REMOVE_PROGRESSIONS_DIALOG', true);
          }
          this.syllabusInternal = this.syllabusInternal.filter(s => s !== removedItem);
        }
        if (this.outcomesInternal.map(o => o.id).includes(removedItem)) {
          if (this.progressionsInternal.length > 0) {
            this.$store.commit('editor/SET_REMOVE_PROGRESSIONS_DIALOG', true);
          }
          this.outcomesInternal = this.outcomesInternal.filter(o => o.id !== removedItem);
        }
      }
    },
    allowedToClear: function () {
      switch (this.currentStep) {
        case 1:
          return this.syllabusInternal;
        // case 2:
        //   return this.outcomesInternal.map(o => o.id);
        case 2:
          return this.outcomesInternal.map(o => o.id);
        case 3:
          return [...this.v2Progressions.map(p => p.level), ...this.v3Progressions.map(p => p.level + ' (V3)')];
        default:
          return [];
      }
    }
  },
  watch: {
    clearProgressions: function (val) {
      if (val) {
        if (this.progressionsInternal.length > 0) {
          this.progressionsInternal = [];
        }
        this.$store.commit('myResources/SET_CLEAR_PROGRESSIONS', false);
      }
    },
    previewValues: {
      immediate: true,
      handler: function () {
        this.$nextTick(() => {
          const chipsRow = document.getElementById('chips__row');
          if (chipsRow) {
            chipsRow.scrollTo(chipsRow.scrollWidth, 0);
          }
        });
      }
    },
    syllabusInternal: async function (selections, oldSelections) {
      const removedSyllabus = oldSelections.find(i => !selections.includes(i));
      if (removedSyllabus) {
        const hasStages = this.curriculumInput.stage.length > 0;
        const hasKlas = this.curriculumInput.kla.length > 0;
        if (this.progressionsInternal.length > 0 && !this.removeProgressionsDialog && hasKlas && hasStages) {
          this.$store.commit('editor/SET_REMOVE_PROGRESSIONS_DIALOG', true);
        }
        // remove  outcomes related to the removed syllabus
        this.outcomesInternal = this.outcomesInternal.filter(o => o.syllabus !== removedSyllabus);
      }
      const payload = {
        syllabus: selections,
        stage: this.curriculumInput.stage
      }
      await this.$store.dispatch('metadata/fetchOutcomes', payload);
    },
    outcomesInternal: function (selections, oldSelections) {
      const outcomes = selections.map(o => o.id);
      const oldOutcomes = oldSelections.map(o => o.id);
      const removedOutcome = oldOutcomes.find(i => !outcomes.includes(i));
      const hasStages = this.curriculumInput.stage.length > 0;
      const hasKlas = this.curriculumInput.kla.length > 0;
      if (removedOutcome && this.progressionsInternal.length > 0 && !this.removeProgressionsDialog && hasStages && hasKlas) {
        this.$store.commit('editor/SET_REMOVE_PROGRESSIONS_DIALOG', true);
      }
    },
    syllabus: {
      immediate: true,
      handler: function (syllabus) {
        this.syllabusInternal = clone(syllabus);
      }
    },
    outcomes: {
      immediate: true,
      handler: function (outcomes) {
        this.outcomesInternal = clone(outcomes);
      }
    },
  },
  methods: {
    handleConfirm: function () {
      this.$emit('confirm', {
        syllabus: this.syllabusInternal,
        outcomes: this.outcomesInternal
      });
      // close dialog
      this.$emit('cancel');
    },
    handleCancel: function () {
      // reset state
      this.syllabusInternal = this.syllabus;
      this.outcomesInternal = this.outcomes;
      // close dialog
      this.$emit('cancel');
    },
    handleChangedStep: function (step) {
      this.currentStep = step;
      this.ckey++;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../scss/variables";

.stepper__wrapper {
  padding-top: 24px;

  ::v-deep.v-stepper__content {
    padding: 0 0 0 48px !important;
  }
}

@media only screen and (max-width: 600px) {

  .stepper__wrapper {
    padding-top: 56px;

    ::v-deep.v-stepper__content {
      padding: 0 0 0 0 !important;
    }
  }
}
</style>
