<template>
  <Dialog
    v-bind="$attrs"
    title="Overwrite existing Resource"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        class="mt-3 pb-0"
        :style="{'color': ADS_Colors.Dark}"
      >
        <span class="mb-2">
          Proceeding will replace existing published content with changes applied in this resource card.
        </span>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between px-6 pb-6 mt-10">
        <AdsButton
          button-text="Back"
          @click="$downwardBroadcast('close:dialog')"
          @keyup.enter="$downwardBroadcast('close:dialog')"
        />
        <AdsButton
          :color="ADS_Colors.Red_Dark"
          secondary
          button-text="Overwrite existing"
          @click="onOverwrite"
          @keyup.enter="onOverwrite"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'OverwriteExistingDialog',
  components: {
    Dialog,
    AdsButton
  },
  props: {
    resource: {
      type: Object
    }
  },
  data() {
    return {
      ADS_Colors: ADS_Colors
    }
  },
  methods: {
    onOverwrite() {
      this.$emit('overwrite:existing');
      this.$downwardBroadcast('close:dialog');
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  span {
    display: block;
    font-size: 16px;
  }
}
</style>

