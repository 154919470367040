<template>
  <AdsDataTable
    :items="items"
    :headers="headers"
    @click:row="handleClickRow"
  >
    <template #top>
      {{ null }}
    </template>
    <template #item.name="{item}">
      {{ getResourceTitle(item) }}
    </template>
    <template #item.resourceType="{item}">
      {{ item.document && (item.document.ResourceType ? item.document.ResourceType : 'Resource type') }}
    </template>
    <template #item.year="{item}">
      {{ getYears(item) }}
    </template>
    <template #item.updated="{ item }">
      {{
        moment(item.record.modified).format('DD MMM YYYY')
      }}
    </template>
    <template #item.actions="{ item }">
      <ActionsMenu
        icon
        top
        offset-y
        left
        :external-data="item"
        :items="moreActionsItems(item)"
        @clickedAction="handleAction"
      />
      <DeleteResourceDialog
        v-model="deleteDialog"
        :resource-id="item.record.id"
        :resource-name="item.document.Name"
        :is-owner="isOwner"
        :is-editor="isEditor"
      />
    </template>
  </AdsDataTable>
</template>

<script>
import {AdsDataTable} from '@nswdoe/doe-ui-core';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu';
import DeleteResourceDialog from '@/components/Dialogs/DeleteResourceDialog';
import moment from 'moment';

export default {
  name: 'MyResourcesListView',
  components: {
    ActionsMenu,
    AdsDataTable,
    DeleteResourceDialog
  },
  props: {
    resources: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      moment,
      deleteDialog: false,
      headers: [
        {
          text: 'Resource title',
          value: 'name',
          width: this.resourceTitleWidth,
          sortable: false
        },
        {
          text: 'Resource type',
          value: 'resourceType',
          sortable: false
        },
        {
          text: 'Years',
          value: 'year',
          sortable: false
        },
        {
          text: 'Updated',
          value: 'updated',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
    }
  },
  computed: {
    items() {
      return this.resources.map(resource => {
        return {
          ...resource,
          ...resource.document,
        }
      });
    },
    resourceTitleWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 500;
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        return 400;
      }
      return 300;
    }
  },
  methods: {
    handleClickRow(item) {
      this.$router.push('/myresources/' + item.record.id);
    },
    isOwner(item) {
      return item.userAccess.owner.includes(this.$store.getters['users/userProfile'].userId.toLowerCase());
    },
    isEditor(item) {
      return item.userAccess.editor.includes(this.$store.getters['users/userProfile'].userId.toLowerCase());
    },
    isViewer(item) {
      return item.userAccess.viewer.includes(this.$store.getters['users/userProfile'].userId.toLowerCase());
    },
    getResourceTitle(item) {
      const name = item.document?.Name ? item.document?.Name : 'No title created';
      return !item.record?.published && !this.isViewer(item) ? name + ' (Draft)' : name;
    },
    getYears(item) {
      if (item.document?.Year.length) {
        return this.parseAndSortYearsOrStages(item.document?.Year, 'Kindergarten').join(', ');
      }
      return 'None selected';
    },
    parseAndSortYearsOrStages(arr, str) {
      let arrModified = [];
      let includeString = false;
      if (arr.includes(str)) {
        includeString = true;
        arrModified = arr.filter(s => s !== str);
        arrModified = arrModified.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      } else {
        arrModified = arr.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      }
      if (includeString) {
        arrModified.unshift('K');
      }
      return arrModified;
    },
    moreActionsItems(item) {
      let access;
      let isDraft = false;
      if (item.userAccess.owner.includes(this.$store.getters['users/userProfile'].userId.toLowerCase())) {
        access = 'owner';
      }
      if (item.userAccess.editor.includes(this.$store.getters['users/userProfile'].userId.toLowerCase())) {
        access = 'editor';
      }
      if (!item.record.published) {
        isDraft = true;
      }

      let items;
      if (access === 'editor' || access === 'owner') {
        items = [
          {
            id: 'goToResource',
            name: 'Go to resource',
            tag: 'a',
            icon: 'mdi-open-in-new'
          },
          {
            id: 'editCard',
            name: 'Edit this resource',
            tag: 'a',
            icon: 'mdi-pencil-outline'
          },
          {
            id: 'delete',
            name: 'Delete',
            tag: 'button',
            icon: 'mdi-delete-outline',
          }
        ];
        if (isDraft) {
          items = items.filter(i => i.id === 'delete' || i.id === 'editCard');
        }
      } else {
        items = [
          {
            id: 'goToResource',
            name: 'Go to resource',
            tag: 'a',
            icon: 'mdi-open-in-new'
          },
          {
            id: 'delete',
            name: 'Delete',
            tag: 'button',
            icon: 'mdi-delete-outline',
          }
        ]
      }
      return items;
    },
    handleGoToResource({resourceId, resourceUrl}) {
      this.$store.dispatch('captureGoToResource', resourceId);
      window.open(resourceUrl, '_blank');
    },
    handleAction({externalData, action}) {
      const resource = externalData;
      const resourceUrl = resource.links?.path || resource.files[0]?.DownloadUrl || resource.document?.ResourceURL;
      const resourceId = resource.record.id;
      switch (action) {
        case 'goToResource':
          this.handleGoToResource({resourceId, resourceUrl});
          break;
        case 'shareCard':
          this.shareDialog = true;
          break;
        case 'editCard':
          this.$store.commit('myResources/SET_EDIT_MODE', true);
          this.$router.push(`/myresources/${resourceId}`);
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    }
  }
}
</script>
