import {get, patch, post, put} from '../http';
import Vue from 'vue'
import axios from 'axios'
import {apiBaseUrl} from '@/store/config'

const CONFIG = (window as any).dlrhConfig;

const createResource = (payload, progressCallback=(progress: any)=>{return ;}) => {
  // force all IDs to be uppercase, as Elastic treats lower and upper case ids as different ids
  payload.document.ItemID = payload.document.ItemID.toString().toUpperCase();
  const uid = payload.uid;
  const resourceId = payload.document.ItemID;
  const repository = payload.document.Source;
  const files = payload.files;
  const links = payload.links;
  const zipFiles = payload.zipFiles;

  try {
    let zipAliases = {};
    let zipUnpackOnly = {};
    zipFiles?.forEach(f => {
      if (f.original !== f.new && f.new !== '') {
        zipAliases = {
          ...zipAliases,
          [f.original]: f.new
        }
      }
      zipUnpackOnly = {
        ...zipUnpackOnly,
        [f.new || f.original]: f.unpackOnly.map(name => {return {name}})
      }
    })
    console.log('all zip files', JSON.stringify(zipFiles))
    console.log('zip files:', JSON.stringify(zipAliases))
    console.log('zip unpack:', JSON.stringify(zipUnpackOnly))

    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'multipart/form-data',
          application: payload.application || CONFIG.APPLICATION,
          source: repository,
          uid: uid,
          sessionid: payload.sessionId,
          filerename: JSON.stringify(zipAliases)
        })
      },
      onUploadProgress: progressCallback,
    };
    const bodyFormData = new FormData();

    const action = `action=${payload.isDraft ? 'savedraft' : 'publish'}`
    const scope = `scope=${payload.scope}`

    const resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}?${action}&${scope}`;

    if (payload.isDraft) {
      // add the draft object
      bodyFormData.append('draft', JSON.stringify({
        ...payload.document,
        DateCreated: new Date().toISOString().substr(0, 19), // remove micro seconds
        DateModified: new Date().toISOString().substr(0, 19),
        DateResCreated: new Date().toISOString().substr(0, 19)
      }));
    } else {
      // add the document object
      bodyFormData.append('document', JSON.stringify({
        ...payload.document,
        DateCreated: new Date().toISOString().substr(0, 19), // remove micro seconds
        DateModified: new Date().toISOString().substr(0, 19),
        DateResCreated: new Date().toISOString().substr(0, 19)
      }));
    }

    // 4.3 - move links from document/draft
    bodyFormData.append('links', JSON.stringify(links));

    console.log('files in the create payload', files)
    for (let i = 0; i < files.length; i++) {
      if (files[i].file.size !== 0) { // filter out dummy binary file
        // console.log('attaching to bodyFormData', files[i])
        bodyFormData.append('files', files[i].file);
      }
    }

    // 4.4 - unpack files in zip files
    bodyFormData.append('unpackonly', JSON.stringify(zipUnpackOnly));

    return axios.post(resourcesURL, bodyFormData, config);
  } catch (err) {
    console.error(err);
    return null;
  }
};
const deleteResource = (url, config = {}) => axios.delete(url, config);
const deleteFiles = (url, config = {}) => axios.delete(url, config);
const publish = (url, data, config = {}) => axios.put(url, data, config);
const updateResource = (url, data, config = {}) => axios.put(url, data, config);
const savePublished = (url, data, config = {}) => axios.put(url, data, config);
const getResources = (url, config = {}) => post(url, {}, config);
const getDetailCard = (url, config = {}) => get(url, config);
const getMetaData = (url, config = {}) => get(url, config);
const UpsConfig = (url, config = {}) => get(url, config);
const patchResource = (url, data = {}, config = {}) => patch(url, data, config);
const sendEmail = (url, data, config) => post(url, data, config);
const getResourceId = (url, config) => get(url, config);
const postResourceStatus = (url, data, config) => post(url, data, config);
const getStateMachineForResource = (url, config) => get(url, config);
const getApproversPublishers = (url, config) => get(url, config);

export default {
  createResource,
  updateResource,
  savePublished,
  deleteResource,
  getResources,
  getDetailCard,
  getMetaData,
  publish,
  UpsConfig,
  patchResource,
  sendEmail,
  getResourceId,
  postResourceStatus,
  getStateMachineForResource,
  getApproversPublishers,
  deleteFiles
}
