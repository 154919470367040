<template>
  <v-row
    class="copyright__container pa-2 mb-8"
  >
    <h3 class="title pb-3">
      {{ copyrightTitle }}
    </h3>
    <p
      class="body-1 mb-0"
      style="white-space: pre-line;"
      v-html="copyrightBody"
    />
    <v-col
      cols="12"
      class="pl-0 pb-0 mt-2"
    >
      <v-radio-group
        v-model="selectedOption"
        :rules="[v => !!v || 'Selection is required']"
      >
        {{ copyrightRadioGroupLabel }}
        <span
          v-for="(rights, i) in copyrightItems"
          :key="i"
        >
          <v-radio
            v-if="!rights.label"
            :label="rights.text || rights"
            :value="rights.value || rights"
            class="mt-2 mb-2"
            :class="{ 'ml-4' :rights.subOption}"
          />
          <label v-else>
            {{ rights.text }}
          </label>
        </span>

        <template v-if="showOtherStatement">
          <v-radio
            label="Other"
            value="Other"
            class="mt-2 mb-2"
          />
          <AdsTextField
            v-if="isOther"
            v-model="otherStatement"
            :rules="[v => !!v || 'This is required']"
            label="Other copyright statement"
            aria-required
            placeholder="Please specify the copyright statement"
            @change="handleRadioGroupChange"
          />
        </template>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { AdsTextField } from '@nswdoe/doe-ui-core';

export default {
  name: 'CopyrightSelector',
  components: {
    AdsTextField
  },
  props: {
    statement: String,
    copyrightTitle: {
      type: String,
      default: ''
    },
    copyrightRadioGroupLabel: {
      type: String,
      default: ''
    },
    copyrightBody: {
      type: String,
      default: ''
    },
    copyrightItems: {
      type: Array,
      default: () => []
    },
    showOtherStatement: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      otherStatement: ''
    }
  },
  computed: {
    isOther() {
      return this.copyrightItems.indexOf(this.statement) === -1
    },
    selectedOption: {
      get() {
        return this.isOther? 'Other': this.statement
      },
      set(val) {
        this.$emit('change', (val === 'Other')? this.otherStatement: val)
      }
    }
  },
  watch: {
    statement() {
      if(this.isOther) {
        this.otherStatement= this.statement
      }
    }
  },
  methods: {
    handleRadioGroupChange() {
      this.$emit('change', this.selectedOption === 'Other'? this.otherStatement: this.selectedOption)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/ads";
@import "../../../scss/variables";

.theme--light {
  ::v-deep label.v-label {
    color: $ads-dark;
  }
}

.copyright__container {
  border: 1px solid $ads-light-40;
  box-sizing: border-box;
  border-radius: 4px;

  .body-1 {
    letter-spacing: normal !important;
  }
}
</style>
