import {get, post, patchSearch, deleteSearch} from '../http';

const getSavedSearchesById = (url, config = {}) => get(url, config);
const postSaveSearch = (url, data, config = {}) => post(url, data, config);
const patchSaveSearch = (url, data = {}, config = {}) => patchSearch(url, data, config);
const deleteSaveSearchById = (url, config = {}) => deleteSearch(url, config);

export default {
  getSavedSearchesById,
  postSaveSearch,
  patchSaveSearch,
  deleteSaveSearchById
}
