<template>
  <Dialog
    v-bind="$attrs"
    icon="mdi-upload-outline"
    title="Upload Progress"
    :width="
      $vuetify.breakpoint.mdAndUp
        ? 800
        : $vuetify.breakpoint.smAndUp
          ? 600
          : 360
    "
    v-on="$listeners"
  >
    <template #content>
      <div class="fileprog-wrapper mt-6 pa-10">
        <v-card-text>
          <div class="d-flex justify-center spinner-holder mb-10">
            <v-img
              :src="spinnerImage"
              :width="96"
              class="spinner"
            />
          </div>
          <div class="text-center upload-file-text pb-2">
            <p> Your file is being uploaded </p>
          </div>
          <v-progress-linear
            v-model="estimatedLoadPercent"
            rounded
            color="#00AA45"
            height="25"
            background-color="#F4F4F7"
            :indeterminate="estimatedLoadPercent === 100 ? true : false"
          >
            <template #default="{ value }">
              <div>{{ value === 100 ? "" : Math.round(value)+" %" }}</div>
            </template>
          </v-progress-linear>
        </v-card-text>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog.vue';
import spinnerImage from '@/assets/spinner.png'
import {mapState} from 'vuex';

export default {
  name: 'FileProgressDialog',
  components: {
    Dialog,
  },
  props: {
    addMult: Number,
  },
  data() {
    return {
      spinnerImage,
      averageSpeed: 0,
      highestSpeed: 0,
      extraPercent: 0,

      shouldBeFakingSpeed: true,
      startCountingSpeedInterval: undefined,
      isRunning: false,
    }
  },
  computed: {
    totalMult() {
      let tMult = 1.5;
      if(typeof this.addMult == 'number') tMult += (this.addMult > 0 ? this.addMult : 0);
      if(this.addMult > 0.01) tMult += 1
      return tMult;
    },
    ...mapState({
      loadPercent: state => state.loadPercent,
    }),
    estimatedLoadPercent: {
      get: function() { let tlp = (this.loadPercent + this.extraPercent) / this.totalMult; return tlp > 100 ? 100 : tlp }, // cap 100
      set: function(nv) { return this.estimatedLoadPercent; } // disallow setting, quietly.
    },
    isHidden() {
      return !this.$attrs.value;
    },
    progSpeed() {
      let tSpeed = this.highestSpeed > 40 ? 40 : this.highestSpeed;
      return tSpeed + ((Math.random()-0.5)*0.35*tSpeed)
    }
  },
  watch: {
    loadPercent(val) {
      if(val >= 99.999) {
        this.startFakingSpeed()
        clearInterval(this.startCountingSpeed);
      } else if (val <= 0) {
        this.resetCountSpeedEtc();
      } else if (!this.isRunning) this.startCountingSpeed();
    },
    isHidden(dialogIsHidden) {
      if(dialogIsHidden) this.resetCountSpeedEtc();
      else this.startCountingSpeed();
    }
  },
  beforeUnmount() {
    this.resetCountSpeedEtc();
  },
  methods: {
    selectAll() {
      if (this.isSelectAll === false) {
        this.selected = [];
      } else {
        this.selected = [...this.zipFileContents];
      }
    },
    handleCancel() {
      this.$downwardBroadcast('close:dialog');
    },
    handleConfirm() {
      this.$downwardBroadcast('close:dialog');
    },
    startFakingSpeed() {
      let updateInterval = 0.25
      let randomFactor = 0.35
      this.shouldBeFakingSpeed = true;

      let updateFakeSpeed = () => {
        if(this.estimatedLoadPercent === 100) this.shouldBeFakingSpeed = false;
        let addPercent = this.progSpeed // math.random makes it look more natural
        let tUpdateInterval = updateInterval + ((Math.random()-0.5)*randomFactor*updateInterval) // give it an update between 0.2 and 0.35s timer, roughly
        this.extraPercent = this.extraPercent + (addPercent * tUpdateInterval)

        if(this.shouldBeFakingSpeed) setTimeout(updateFakeSpeed, 1000 * tUpdateInterval)
      }
      updateFakeSpeed();
    },
    startCountingSpeed() {
      let previousPercent = this.loadPercent;
      let countFreq = 1.5
      this.isRunning = true;

      this.startCountingSpeedInterval = setInterval(() => {
        if(this.loadPercent === 0) this.resetCountSpeedEtc()
        this.averageSpeed = (this.loadPercent - previousPercent) / countFreq; // avgspeed now irrelevant to countFrequency
        this.highestSpeed = (this.highestSpeed > this.averageSpeed) ? this.highestSpeed : this.averageSpeed // choose greater
        previousPercent = this.loadPercent;
      }, 1000 * countFreq)
    },
    resetCountSpeedEtc() {
      this.highestSpeed = 0; this.averageSpeed = 0; this.extraPercent = 0;
      clearInterval(this.startCountingSpeedInterval)
      clearInterval(this.startFakingSpeedInterval)
      this.shouldBeFakingSpeed = false;
      this.isRunning = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.upload-file-text {
  font-size: 18px;
}
.spinner {
  opacity: 0.5;
  transform: rotate(0);
  animation: spin 1.5s linear 0s infinite forwards;
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

</style>
