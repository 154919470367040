<template>
  <v-row class="resource-info__wrapper">
    <v-col
      cols="12"
      sm="12"
    >
      <div class="d-md-flex mt-2 justify-space-between">
        <div class="resource-type d-md-flex flex-md-row-reverse justify-end">
          <span
            v-if="resourceCategoryDetails"
            class="d-flex mb-3"
          >
            <FeatureIcon
              v-for="cat in resourceCategoryDetails"
              :key="cat.id"
              size="20"
              :aria-label="cat.name"
              :color="cat.iconColor"
              :backgroundColor="cat.iconBg"
              :icon="cat.icon"
              class="mr-2"
            />
            <span
              class="text--secondary text-body-2 font-weight-regular"
              aria-hidden="true"
            >{{ resourceCategoryLabel }}</span>
          </span>
          <span class="font-weight-bold mr-2">{{ resourceType }}</span>
        </div>

        <div class="d-flex">
          <AdsButton
            v-if="isEquellaResource"
            secondary
            small
            buttonText="Share"
            icon="share"
            :color="ADS_Colors.Navy"
            ariaLabel="Share"
            class="mr-2 mt-n1"
            :class="{'share-button': true, 'share-button__mobile': $vuetify.breakpoint.xsOnly}"
            @click="showShareDialog = true"
          />
          <div class="mr-2 mt-n1">
            <GoToResources
              :id="document?.ItemID"
              primary
              icon="arrow_drop_down"
              button-text="Go to resources"
              align-right
              :item-url="document?.ResourceURL"
              :name="document?.Name"
              :items="resourceList"
            />
          </div>
          <Chip
            v-if="resourceStatus"
            chipClass="status-chip"
            :text="resourceStatus"
            :color="statusChipColor(resourceStatus)"
            :outlined="statusChipOutlined(resourceStatus)"
            :textColor="statusChipTextColor(resourceStatus)"
          />
        </div>
      </div>

      <h2 class="page-title">
        {{ name }}
      </h2>
      <p class="updated-date">
        <!--        <span v-if="isEditor && !isNewResource">Created by me&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
        <span v-if="!isEditor && !isNewResource">Created by {{ creatorName }}&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</span>
        <span v-if="!isNewResource">
          Version {{
            currentVersion
          }}.0&nbsp;&nbsp;&#8226;&nbsp;&nbsp;
        </span>-->
        Updated: {{ updated }}
      </p>
      <div :class="{'mb-3': true, 'd-flex flex-column': $vuetify.breakpoint.xsOnly}">
        <span class="label">Format: </span>
        <span>
          {{ format }}
        </span>
      </div>
      <div class="tags__wrapper">
        <div class="tag--group__wrapper">
          <span class="label">Stage(s): </span>
          <span>
            {{ stage }}
          </span>
        </div>
        <div class="tag--group__wrapper">
          <span class="label">Year(s): </span>
          <span>
            {{ year }}
          </span>
        </div>
        <div class="tag--group__wrapper">
          <span class="label">Learning Area(s): </span>
          <span>
            {{ learningArea }}
          </span>
        </div>
      </div>
      <div class="label">
        Description:
      </div>
      <div v-html="description" />
    </v-col>
    <ShareResourcesDialog
      v-if="isEquellaResource"
      v-model="showShareDialog"
    />
  </v-row>
</template>

<script>
import {Chip, FeatureIcon, AdsButton, ADS_Colors} from '@nswdoe/doe-ui-core';
import moment from 'moment';
import {mapGetters, mapState} from 'vuex';
import {RESOURCE_CATEGORIES, ResourceStatus} from '@/constants'
import GoToResources from '@/components/GoToResource/GoToResources';
import statusChipMixin from '@/mixins/statusChipMixin';
import ShareResourcesDialog from '@/components/Dialogs/Editor/ShareResourcesDialog';

export default {
  name: 'PreviewResourceInfo',
  components: {
    AdsButton,
    Chip,
    FeatureIcon,
    GoToResources,
    ShareResourcesDialog
  },
  mixins: [statusChipMixin],
  props: {
    isNewResource: {     // prop used to differentiate when the component is used to create a resource or edit one
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moment,
      ckey: 0,
      ADS_Colors,
      showShareDialog: false
    }
  },
  computed: {
    ...mapState({
      document: state => state.editor.resourceDetails?.record?.status === ResourceStatus.published ? state.editor.resourceDetails?.document : state.editor.resourceDetails?.draft,
      userAccess: state => state.editor.resourceDetails?.userAccess,
      published: state => state.editor.resourceDetails?.record?.status === ResourceStatus.published,
      isEquellaResource: state => state.editor.resourceDetails?.audit?.iss === 'equ'
    }),
    ...mapGetters({
      usersProfile: 'users/userProfile',
      allHubResourceTypes: 'metadata/allHubResourceTypes',
      stagesAndYears: 'metadata/mappedStagesAndYears',
      scopes: 'metadata/scopes',
      resourceStatus: 'editor/resourceStatus',
      orderedSources: 'editor/orderedSources',
      resourceList: 'editor/goToResourceList'
    }),
    // status() {
    //   return this.published ? 'published' : 'draft';
    // },
    resourceUrl() {
      // @TODO find out ResourceURL vs DownloadURL
      return this.document?.ResourceURL || (this.$store.state.editor.resourceDetails?.files ? this.$store.state.editor.resourceDetails.files[0]?.DownloadUrl : '') || '';
    },
    resourceCategory() {
      const resourceType = this.document?.ResourceCategory[0];
      return this.$store.getters['metadata/hubTypes'].filter(item => resourceType === item.resourcecatid);
    },
    resourceCategoryDetails() {
      return this.document?.ResourceCategory?.map(id => this.getCategoryDetails(id));
    },
    resourceCategoryLabel() {
      return this.resourceCategory[0]?.name;
    },
    /*    currentVersion() {
          if (this.$route.query.version) {
            const versionHistory = this.$store.getters['myResources/versionHistory'];
            const index = versionHistory.findIndex(i => i._id === this.$route.query.version);
            return versionHistory.length - index;
          } else {
            return this.$store.getters['myResources/currentVersion'];
          }
        },*/
    /*    isEditor() {
          return this.usersProfile?.appRole?.includes('editor');
        },*/
    resourceType() {
      const type = this.allHubResourceTypes.filter(el => el.id === this.document?.ResourceType)
      return this.document?.ResourceType?.length > 0 ? type[0]?.name : 'General Resource'
    },
    name() {
      return this.document?.Name ? this.document.Name : 'No title created';
    },
    updated() {
      if (this.document && this.document.DateModified) {
        return this.moment(this.document?.DateModified).format('DD MMM YYYY');
      } else {
        return this.moment(new Date()).format('DD MMM YYYY');
      }
    },
    scope() {
      return this.scopes.find(s => s.id === this.userAccess?.scope)?.value
    },
    format() {
      return this.document?.Format ? this.document?.Format : 'None';
    },
    stage() {
      if (this.document?.Year && !this.document?.Stage) {
        const years = this.document?.Year
        const stages = years ? this.stagesAndYears.filter(sy => {
          return years.includes(sy.year)
        }).map(s => s.stage) : []

        // just removing duplicates
        return [...new Set(stages)].join(', ');
      }
      return this.document?.Stage?.join(', ') || 'none'
    },
    year() {
      // return this.document?.Year?.length > 0 ? this.parseAndSortYearsOrStages(this.document.Year, 'Kindergarten').join(', ') : 'None';
      return this.document?.Year?.join(', ') || 'none'
    },
    learningArea() {
      return this.document?.KLA?.length > 0 ? this.document.KLA.join(', ') : 'None';
    },
    description() {
      return this.document?.Description ? this.document.Description : 'None';
    },
    /*    creatorName() {
          return this.resourceDetails?.creator ? this.resourceDetails.creator.name : '';
        }*/
  },
  watch: {
    description: {
      immediate: true,
      handler() {
        this.ckey++;
      }
    },
  },
  methods: {
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    parseAndSortYearsOrStages(arr, str) {
      let arrModified = [];
      let includeString = false;
      if (arr.includes(str)) {
        includeString = true;
        arrModified = arr.filter(s => s !== str);
        arrModified = arrModified.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      } else {
        arrModified = arr.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      }
      if (includeString) {
        arrModified.unshift('K');
      }
      return arrModified;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.resource-info__wrapper {
  margin: 0 0 24px 0 !important;

  .col-12 {
    padding-left: 0;
    padding-right: 0;

    .resource-type {
      font-size: 16px;
      font-weight: bold;
      color: $ads-blue-1;
      margin-bottom: 8px;

      .status-type {
        background-color: $ads-blue-1;
        width: 20%;
        vertical-align: baseline;
        text-align: center;
        border-radius: 100px;;
        color: $ads-white;
        margin-left: auto;
        font-weight: bold;
      }
    }

    .page-title {
      font-size: 34px;
      font-weight: bold;
      color: $ads-navy;
      word-break: break-word;
      line-height: 40px;
      margin-bottom: 8px;
    }

    .updated-date {
      color: $ads-dark-60;
    }

    .tags__wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 28px;

      .tag--group__wrapper {
        display: flex;
        min-width: 120px;
        margin-right: 24px;
      }
    }

    .label {
      color: $ads-navy;
      margin-right: 8px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 669px) {
  .resource-info__wrapper {
    .col-12 {
      .page-title {
        font-size: 20px;
        line-height: 28px;
      }

      .tags__wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        .tag--group__wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
