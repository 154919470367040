<template>
  <v-select
    v-bind="$attrs"
    outlined
    chips
    deletable-chips
    multiple
    :label="label"
    :items="items"
    item-text="element"
    return-object
    v-on="$listeners"
  />
</template>
<script>
export default {
  name: 'GroupedSelect',
  props:{
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: []
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/variables.scss";

::v-deep.v-autocomplete {
  padding: 0;
}

.theme--light {
  ::v-deep.v-messages {
    text-align: right !important;
  }
}

::v-deep.v-select {
  &.v-select--chips:not(.v-text-field--single-line) {
    &.v-text-field--enclosed {
      .v-select__selections {
        min-height: 56px;
      }
    }
  }
}

.theme--light {
  ::v-deep.v-icon {
    &:focus {
      border: 2px solid $ads-navy;
      border-radius: 50%;

      &::after {
        opacity: 0;
      }
    }
  }

  ::v-deep.v-chip {
    background: $ads-white;
    border: 1px solid $ads-navy;

    .v-chip__content {
      button {
        &:focus {
          border: none !important;
          outline: 2px auto $ads-navy;
        }
      }
    }
  }
}
</style>
