<template>
  <div>
    <v-row
      class="image__footer"
      :class="{'image__footer--disabled': !allowed}"
    >
      <div class="first-col">
        <div class="left-content">
          <!--          <span class="like-button">-->
          <!--            <v-btn-->
          <!--              icon-->
          <!--              small-->
          <!--              @click.stop.prevent="onClickLikeIcon"-->
          <!--              v-if="likeClickable"-->
          <!--            >-->
          <!--              <v-icon-->
          <!--                v-if="liked"-->
          <!--                class=""-->
          <!--                aria-label="number of views"-->
          <!--                role="img"-->
          <!--                aria-hidden="false"-->
          <!--              >-->
          <!--                mdi-thumb-up-->
          <!--              </v-icon>-->
          <!--              <v-icon-->
          <!--                v-else-->
          <!--                aria-label="number of views"-->
          <!--                role="img"-->
          <!--                aria-hidden="false"-->
          <!--              >-->
          <!--                mdi-thumb-up-outline-->
          <!--              </v-icon>-->
          <!--            </v-btn>-->
          <!--            <v-btn-->
          <!--              icon-->
          <!--              small-->
          <!--              @click.stop.prevent="onClickLikeIcon"-->
          <!--              v-if="!likeClickable"-->
          <!--              disabled-->
          <!--            >-->
          <!--              <v-icon-->
          <!--                v-if="liked"-->
          <!--                class=""-->
          <!--                aria-label="number of views"-->
          <!--                role="img"-->
          <!--                aria-hidden="false"-->
          <!--              >-->
          <!--                mdi-thumb-up-->
          <!--              </v-icon>-->
          <!--              <v-icon-->
          <!--                v-else-->
          <!--                aria-label="number of views"-->
          <!--                role="img"-->
          <!--                aria-hidden="false"-->
          <!--              >-->
          <!--                mdi-thumb-up-outline-->
          <!--              </v-icon>-->
          <!--            </v-btn>-->
          <!--            {{ likes }}-->
          <!--          </span>-->
          <!--          <span>-->
          <!--            <v-icon-->
          <!--              class="material-icons-outlined"-->
          <!--              aria-label="number of views"-->
          <!--              role="img"-->
          <!--              aria-hidden="false"-->
          <!--            >-->
          <!--              remove_red_eye-->
          <!--            </v-icon>-->
          <!--            {{ views }}-->
          <!--          </span>-->

          <v-tooltip
            bottom
            activator=".report-button"
          >
            <span>Flag / Report</span>
          </v-tooltip>
        </div>
        <div
          class="right-content"
        />
      </div>
      <div class="second-col">
        <div class="share-button__wrapper">
          <Share
            class="share-button"
            :item="{
              id: itemId,
              url: resourceUrl,
              name: name,
              statement: resourceDetails?.document?.Rights
            }"
          />
        </div>
        <div class="resource-button__wrapper">
          <GoToResource
            :id="itemId"
            :item-url="resourceUrl"
            :name="name"
          />
        </div>
      </div>
    </v-row>

    <CopyrightDialog
      v-model="dialogCopyright"
      icon="info_outline"
      title="You cannot share this resource until you have provided a known copyright status"
      :args="args"
    />
  </div>
</template>

<script>
import GoToResource from '@/components/GoToResource'
import Share from '@/components/Share'
import {mapState} from 'vuex';
import CopyrightDialog from '@/components/Dialogs/CopyrightDialog';

export default {
  name: 'PreviewImageFooter',
  components: {
    CopyrightDialog,
    GoToResource,
    Share
  },
  props: {
    isDraft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reportDialog: false,
      shareToggle: null,
      liked: false,
      likeClickable: true,
      dialogCopyright: false,
      args: ''
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.myResources.resourceDetails,
      document: state => state.myResources.resourceDetails?.document
    }),
    copyrightCompleted() {
      return (this.document?.Rights === 'Rights not known.' || this.document?.Rights === '') ? false : true;
    },
    isViewer() {
      if (!this.resourceDetails) {
        return false;
      }
      const loggedUser = this.$store.state.uid;
      return !this.resourceDetails?.userAccess?.owner?.includes(loggedUser) && !this.resourceDetails?.userAccess?.editor?.includes(loggedUser);
    },
    isEditor() {
      if (!this.resourceDetails) {
        return false;
      }
      const loggedUser = this.$store.state.uid;
      return this.resourceDetails?.userAccess?.editor?.includes(loggedUser);
    },
    isOwner() {
      if (!this.resourceDetails) {
        return false;
      }
      const loggedUser = this.$store.state.uid;
      return this.resourceDetails?.userAccess?.owner?.includes(loggedUser);
    },
    allowed() {
      if (this.isDraft && this.isViewer) {
        return true;
      }
      if (this.isDraft && (this.isEditor || this.isOwner)) {
        return false;
      }
      return (this.isViewer && !this.isEditor && !this.isOwner) || (this.$route.params.id !== 'new');
    },
    views() {
      return this.resourceDetails?.misc?.views > 0 ? this.resourceDetails?.misc.views : 0;
    },
    likes() {
      return this.resourceDetails?.misc?.likes > 0 ? this.resourceDetails?.misc.likes : 0;
    },
    resourceUrl() {
      return this.resourceDetails?.document?.ResourceURL || this.resourceDetails?.links?.path || (this.resourceDetails?.files ? this.resourceDetails.files[0]?.DownloadUrl : '') || '';
    },
    name() {
      return this.document?.Name ?? '';
    },
    itemId() {
      return this.resourceDetails?.record?.id ?? '';
    }
  },
  watch: {
    shareToggle() {
      this.shareToggle = undefined;
    }
  },
  mounted() {
    this.$eventHub.$on('show:copyright', this.showCopyright);
    if (this.resourceUrl || this.itemId) {
      this.displayGoogleClassroomShare();
      this.displayMsTeamsShare();
      this.liked = this.resourceDetails.misc?.hasLiked ? true : false;
    }
  },
  beforeUnmount() {
    this.$eventHub.$off('show:copyright');
  },
  updated() {
    if (this.resourceUrl || this.itemId) {
      this.displayGoogleClassroomShare();
      this.displayMsTeamsShare();
    }
  },
  methods: {
    handleDialog() {
      if (this.document.Rights === 'Rights not known.' || this.document.Rights === '') {
        this.$eventHub.$emit('show:copyright');
      }
    },
    showCopyright(args) {
      if (args === 'shareCard') {
        this.args = args;
      }
      this.dialogCopyright = true;
    },
    async onClickLikeIcon() {
      if (!this.likeClickable) {
        return;
      }
      this.likeClickable = false;
      this.liked = !this.liked;
      this.$store.commit('myResources/SET_HASLIKED', this.liked);
      let result = await this.$store.dispatch('myResources/patchLike');
      if (result.status === 200) {
        this.likeClickable = true;
        try {
          await this.$store.dispatch('myResources/fetchResourceDetails', {id: this.itemId, fromLike: true});
          this.liked = this.resourceDetails.misc.hasLiked;
        } catch (e) {
          console.err(e);
        }
      }
    },
    displayGoogleClassroomShare() {
      const gapi = window['gapi'];  // included in index.html
      if (gapi === undefined) {
        console.error('DetailCardDialog - window.gapi is undefined');
      } else {
        if (this.itemId) {
          gapi.sharetoclassroom.render(`googleClassroom${this.itemId}`, {
            url: this.resourceUrl,
            onsharestart: this.googleClassroomClick
          });
        }

      }
    },
    displayMsTeamsShare() {
      const shareToMicrosoftTeams = window['shareToMicrosoftTeams'];
      const element = document.getElementById(`msTeams${this.itemId}`);
      if (shareToMicrosoftTeams === undefined) {
        console.error('DetailCardDialog - window.shareToMicrosoftTeams is undefined');
      } else if (this.itemId && element) {
        shareToMicrosoftTeams.renderButtons({
          elements: [document.getElementById(`msTeams${this.itemId}`)]
        });
      }
    },
    msTeamsClick() {
      this.$store.dispatch('captureTeamsShare', {
        id: this.itemId,
        name: this.name
      });
    },
    googleClassroomClick() {
      this.$store.dispatch('captureGoogleClassroomShare', {
        id: this.itemId,
        name: this.name
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.image__footer {
  margin: 0 !important;

  .first-col {
    padding: 12px 12px 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;

    .left-content {
      display: flex;
      align-items: center;
      min-width: 130px;
      margin-right: 12px;

      span {
        margin-right: 8px;
      }

      .like-button {
        ::v-deep.v-btn {
          &:focus, &:hover {
            outline-color: $ads-navy !important;

            &:before {
              opacity: 0;
            }
          }
        }
      }
    }

    .right-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .icon-container {
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.teams-share-button__container {
          padding-top: 6px;
        }
      }
    }
  }

  .second-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 0;
    width: fit-content;

    .share-button__wrapper {
      padding-right: 12px;
    }
  }
}

.image__footer--disabled {
  opacity: 0.4;
  pointer-events: none;
}

::v-deep.report-button {
  margin-left: 8px;

  &:focus, &:hover {
    outline-color: $ads-red !important;

    &:before {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 1060px) and (min-width: 960px) {

  .image__footer {
    .first-col {
      padding-right: 0;
    }

    .second-col {
      width: 100%;

      .share-button__wrapper, .resource-button__wrapper {
        flex: 1;
      }
    }
  }
}

@media only screen and (max-width: 730px) {
  .image__footer {
    margin-bottom: 0 !important;

    .first-col {
      padding-right: 0;
    }

    .second-col {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      padding-top: 0;

      .share-button__wrapper {
        width: 100%;
        padding-right: 0;
        padding-top: 12px;
      }

      .resource-button__wrapper {
        width: 100%;
      }
    }
  }
}
</style>
