import api from '@/api';
import {apiBaseUrlV2} from '@/store/config';

const moduleTutorials = {
  namespaced: true,
  state: {
    loading: false,
    tutorials: {
      items: [],
      metadata: {}
    },
    tutorialDetail: null,
    perPage: 16,
    pageNum: 1,
    sort: 'relevance',
  },
  getters: {
    loading: state => state.loading,
    sort: state => state.sort,
  },
  mutations: {
    SET_IS_LOADING(state, val) {
      state.loading = val;
    },
    SET_TUTORIALS(state, tutorials) {
      state.tutorials = tutorials;
    },
    SET_SORT(state, sortSearchObj) {
      state.sort = sortSearchObj;
    },
  },
  actions: {
    async fetchTutorials({commit, state, rootState}) {
      if (state.loading) {
        commit('SET_IS_LOADING', true);
      }

      const resourcesURL = `?page=1&size=16&sort=${state.sort}&SubSource=tutorial`;
      const response = await api.tutorials.getTutorials(apiBaseUrlV2, {path: resourcesURL, uid: rootState.uid});
      if (response) {
        commit('SET_TUTORIALS', {
          items: response.resources,
          metadata: response.meta
        });
        commit('SET_IS_LOADING', false);
      }
    }
  }
};

export default moduleTutorials;
