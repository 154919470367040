<template>
  <v-sheet>
    <v-list-item-title @click.stop>
      <v-list-item-action @click="expandTop=!expandTop">
        <div class="d-flex filter-title">
          <v-icon>{{ expandTop? 'mdi-menu-down' : 'mdi-menu-right' }}</v-icon>
          <h4 class="ml-2 font-weight-regular">
            {{ heading }}
          </h4>
        </div>
      </v-list-item-action>
    </v-list-item-title>
    <v-expand-transition>
      <v-sheet v-if="expandTop">
        <!-- one level checkboxes -->
        <ul
          v-if="type === TYPE.CHECKBOX && !multiLevel"
        >
          <li
            v-for="item in items"
            :key="item.id"
            style="list-style: none;"
          >
            <v-checkbox
              v-model="selectedItems"
              dense
              multiple
              :label="item.name"
              :value="item.id"
              @click.stop
            />
          </li>
        </ul>
        <!-- multi-level checkboxes -->
        <v-treeview
          v-else-if="type === TYPE.CHECKBOX && multiLevel"
          v-model="selectedItems"
          open-on-click
          :selected-color="ADS_Colors.Navy"
          :items="items"
          :class="{'treeview-group-options': !selectableParent}"
          @click.stop.native
        >
          <template #prepend="{item, open, leaf}">
            <v-checkbox
              v-if="leaf || selectableParent"
              dense
              hide-details
              :indeterminate="!isAllSelected(item) && !isAllUnselected(item)"
              :input-value="isAllSelected(item)"
              :aria-label="item.name"
              class="treeview-checkbox"
              @click="handleSelect(item, open, $event)"
            />
          </template>
          <template
            v-if="!hideArrows || !selectableParent"
            #append="{ item, leaf, open }"
          >
            <v-btn
              v-if="!leaf"
              icon
              small
              :aria-expanded="open ? 'true' : 'false'"
              :aria-label="`Expand ${item.name} menu`"
            >
              <v-icon>
                {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </template>
        </v-treeview>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
import { AdsButton, ADS_Colors } from '@nswdoe/doe-ui-core';

export default {
  name: 'FilterContent',
  components: {
    AdsButton
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    multiLevel: {
      type: Boolean,
      default: false
    },
    selectableParent: {
      type: Boolean,
      default: true
    },
    hideArrows: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    clearLink: {
      type: Boolean,
      default: false
    },
    clearLinkText: {
      type: String,
      default: 'Clear filter'
    },
  },
  data() {
    return {
      ADS_Colors,
      TYPE: {
        RADIO: 'radio',
        CHECKBOX: 'checkbox'
      },
      expandTop: false
    }
  },
  computed: {
    selectedItems: {
      get() {
        return this.$attrs.value ? this.$attrs.value : [];
      },
      set(value) {
        // if (this.type === TYPE.CHECKBOX) {
        //   document.activeElement.blur();
        // }
        this.$listeners.input && this.$listeners.input(value);
      }
    },
  },
  methods: {
    handleSelect(item, open, e) {
      if (this.isAllSelected(item)) {
        if (open) {
          e.stopPropagation();
        }
        this.unSelectItem(item);
      } else {
        if (open) {
          e.stopPropagation();
        }
        this.selectItem(item);
      }
    },
    selectItem(item) {
      if (item.children) {
        item.children.forEach(c => this.selectItem(c));
      } else {
        if (!this.selectedItems.includes(item.id)) {
          this.selectedItems.push(item.id);
          this.selectedItems = [...this.selectedItems];
        }
      }
    },
    unSelectItem(item) {
      if (item.children) {
        item.children.forEach(c => this.unSelectItem(c));
      } else {
        this.selectedItems.forEach((el, i) => {
          if (el === item.id) {
            this.selectedItems.splice(i, 1);
          }
        });
      }
    },
    isAllSelected(item) {
      if (item.children) {
        return item.children.every(c => this.isAllSelected(c));
      } else {
        if (this.selectedItems) {
          return this.selectedItems.includes(item.id);
        }
      }
    },
    isAllUnselected(item) {
      if (item.children) {
        return item.children.every(c => this.isAllUnselected(c));
      } else {
        if (this.selectedItems) {
          return !this.selectedItems.includes(item.id);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.filter-title {
  cursor: pointer;
}
.v-card, .v-dialog > .v-card {
  .v-card__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    font-size: 16px;
    font-weight: bold;

    .heading {
      flex: 1;
      padding: 12px 0 12px 16px;

      .theme--light & {
        color: $ads-navy;
      }

      .theme--dark & {
        color: $ads-navy-dark;
      }
    }

    .close-button {
      margin: 12px 16px 12px 0;

      .theme--light & {
        color: $ads-navy;
      }

      .theme--dark & {
        color: $ads-navy-dark;
      }

      &:focus {
        outline: none !important;
        border: 2px solid $ads-navy;

        .theme--dark & {
          border-color: $ads-navy-dark;
        }
      }
    }
  }

  .v-card__actions {
    padding: 0;

    .v-input:not(.v-input--checkbox) {
      margin-top: 0;
      padding: 12px 0 0 12px;
      width: 100%;

      .v-radio {
        margin-bottom: 16px;

        ::v-deep.v-input--selection-controls__input {
          margin-right: 12px;
        }
      }
    }

    .v-input {
      &.v-input--checkbox {
        margin-top: 0;
        padding-top: 0;
      }
    }

    ul {
      padding: 12px 0 0 12px;
      width: 100%;
    }

    .v-treeview {
      width: 100%;
      padding-bottom: 12px;

      .treeview-checkbox {
        margin-top: 6px;
        margin-bottom: 4px;
      }

      ::v-deep.v-treeview-node__root {
        min-height: 42px;

        &:hover {
          &::before {
            opacity: 0;
          }
        }

        .mdi-checkbox-marked, .mdi-minus-box {
          color: $ads-navy !important;

          .theme--dark & {
            color: $ads-navy-dark !important;
          }
        }

        .v-treeview-node__toggle {
          display: none;
        }

        .v-treeview-node__level {
          width: 0;
        }
      }

      ::v-deep.v-treeview-node {
        &.v-treeview-node--click {

          ::v-deep.v-treeview-node {
            &.v-treeview-node--click {

              .v-treeview-node__root {
                margin-left: 12px;
              }
            }
          }
        }
      }

      ::v-deep.v-treeview-node__children {
        .v-treeview-node {
          .v-treeview-node__level {
            width: 12px;
          }

          &.v-treeview-node--click {
            &:not(.v-treeview-node--leaf) {
              margin-left: 12px !important;
            }
          }
        }
      }

      // add focus state in treeview checkboxes
      ::v-deep.v-icon {
        &.v-icon {
          font-size: 20px;
          height: 24px;

          &:focus {
            &:before {
              border: 2px solid $ads-navy;
              border-radius: 50%;
              padding: 4px;

              .theme--dark & {
                border-color: $ads-navy-dark;
              }
            }
          }
        }
      }
    }

    ::v-deep.treeview-group-options {
      .v-treeview-node__prepend {
        min-width: 0;
      }
    }

    .v-treeview-node__append {

      .v-btn {
        margin-right: 8px;

        &:focus {
          border: 2px solid $ads-navy;
          border-radius: 50%;
          outline: none !important;

          .theme--dark & {
            border-color: $ads-navy-dark;
          }
        }
      }
    }

    ::v-deep.v-label {
      .theme--light & {
        color: $ads-dark;
      }

      .theme--dark & {
        color: $ads-light-20;
      }
    }

    &.clear-button__container {
      padding: 0 12px 12px 0;
      display: flex;
      justify-content: flex-end;
    }
  }

  .theme--dark & {
    background-color: $ads-dark;
  }

  // add accessible focus state in radio inputs
  ::v-deep.v-input--selection-controls {
    .v-radio--is-focused {
      .v-input--selection-controls__ripple {
        &:before {
          border: 2px solid $ads-navy;
          background-color: rgba(0, 0, 0, 0.2);
          opacity: 1;

          .theme--dark & {
            border-color: $ads-navy-dark;
          }
        }
      }
    }

    // add accessible focus state in checkbox inputs - not multilevel
    &.v-input--is-focused {
      .v-input--selection-controls__ripple {
        &:before {
          border: 2px solid $ads-navy;
          background-color: rgba(0, 0, 0, 0.2);
          opacity: 1;

          .theme--dark & {
            border-color: $ads-navy-dark;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .v-card {
    width: 100vw;
  }
}
</style>
