/**
 * Performs deep copy of object's own properties
 * @param object
 * @returns {{}|*}
 */
export function clone(object) {
  if (!object) {
    return object;
  } else if (Array.isArray(object)) {
    return object.map(item => clone(item));
  } else if (typeof object === 'object') {
    const result = {};
    Object.entries(object).forEach(([key, value]) => {
      result[key] = clone(value);
    })
    return result;
  }
  return object;
}

/**
 * Capitalise first letter of each word in a string
 * @param str
 * @returns {string|*}
 */
export function capitalize(str) {
  if (!str) {
    return '';
  }
  if (str.includes(' ')) {
    let arr = str.split(' ');
    arr = arr.map(word => {
      word = word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    return arr.join(' ');
  } else {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
