<template>
  <v-card
    class="recipient-details__wrapper"
    :width="$vuetify.breakpoint.lgAndUp ? '320px' : '240px'"
    :style="{ position: 'fixed', top: position.y + 'px', left: position.x + 'px'}"
  >
    <v-card-title>
      <v-avatar size="40">
        <span class="initials">
          {{ initials }}
        </span>
      </v-avatar>
    </v-card-title>
    <v-card-text>
      <div class="details-top">
        <span class="name">
          {{ details.name }}
        </span>
        <span>
          {{ details.email }}
        </span>
        <span v-if="details.phone">
          P: {{ details.phone }}
        </span>
      </div>
      <div
        v-if="details.title || details.school"
        class="details-bottom"
      >
        <span v-if="details.title">
          {{ details.title }}
        </span>
        <span v-if="details.school">
          {{ details.school }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RecipientDetails',
  props: {
    details: {
      type: Object,
      default: null
    },
    position: {
      type: Object,
      default: null
    }
  },
  computed: {
    initials() {
      let names = this.details.name.split(' ');
      return names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.recipient-details__wrapper {
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  z-index: 1000000;

  .v-card__title {
    padding: 0;

    .v-avatar {
      background-color: $ads-light-blue;

      span {
        &.initials {
          font-size: 16px;
          color: $ads-navy;
        }
      }
    }
  }

  .v-card__text {
    padding: 0 0 0 12px;

    .details-top, .details-bottom {
      span {
        display: block;
        overflow-wrap: anywhere;
        font-size: 14px;
        color: $ads-dark-70;
        margin-top: 4px;

        &.name {
          font-size: 16px;
          font-weight: bold;
          color: $ads-navy;
        }
      }
    }

    .details-bottom {
      margin-top: 24px;

    }
  }
}

@media only screen and (max-width: 1264px) {
  .recipient-details__wrapper {
    display: flex;
    flex-direction: column;

    .v-card__text {
      padding: 0;

      .details-top {
        span {
          &.name {
            margin-top: 12px;
          }
        }
      }
    }
  }
}
</style>
