import {SourceType} from '@/constants'

type ExtractOrderedFilesAndLinks =
  <T extends object, U extends object>(sourceFilesAndLinks: { type: keyof typeof SourceType; data: Array<T | U> }[]) => {
    files: (T | U)[], links: Array<(T | U) & { order: number }>
  }

/*export const extractOrderedFilesAndLinks: ExtractOrderedFilesAndLinks = (sourceFilesAndLinks: { type: keyof typeof SourceType; data: Array<T | U> }[]) =>
    sourceFilesAndLinks.reduce<{ files: T[], links: Array<U & { order: number }> }>(({files, links}, {type, data}, order) => ({
      files: type === SourceType.File ? files.concat(...data as T[]) : files,
      links: type === SourceType.Link && Object.keys(data).length > 0
        ? links.concat({...data, order} as Array<U & { order: number }>)
        : links
    }), {files: [], links: []});*/

export const extractOrderedFilesAndLinks = (sourceFilesAndLinks) => {
  console.log('sourceFilesAndLinks = ', sourceFilesAndLinks);
  const res = sourceFilesAndLinks.reduce(({files, links}, {type, data}, order) => ({
    files: type === SourceType.File ? files.concat(...data) : files,
    links: type === SourceType.Link && Object.keys(data).length > 0
      ? links.concat({...data, order})
      : links
  }), {files: [], links: []});
  console.log(res);
  return res;
}

export const combineFilesAndOrderedLinks = <T, U extends { order: number }>(files: T[] = [], orderedLinks: U[] = []) =>
  orderedLinks.reduce<Array<T | U & { type: string }>>((combined, {order, ...rest}) => {
    combined.splice(order, 0, {...rest, type: 'link'} as U & { type: string });
    return combined;
  }, files);


export const getFilesOrLinks = (filesAndLinks: any[], type) => {
  if (type === SourceType.Link) {
    const links: any[] = [];
    filesAndLinks.forEach((item, index) => {
      if (item.type === SourceType.Link) {
        links.push({
          ...item.data,
          order: index
        })
      }
    });
    return links;
  } else if (type === SourceType.File) {
    const files: any[] = [];
    filesAndLinks.forEach((item, index) => {
      if (item.type === SourceType.File) {
        files.push({
          file: item?.data?.[0],
          order: index
        })
      }
    });
    return files;
  }
}

export function getOperatingSystem() {
  const os = navigator.userAgent;
  let finalOs = '';
  if (os.search('Windows') !== -1) {
    finalOs = 'Windows';
  } else if (os.search('Mac') !== -1) {
    finalOs = 'MacOS';
  } else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
    finalOs = 'UNIX';
  } else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
    finalOs = 'Linux'
  }

  return finalOs;
}
