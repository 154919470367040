<template>
  <v-row
    v-if="!$store.state.allowAccess"
    justify="center"
    class="mt-12"
  >
    <v-card
      width="500"
      height="500"
    >
      <v-card-text>
        <h1
          style="color: #041E42; margin-top: 30%; display: flex; align-items: center; justify-content: center;"
        >
          <v-icon
            color="red"
            large
          >
            error
          </v-icon>
          <span class="ml-4">Access Denied</span>
        </h1>
        <h2
          style="color: #041E42; display: flex; justify-content: center;"
          class="mt-10"
        >
          Sorry, you don't have access to this site.
        </h2>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: 'AccessDeniedError',
};

</script>

<style lang="scss" scoped>

</style>
