<template>
  <v-row class="ma-0">
    <v-col
      cols="12"
      class="search-bar__wrapper"
    >
      <v-text-field
        v-model="searchProgressionsInput"
        :outlined="$vuetify.breakpoint.smAndUp"
        prepend-inner-icon="search"
        hide-details
        placeholder="Search for Progression level"
      />
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'ProgressionsSearchInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      searchProgressionsInput: '',
    }
  },
  watch: {
    searchProgressionsInput: function (val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../scss/variables";

.search-bar__wrapper {
  padding-right: 60px;
  padding-top: 16px;
}

@media only screen and (max-width: 600px) {

  .search-bar__wrapper {
    padding: 12px 0;

    ::v-deep.theme--light.v-text-field > {
      .v-input__control > {
        .v-input__slot {
          .v-input__prepend-inner {
            margin-left: 12px;
          }

          &:before {
            border-color: $ads-light-20;
          }
        }
      }
    }
  }
}
</style>
