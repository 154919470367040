<template>
  <div>
    <Dialog
      v-bind="$attrs"
      title="Learning sequence"
      :hide-heading="false"
      icon="mdi-format-list-numbered"
      :width="$vuetify.breakpoint.xsOnly ? $vuetify.breakpoint.width : showResources ? 1000 : 1500"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #content>
        <v-card-text>
          <span class="subtitle-2 font-weight-regular">{{ heading }}</span>
        </v-card-text>
        <v-container class="new-collection-content">
          <LearningSequence @modalClose="$downwardBroadcast('close:dialog')" />
        </v-container>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '../Dialog';
import LearningSequence from '@/components/Collection/LearningSequence/LearningSequence.vue';

export default {
  name: 'LearningSequenceDialog',
  components:{
    Dialog,
    LearningSequence
  },
  data(){
    return{
      showResources: false,
      heading: 'Click and drag resources into an order of sequence. This will help your colleagues understand the recommended order resource use',
    }
  }
}
</script>
<style lang="scss" scoped>

.new-collection-content{
  display: flex;
  flex-flow: column;
  position: relative;
  height: 100%;

  .data-content{
    min-height: 65vh;
  }
  .footer-mobile{
    width: 100%;
  }
}
</style>

