<template>
  <v-combobox
    v-bind="$attrs"
    v-model="valueInternal"
    :search-input.sync="search"
    outlined
    :items="allowUserInput ? itemsInternal : items"
    hide-no-data
    chips
    deletable-chips
    multiple
    :append-icon="itemsInternal.length ? '$dropdown' : ''"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'Autocomplete',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    allowUserInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      valueInternal: [],
    }
  },
  computed: {
    itemsInternal: {
      get() {
        const insertionAndOptions = this.valueInternal.concat(this.items);
        return [...new Set(insertionAndOptions)];
      }
    }
  },
  watch: {
    search(newValue, oldValue) {
      if (oldValue === '') {
        return;
      }
      const breakers = [';', ',']

      if (this.search && this.search.trim() !== '' && breakers.includes(newValue.substring(newValue.length - 1))) {
        let item = newValue.substring(0, newValue.length - 1).trim();
        if (this.items.includes(item) || this.allowUserInput) {
          this.valueInternal.push(item);
        }
        this.search = '';
      }
    },
    valueInternal: function (val) {
      if (!this.allowUserInput) {
        if (val && val.every(i => this.items.includes(i))) {
          this.$emit('input', val);
        } else {
          if (this.valueInternal) {
            this.valueInternal = this.valueInternal.filter(i => this.items.includes(i));
          }
        }
      } else {
        this.$emit('input', val);
      }
    },
    value: {
      immediate: true,
      handler: function (val) {
        this.valueInternal = val;
      }
    }
  }
};

</script>
<style lang="scss" scoped>
@import "../../../scss/variables";

::v-deep.v-autocomplete {
  padding: 0;
}

.theme--light {
  ::v-deep.v-messages {
    text-align: right !important;
  }
}

::v-deep.v-select {
  &.v-select--chips:not(.v-text-field--single-line) {
    &.v-text-field--enclosed {
      .v-select__selections {
        min-height: 56px;
      }
    }
  }
}

::v-deep.v-menu__content {
  &.v-autocomplete__content {
    .v-list {
      .v-list-item--link {
        &.v-list-item {
          &.theme--light {
            &:hover {
              &::before {
                opacity: 0;
              }
            }
          }
        }

        &.v-list-item--highlighted {
          background-color: $ads-navy !important;
          color: $ads-white !important;
          outline: none !important;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.theme--light {
  ::v-deep.v-icon {
    &:focus {
      border: 2px solid $ads-navy;
      border-radius: 50%;

      &::after {
        opacity: 0;
      }
    }
  }

  ::v-deep.v-chip {
    background: $ads-white;
    border: 1px solid $ads-navy;

    .v-chip__content {
      button {
        &:focus {
          border: none !important;
          outline: 2px auto $ads-navy;
        }
      }
    }
  }
}
</style>
