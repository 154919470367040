<template>
  <div>
    <Dialog
      v-bind="$attrs"
      :title="$vuetify.breakpoint.smAndUp ? 'Edit' : 'Edit collection details'"
      :subTitle="$vuetify.breakpoint.smAndUp ? 'Edit collection details' : ''"
      :hide-heading="false"
      icon="mdi-image-plus"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #content>
        <v-container class="new-collection-content mt-2">
          <v-form ref="newCollectionForm">
            <v-card-text class="data-content">
              <AddNewImage
                v-model="thumbnailImage"
                text="Click to add a cover image for your collection. Leaving this blank will create a collated image from the resources selected."
              />
              <AdsTextField
                v-model="document.Name"
                label="Name"
                aria-required="true"
                placeholder="Collection Name"
                persistent-placeholder
                :rules="[rules.required, rules.specialChars]"
                :aria-invalid="rules.specialChars || rules.required ? 'true':'false'"
                aria-describedby="Name"
              />
              <v-select
                v-if="hubCollection"
                v-model="document.Source"
                class="input-container"
                aria-label="content group"
                readonly
                aria-required
                label="Content group *"
                outlined
                item-text="name"
                item-value="id"
                placeholder="Select"
                persistent-placeholder
                :return-object="false"
                :items="supportedContentGroups"
              />
              <v-textarea
                v-model="document.Description"
                label="Description (Optional)"
                aria-required="true"
                placeholder="Description"
                persistent-placeholder
                outlined
              />
              <template v-if="hubCollection">
                <div class="section">
                  <fieldset>
                    <legend>
                      <h4 class="checkbox-group__heading">
                        Resource Category *
                      </h4>
                      <label class="body-2">
                        Which category best describes this resource?
                      </label>
                    </legend>

                    <!-- Resource Category -->
                    <v-row class="mb-7">
                      <v-col
                        cols="6"
                      >
                        <v-checkbox
                          v-model="document.ResourceCategory"
                          label="Teaching and learning resource"
                          hide-details
                          :rules="[rules.required]"
                          value="tlr"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-checkbox
                          v-model="document.ResourceCategory"
                          label="School Improvement resource"
                          :rules="[rules.required]"
                          hide-details
                          value="sir"
                        />
                      </v-col>
                    </v-row>
                  </fieldset>
                </div>
              </template>

              <AdsButton
                text
                class="pl-1 mb-4"
                tertiary
                :ripple="false"
                :aria-expanded="showOtherfields? 'true' : 'false'"
                :icon="showOtherfields?'arrow_drop_down':'arrow_right'"
                button-text="Other fields"
                @click="showOtherfields = !showOtherfields"
              />
              <v-expand-transition
                class="py-0"
                origin="'top center 0"
                mode="in-out"
              >
                <div
                  v-if="showOtherfields"
                  key="row-optionals"
                >
                  <v-row>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                      class="mb-0"
                    >
                      <v-select
                        id="stages-field"
                        v-model="stages"
                        class="input-container optional"
                        aria-label="stages"
                        aria-required="true"
                        label="Stages"
                        multiple
                        outlined
                        item-text="text"
                        item-value="value"
                        chips
                        deletable-chips
                        placeholder="Select"
                        persistent-placeholder
                        :return-object="false"
                        :items="allStages"
                      />
                    </v-col>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <v-select
                        id="years-field"
                        v-model="years"
                        class="input-container optional"
                        aria-label="years"
                        aria-required="true"
                        label="Years"
                        multiple
                        outlined
                        item-text="text"
                        item-value="value"
                        chips
                        deletable-chips
                        placeholder="Select"
                        persistent-placeholder
                        :return-object="false"
                        :items="scholasticYears"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <v-select
                        v-model="document.KLA"
                        label="Learning areas (Optional)"
                        aria-label="Learning areas"
                        aria-required="true"
                        multiple
                        outlined
                        chips
                        deletable-chips
                        item-text="name"
                        item-value="id"
                        persistent-placeholder
                        :items="$store.getters['metadata/klas']"
                      />
                    </v-col>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <Autocomplete
                        v-model="document.Keywords"
                        allow-user-input
                        label="Keywords (Optional)"
                        persistent-hint
                        persistent-placeholder
                        hint="Use comma or enter to add new keyword"
                        aria-describedby="Keywords (Optional)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <Autocomplete
                        v-model="document.Topic"
                        label="Topics (Optional)"
                        aria-required="true"
                        persistent-hint
                        persistent-placeholder
                        :items="topics.map(el => el.name)"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-card-text>
            <v-divider />
            <v-card-actions :class="{'d-flex justify-space-between px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
              <AdsButton
                tertiary
                button-text="Cancel"
                @click="$downwardBroadcast('close:dialog'); showEditMetaDataDialog = false"
                @keyup.enter="$downwardBroadcast('close:dialog'); showEditMetaDataDialog = false"
              />
              <AdsButton
                primary
                button-text="Save changes"
                @click="saveMetaData(document)"
                @keyup.enter="saveMetaData(document)"
              />
            </v-card-actions>
          </v-form>
        </v-container>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { AdsButton, AdsTextField } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import topics from '@/views/ResourceEditorDetailsPage/Edit/topics.json'
import Autocomplete from '@/views/ResourceEditorDetailsPage/Edit/Autocomplete'
import {ResourceStatus} from '@/constants'
import {mapGetters} from 'vuex';
import AddNewImage from '@/views/ResourceEditorDetailsPage/Edit/AddNewImage.vue';

export default {
  name: 'EditMetaDataDialog',
  components: {
    AddNewImage,
    AdsButton,
    AdsTextField,
    Autocomplete,
    Dialog,
  },
  props: {
    collection: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      topics,
      thumbnailImage: '',
      showEditMetaDataDialog: false,
      showOtherfields: false,
      stages: [],
      years: [],
      rules: {
        specialChars: value => {
          // \xB0C  is for math degree symbol
          const pattern = /^[a-zA-Z0-9_/?,:()!\xB0C+=&' .-]*$/
          const testResult = pattern.test(value)
          return !!testResult || 'Collection name cannot include the special characters used.'
        },
        required: v => !!v || 'Collection name is required'
      }
    };
  },
  watch: {
    '$attrs.value' : {
      handler(showModel) {
        if (showModel) {
          this.stages = this.document.Stages;
          this.years = this.document.Years;
          this.thumbnailImage = this.document.ThumbnailImage;
        }
      }
    },
    years: {
      deep: true,
      handler(selectedYears) {
        // when element is active or Stage is not defined (to support old resources) need to re calculate stages
        if (document.activeElement && document.activeElement.id === 'years-field' || (selectedYears && !this.document.Stages)) {
          const stages = selectedYears ? this.stagesAndYears.filter(sy => {
            return selectedYears.includes(sy.year)
          }).map(s => s.stage) : []
          // just removing duplicates
          this.document.Stages = this.stages = [ ...new Set(stages) ];
          this.document.Years = selectedYears;
        }
      }
    },
    stages: {
      deep: true,
      handler(selectedStages) {
        if (document.activeElement && document.activeElement.id === 'stages-field') {
          const years = selectedStages ? this.stagesAndYears.filter(sy => {
            return selectedStages.includes(sy.stage)
          }).map(s => s.year) : []
          this.document.Years = this.years = years;
          this.document.Stages = selectedStages;
        }
      }
    },
    thumbnailImage: {
      deep: true,
      handler(image) {
        this.document.ThumbnailImage = image;
      }
    },
  },
  computed: {
    ...mapGetters({
      stagesAndYears: 'metadata/mappedStagesAndYears'
    }),
    scholasticYears() {
      return this.$store.getters['metadata/years']?.map(y => {
        return {
          value: y.shortName,
          text: y.name
        }
      }) || []
    },
    allStages() {
      return this.$store.getters['metadata/stages'].map(s => {
        return {
          id: s.id,
          value: s.name === 'Early Stage 1' ? 'ES1' : s.name.replace('Stage ', ''),
          text: s.name
        }
      })
    },
    supportedContentGroups() {
      return [this.collection?.record?.collection ]
    },
    document() {
      return  this.isPublished || !this.collection.draft?.ItemID ? {
        ...this.collection.document
      } : {
        ...this.collection.draft
      }
    },
    isPublished() {
      return this.collection?.record?.status === ResourceStatus.published
    },
    hubCollection() {
      return this.collection?.record?.collection !== 'collections'
    }
  },
  methods: {
    async saveMetaData() {
      const valid = this.$refs.newCollectionForm.validate()
      if (!valid) {
        return false
      }
      this.$downwardBroadcast('close:dialog');
      const document = {
        Stages: this.document.Stages,
        Years: this.document.Years,
        KLA: this.document.KLA,
        Topic: this.document.Topic,
        Keywords: this.document.Keywords,
        Name: this.document.Name,
        ItemID: this.document.ItemID,
        Description: this.document.Description,
        Source: this.document.Source,
        ResourceCategory: this.document.ResourceCategory,
        ThumbnailImage: this.thumbnailImage
      }
      await this.$store.dispatch('myCollections/saveMetaData', {
        collection: this.collection,
        document,
        published: this.isPublished
      });
      this.$emit('collections:updated')
      // when we get a collection successfuly display the notification
      console.log('Saving collection metadata... ' + this.document.Name)
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../scss/mixins';

fieldset {
  border: none;
  legend {
    h4 {
      color: #002664
    }
    label {
      color: $ads-grey-02 !important;
    }
  }
}
.new-collection-content{

  @include respond(phone){
    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 80vh;
    }
    .footer-mobile{
      width: 100%;
    }
  }
}
</style>
