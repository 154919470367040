<template>
  <Dialog
    v-bind="$attrs"
    icon="mdi-account-multiple-plus-outline"
    title="Invite Members"
    :width="$vuetify.breakpoint.smAndUp ? 1000 : 1000"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        class="mt-0 pb-0"
        :style="{'color': ADS_Colors.Dark}"
      >
        <span>{{ heading }}</span>
        <span v-if="subHeading"><strong>Note: </strong>{{ subHeading }} </span>
      </v-card-text>

      <v-container
        v-if="collection.userAccess"
        class="mt-8 new-collection-content"
      >
        <v-row v-show="isEditor(userProfile.userId) || isOwner(userProfile.userId)">
          <v-col
            cols="12"
            class="search-staff"
          >
            <SearchDoeStaff
              v-model="recipients"
              display-details
              counter="30"
              :notAllowed="[...members, ...Owner]"
              @getRule="handleRule"
              @getError="handleError"
            />
            <SetAccess
              v-model="accessas"
              :items="permissions"
            />
            <AdsButton
              v-if="$vuetify.breakpoint.smAndUp"
              button-text="Send invite"
              :disabled="recipients.length === 0 || rule[0] !== true"
              @click="handleSendInvite('addaccess')"
            />
          </v-col>
        </v-row>

        <v-divider />
        <v-list
          subheader
        >
          <div
            class="permission-header"
          >
            <v-subheader
              v-for="(header, i) in ['Members', 'Permission']"
              :key="i"
              :class="{'pa-0': $vuetify.breakpoint.xsOnly }"
            >
              <h3>{{ header }}</h3>
              <span v-if="header === 'Members'">&nbsp;({{ members.length + 1 }})</span>
            </v-subheader>
          </div>

          <div
            v-if="Owner.length > 0"
            class="scroll-container"
          >
            <v-list-item>
              <!-- since the will be owned by only one owner, this cannot be looped -->
              <div class="member-name">
                <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                  <v-avatar
                    :size="40"
                  >
                    {{ getInitials(Owner.at(0)) }}
                  </v-avatar>
                </v-list-item-avatar>
                <span>{{ fullName(Owner.at(0)) }}</span>
              </div>
              <v-list-item-content class="ml-4">
                <v-list-item-title>Owner</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- Editor and viewer list -->
            <v-list-item
              v-for="(member, i) in members"
              :key="`editor_${i}`"
            >
              <div class="member-name">
                <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                  <v-avatar
                    :size="40"
                  >
                    {{ getInitials(member) }}
                  </v-avatar>
                </v-list-item-avatar>
                <span>{{ fullName(member) }}</span>
              </div>
              <v-list-item-content class="ml-4">
                <v-list-item-title>
                  {{ isEditor(member)? 'Editor': 'Viewer' }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-show="isEditor(userProfile.userId) || isOwner(userProfile.userId)">
                <v-btn
                  text
                  icon
                  @click="removeCollaborator(member)"
                >
                  <v-icon>
                    mdi-minus-circle-outline
                  </v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <!-- End of member list -->
          </div>
        </v-list>
        <v-divider v-if="$vuetify.breakpoint.xsOnly" />
        <v-card-actions
          v-if="$vuetify.breakpoint.xsOnly"
          :class="{'d-flex justify-space-between px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}"
        >
          <AdsButton
            tertiary
            button-text="Cancel"
            @click="$downwardBroadcast('close:dialog');"
            @keyup.enter="$downwardBroadcast('close:dialog');"
          />
          <!-- if no selected cards, disable the Save button -->
          <AdsButton
            primary
            button-text="Send invite"
            :disabled="recipients.length === 0 || rule[0] !== true"
            @click="handleSendInvite('addaccess','editor')"
          />
        </v-card-actions>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog.vue';
import { ADS_Colors, AdsButton } from '@nswdoe/doe-ui-core';
import SearchDoeStaff from '@/components/SearchDoeStaff/SearchDoeStaff.vue';
import SetAccess from '@/components/SearchDoeStaff/SetAccess.vue';
import {apiBaseUrl} from '@/store/config';
import { mapGetters } from 'vuex';
export default {
  name: 'InviteMembersDialog',
  components: {
    Dialog,
    AdsButton,
    SearchDoeStaff,
    SetAccess
  },
  props:{
    heading: {
      type: String,
      default: 'Invite members to view and edit this collection.'
    },
    subHeading: {
      type: String,
      default: 'Only Owner and Editors are able to add and remove access or resource from this collection.'
    },
    collection: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      ADS_Colors,
      recipients: [],
      rule: [true],
      senderEmail: this.$OAuth.profile.email,
      senderName: this.$OAuth.profile.givenName + ' ' + this.$OAuth.data.profile.sn,
      error: '',
      collectionAccess: this.collection?.userAccess || {},
      members: [],
      permissions: [
        { access: 'editor', text: 'Editor'},
        { access: 'viewer', text: 'Viewer'}
      ],
      accessas: { access: 'viewer', text: 'Viewer'}
    }
  },
  watch: {
    collection(val) {
      if(val?.userAccess) {
        this.members = [...val.userAccess.editor, ...val.userAccess.viewer]
        this.collectionAccess = val.userAccess
      }
    }
  },
  mounted () {
    if (this.collection && this.collection?.userAccess) {
      this.members = [...this.collection.userAccess.editor, ...this.collection.userAccess.viewer]
      this.collectionAccess = this.collection.userAccess
    }
  },
  computed:{
    ...mapGetters({
      userProfile: 'users/userProfile'
    }),
    Owner(){
      return this.collection && Object.keys(this.collection).length > 0 ?  this.collection.userAccess.owner : []
    },
    collectionName(){
      return this.collection?.document ? this.collection.document.Name : this.collection.Name
    },
    collectionUrl(){
      let url = apiBaseUrl.replace('/api/v1', '');
      /**
       * Invite can be sent from either my collection landing page or details page.
       * so check if this.collection.document is present, if it is then its my collections landing page.
       * @type {`/collection/${string}`}
       */
      url += this.collection?.document ? `/collection/${this.collection?.document?.ItemID}` : `/collection/${this.collection?.ItemID}`
      return (url || '')
    },
    Editor(){
      return this.collection && Object.keys(this.collection).length > 0 ?  this.collection.userAccess.editor : []
    }
  },
  methods:{
    handleRule(val) {
      this.rule = val;
    },
    isEditor(id) {
      return this.collectionAccess && this.collectionAccess.editor?.includes(id) || false
    },
    isOwner(id){
      return this.collectionAccess && this.collectionAccess.owner?.includes(id) || false
    },
    handleError(val) {
      this.error = val;
    },
    async removeCollaborator(memberId){

      // prepare payload
      let payload = {
        action: 'removeaccess',
        alert: {
          type: 'success',
          text: 'Access has been removed for the user'
        },
        collection: this.collection.document ? this.collection.document.ItemID : this.collection.ItemID,
        userAccess:{
          accessFor: memberId,
          accessScope: this.isEditor(memberId) ? 'editor' : 'viewer'
        }
      };
      const response = await this.$store.dispatch('myCollections/shareCollection', payload);
      const collection = response.data

      if (collection) {
        // this.members = [...collection.userAccess.editor, ...collection.userAccess.viewer]
        this.userAccess = collection.userAccess
        this.members = [...collection.userAccess.editor, ...collection.userAccess.viewer]
        await this.sendEmail(' has shared this collection as ' + this.accessas.access);
        this.$emit('update:collection')
      }
    },
    getInitials(initials){
      return initials.split('.').map(item => item.charAt(0).toUpperCase()).join('')
    },
    fullName(username){
      return username.split('.').map(element => element.charAt(0).toUpperCase() + element.slice(1)).join(' ')
    },
    async sendEmail(message) {
      this.emailBody = (
        `<p>This resource was sent from the <a href="https://resources.education.nsw.gov.au/">URH</a> by: ${this.senderName} (${this.senderEmail})</p><p>Collection: <a href="${this.collectionUrl}">${this.collectionName}</a></p>`
      );
      //compose email header and body
      const data = {
        to: this.recipients,
        from: this.senderEmail,
        subject: `${this.senderName} ${message}`,
        body: this.emailBody,
      };
      try {
        await this.$store.dispatch('sendResourceByEmail', data);
        this.recipients = [];
        //this.$downwardBroadcast('close:dialog');
      } catch (e) {
        console.error(e);
        this.error = 'There was an issue with your action. Try again or contact support.';
      }
    },
    async handleSendInvite(action = 'addaccess') {
      /**
       * dispatch action for sharethiscard endpoint.
       */

      // get user email and access scope
      let userAccess = this.recipients.map(item => {
          let userId = item.split('@')[0].toLowerCase();
          return {
            accessFor: userId,
            accessScope: this.accessas.access,
          }
        });

      /**
       * since the alert is variable depending on dialog, we will compose alert message here and send it as a payload
       */
      let alert;
      if( action === 'addaccess'){
        alert= {
          type: 'success',
          text: 'Invitation has been sent'
        }
      }
      // prepare payload
      let payload = {
        action,
        alert,
        collection: this.collection.document ? this.collection.document.ItemID : this.collection.ItemID,
        access: 'sharedbyme',
        userAccess
      };

      const response = await this.$store.dispatch('myCollections/shareCollection', payload);
      const collection = response.data
      if (collection) {
        this.collectionAccess = collection.userAccess
        this.members = [...collection.userAccess.editor, ...collection.userAccess.viewer]
        await this.sendEmail(' has shared this collection as ' + this.accessas.access);
        this.$emit('update:collection')
        this.recipients =[]
        this.accessas = { access: 'viewer', text: 'Viewer'};
      }

      //this updates the activitiy under inforfation drawer
      //await this.$store.dispatch('myCollections/getCollectionNotification', this.collection?.document.ItemID);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/scss/variables";
@import "../../../scss/mixins";


.v-dialog{
  overflow: hidden;
}

.new-collection-content{

  .search-staff{
    display: flex;
    column-gap: 10px;

    @include respond(phone){
      display: block;
      width: 100%;
    }
  }

  ::v-deep .search-doe-staff__wrapper{
    flex: 1;

    @include respond(phone){
      display: block!important;
      width: 100%;
    }
  }

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;

    .data-content{
      min-height: 100vh;
    }
    .footer-mobile{
      width: 100%;
    }
  }
  .scroll-container {
      max-height: 300px;

      @include customScrollbar(phone, $ads-light-40, $ads-white, $ads-light-40);

      .v-list-item {
        padding: 0;
        max-height: 70px;
        .member-name{
          width: 50%;
        }

        .v-list-item__content{
          .v-btn{
            height: 50px;
            border-radius: 100%;
            margin-left: auto;
            margin-right: 40px;
            max-width: 50px;

            @include respond(phone){
              display: block !important;
              margin-right: 0px;
            }
          }
        }
      }
    }


    .v-avatar {
      font-weight: bold;
      color: $ads-white;
      font-size: 16px;
      background: #1D428A;
      border: 1px solid white !important;
    }

    .permission-header{
      display: flex;
      justify-content: flex-start;
      flex: 1;

      .v-subheader{
        width: 50%;
        color: $ads-navy;
      }
    }
}

.v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
}

button {
  @media only screen and (max-width: 600px) {
    &.v-btn {
      display: block !important;
    }
  }
}
</style>
