<template>
  <div class="stepper__wrapper">
    <v-stepper
      v-bind="$attrs"
      v-model="currentStep"
      non-linear
      alt-labels
      class="elevation-0"
      v-on="$listeners"
    >
      <v-stepper-header class="elevation-0 stepper__header">
        <template
          v-for="step of steps"
        >
          <v-stepper-step
            :id="step.id"
            :key="step.id"
            :complete="currentStep > step.number"
            :step="step.number"
            tabindex="-1"
          >
            <span class="text-center body-2">{{ step.name }}</span>
          </v-stepper-step>
          <v-divider
            v-if="step !== steps[steps.length - 1]"
            :key="`divider-${step.id}`"
          />
        </template>
      </v-stepper-header>
      <div
        v-if="hasFixedContent"
        class="fixed-content"
      >
        <slot name="fixedContent" />
      </div>
      <v-stepper-items>
        <v-stepper-content
          v-for="step of steps"
          :key="step.id"
          :step="step.number"
        >
          <slot :name="step.id" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div
      class="actions__wrapper"
    >
      <AdsButton
        v-if="$vuetify.breakpoint.smAndUp"
        tertiary
        button-text="Cancel"
        @click="handleCancel"
      />
      <div class="primary-secondary__mobile">
        <AdsButton
          v-if="currentStep !== 1"
          secondary
          icon="arrow_back"
          button-text="Previous"
          @click="handleChangeStep('prev')"
        />
        <AdsButton
          v-if="currentStep < steps.length"
          :disabled="nextDisabled"
          icon="arrow_forward"
          button-text="Next"
          @click="handleChangeStep('next')"
        />
        <AdsButton
          v-if="currentStep === steps.length"
          button-text="Confirm"
          @click="handleConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'Stepper',
  components: {
    AdsButton
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    nextDisabled: {
      type: Boolean,
      default: false
    },
    hasFixedContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentStep: 1
    }
  },
  watch: {
    currentStep(val) {
      this.$emit('changedStep', val);
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
      this.currentStep = 1;
    },
    handleChangeStep(direction) {
      if (direction === 'prev') {
        this.currentStep -= 1;
      }
      if (direction === 'next') {
        this.currentStep += 1;
      }
      const step = this.steps.find(step => step.number === this.currentStep);
      if (step) {
        const stepEl = document.getElementById(step.id);
        if (stepEl) {
          stepEl.focus();
        }
      }
    },
    handleCancel() {
      this.$emit('cancel');
      this.currentStep = 1;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.v-stepper {
  flex: 1;
  display: flex;
  flex-direction: column;

  .v-stepper__items {
    .v-stepper__content {
      height: 100%;
      margin-bottom: 4px;
    }
  }
}

.theme--light.v-divider {
  border-color: $ads-light-40 !important;
}

.stepper__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep.stepper__header {
    margin-left: 120px;
    margin-right: 120px;
    margin-top: -24px;

    .v-stepper__step {
      min-width: 126px;
      flex-basis: 100px;


      .v-stepper__label {
        display: flex;
        justify-content: center;

        .body-2 {
          width: 100%;
          color: $ads-dark;
          text-align: center;
        }
      }

      &.v-stepper__step--active {
        .v-stepper__label {
          .body-2 {
            font-weight: bold;
          }
        }
      }

      .v-stepper__step__step {
        height: 40px;
        width: 40px;
        font-weight: bold;
        font-size: 20px;
      }

      &.v-stepper__step--inactive {
        .v-stepper__step__step {
          background: $ads-white !important;
          color: $ads-navy;
          border: 2px solid $ads-navy;
          box-sizing: border-box;
        }
      }

      &.v-stepper__step--complete {
        .v-stepper__step__step {
          background: $ads-success-green !important;
          color: $ads-navy;
        }
      }
    }

    .v-divider {
      margin: 44px 0;
    }
  }

  .v-stepper__items {
    height: 358px;
    border-top: 1px solid $ads-light-40;
    overflow: auto;
  }

  .row {
    margin: 0;
    height: fit-content;
    flex: 0;

    .col {
      height: fit-content;
    }
  }

  .actions__wrapper {
    border-top: 1px solid $ads-light-40;
    display: flex;
    justify-content: flex-end;
    padding: 24px 40px 40px 40px;

    .v-btn {
      margin-left: 12px;
    }
  }
}


@media only screen and (min-width: 600px) and (max-width: 960px) {
  .stepper__wrapper {
    ::v-deep.stepper__header {
      margin-left: 0;
      margin-right: 0;

      .v-stepper__step {
        .v-stepper__label {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .stepper__wrapper {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    position: relative;

    .v-stepper {
      margin-bottom: 76px; // actions height
    }

    ::v-deep.stepper__header {
      width: 100%;
      height: 120px;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding: 0 12px;
      position: absolute;
      top: 0;

      .v-stepper__step {
        padding-left: 4px;
        padding-right: 4px;
        padding-bottom: 12px;
        flex-basis: 90px;
        min-width: 0;

        .v-stepper__step__step {
          height: 32px;
          width: 32px;
          font-size: 14px;
        }

        &.v-stepper__step--active {
          .v-stepper__label {
            display: flex;
            justify-content: center;
            text-align: center;
          }
        }

        &:not(.v-stepper__step--active) {
          .v-stepper__label {
            display: flex;
            justify-content: center;
            text-align: center;

            .body-2 {
              font-size: 12px !important;
            }
          }
        }
      }

      .v-divider {
        margin: 44px -10px;
      }
    }

    .fixed-content {
      margin-top: 120px;
    }

    .v-stepper__items {
      height: 100%;
    }

    .actions__wrapper {
      height: 76px;
      position: absolute;
      bottom: 0;
      width: 100%;
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding: 12px 12px 0 12px;

      .primary-secondary__mobile {
        display: flex;

        .v-btn {
          flex: 1;

          &.v-btn--outlined {
            margin-right: 4px;
          }

          &.primary {
            margin-left: 4px;
          }
        }
      }

      .v-btn {
        margin-left: 0;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
