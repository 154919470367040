<template>
  <div class="outcomes__wrapper">
    <v-row class="ma-0">
      <v-col
        cols="12"
        class="search-bar__wrapper"
      >
        <v-text-field
          v-model="searchOutcomesInput"
          :outlined="$vuetify.breakpoint.smAndUp"
          prepend-inner-icon="search"
          hide-details
          placeholder="Search for outcome"
        />
      </v-col>
    </v-row>
    <ul
      v-if="filteredOutcomes.length > 0"
      :key="ckey"
      v-infinite-scroll="loadMore"
      class="checkbox-list__wrapper --outcomes"
      :infinite-scroll-disabled="false"
      infinite-scroll-distance="50"
    >
      <li
        v-for="(outcome, i) of filteredOutcomes"
        :key="i"
      >
        <v-checkbox
          v-model="outcomesInternal"
          :value="outcome.id"
          multiple
          hide-details
        >
          <template #label>
            <div class="d-flex flex-column custom-checkbox-label">
              <h2 class="code">
                {{ outcome.id }}
              </h2>
              <p class="description">
                {{ capitalizeFirstLetter(outcome.name) }}
              </p>
            </div>
          </template>
        </v-checkbox>
      </li>
    </ul>
    <v-row
      v-else
      class="ma-0"
    >
      <v-col
        cols="12"
        class="checkbox-list__wrapper --outcomes pa-3"
      >
        <p>No results.</p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import isEqual from 'lodash.isequal';
import {mapGetters} from 'vuex';

export default {
  name: 'Outcomes',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      searchOutcomesInput: '',
      loadingCount: 1,
      outcomesInternal: this.value.map(o=>o.id),
      outcomesInternalTemp: [],
      ckey: 0
    }
  },
  computed: {
    ...mapGetters({
      storeOutcomes: 'metadata/editorOutcomes'
    }),
    filteredOutcomes: function () {
      const storeOutcomes = this.storeOutcomes;
      return this.filterOutcomes(storeOutcomes);
    },
    filteredOutcomesWithLimit: function () {
      return this.filteredOutcomes.slice(0, this.loadingCount * 100);
    },
  },
  watch: {
    outcomesInternal: function (val) {

      // not changed from previous
      if (isEqual(val, this.value.map(o=>o.id))) {
        return;
      }

      // to avoid getting the values which are not in the search list we need below step
      let selectedValuesOutOfFilteredList = [];
      if (this.searchOutcomesInput && this.value.length > 0) {
        selectedValuesOutOfFilteredList = this.value.filter(v => !this.filteredOutcomes.map(o => o.id).includes(v.id)).map(o=>o.id);
      }

      // remove if there are any duplicates
      const selectedOutcomes = selectedValuesOutOfFilteredList.concat(val);
      let uniqueSelectedOutcomes = selectedOutcomes.filter((o, index) => {
        return selectedOutcomes.indexOf(o) === index;
      });

      this.outcomesInternal = uniqueSelectedOutcomes

      // map the return values from the original list from store
      const returnOutcomes = this.outcomesInternal.map(o => this.getOutcome(o))
      this.$emit('input', returnOutcomes);
    },
    value: function (val) {
      if (!this.isSameOutcomes(val, this.outcomesInternal)) {
        this.outcomesInternal = this.value.map(o => o.id);
        this.ckey++;
      }
    },
    searchOutcomesInput: function () {
      this.ckey++;
    }
  },
  methods: {
    getOutcome(id) {
      return this.storeOutcomes.find(o => o.id === id)
    },
    isSameOutcomes(oldOutcomes, newOutcomes) {
      const newIds = newOutcomes.map(o => o.id);
      const oldIds = oldOutcomes.map(o => o.id);
      return newIds.sort() === oldIds.sort();
    },
    filterOutcomes: function (arr) {
      if (!this.searchOutcomesInput) {
        return arr;
      }

      return arr.filter(o => {
        const outcomeCodeCaseInsensitive = o.id.toLowerCase();
        const descriptionCaseInsensitive = o.name.toLowerCase();
        const searchStringCaseInsensitive = this.searchOutcomesInput.toLowerCase();
        return outcomeCodeCaseInsensitive.includes(searchStringCaseInsensitive) || descriptionCaseInsensitive.includes(searchStringCaseInsensitive);
      });
    },
    loadMore: function () {
      this.loadingCount++;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1) + '.';
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../../scss/variables";

.v-stepper__items {
  .v-stepper__content {
    .v-stepper__wrapper {
      .outcomes__wrapper {
        //height: 360px;
        //overflow-y: scroll;

        .search-bar__wrapper {
          padding-right: 60px;
          padding-top: 16px;
        }

        .checkbox-list__wrapper {
          list-style: none;
          padding: 0;

          &.--outcomes {
            ::v-deep.v-input__slot {
              align-items: flex-start;
            }
          }

          li {
            margin-left: 12px;
            margin-bottom: 28px;

            ::v-deep.v-input--selection-controls__input {
              margin-right: 12px;
            }

            ::v-deep.v-label {
              color: $ads-dark;
            }
          }
        }
      }
    }
  }
}

.custom-checkbox-label {
  .code {
    font-size: 16px;
    font-weight: normal;
    padding-bottom: 8px;
  }

  .description {
    font-size: 16px;
    color: $ads-dark-70;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {

  .v-stepper__items {
    .v-stepper__content {
      .v-stepper__wrapper {
        .outcomes__wrapper {
          //height: 100%;

          .search-bar__wrapper {
            padding: 12px 0;

            ::v-deep.theme--light.v-text-field > {
              .v-input__control > {
                .v-input__slot {
                  .v-input__prepend-inner {
                    margin-left: 12px;
                  }

                  &:before {
                    border-color: $ads-light-20;
                  }
                }
              }
            }
          }

          .checkbox-list__wrapper {
            padding-right: 8px;

            li {
              margin-left: 12px;
              margin-bottom: 12px;

              .v-input--selection-controls {
                margin-top: 4px;
              }

              ::v-deep.v-input--selection-controls__input {
                margin-right: 12px;
              }

              ::v-deep.v-label {
                color: $ads-dark;
              }
            }
          }
        }
      }
    }
  }
}
</style>
