<template>
  <v-treeview
    v-bind="$attrs"
    :ref="id"
    :open.sync="open"
    class="progressions__treeview"
    :selected-color="ADS_Colors.Navy"
    :items="items"
    item-key="id"
    item-text="id"
    :search="searchInput"
    :filter="filter"
  >
    <template
      #prepend="{item, open, selected, leaf}"
    >
      <v-checkbox
        v-if="!item.children"
        :key="item.level"
        :label="item.level"
        class="treeview-checkbox"
        hide-details
        :aria-label="item.level"
        :input-value="isSelected(item)"
        @click.native="handleSelect(item)"
      />
    </template>
    <template #label="{item, open}">
      <ProgressionLabel
        :item="item"
        :open="open"
      />
    </template>
  </v-treeview>
</template>
  </v-treeview>
</template>
<script>
import ProgressionLabel
  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Progressions/ProgressionsTree/ProgressionLabel.vue';
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import _sort from 'lodash/sortBy'

export default {
  name: 'ProgressionsTree',
  components: {
    ProgressionLabel
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: 'prog-tree'
    },
    items: {
      type: Array,
      default: () => []
    },
    openAll: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    searchInput: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      ADS_Colors,
      open: [],
      allOpened: false,
      lastOpen: [],
    }
  },
  computed: {
    filter () {
      return (item, search, textKey) => item.level?.toLowerCase().indexOf(search?.toLowerCase()) > -1
    },
    allKeys: function () {
      if (!this.openAll) {
        return [];
      }
      return this.items.flatMap(progression =>
        [progression.id, ...progression.children.map(child => child.id)]
      )
    },
    selectedIds: {
      get() {
        return this.selected.map(item => item.level)
      },
      set(val) {
        console.log('setting values', val)
      }
    }
  },
  watch: {
    searchInput(val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs[this.id].updateAll(true);
        }
      } else {
        this.$refs[this.id].updateAll(false);
        this.allOpened = false;
        this.open = this.selectedIds;
      }
    }
  },
  methods: {
    handleSelect: function (item) {
      // This will be handled in the Progressions.vue
      if (this.selectedIds.includes(item.level)) {
        this.selectedIds = this.selectedIds.filter(level => level !== item.level); // if exists remove it
        // this.selected = this.selected.filter(i => i !== item)
        this.$emit('updateProgressions', this.selected.filter(i => i.level !== item.level));
      } else { // add it
        this.selectedIds = [...this.selectedIds, item.level];
        // this.selected = [...this.selected, item];
        this.$emit('updateProgressions',  _sort([...this.selected, item], function(o) { return o.level; }));
      }
    },
    isSelected: function (item) {
      // console.log(this.selectedIds)
      return this.selectedIds.find(i => i === item.level);
    },
  }
}

</script>

<style lang="scss" scoped>
@import "../../../../../../scss/variables";

.progressions__treeview {
  padding-right: 60px;

  ::v-deep.v-treeview-node__root {
    min-height: 30px;
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }

    .mdi-checkbox-marked, .mdi-minus-box {
      color: $ads-navy !important;
    }

    .v-treeview-node__content {
      margin-left: 0;

      .v-treeview-node__prepend {
        margin-bottom: 6px;
        min-width: 8px;
      }

      .v-treeview-node__label {
        white-space: normal;
      }
    }
  }
}

.treeview-checkbox {
  margin-top: 4px;
  margin-bottom: 4px;
}

@media only screen and (max-width: 600px) {

  .progressions__treeview {
    padding-right: 8px;

    ::v-deep.v-treeview-node__root {
      margin-top: 0;

      .v-treeview-node__level {
        width: 12px;
      }
    }
  }

  .treeview-checkbox {
    margin-left: 12px;
  }
}
</style>
