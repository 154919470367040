<template>
  <div class="settings-page__wrapper">
    <Banner
      v-if="$vuetify.breakpoint.mdAndUp"
      id="main-content"
      heading="Settings"
      subtitle="Manage your resource interests"
      icon="settings"
      :icon-size="bannerIconSize"
      tabindex="-1"
    />
    <div class="settings-content__container">
      <v-card
        :class="{'preferences__container': true, 'pt-5': $vuetify.breakpoint.mdAndUp, 'rounded-0': $vuetify.breakpoint.xsOnly}"
      >
        <v-row
          :class="{'ma-0 section': true, 'pa-8': $vuetify.breakpoint.mdAndUp, 'px-3 pt-6 pb-3': $vuetify.breakpoint.smAndDown}"
        >
          <v-col
            cols="12"
            lg="10"
            md="10"
            sm="10"
            class="pa-0"
          >
            <h1 :class="{'headline mb-3': $vuetify.breakpoint.smAndUp, 'title mb-3': $vuetify.breakpoint.xsOnly}">
              Interests
            </h1>
            <p class="body-1">
              Tell us your areas of interest. Making selections to these options will help us to suggest
              relevant resources.
            </p>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            cols="2"
            class="d-flex justify-end pa-0"
          >
            <AdsButton
              button-text="Update"
              @click="submitPreferences"
              @keyup.enter="submitPreferences"
            />
          </v-col>
        </v-row>
        <v-row
          :class="{'ma-0 section': true, 'pa-8': $vuetify.breakpoint.mdAndUp, 'px-3 pt-6 pb-3': $vuetify.breakpoint.smAndDown}"
        >
          <v-col
            cols="12"
            class="pa-0"
          >
            <h2 :class="{'headline mb-3': $vuetify.breakpoint.smAndUp, 'title mb-3': $vuetify.breakpoint.xsOnly}">
              Teaching and learning resources
            </h2>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="6"
            class="pl-0"
          >
            <PreferencesCheckboxGroup
              v-model="learningAreas"
              heading="Learning areas"
              parent-checkbox-label="All learning areas"
              :items="$store.getters['metadata/klas']"
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="6"
            class="pl-0"
          >
            <PreferencesCheckboxGroup
              v-model="years"
              heading="Stages"
              treeview
              parent-checkbox-label="All stages"
              :items="$store.getters['metadata/stagesAndYears']"
            />
          </v-col>
        </v-row>
        <v-row
          :class="{'ma-0 section': true, 'pa-8': $vuetify.breakpoint.mdAndUp, 'px-3 pt-6 pb-3': $vuetify.breakpoint.smAndDown}"
        >
          <v-col
            cols="12"
            class="pa-0"
          >
            <h2 :class="{'headline mb-3': $vuetify.breakpoint.smAndUp, 'title mb-3': $vuetify.breakpoint.xsOnly}">
              School improvement resources
            </h2>
          </v-col>
          <v-col
            cols="4"
            class="pl-0"
          >
            <PreferencesCheckboxGroup
              v-model="schoolImprovementSources"
              parent-checkbox-label="All"
              :items="$store.getters['metadata/schoolImprovementSources']"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-row
      v-if="$vuetify.breakpoint.xsOnly"
      class="ma-0"
    >
      <v-col
        cols="12"
        class="d-flex justify-end"
      >
        <AdsButton
          button-text="Update"
          @click="submitPreferences"
          @keyup.enter="submitPreferences"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Banner, AdsButton} from '@nswdoe/doe-ui-core';
import {mapGetters} from 'vuex';
import PreferencesCheckboxGroup from '@/components/Dialogs/Preferences/PreferencesCheckboxGroup';

export default {
  name: 'SettingsPage',
  components: {
    Banner,
    AdsButton,
    PreferencesCheckboxGroup,
  },
  data() {
    return {
      bannerIconSize: '3.5rem',
      // below are used as v-models for PreferencesCheckboxGroup:
      years: [],
      learningAreas: [],
      schoolImprovementSources: [],
    };
  },
  computed: {
    ...mapGetters({
      preferences: 'users/preferences'
    }),
  },
  watch: {
    preferences: {
      immediate: true,
      handler(val) {
        if (val) {
          this.years = val.years;
          this.learningAreas = val.learningAreas;
          this.schoolImprovementSources = val.schoolImprovementSources;
        } else {
          this.years = this.$store.getters['metadata/years'].map(y => y.id);
          this.learningAreas = this.$store.getters['metadata/klas'].map(kla => kla.id);
          this.schoolImprovementSources = this.$store.getters['metadata/schoolImprovementSources'].map(sis => sis.id);
        }
      }
    }
  },
  methods: {
    submitPreferences() {
      const preferences = {
        years: this.years,
        learningAreas: this.learningAreas,
        schoolImprovementSources: this.schoolImprovementSources
      };

      // calculate resourceCategory from preferences and call the preference api
      let resourceCategory = [];
      if (preferences.years.length > 0 || preferences.learningAreas.length > 0) {
        resourceCategory.push('tlr');
      }
      if (preferences.schoolImprovementSources.length > 0) {
        resourceCategory.push('sir');
      }

      if (!this.preferences) {
        this.$store.dispatch('users/sendPreferences', {...preferences, resourceCategory});
      } else {
        this.$store.dispatch('users/updatePreferences', {...preferences, resourceCategory});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.settings-page__wrapper {
  min-height: 100%;
  margin-top: 12px;

  .settings-content__container {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    padding: 60px;

    .preferences__container {
      width: 100%;

      .section {
        border-bottom: 1px solid $ads-light-40;

        .body-1 {
          letter-spacing: 0 !important;
        }
      }
    }
  }
}

.v-icon.v-icon {
  align-items: flex-start;
}

.help-button {
  background-color: transparent;
  border: none;
}

.gray-background {
  padding: 24px 12px;
  margin-top: 24px;
  width: 100%;
  border-radius: 4px;
  background-color: $ads-light-10;

  .body-1 {
    margin-bottom: 0;
  }
}

.body-1 {
  letter-spacing: 0 !important;
}

@media only screen and (max-width: 740px) {
  .settings-page__wrapper {
    margin-top: 0;

    .settings-content__container {
      padding: 0;
      margin-top: 0;
    }
  }
}
</style>
