<template>
  <div class="discussion-panel">
    <div
      class="text-editor mt-8 pl-8"
    >
      <QuillEditor
        ref="discussionEditor"
        v-model="content"
        class="quill-editor"
        :options="edtiorOptions"
        @blur="onEditorBlur($event)"
      />

      <AdsButton
        secondary
        :buttonText="$vuetify.breakpoint.lgAndUp ? '': 'Add discussion'"
        icon="send"
        class="discussion-btn"
        @click="addDiscussion"
      />
    </div>

    <v-spacer />

    <div
      class="discussion-container"
    >
      <h3 class="pl-8 mt-8">
        Discussion
      </h3>

      <v-list
        v-if="discussion.length > 0"
      >
        <v-list-item
          v-for="item in discussion"
          :key="item.id"
        >
          <v-list-item-avatar
            class="align-self-start"
          >
            <!-- <v-img src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"/> -->
            <v-avatar
              :color="ADS_Colors.Blue_1"
              :size="40"
            >
              {{ getInitials(item.userId) }}
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <div
              class="chat_bubble"
              @click="editDiscussion(item.message)"
              v-html="item.message"
            />
            <v-icon
              v-if="isOwner"
              class="delete-icon"
              @click="deleteDiscussion"
            >
              mdi-delete-outline
            </v-icon>
            <small class="d-block mt-4 pl-2">{{ moment(item.createdAt).fromNow() }}</small>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list
        v-else
      >
        <v-list-item>
          <v-list-item-content>
            There are no discussion for this collection. Be the first to start the discussion
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { quillEditor } from 'quill-vuejs'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import { AdsButton, ADS_Colors, AdsTextField, } from '@nswdoe/doe-ui-core';
import { mapState } from 'vuex';
export default {
  name: 'DiscussionPanel',
  components:{
    AdsButton,
    // AdsTextField,
    QuillEditor: quillEditor
  },
  props:{
    discussion: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{
      ADS_Colors,
      moment,
      message: '',
      content: '',
      edtiorOptions:{
        modules: {
          toolbar:  [
            ['bold', 'italic', 'image','link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ]
        },
        placeholder: 'Write you discussion here ...',
        theme: 'snow'
      }
    }
  },
  created(){
    console.log(this.access, this.userAccess);
  },
  computed:{
    ...mapState({
      access: state => state.myCollections.collectionAccess,
    }),
    editor(){
      return this.$refs.discussionEditor.quill
    },
    userId() {
      return this.$store.getters['users/userProfile']?.userId?.toLowerCase() || '';
    },
    isOwner(){
      const collection = this.$store.state.myCollections.collectionDetails
      return collection.userAccess.owner.includes(this.userId)
    }
  },
  methods:{
    addDiscussion(){
      const discussionContent = this.editor.getContents().ops;
      /**
       * quill getLength will 1 because according to is api, Delta will
       * still hold 'n/' character, if the there is content, then the length
       * will be greater than 1.
       * so in this case, we only commit our action if the lenght is greater than 1.
       */
      if(this.editor.getLength() < 2){
        return // return if the length is less than 2.
      }
      this.$store.dispatch('myCollections/createDiscussion', discussionContent)
      this.editor.setText('')
    },
    getInitials(initials){
      return initials.split('.').map(item => item.charAt(0).toUpperCase()).join('')
    },
    deleteDiscussion(){
      console.log('discussion deleted...')
    },
    editDiscussion(item){
      this.editor.setText(item)
    },
    onEditorBlur() {
      this.editor.setText('');
    },
  },
}
</script>
<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins";

  .discussion{

    &-panel{
      width: 100%;

      .text-editor{
        display: block;
        width: inherit;
        max-height: 200px;
        padding: 0 36px 0;

        .ql-snow{
          &.ql-toolbar{
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border: 1.5px solid $ads-navy;
          }

          &.ql-container{
            border: 1.5px solid $ads-navy;
            border-top: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }

      .discussion-btn.v-btn{
        display: block;
        float: right;
        margin-top: 20px;

        @media only screen and (max-width: 600px){
          width: 100%;
        }
      }

      .chat_bubble{
        font-weight: 400;
        background: $ads-light-10;
        padding: 15px;
        border-radius: 15px;
        /* line-height: 10; */
        word-spacing: 2px;
        position: relative;
        cursor: pointer;
      }

      button.delete-icon.v-icon--link{
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 18px;
      }

      .quill-editor{
        width: 100%;


        .ql-editor[data-placeholder]{
          font-size: 16px;
          font-style: bold!important;
        }

        p {
          font-family: inherit;
          font-size: 16px;
        }
      }
    }


    &-container{
      width: 100%;
      overflow-y: scroll;
      height: 100%;

      @include respond(phone){
        height: 700px;
      }
      /* Works on Firefox */
      & {
        scrollbar-width: thin!important;
        scrollbar-color: $ads-light-40!important;
      }

      /* Works on Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: $ads-white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $ads-light-40;
        border-radius: 20px;
      }
    }

  }



</style>
