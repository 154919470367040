<template>
  <div>
    <AdsDrawer
      v-bind="$attrs"
      heading="Information"
      icon="info_outline"
      v-on="$listeners"
    >
      <template
        v-if="!$store.getters['myResources/editMode']"
        #content
      >
        <v-list>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Date created:
            </v-list-item-content>
            <v-list-item-content>
              {{ resourceDetails && resourceDetails.document ? moment(resourceDetails.document.DateCreated).format('DD MMM YYYY') : '' }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Last updated:
            </v-list-item-content>
            <v-list-item-content>
              {{ resourceDetails && resourceDetails.document ? moment(resourceDetails.document.DateModified).format('DD MMM YYYY') : '' }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Views:
            </v-list-item-content>
            <v-list-item-content>
              {{ views }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Total shares:
            </v-list-item-content>
            <v-list-item-content>{{ totalShares }}</v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Likes:
            </v-list-item-content>
            <v-list-item-content>
              {{ likes }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Collaborators:
            </v-list-item-content>
            <v-list-item-content class="collaborators">
              <span>
                {{ collaborators.length }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="mt-4">
            <v-list-item-content class="subtitle-1 activities">
              <span>Activity</span>
              <template v-if="resourceNotifications.length">
                <p
                  v-for="resource in resourceNotifications"
                  :key="resource._id"
                >
                  <template v-if="resource.origin.action === 'shared_resource'">
                    <span>
                      <v-icon>
                        mdi-export-variant
                      </v-icon>
                    </span>
                    <span>
                      <strong> {{ resource.userName }}</strong>
                      {{ userActivity(resource) }}
                      <small>
                        {{ moment(resource.createdAt).fromNow() }}
                      </small>
                    </span>
                  </template>
                  <template v-if="resource.origin.action === 'updated_resource'">
                    <span>
                      <v-icon>
                        mdi-export-variant
                      </v-icon>
                    </span>
                    <span>
                      <strong> {{ resource.userName }}</strong>
                      {{ userActivity(resource) }}
                      <small>
                        {{ moment(resource.createdAt).fromNow() }}
                      </small>
                    </span>
                  </template>
                  <template v-if="resource.origin.action === 'deleted_resource'">
                    <span>
                      <v-icon>
                        mdi-delete-outline
                      </v-icon>
                    </span>
                    <span>
                      <strong> {{ resource.userName }}</strong>
                      {{ userActivity(resource) }}
                      <small>
                        {{ moment(resource.createdAt).fromNow() }}
                      </small>
                    </span>
                  </template>
                </p>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </AdsDrawer>
    <CollaboratorsDialog v-model="dialogCollaborators" />
  </div>
</template>

<script>
import CollaboratorsDialog from '../Dialogs/CollaboratorsDialog.vue'
import {ADS_Colors, AdsDrawer} from '@nswdoe/doe-ui-core';
import moment from 'moment';
import {mapState, mapGetters} from 'vuex';

export default {
  name: 'InformationDrawer',
  components: {
    AdsDrawer,
    CollaboratorsDialog
  },
  data() {
    return {
      ADS_Colors,
      moment,
      dialogCollaborators: false,
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.myResources.resourceDetails
    }),
    ...mapGetters({
      resourceNotifications: 'myResources/resourceNotifications'
    }),
    views() {
      return this.resourceDetails?.misc?.views > 0 ? this.resourceDetails.misc.views : 0;
    },
    likes() {
      return this.resourceDetails?.misc?.likes > 0 ? this.resourceDetails.misc.likes : 0;
    },
    collaborators() {
      return this.resourceDetails?.userAccess?.editor ? this.resourceDetails.userAccess.editor : [];
    },
    editor() {
      return this.resourceDetails?.userAccess?.editor.filter(item => item === this.$store.state.uid)
    },
    owner() {
      return this.resourceDetails?.userAccess?.owner[0] === this.$store.state.uid
    },
    viewers() {
      return this.resourceDetails?.userAccess?.viewer ? this.resourceDetails?.userAccess.viewer : [];
    },
    totalShares() {
      return this.collaborators.length + this.viewers.length
    }
  },
  updated() {
    this.$store.dispatch('myResources/getResourceNotifications', this.$route.params.id);
  },
  methods: {
    userActivity(resource) {
      let message;
      switch (resource.origin.action) {
        case 'shared_resource':
          message = ` shared this resource card with ${this.sharedUserName(resource)}`;
          break;
        case 'updated_resource':
          message = ' updated this resource card';
          break;
        case 'deleted_resource':
          message = ' deleted this resource card';
          break;
      }
      return message;
    },
    sharedUserName(getUser) {
      const user = getUser?.description?.split(' ').slice(-1)[0].slice(0, -1).split('.');
      const [firstName, lastName] = user;
      return `${firstName?.charAt(0).toUpperCase() + firstName?.slice(1)} ${lastName?.charAt(0).toUpperCase() + lastName?.slice(1)}`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.information {
  overflow: hidden;

  .divider {
    top: 70px;
    z-index: 1000;
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    opacity: 1;
    border-color: $ads-light-40;
  }

  .size--40 {
    width: 40px;
    height: 40px
  }
}

::v-deep.v-navigation-drawer {
  .v-list {
    .v-list-item {
      min-height: 48px !important;
    }
  }
}

.v-overlay__scrim {
  opacity: 0 !important;
}

.v-overlay v-overlay--active {
  z-index: 0 !important;
}

.v-list-item__content {
  &.collaborators {
    display: inline-block;

    & span:not(first-child) {
      margin-right: 20px;
      text-transform: capitalize;
      //font-weight: bold;
      //color: $ads-blue-1;
      //cursor: pointer;
    }
  }

  &.activities {
    display: block;

    & p {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-gap: 8px;
      justify-items: start;
      align-content: start;
      padding: 10px 0;
      width: 100%;
      font-weight: normal;

      small {
        margin-top: 4px;
        display: block;
      }

      & span:first-child {
        justify-self: start;
      }
    }

  }
}
</style>
