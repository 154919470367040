<template>
  <div>
    <div
      v-if="!saved && !reachedLimitError"
      class="save-search__content"
    >
      <v-card-text class="first-step__content">
        <AdsTextField
          v-model="searchTitle"
          label="Search title"
          persistent-placeholder
          placeholder="Create a name for this search"
          counter="100"
          maxlength="100"
          :rules="[rules.title]"
        />
        <SearchParameters
          v-model="valueInternal"
          :search-params-obj="searchParamsObj"
        />
        <p
          v-if="!$store.state.searchMode && !updateMode"
          class="paragraph"
        >
          No search detected.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <AdsButton
          button-text="Cancel"
          tertiary
          @click="handleCloseDialog"
        />
        <AdsButton
          v-if="!$store.state.saveSearches.updateSearchMode"
          :disabled="!$store.state.searchMode || !searchTitle || hasInvalidChars"
          button-text="Save"
          @click="handleSave"
        />
        <AdsButton
          v-else
          :disabled="!searchTitle"
          button-text="Update"
          @click="handleUpdate"
        />
      </v-card-actions>
    </div>

    <div
      v-if="saved || reachedLimitError"
      class="save-search__content"
    >
      <v-card-text class="second-step__content">
        <FeatureIcon
          size="160"
          icon-size="130"
          :icon="!reachedLimitError ?'check' : 'info_outline'"
        />
        <h2
          v-if="!reachedLimitError"
          class="subtitle"
        >
          Your search for <span class="title">{{ searchTitle }}</span> has been {{ updateMode ? 'updated' : 'saved' }}!
        </h2>
        <h2
          v-else
          class="subtitle"
        >
          You have no saves left
        </h2>
        <p class="paragraph">
          {{ infoText }}
        </p>
      </v-card-text>
      <v-card-actions v-if="reachedLimitError">
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <AdsButton
          button-text="Cancel"
          tertiary
          @click="handleCloseDialog"
        />
        <AdsButton
          button-text="Manage saved searches"
          @click="handleViewSavedSearches"
        />
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <AdsButton
          v-if="!updateMode"
          button-text="Manage saved searches"
          tertiary
          @click="handleViewSavedSearches"
        />
        <AdsButton
          v-if="!updateMode"
          button-text="Done"
          @click="handleCloseDialog"
        />
        <AdsButton
          v-else
          button-text="Close"
          @click="handleCloseDialog"
        />
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import {AdsTextField, AdsButton, FeatureIcon} from '@nswdoe/doe-ui-core';
import SearchParameters from '@/views/SavedSearchesPage/SearchParameters';

export default {
  name: 'SaveUpdateSearch',
  components: {
    AdsTextField,
    AdsButton,
    FeatureIcon,
    SearchParameters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueInternal: false,
      searchTitle: '',
      saved: false,
      searchKeywords: '',
      searchParams: '',
      searchParamsObj: {},
      savedSearch: null,
      infoText: '',
      rules: {
        title: value => {
          const pattern = /^[a-zA-Z0-9_/+=&' .-]*$/
          const testResult = pattern.test(value)
          if (testResult) {
            this.hasInvalidChars = false
            return testResult
          } else {
            this.hasInvalidChars = true
            return 'Invalid characters.'
          }
        },
      },
      reachedLimitError: false,
      hasInvalidChars: false
    }
  },
  computed: {
    updateMode() {
      return this.$store.state.saveSearches.updateSearchMode;
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(val) {
        this.valueInternal = val;
        if (val) {
          let limit;
          if (this.$store.getters['users/upsConfig']) {
            limit = this.$store.getters['users/upsConfig'].maxNumSaveSearch;
          }
          if (this.$store.state.saveSearches.savedSearches.length >= limit && !this.$store.state.saveSearches.updateSearchMode) {
            this.$emit('title', '');
            this.reachedLimitError = true;
            this.infoText = 'You’ve reached the maximum amount of saved searches. Manage your saved searches to clear some space.';
            return;
          } else {
            this.reachedLimitError = false;
            this.infoText = 'You can reactivate this search at any time through the Manage saved searches menu';
          }
          let searchParamsObj = {};
          if (this.$store.state.saveSearches.updateSearchMode) {
            this.savedSearch = this.$store.state.saveSearches.savedSearch;
            this.searchTitle = this.savedSearch.searchName;
            searchParamsObj = this.parseSearchString(this.$store.state.saveSearches.savedSearch.searchParam);
          } else {
            searchParamsObj = Object.assign(searchParamsObj, this.$store.getters.filters);
            searchParamsObj = Object.assign(searchParamsObj, { keywords: this.$store.state.keywords });
          }
          this.searchParamsObj = searchParamsObj;
          let searchParams = [];
          Object.entries(searchParamsObj).forEach(([key, value]) => {
              if (value !== '') {
                searchParams.push(`${key}=${value}`);
              }
            }
          );
          this.searchParams = searchParams.join(' - ');
        } else {
          this.handleCloseDialog();
          await this.$store.dispatch('saveSearches/getSavedSearchesById', this.$store.state.uid);
        }
      }
    },
    valueInternal: function (val) {
      this.$emit('input', val);
    }
  },
  methods: {
    handleCloseDialog() {
      if (this.value) {
        this.valueInternal = false;
      }
      if (this.updateMode) {
        this.$emit('title', 'Update this search');
      } else {
        this.$emit('title', 'Save this search');
      }
      this.searchTitle = '';
      this.saved = false;
      this.formattedFiltersParams = [];
      this.searchParams = '';
      this.savedSearch = null;
      this.$store.commit('saveSearches/SET_UPDATE_SEARCH_MODE', false);
      this.$store.commit('saveSearches/SET_SAVED_SEARCH', null);
    },
    handleSave() {
      if (this.$store.getters['saveSearches/savedSearches'].length >= 50) {
        this.reachedLimitError = true;
        this.infoText = 'You’ve reached the maximum amount of saved searches. Manage your saved searches to clear some space.'
        return
      }
      this.$store.dispatch(
        'saveSearches/postSaveSearch',
        {
          uid: this.$store.state.uid,
          searchTitle: this.searchTitle,
          searchParam: this.searchParams,
          currentURL: window.location.href,
          count: this.$store.state.searchResultsCount
        })
        .then(() => {
          this.$emit('title', '');
          this.saved = true;
        })
        .catch(error => console.error(error))
        .finally(() => {
          if (this.$store.state.isLoading) {
            this.$store.commit('SET_IS_LOADING', false);
          }
        });
    },
    handleUpdate() {
      const payload = {
        uid: this.savedSearch.userId,
        id: this.savedSearch._id,
        searchTitle: this.searchTitle,
        searchParam: this.savedSearch.searchParam,
        searchString: this.savedSearch.searchString,
        count: this.savedSearch.count,
        type: 'updated'
      }
      this.$store.dispatch('saveSearches/patchSavedSearchById', payload)
        .then(() => {
          this.$emit('title', '');
          this.saved = true;
          this.$store.dispatch('saveSearches/getSavedSearchesById', this.$store.state.uid);
        })
        .catch(error => {
            console.error(error);
            this.saved = false;
          }
        )
        .finally(() => {
          if (this.$store.state.isLoading) {
            this.$store.commit('SET_IS_LOADING', false);
          }
        });
    },
    handleViewSavedSearches() {
      if (!this.$store.state.saveSearches.updateSearchMode) {
        this.$router.push({name: 'SavedSearchesPage'});
      } else {
        this.closeDialog();
      }
    },
    parseSearchString(str) {
      let params = str.split(' - ');
      let paramsObj = {};
      params.forEach(p => {
        p = p.split('=');
        paramsObj[p[0]] = p[1];
      });
      return paramsObj;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/ads';

.save-search__content {
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;

  .v-card__actions {
    padding: 0;
  }

  .first-step__content {
    padding: 0;
  }

  .second-step__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .subtitle {
      margin: 20px 0 12px 0;
      text-align: center;

      .title {
        color: $ads-blue-1;
      }
    }

    .paragraph {
      font-size: 16px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 620px) {
  .v-card__actions {
    display: flex;
    flex-direction: column-reverse;

    .v-btn {
      min-width: 100%;
      margin-left: 0 !important;
    }
  }
}
</style>
