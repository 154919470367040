<template>
  <div :class="{'px-2 pt-2 pb-4': $vuetify.breakpoint.xs, 'px-6 pt-6 pb-6': $vuetify.breakpoint.smAndUp } ">
    <v-row align="center">
      <v-col cols="12">
        <a
          target="_blank"
          href="//curriculum.nsw.edu.au/learning-areas">
          <h3>
            NSW Syllabus
            <v-icon
              class="ml-1 pr-2"
              alt="opens in a new window"
              color="#1D428A"
              small>open_in_new
            </v-icon>
          </h3>
        </a>
      </v-col>
      <v-col
        v-if="itemDetail.NSWSyllabus && itemDetail.NSWSyllabus.length > 0"
        cols="12"
        class="py-0"
      >
        <div
          v-for="(syllabus, index) in itemDetail.NSWSyllabus"
          :key="index"
        >
          <v-list
            v-if="syllabus.outcomes && syllabus.outcomes.length > 0"
            class="pb-0"
          >
            <h4
              style="font-size: 16px;"
              class="mb-2"
            >
              {{ syllabus.name }}
            </h4>
            <div
              v-for="(outcome, i) in syllabus.outcomes"
              :key="i"
            >
              <p class="mb-0">
                {{ outcome.id }}
              </p>
              <p>{{ outcome.name ? capitalizeFirstLetter(outcome.name) : outcome.name }}</p>
            </div>
          </v-list>

          <v-list v-if="!syllabus.outcomes || syllabus.outcomes.length == 0">
            <h4
              style="font-size: 16px;"
              class="mb-2"
            >{{ syllabus.name }}</h4>
          </v-list>
        </div>
      </v-col>
      <v-col
        v-else
        cols="12"
        class="py-0"
      >
        <span>Not Available</span>
      </v-col>
    </v-row>

    <v-row
      align="center"
      class="pt-3"
    >
      <v-col cols="12">
        <h3 style="font-size: 16px;">
          Australian Curriculum content descriptions
        </h3>
      </v-col>
      <v-col
        v-if="itemDetail.AusCurrContDesc && itemDetail.AusCurrContDesc.length > 0"
        cols="12"
      >
        <div
          v-for="(description, index) in itemDetail.AusCurrContDesc"
          :key="index"
        >
          <p v-if="description.url !== ''">
            <a
              target="_blank"
              :href="description.url"
              style="font-weight: bold;"
            >
              {{ description.code }}
            </a> - {{ description.description }}
          </p>
        </div>
      </v-col>
      <v-col
        v-else
        cols="12"
        class="pt-0"
      >
        <span>Not Available</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'CardCurriculumAlignment',
  computed: {
    ...mapState({
      itemDetail: state => state.itemDetail
    })
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

a {
  color: $ads-blue-1 !important;
}
</style>
