import {apiBaseUrl} from '@/store/config';
import api from '../../api';

const moduleSaveSearches = {
  namespaced: true,
  state: {
    savedSearches: [],
    sortSavedSearches: 'updatedAt:desc',
    updateSearchMode: false,
    savedSearch: null,
  },
  getters: {
    savedSearches: state => state.savedSearches,
    savedSearch: state => state.savedSearch,
  },
  mutations: {
    SET_SAVED_SEARCHES(state, savedSearches) {
      state.savedSearches = savedSearches;
    },
    SET_SORT_SAVED_SEARCHES(state, sortSavedSearches) {
      state.sortSavedSearches = sortSavedSearches;
    },
    SET_UPDATE_SEARCH_MODE(state, value) {
      state.updateSearchMode = value;
    },
    SET_SAVED_SEARCH(state, savedSearch) {
      state.savedSearch = savedSearch;
    }
  },
  actions: {
    async postSaveSearch({dispatch}, payload) {
      const {uid, searchTitle, searchParam, currentURL, count} = payload;

      const url = `${apiBaseUrl}/savesearch/${uid}`;
      const data = {
        'searchName': searchTitle,
        'searchString': currentURL,
        'searchParam': searchParam.toString(),
        'count': count
      }
      const savedSearches = await api.saveSearches.postSaveSearch(url, data);
      await dispatch('users/fetchUserProfile', uid, {root: true});
      return savedSearches;
    },
    getSavedSearchesById({commit, state}, uid) {
      const url = `${apiBaseUrl}/savesearches/?userId=${uid}&sort=${state.sortSavedSearches}`;
      api.saveSearches.getSavedSearchesById(url)
        .then(response => {
          const savedSearches = response.data;
          commit('SET_SAVED_SEARCHES', savedSearches);
        })
        .catch(error => console.error(error));
    },
    async deleteSavedSearchById({commit, dispatch}, {uid, id}) {
      const url = `${apiBaseUrl}/savesearch/${uid}`;
      const data = {
        'id': [
          id
        ]
      };
      try {
        await api.saveSearches.deleteSaveSearchById(url, data);
        await dispatch('getSavedSearchesById', uid);
        await dispatch('users/fetchUserProfile', uid, {root: true});
        const alert = {
          type: 'success',
          text: 'Your saved search has been deleted'
        }
        commit('SET_ALERT', alert, {root: true});
      } catch (e) {
        console.error(e);
        const alert = {
          type: 'error',
          text: 'There was an error deleting your saved search'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },
    async patchSavedSearchById({dispatch}, payload) {
      const {uid, id, searchTitle, searchParam, searchString, count, type} = payload;
      const url = `${apiBaseUrl}/savesearch/${uid}?id=${id}&type=${type}`;
      const data = {
        'searchName': searchTitle,
        'searchString': searchString,
        'searchParam': searchParam.toString(),
        'count': count
      }
      const updatedSavedSearches = await api.saveSearches.patchSaveSearch(url, data);
      await dispatch('users/fetchUserProfile', uid, {root: true});
      return updatedSavedSearches;
    }
  }
};

export default moduleSaveSearches;
