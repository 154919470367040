<template>
  <v-list>
    <v-list-item>
      <Dialog
        v-model="dialogEmbedCode"
        title="Embed this code"
        dark-heading
        :width="$vuetify.breakpoint.smAndUp ? 500 : 360"
      >
        <template #activator>
          <button
            class="button-icon"
            @click.stop.prevent="handleDialog('EmbedCode')"
          >
            <v-icon size="28">
              code
            </v-icon>
            <span>Embed Code</span>
          </button>
        </template>
        <template #content>
          <CopyClipboardMenu
            :is-open="dialogEmbedCode"
            description="Copy and paste this code to embed on your platform to use this:"
            :text-to-copy="embedCode"
          />
        </template>
      </Dialog>
    </v-list-item>
    <v-list-item>
      <Dialog
        v-model="dialogEmail"
        title="Email this resource"
        dark-heading
        :width="$vuetify.breakpoint.smAndUp ? 500 : 360"
      >
        <template #activator>
          <button
            class="button-icon"
            @click.stop.prevent="handleDialog('Email')"
          >
            <v-icon
              size="28"
              class="material-icons-outlined"
            >
              email
            </v-icon>
            <span>Email</span>
          </button>
        </template>
        <template #content>
          <ShareEmailMenu
            v-model="dialogEmail"
            :resource-details="{ url: url, name: resourceName }"
            @displayDetails="getResultDetails"
          />
          <RecipientDetails
            v-if="resultDetails && $vuetify.breakpoint.mdAndUp"
            :details="resultDetails"
            :position="hoverPosition"
          />
        </template>
      </Dialog>
    </v-list-item>
    <v-list-item>
      <Dialog
        v-model="dialogCopyLink"
        title="Copy this link"
        dark-heading
        :width="$vuetify.breakpoint.smAndUp ? 500 : 360"
      >
        <template #activator>
          <button
            class="button-icon"
            @click.stop.prevent="handleDialog('CopyLink')"
          >
            <v-icon size="28">
              content_copy
            </v-icon>
            <span>Copy URL</span>
          </button>
        </template>
        <template #content>
          <CopyClipboardMenu
            :is-open="dialogCopyLink"
            description="Copy and paste this link:"
            :text-to-copy="url"
          />
        </template>
      </Dialog>
    </v-list-item>
    <v-list-item class="pl-4 d-flex align-center">
      <span
        :id="`googleClassroom${item.id}`"
        class="google-classroom-span mr-3"
        @click="googleClassroomClick"
      />
      <span>
        Google Classroom
      </span>
    </v-list-item>
    <v-list-item class="pl-4 d-flex align-center">
      <span
        :id="`msTeams${item.id}`"
        class="teams-share-button mr-3"
        data-icon-px-size="25"
        :data-href="item.url"
        @click="msTeamsClick"
      />
      <span>Microsoft Teams</span>
    </v-list-item>
  </v-list>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import ShareEmailMenu from '@/components/ShareEmailMenu';
import CopyClipboardMenu from '@/components/CopyClipboardMenu';
import RecipientDetails from './SearchDoeStaff/RecipientDetails.vue';
import {mapState} from 'vuex';

export default {
  name: 'ShareList',
  components: {
    CopyClipboardMenu,
    ShareEmailMenu,
    Dialog,
    RecipientDetails
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialogCopyLink: false,
      dialogEmail: false,
      dialogEmbedCode: false,
      resultDetails: null,
      hoverPosition: null,
      name: ''
    };
  },
  mounted() {
    this.$eventHub.$on('show:dialog', this.showDialog);
    // try to use updated()?
    // this time delay is required as the js files of teams/google are not loaded yet after the component's rendering
    setTimeout(() => {
      this.displayGoogleClassroomShare();
      this.displayMsTeamsShare();
    }, 300);
  },
  beforeUnmount() {
    this.$eventHub.$off('show:dialog');
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.myResources.resourceDetails
    }),
    url() {
      return this.item.url || '';
    },
    resourceName() {
      return this.item?.name || ''
    },
    embedCode() {
      return `<iframe frameborder="0" style="overflow: hidden; height: 100%; width: 100%;" height="100%" width="100%" src=${this.url}></iframe>`;
    }
  },
  methods: {
    showDialog() {
      switch (this.name) {
        case 'CopyLink':
          this.dialogCopyLink = true;
          this.name = '';
          break;
        case 'Email':
          this.dialogEmail = true;
          this.name = '';
          break;
        case 'EmbedCode':
          this.dialogEmbedCode = true;
          this.name = '';
          break;
      }
    },
    handleDialog(name) {
      this.name = name;
      if (this.resourceDetails?.document.Rights === 'Rights not known.' || this.resourceDetails?.document.Rights === '') {
        this.$eventHub.$emit('show:copyright');
      } else {
        this.showDialog();
      }
    },
    getResultDetails(details, position) {
      this.resultDetails = details;
      this.hoverPosition = position;
    },
    displayGoogleClassroomShare() {
      const gapi = window['gapi'];  // included in index.html
      if (gapi === undefined) {
        console.error('DetailCardDialog - window.gapi is undefined');
      } else {
        gapi.sharetoclassroom.render(`googleClassroom${this.item.id}`, {
          size: '25',
          url: this.url, // this was missed in one of the previous releases, I think Vish made this change to cater for files sharing and changed it to return in this.url. Issue reported in JIRA-1755
          title: 'Google Classroom',
          onsharestart: this.googleClassroomClick
        });
      }
    },
    displayMsTeamsShare() {
      const shareToMicrosoftTeams = window['shareToMicrosoftTeams'];
      if (shareToMicrosoftTeams === undefined) {
        console.error('DetailCardDialog - window.shareToMicrosoftTeams is undefined');
      } else {
        shareToMicrosoftTeams.renderButtons({
          elements: [document.getElementById(`msTeams${this.item.id}`)]
        });
      }
    },
    msTeamsClick() {
      this.$store.dispatch('captureTeamsShare', {
        id: this.item.id,
        name: this.item.Name
      });
    },
    googleClassroomClick() {
      this.$store.dispatch('captureGoogleClassroomShare', {
        id: this.item.id,
        name: this.item.Name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.v-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .v-list-item {
    display: block;
    width: 100%;
    padding: 0 8px;
  }
}

.button-icon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 4px;

  span {
    margin-left: 10px;
    font-weight: normal;
    font-size: 15px;
  }

  &:focus {
    border-color: $ads-navy;
    outline: none;
  }
}
</style>
