<template>
  <v-card
    :width="size"
    height="300"
    :class="{'rounded-0': $vuetify.breakpoint.width < 690}"
    :title="hoverText"
  >
    <v-card-title>
      <router-link
        :id="savedSearch._id"
        :to="{ name: 'HubResourcesPage', query: searchQuery}"
        tabindex="0"
        class="primary-control"
      >
        <span class="d-flex flex-row">
          <span
            v-if="resourceCategory"
          >
            <FeatureIcon
              v-for="cat in resourceCategoryDetails"
              :key="cat.id"
              size="20"
              :label="cat.label"
              :color="cat.iconColor"
              :backgroundColor="cat.iconBg"
              :icon="cat.icon"
              class="mr-2"
            />
          </span>
          <h2 :class="{'card-title__mdAndDown': size < 320, 'card-title': true}">
            {{ savedSearch.searchName }}
          </h2>
        </span>
      </router-link>
      <v-progress-circular
        v-if="loading"
        class="loading-circle"
        indeterminate
        :size="20"
        :width="3"
      />
    </v-card-title>
    <SearchParameters
      :value="true"
      is-card
      :search-params-obj="searchParamsObj"
      @formattedFilterParams="generateHoverText"
    />

    <v-card-text>
      <div>
        <p class="results">
          <strong>{{ savedSearch.count }}</strong> {{ savedSearch.count !== 1 ? 'results' : 'result' }} at last run
        </p>
        <p class="saved">
          Saved {{ moment(savedSearch.updatedAt).format('DD MMM YYYY') }}
        </p>
      </div>
      <div class="actions">
        <v-btn
          icon
          aria-label="delete"
          @click="deleteDialog = true"
          @keyup.enter="deleteDialog = true"
        >
          <v-icon
            color="#CE0037"
            class="material-icons-outlined"
          >
            delete
          </v-icon>
        </v-btn>
        <v-btn
          icon
          aria-label="edit"
          @click="handleUpdate"
          @keyup.enter="handleUpdate"
        >
          <v-icon class="material-icons-outlined">
            edit
          </v-icon>
        </v-btn>
      </div>
    </v-card-text>

    <Dialog
      v-model="saveSearchDialog"
      :title="saveUpdateSearchDialogTitle"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    >
      <template #content>
        <SaveUpdateSearch
          v-model="saveSearchDialog"
          @title="getTitle"
        />
      </template>
    </Dialog>

    <Dialog
      v-model="deleteDialog"
      icon="delete_outline"
      title="Are you sure you want to delete?"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    >
      <template #content>
        <v-card-text>
          <p>You will not be able to undo these changes.</p>
        </v-card-text>
        <v-card-actions>
          <AdsButton
            secondary
            button-text="Yes"
            @click="handleDelete"
            @keyup.enter="handleDelete"
          />
          <AdsButton
            button-text="No"
            @click="deleteDialog = false"
            @keyup.enter="deleteDialog = false"
          />
        </v-card-actions>
      </template>
    </Dialog>
  </v-card>
</template>

<script>
import {AdsButton, FeatureIcon} from '@nswdoe/doe-ui-core';
import moment from 'moment';
import Dialog from '@/components/Dialogs/Dialog';
import SaveUpdateSearch from '@/views/SavedSearchesPage/SaveUpdateSearch';
import SearchParameters from '@/views/SavedSearchesPage/SearchParameters';
import { HUB_FILTERS_SELECTION_STRUCTURE, HUB_FILTERS_STRUCTURE, RESOURCE_CATEGORIES } from '@/constants'

export default {
  name: 'SavedSearchesCard',
  components: {
    SearchParameters,
    SaveUpdateSearch,
    Dialog,
    AdsButton,
    FeatureIcon
  },
  props: {
    savedSearch: {
      type: Object,
      default: () => {
      }
    },
    size: {
      type: [Number, String],
      default: 350
    },
    mobileBreakpoint: {
      type: Number,
      default: 620
    }
  },
  data: function () {
    return {
      moment: moment,
      saveSearchDialog: false,
      deleteDialog: false,
      allFilters: '',
      saveUpdateSearchDialogTitle: 'Update this search',
      loading: false,
    }
  },
  computed: {
    resourceCategory() {
      return this.searchQuery['resource-categories']?.split(',') || []
    },
    resourceCategoryDetails() {
      return this.resourceCategory.map(id => this.getCategoryDetails(id));
    },
    searchParamsObj() {
      if (this.savedSearch.searchParam) {
        let params = this.savedSearch.searchParam.split(' - ');
        let paramsObj = {};
        params.forEach(p => {
          p = p.split('=');
          paramsObj[p[0]] = p[1];
        });
        return paramsObj;
      }
      return {};
    },
    searchQuery() {
      const allParams = this.savedSearch.searchParam.split(' - ')
      console.log('allParams ', allParams)
      const query = {
        traffic: 'saved-searches'
      }
      for (let param of allParams) {
        console.log(param)
        const [key, value] = param.split('=');
        switch (key) {
          case 'library':
            query.library = value;
            break;
          case 'resource-years':
            query.years = value;
            break;
          case 'resource-klas':
            query.klas = value;
            break;
          case 'resource-types':
            query['resource-type'] = value;
            break;
          case 'resource-sources':
            query.source = value;
            break;
          case 'resource-randntopics':
            if (!query.source.includes('readingandnumeracy'))
              delete query.randntopics;
            else query.randntopics = value;
            break;
          case 'formats':
            query['formats'] = value;
            break;
          case 'resourcetype':
            query['resourcetype'] = value;
            break;
          case 'phase':
            query['phase'] = value;
            break;
          case 'sefalignment':
            query['sefalignment'] = value;
            break;
          case 'focusarea':
            query['focusarea'] = value;
            break;
          case 'resource-categories': // keeping this code so we support old query params
            query['resource-categories'] = value
            break;
          case 'resource-category':
            query['resource-categories'] = value
            break;
          case 'keywords':
            query['search'] = value
            break;
        }
      }
      return query;
    },
    hoverText() {
      const hoverTextArr = [];
      hoverTextArr.push(this.savedSearch.searchName);
      this.searchParamsObj.keywords ? hoverTextArr.push(this.searchParamsObj.keywords) : null;
      if (this.allFilters !== '' ) {
        hoverTextArr.push(this.allFilters);
      }
      return hoverTextArr.join(' - ');
    }
  },
  methods: {
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    handleDelete: async function () {
      const payload = {
        uid: this.$store.state.uid,
        id: String(this.savedSearch._id)
      }
      await this.$store.dispatch('saveSearches/deleteSavedSearchById', payload);
      this.deleteDialog = false;
    },
    handleUpdate: function () {
      this.$store.commit('saveSearches/SET_SAVED_SEARCH', this.savedSearch);
      this.$store.commit('saveSearches/SET_UPDATE_SEARCH_MODE', true);
      this.saveSearchDialog = true;
    },
    getTitle: function (title) {
      this.saveUpdateSearchDialogTitle = title;
    },
    generateHoverText: function (formattedFiltersParams) {
      this.allFilters = formattedFiltersParams.join(', ');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-card {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .v-card__title {
    padding: 0;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .card-title {
      font-size: 20px;
      color: $ads-blue-1;
      line-height: 26px;
      height: 52px;
      display: -webkit-box;
      word-break: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.card-title__mdAndDown {
        font-size: 16px;
        line-height: 23px;
        height: 46px;
      }
    }

    .loading-circle {
      color: $ads-blue-1;
    }
  }

  .v-card__text {
    flex: 1;
    padding: 0;
    color: $ads-dark;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 12px;

    .results {
      font-size: 16px;
      margin-bottom: 4px;
    }

    .saved {
      font-size: 14px;
      margin-bottom: 0;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: -12px;
    }
  }
}

.v-dialog {
  .v-card__text {
    padding: 24px;
    font-size: 16px;
  }

  .v-card__actions {
    padding: 0 24px 24px 24px;
    display: flex;
    justify-content: flex-end;
  }
}

.primary-control {
  text-decoration: none;

  &:focus {
    outline-color: $ads-navy;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}


</style>
