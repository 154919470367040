<template>
  <div>
    <Dialog
      v-bind="$attrs"
      title="Edit User"
      hideCloseButton
      icon="mdi-account-edit"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #content>
        <v-container class="new-collection-content">
          <v-form ref="newUserForm">
            <v-card-text class="data-content">
              <v-sheet
                color="#F4F4F7"
                class="mb-10"
                rounded
                outlined
              >
                <v-list-item>
                  <InitialsIcon
                    :given-name="userProfile.givenName"
                    :surname="userProfile.familyName"
                  />
                  <v-list-item-content class="ml-4">
                    <v-list-item-title :color="ADS_Colors.Navy">
                      {{ userProfile.fullName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ userProfile.id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
              <template v-for="(access, index) in accesses">
                <v-row
                  v-if="access.status !== 'deleted'"
                  :key="index"
                  class="mt-5"
                >
                  <v-col>
                    <v-autocomplete
                      v-model="access.content"
                      class="input-container"
                      aria-label="content group"
                      aria-required
                      :rules="[ rules.required('Content owner'), rules.isUniqueOwner(accesses,'Content owner') ]"
                      label="Content owner*"
                      outlined
                      item-text="name"
                      item-value="id"
                      placeholder="Select"
                      persistent-placeholder
                      :return-object="false"
                      :items="owners"
                    />
                  </v-col>
                  <v-col>
                    <div class="d-flex">
                      <v-select
                        v-model="access.roles[0]"
                        class="input-container"
                        :rules="[ rules.required('Role') ]"
                        aria-label="Role"
                        aria-required
                        label="Role(s) *"
                        outlined
                        item-text="name"
                        item-value="id"
                        placeholder="Select"
                        persistent-placeholder
                        :return-object="false"
                        :items="editorRoles"
                      />
                      <v-btn
                        text
                        icon
                        :color="ADS_Colors.PRIMARY_RED"
                        @click="removeAccess(access)"
                      >
                        <v-icon>
                          mdi-minus-circle-outline
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-col>
                  <AdsButton
                    tertiary
                    icon="mdi-plus-circle-outline"
                    button-text="New Line"
                    @click="addNewLine"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions :class="{'justify-end px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
              <AdsButton
                class="mr-6"
                tertiary
                :color="ADS_Colors.PRIMARY_RED"
                button-text="Remove User"
                @click="confirmDeletion = true"
                @keyup.enter="confirmDeletion = true"
              />
              <AdsButton
                class="mr-6"
                secondary
                button-text="Cancel"
                @click="$downwardBroadcast('close:dialog')"
                @keyup.enter="$downwardBroadcast('close:dialog')"
              />
              <AdsButton
                primary
                button-text="Confirm"
                @click="editUser"
                @keyup.enter="editUser"
              />
            </v-card-actions>
          </v-form>
        </v-container>
        <ConfirmDeletionDialog
          v-model="confirmDeletion"
          @onConfirm="removeUser"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { AdsButton, ADS_Colors, InitialsIcon } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapGetters} from 'vuex';
import ConfirmDeletionDialog from '@/components/Dialogs/Admin/ConfirmDeletion';
const uuid = require('uuid');

export default  {
  name: 'EditUserDialog',
  components: {
    ConfirmDeletionDialog,
    AdsButton,
    Dialog,
    InitialsIcon
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      ADS_Colors,
      accesses: [],
      accessModel: {
        content: {},
        accessId: '',
        roles: []
      },
      confirmDeletion: false,
      rules: {
        required: field => v => {
          return (!!v && v?.length > 0) || `${field} is required`
        },
        requiredId: field => v => {
          return (!!v?.id) || `${field} is required`
        },
        isUniqueOwner: (accesses, field) => v => {
          return (!!v && accesses?.filter(a=> a?.content === v && a.status !== 'deleted').length < 2) || `${field} duplicated`
        }
      }
    };
  },
  watch: {
    '$attrs.value'(val) {
      // reset form validation on close
      !val && this.$refs.newUserForm && this.$refs.newUserForm.reset()
      if (val) {
        this.accesses = this.accessDetails.map(access => {
          return {
            id: access.accessId,
            content: access.content.application + '-' + access.content.id,
            roles: access.roles,
            status: access.status
          }
        })
      }
    },
    accessDetails(val) {
      this.accesses = val.map(access => {
        return {
          id: access.accessId,
          content: access.content.application + '-' + access.content.id,
          roles: access.roles,
          status: access.status
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      editorRoles: 'metadata/editorRoles',
      accessDetails: 'admin/urhUserAccesses',
      upsConfig: 'users/upsConfig',
      contentOwners: 'metadata/sources',
      loggedInUserProfile: 'users/userProfile',
    }),
    owners() {
      return this.contentOwners.map(o => {
        return {
          id: o.application + '-' + o.id,
          name: o.name
        }
      })
    },
    userProfile() {
      const details = this.user
      return {
        givenName: details.firstName,
        familyName: details.surName,
        fullName: details.userName,
        id: details.userId,
        details: details.details
      }
    }
  },
  methods: {
    async removeUser() {
      const accesses = this.accessDetails.map(access => {
        return {
          ...access,
          status: 'deleted'
        }
      })
      const response = await this.$store.dispatch('admin/patchUserAccess', {
        accesses
      });
      this.$downwardBroadcast('close:dialog');
      this.$emit('onChange')
    },
    removeAccess(access) {
      const removeIndex = this.accesses.map(item => item.id).indexOf(access.id);

      if (removeIndex >= 0) {
        this.accesses.splice(removeIndex, 1)//[removeIndex] = {...access, deleted: true}
      }
      // flag only existing records, new accesses we can delete safely from FE
      access.status !== 'new' && this.accesses.push({
        ...access,
        status: 'deleted'
      })
    },
    addNewLine(){
      this.accesses.push({
        ...this.accessModel,
        roles: [ ...this.accessModel.roles ], // this is still required to avoid the mutation as we are using only role[0]
        status: 'new',
        id: uuid.v4()
      })
    },
    async editUser() {
      const valid = await this.$refs.newUserForm.validate()
      if (!valid) {
        return false
      }
      const userProfile = this.userProfile
      const accesses = this.accesses.map(access => {
        const isNewAccess = access.status === 'new' // this is just created from FE
        const isDeleted = access.status === 'deleted'
        const content = access.content.split('-')
        return {
          ...(!isNewAccess && { accessId: access.id }), // send empty so BE can validate to overwrite duplicates
          content: {
            application: content[0],
            id: content[1]
          },
          roles: access.roles,
          userId: userProfile.id,
          userDetails: userProfile.details,
          updatedBy: this.loggedInUserProfile.userId,
          status: isNewAccess ? 'added': isDeleted ? 'deleted' : access.status
        }
      })
      const response = await this.$store.dispatch('admin/patchUserAccess', {
        accesses
      });
      if (response?.message?.toLowerCase() === 'success') {
        const alert = {
          type: 'success',
          text: 'User access successfully updated.'
        }
        this.$store.commit('SET_ALERT', alert, {root: true});
        this.$store.commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
      this.$downwardBroadcast('close:dialog');

      console.log('updating accesses', accesses, response)
      this.$emit('onChange')
    },
  }
};
</script>
<style lang="scss" scoped>

@import "../../../scss/mixins";

fieldset {
  border: none;
  legend {
    h4 {
      color: #002664
    }
    label {
      color: $ads-grey-02 !important;
    }
  }
}

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 65vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }

  .input-container {
    ::v-deep .v-label {
      color: $ads-primary-blue;
    }

    &.optional ::v-deep .v-label {
      &::after {
        content: ' (optional)';
        font-weight: normal;
      }
    }
  }
}
</style>
