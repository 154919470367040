<template>
  <Dialog
    v-bind="$attrs"
    title="Submit for review"
    subTitle="Do you want to add any comments to be sent to the reviewer(s)?"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <div class="share-card__wrapper mt-6">
        <v-card-text>
          <AdsSelect
            v-model="assignee"
            label="Assignee"
            :items="approvers"
            item-text="name"
            item-value="userId"
            return-object
          />
          <AdsTextArea
            v-model="comments"
            label="Comments"
          />
        </v-card-text>
        <v-card-actions class="pa-4 pt-0">
          <v-spacer />
          <CancelButton @click="$downwardBroadcast('close:dialog')" />
          <SubmitForReviewButton
            :disabled="!assignee"
            @click="handleSubmitForReview"
          />
        </v-card-actions>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {AdsSelect, AdsTextArea} from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapGetters} from 'vuex';
import {EDPUSH_ACTION, ELASTIC_STATUS} from '@/constants';
import SubmitForReviewButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/SubmitForReviewButton.vue';
import CancelButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/CancelButton.vue';

export default {
  name: 'SubmitForReviewDialog',
  components: {
    CancelButton,
    SubmitForReviewButton,
    Dialog,
    AdsSelect,
    AdsTextArea
  },
  props:{
    hubCollection: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: null
    },
    recordId: {
      type: String,
      default: null
    },
    application: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      assignee: null,
      comments: ''
    };
  },
  methods: {
    async handleSubmitForReview() {
      // if Preview, no need to call handleCreate or handleUpdate, just notify and update the current Draft status to be Pending
      if (!this.$store.state.editor.editMode) {
        await this.postEdPushAndElastic();
        return;
      }

      if (this.isNewResource) {
        // 1. new resource, create it first
        const createdResource = await this.$parent.handleCreate(true);
        if (createdResource) {
          // 2. POST EdPush to send notifications and Elastic to update status to be Pending
          await this.postEdPushAndElastic();
        }
      } else {
        // 1. existing resource, update it first
        await this.$parent.handleUpdate(EDPUSH_ACTION.SUBMIT);
        // 2. POST EdPush to send notifications and Elastic to update status to be Pend
        await this.postEdPushAndElastic();
      }
    },
    async postEdPushAndElastic() {
      const {application, source, recordId, title} = this;
      // 1. EdPush Post
      const edPushResult = await this.$store.dispatch(
        'editor/submitForReview',
        {
          type: this.assignee.userId === this.userIdFirstApprover ? 'group' : 'user',
          assignee: this.assignee.name,
          assigneeId: this.assignee.userId,
          comments: this.comments,
          application,
          source,
          title,
          recordId
        });
      if (edPushResult) {
        this.$downwardBroadcast('close:dialog');
        // 2. Post Elastic for status Update in Elastic
        const elasticResult = await this.$store.dispatch(
          'editor/updateResourceStatus',
          {
            action: ELASTIC_STATUS.PENDING,
            assigneeId: this.assignee.userId,
            application,
            source,
            recordId
          });
        if (elasticResult) {
          this.$emit('onReviewSubmitted')
          if (this.hubCollection) {
            this.$emit('onSubmit', elasticResult);
            this.$store.commit('editor/SET_REVIEW_SUBMITTED', true);
          } else {
            // 3. switch to data table when api calls are successful
            this.$store.commit('editor/SET_REVIEW_SUBMITTED', true);
            await this.$router.push({name: 'ResourceEditorPage'});
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      approvers: 'editor/approvers',
      userProfile: 'users/userProfile',
      userIdFirstApprover: 'editor/userIdFirstApprover'
    }),
    isNewResource() {
      return this.$route.params?.id === 'new';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.share-card__wrapper {
  display: block;
  min-width: 240px;

  .v-divider {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 43.75em) {
    // 700px === 43.75em
    & {
      width: 100%;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  & .v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .v-btn {
    min-height: 2.5rem;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    border-color: $ads-light-40;
    background-color: $ads-white;
    color: $ads-white;

    &:focus {
      border: 2px solid $ads-navy;
      outline: none !important;
    }
  }

  button.v-btn > .v-btn__content {
    justify-content: center;
  }

  .btnText {
    color: white;
  }

  .v-divider {
    border-color: $ads-light-40;
  }
}
</style>
