<template>
  <v-carousel
    class="notification-carousel"
    height="200px"
    :cycle="false"
    :continuous="true"
    :show-arrows="false"
    :style="{'background': ADS_Colors.Navy}"
    hide-delimiter-background
    :hide-delimiters="notifications.length < 2"
    delimiter-icon="mdi-circle"
  >
    <v-carousel-item
      v-for="(note, i) in notifications"
      :key="i"
    >
      <template v-if="note.type==='alert'">
        <div class="bg-white">
          <v-alert class="ml-10 mt-2 mr-10">
            <h1 class="ads-navy ml-3">
              <v-icon x-large class="mr-5">
                mdi-alert-circle
              </v-icon>{{ note.title }}
            </h1>
            <div class="ml-16 pl-2">
              {{ note.message }}
            </div>
            <AdsButton
              v-if="note.showDismiss"
              tertiary
              :color="ADS_Colors.black"
              class="mt-1 mr-6 ml-12 text-decoration-underline"
              button-text="DISMISS"
              @click="dismissNotification(note.id)"
            />
          </v-alert>
        </div>
      </template>
      <div v-else class="ml-10 mt-1 ads-white d-flex justify-space-between">
        <div>
          <h1 class="ads-white ml-3">
            {{ note.title }}
          </h1>
          <div class="d-flex justify-start">
            <div class="col-12">
              <div>{{ note.message }}</div>
              <div class="d-flex justify-start">
                <AdsButton
                  v-if="note.buttonText"
                  secondary
                  :color="ADS_Colors.Navy_Dark"
                  class="mt-5 mr-6"
                  :button-text="note.buttonText"
                  @click="$router.push({
                    path: note.link,
                    query: { traffic : 'banner' }
                })"
                />
                <AdsButton
                  v-if="note.showDismiss"
                  tertiary
                  :color="ADS_Colors.Navy_Dark"
                  class="mt-5 mr-10"
                  button-text="Dismiss"
                  @click="dismissNotification(note.id)"
                />
              </div>
            </div>
          </div>
        </div>

        <v-img
          class="mr-16 mb-10"
          height="100%"
          :src="itemBackgroundSVG"
        />
      </div>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';
import itemBackgroundSVG from '@/assets/notfication.svg'
import ellipsesSVG from '@/assets/ellipses.svg'

export default {
  name: 'NotificationCarousel',
  components: {
    AdsButton
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      drawer: false,
      ADS_Colors,
      itemBackgroundSVG,
      ellipsesSVG
    }
  },
  methods: {
    async dismissNotification(id) {
      await this.$store.dispatch('users/dismissNotification', {
        uid: this.$store.state.uid,
        notification: id
      });
    }
  }
}
</script>
<style lang="scss" scoped >
.notification-carousel {
  ::v-deep .v-carousel__controls button {
    color: white;
    margin: 1px;
  }
}
</style>
