<template>
  <div class="saved-search-menu__wrapper">
    <v-menu
      v-model="isExpanded"
      bottom
      offset-y
      left
      :nudge-bottom="8"
      attach
    >
      <template #activator="{on, attrs}">
        <v-btn
          v-bind="attrs"
          aria-haspopup="true"
          :aria-expanded="isExpanded ? 'true' : 'false'"
          outlined
          v-on="on"
          @click="isExpanded = !isExpanded"
          @keyup.enter="isExpanded = !isExpanded"
        >
          Saved searches
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-card class="save-search-menu">
        <v-list v-if="$store.getters['users/userProfile'] && $store.getters['users/userProfile'].savedSearches.length > 0">
          <h4>Last Run</h4>
          <v-list-item
            v-for="savedSearch in $store.getters['users/userProfile'].savedSearches"
            :key="savedSearch._id"
          >
            <button @click="applySearch(savedSearch)">
              <span>{{ savedSearch.searchName }}</span>
            </button>
          </v-list-item>
          <v-divider />
        </v-list>
        <span class="manage-saved-searches__wrapper">
          <router-link to="/saved-searches">
            Manage Saved Searches
          </router-link>
        </span>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { HUB_FILTERS_SELECTION_STRUCTURE, HUB_FILTERS_STRUCTURE } from '@/constants'

export default {
  name: 'SavedSearchesMenu',
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    async applySearch(savedSearch) {
      let filters = {...HUB_FILTERS_STRUCTURE};
      let keywords = '';
      savedSearch.searchParam.split(' - ').forEach(param => {
        const [query, value] = param.split('=');
        if (query === 'keywords') {
          keywords = value;
        } else {
          filters[query] = value;
        }
      });
      this.$store.commit('SET_FILTERS', filters);
      this.$store.commit('SET_KEYWORDS', keywords);

      let filtersSelection = {...HUB_FILTERS_SELECTION_STRUCTURE};
      filtersSelection = {
        checkboxResourceCategory : filters['resource-category'] === '' ? [] : filters['resource-category']?.split(',') || [],
        checkboxUrhLibrary: filters['urhlibrary'] ? filters['urhlibrary'].split(',') : [],
        checkboxYears: filters['resource-years'] ? filters['resource-years'].split(',') : [],
        checkboxKLAs: filters['resource-klas'] ? filters['resource-klas'].split(',') : [],
        checkboxResourceType: filters['resource-types'] ? filters['resource-types'].split(',') : [],
        checkboxSource: filters['resource-sources'] ? filters['resource-sources'].split(',') : [],
        checkboxRandN: filters['resource-randntopics'] ? filters['resource-randntopics'].split(',') : [],
        // new in R4:
        checkboxFormat: filters.formats ? filters.formats.split(',') : [],
        checkboxHubType: filters.resourcetype ? filters.resourcetype.split(',') : [],
        checkboxPhase: filters.phase ? filters.phase.split(',') : [],
        checkboxSefAlignment: filters.sefalignment ? filters.sefalignment.split(',') : [],
        checkboxFocusArea: filters.focusarea ? filters.focusarea.split(',') : []
      };
      await this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

      const payload = {
        uid: this.$store.state.uid,
        id: savedSearch._id,
        searchTitle: savedSearch.searchName,
        searchParam: savedSearch.searchParam,
        searchString: savedSearch.searchString,
        count: savedSearch.count,
        type: 'executed'
      }
      await this.$store.dispatch('saveSearches/patchSavedSearchById', payload);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.saved-search-menu__wrapper {
  display: flex;
  justify-content: flex-end;
  min-width: 240px;

  .v-btn {
    min-height: 56px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: normal;
    min-width: 240px;
    display: flex;
    justify-content: space-between;
    border-color: $ads-light-40;
    background-color: $ads-white;
    color: $ads-dark-60;

    &:focus {
      border: 2px solid $ads-navy;
      outline: none !important;
    }
  }

  .save-search-menu {
    min-width: 240px;
    max-width: 300px;
    padding: 16px 0;

    .v-list {
      padding: 8px 0 16px 0;

      h4 {
        padding: 0 16px 16px 16px;
      }

      .v-list-item {
        button {
          display: flex;
          justify-content: flex-start;
          min-height: 36px;
          min-width: 240px;
          max-width: 300px;
          border: none;
          background-color: transparent;

          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $ads-dark-80;
          }
        }
      }

      a {
        text-decoration: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $ads-dark-80;
      }
    }

    .v-divider {
      border-color: $ads-light-40;
    }

    .manage-saved-searches__wrapper {
      padding: 16px;

      a {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .saved-search-menu__wrapper {
    width: 100%;
    margin-top: 8px;

    .v-btn {
      width: 100%;
    }

    .save-search-menu {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
