<template>
  <div class="mt-3">
    <v-container
      v-if="$vuetify.breakpoint.mdAndUp"
      style="padding: 0;"
      fluid
    >
      <Banner
        id="main-content"
        heading="Help & About - Road Map"
        subtitle="Road map for URH from initiation to current release"
        icon="help_outline"
        :icon-size="bannerIconSize"
        tabindex="-1"
      />
    </v-container>
    <v-container fluid>
      <v-timeline
        id="roadmap"
        class="my-12"
        dense
      >
        <template v-for="(item, i) in items">
          <v-timeline-item
            v-if="typeof item === 'number'"
            :key="`divider-${i}`"
          >
            <template #opposite>
              <span>Completed</span>
            </template>
          </v-timeline-item>
          <v-timeline-item
            v-else-if="index == null || index >= i"
            :key="i"
            :aria-label="item.complete ? 'completed release' : 'current release'"
            :icon="item.complete ? 'done' : item.icon"
            :color="item.complete ? 'success darken-1' : item.color"
            :small="item.complete || item.small"
            fill-dot
          >
            <template #opposite>
              <strong
                :class="index === i ? 'primary--text' : undefined"
                v-text="item.caption"
              />
            </template>
            <v-card
              :hover="true"
              class="py-0 pl-0"
              elevation="0"
              @click.native="item.value = !item.value"
            >
              <v-expansion-panels
                class="mb-0"
              >
                <v-expansion-panel
                  @click="sendPanelClickDataToGA(item.title)"
                >
                  <v-expansion-panel-header
                    disable-icon-rotate
                  >
                    <template #actions>
                      <v-icon
                        color="#6D7079"
                        small
                        class="mt-n1"
                      >
                        date_range
                      </v-icon>
                    </template>
                    <v-card-title
                      class="py-0 pr-2 pl-0"
                    >
                      <Markdown
                        class="body-2"
                        :code="item.title"
                      />
                      <v-spacer />

                      <span
                        class="caption-text"
                      >
                        <span class="mr-1">{{ item.caption }}</span>
                      </span>
                    </v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content aria-live="polite">
                    <v-card-text class="pl-0 pt-0">
                      <Markdown
                        v-if="(item.text)"
                        :code="item.text"
                      />
                      <template v-if="item.features">
                        <template v-for="(feature, x) in item.features">
                          <Markdown
                            v-if="(feature.title)"
                            :key="`title-${x}`"
                            :code="feature.title"
                          />
                          <Markdown
                            v-if="(feature.description)"
                            :key="`description-${x}`"
                            :code="feature.description"
                          />
                          <Markdown
                            v-if="(feature.items)"
                            :key="`list-${x}`"
                            :code="feature.items"
                          />
                        </template>
                      </template>
                    </v-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-timeline-item>
        </template>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core';
import Markdown from './Markdown';
import {itemData} from './itemData';

export default {
  name: 'Roadmap',
  components: {
    Markdown,
    Banner
  },
  data() {
    return {
      index: null,
      interval: null,
      items: [],
      bannerIconSize: '3.5rem',
    }
  },
  mounted() {
    this.items = itemData.map(item => {
      const type = types[item.type];
      return {
        ...type,
        ...item,
      };
    }).reverse();
  },
  methods: {
    sendPanelClickDataToGA(itemText) {
      this.$ga?.event({
        eventCategory: 'Roadmap',
        eventAction: 'panel-click',
        eventLabel: `panel: ${itemText}`
      });
    }
  },
};

const types = {
  release: {
    icon: 'event',
    color: 'brand-color',
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

#roadmap >>> div.success.darken-1 {
  background-color: #69B32D !important;
  border-color: #69B32D !important;
}

#roadmap >>> div.brand-color {
  background-color: #407EC9 !important;
  border-color: #407EC9 !important;
}
#roadmap >>> .caption-text {
  color: #6D7079;
  font-size: 1rem;
}
#roadmap .v-expansion-panel-header:focus {
  outline: 2px solid #041e42 !important;
}
</style>
