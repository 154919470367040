<template>
  <div>
    <AppBar
      v-if="$vuetify.breakpoint.xsOnly"
      showBackButton
      :color="ADS_Colors.Navy"
    >
      <template #title>
        <h1 class="appbar-title">
          {{ appBarTitle }}
        </h1>
      </template>
    </AppBar>
    <v-row class="create-new-resource__wrapper">
      <v-col
        cols="12"
        lg="10"
        md="10"
        sm="12"
        class="content__wrapper"
      >
        <div class="top">
          <template v-if="$vuetify.breakpoint.smAndUp">
            <AdsButton
              v-if="$store.state.previousPath !== '/'"
              tertiary
              icon="mdi-arrow-left"
              button-text="Back"
              @click="$router.back()"
              @keyup.enter="$router.back()"
            />
            <AdsButton
              v-else-if="isTutorialResource"
              tertiary
              icon="mdi-arrow-left"
              button-text="Back to Tutorials"
              @click="$router.push({ name: 'TutorialsPage' })"
              @keyup.enter="$router.push({ name: 'TutorialsPage' })"
            />
            <AdsButton
              v-else
              tertiary
              icon="mdi-arrow-left"
              button-text="Back to My Resources"
              @click="$router.push({ name: 'MyResourcesPage' })"
              @keyup.enter="$router.push({ name: 'MyResourcesPage' })"
            />
          </template>
          <v-spacer v-else />
          <div class="right-content">
            <v-switch
              v-if="isNewResource || (!hideEditToggle && (isEditor || isOwner))"
              v-model="editMode"
              hide-details
              label="Edit"
            />
            <AdsButton
              v-if="hideEditToggle && !isViewer && !processing"
              icon="mdi-undo"
              button-text="Use this version"
              class="mr-2"
              @click="revertToPreviousVersion"
            />
            <AdsButton
              v-if="hideEditToggle && isViewer && $route.query.version && !processing"
              icon="mdi-undo"
              button-text="Copy this version"
              class="mr-2"
              @click="copyPreviousVersion"
            />
            <AdsButton
              v-if="!displayActionsButton"
              :disabled="disableSave"
              :loading="$store.state.isLoading"
              :tertiary="disableSave"
              button-text="Save"
              @click="handleSave(false)"
            />
            <ActionsMenu
              v-if="displayActionsButton && (!isTutorialResource || isOwner)"
              bottom
              offset-y
              left
              :icon="hideEditToggle && $vuetify.breakpoint.xsOnly"
              :nudge-bottom="8"
              :items="moreActionsItems"
              @clickedAction="handleAction"
            />
            <DeleteResourceDialog
              v-model="deleteDialog"
              :resourceId="resourceId"
              :resourceName="resourceDetails ? resourceDetails.document.Name: ''"
              :isOwner="isOwner"
              :isEditor="isEditor"
            />
          </div>
        </div>
        <Alert
          v-if="isDraft"
          type="info"
          allowDismiss
          text="Saved automatically"
          :subtext="draftAlertMessage"
          actionText="Discard draft"
          :actionHandler="handleDiscardDraft"
          inPage
        />
        <DeleteDraftDialog v-model="deleteDraftDialog" />
        <v-card class="content__container">
          <Edit
            v-show="editMode"
            :isNewResource="isNewResource"
            @get:formInputs="getFormInputs"
          />
          <Preview
            v-show="!editMode"
            :isNewResource="isNewResource"
            :isDraft="isDraft"
          />
        </v-card>
        <div class="text-right">
          <AdsButton
            v-if="!displayActionsButton"
            :disabled="disableSave"
            :loading="$store.state.isLoading"
            :tertiary="disableSave"
            button-text="Save"
            @click="handleSave(false)"
          />
        </div>
      </v-col>
    </v-row>

    <CollaboratorsDialog v-model="dialogCollaborators" />

    <ShareThisCardDialog
      v-model="dialogShare"
      heading="Who else can view this resource card? They will be notified via email."
      subHeading="Anyone with whom this resource card is shared with can view and share this resource card."
      :resourceName="resourceDetails && resourceDetails.document.Name ? resourceDetails.document.Name : ''"
      :resourceUrl="resourceDetails && resourceDetails.links && resourceDetails.links.path ? resourceDetails.links.path : ''"
    />

    <InformationDrawer v-model="informationDrawer" />

    <VersionHistoryDrawer
      v-model="versionHistoryDrawer"
      :items="versionHistory"
      @copy:version="copyPreviousVersion"
    />
  </div>
</template>

<script>
import {AdsButton, AppBar, Alert, ADS_Colors} from '@nswdoe/doe-ui-core';
import {MORE_ACTIONS_MENU_ITEMS, MORE_ACTIONS_MENU_ITEMS_EDITOR_VIEWER} from '@/constants';
import Edit from '@/views/MyResourceDetailsPage/Edit/Edit';
import Preview from '@/views/MyResourceDetailsPage/Preview/Preview';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu';
import DeleteResourceDialog from '@/components/Dialogs/DeleteResourceDialog';
import InformationDrawer from '@/components/Drawers/InformationDrawer';
import {mapState} from 'vuex';
import {clone} from '@/plugins/utils';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import VersionHistoryDrawer from '@/components/Drawers/VersionHistoryDrawer';
import ShareThisCardDialog from '../../components/Dialogs/ShareThisCardDialog.vue';
import CollaboratorsDialog from '@/components/Dialogs/CollaboratorsDialog';
import DeleteDraftDialog from '@/components/Dialogs/DeleteDraftDialog';
const uuid = require('uuid');

export default {
  name: 'MyResourceDetailsPage',
  components: {
    CollaboratorsDialog,
    VersionHistoryDrawer,
    DeleteResourceDialog,
    InformationDrawer,
    ActionsMenu,
    Edit,
    AdsButton,
    Preview,
    AppBar,
    ShareThisCardDialog,
    Alert,
    DeleteDraftDialog
  },
  data() {
    return {
      ADS_Colors,
      // below 5 fields receive formInputs from Edit
      document: null,
      file: null,
      link: '',
      fileName: '',
      rules: [],

      modifiedContent: [],
      loading: false,
      moment,
      deleteDialog: false,
      informationDrawer: false,
      versionHistoryDrawer: false,
      versionHistory: [],
      hideEditToggle: false,
      shareCard: false,
      dialogShare: false,
      dialogCollaborators: false,
      deleteDraftDialog: false,
      processing: false,
    }
  },
  mounted() {
    this.$eventHub.$on('update:copyright', this.updateCopyright);
    this.$eventHub.$on('show:shareThisCardDialog', this.shareThisCardDialog);
  },
  beforeUnmount() {
    this.$eventHub.$off('update:copyright');
    this.$eventHub.$off('show:shareThisCardDialog');
    this.$store.commit('myResources/SET_RESOURCE_DETAILS', null);
    this.$store.commit('myResources/SET_EDIT_MODE', false);
  },
  computed: {
    ...mapState({
      tokenWillExpire: state => state.myResources.tokenWillExpire,
      resourceDetails: state => state.myResources.resourceDetails,
      storeVersionHistory: state => state.myResources.versionHistory
    }),
    copyrightCompleted() {
      return this.resourceDetails?.document?.Rights === 'Rights not known.' || this.resourceDetails.document.Rights === '' ? false : true;
    },
    moreActionsItems() {
      return this.isOwner ? MORE_ACTIONS_MENU_ITEMS : MORE_ACTIONS_MENU_ITEMS_EDITOR_VIEWER
    },
    isNewResource() {
      return this.$route.params?.id === 'new';
    },
    isTutorialResource() {
      return this.resourceDetails?.misc?.subSource === 'tutorial';
    },
    completedMandatoryFields() {
      // check if user completed all mandatory fields
      const resource = this.document;
      if (!resource) {
        return false;
      } else {
        const fileOrLink = !!this.fileName || !!this.link;
        const mandatoryFields = [!!fileOrLink, !!resource.ResourceType, !!resource.Format, !!resource.Name, !!resource.Stage?.length, !!resource.Year?.length, !!resource.KLA.length];
        return mandatoryFields.every(i => i === true) && this.rules.every(i => i === false)
      }
    },
    completedAtLeastOneField() {
      return !!this.fileName || !!this.link || (this.document && Object.keys(this.document).some(field => !!field));
    },
    disableSave() {
      if (this.isViewer) {
        return true;
      }
      if (this.isDraft && this.completedMandatoryFields) {
        return false;
      }
      if (this.isNewResource) {
        return !this.completedMandatoryFields;
      }
      return !this.completedMandatoryFields;
    },
    displayActionsButton() {
      return this.isViewer || (!this.editMode && !this.isNewResource && !this.isDraft) || this.hideEditToggle;
    },
    editMode: {
      get() {
        return this.$store.state.myResources.editMode;
      },
      set(val) {
        this.$store.commit('myResources/SET_EDIT_MODE', val);
      }
    },
    appBarTitle() {
      if (this.isNewResource) {
        return 'Create new resource';
      } else {
        return this.document.Name;
      }
    },
    resourceId() {
      if (this.isNewResource) {
        return '';
      }
      if (this.resourceDetails?.record.id) {
        return this.resourceDetails?.record.id;
      }
      return '';
    },
    isEditor() {
      const loggedUser = this.$store.getters['users/userProfile']?.userId.toLowerCase() ?? '';
      return this.resourceDetails?.userAccess?.editor.includes(loggedUser);
    },
    isViewer() {
      return !this.isEditor && !this.isOwner;
    },
    isOwner() {
      const loggedUser = this.$store.getters['users/userProfile']?.userId.toLowerCase() ?? '';
      return this.isNewResource || this.resourceDetails?.userAccess?.owner.includes(loggedUser);
    },
    isDraft() {
      if (this.isNewResource || this.isViewer) {
        return false;
      }
      const versionHistory = this.storeVersionHistory || this.versionHistory;
      if (versionHistory && versionHistory.length) {
        const wasPreviouslyDraft = versionHistory[0].isDraft;
        return (this.$route.query.draft && this.$route.query.draft === 'true') || wasPreviouslyDraft;
      }
      return false;
    },
    draftAlertMessage() {
      const versionHistory = this.storeVersionHistory || this.versionHistory;
      if (versionHistory && versionHistory.length === 1) {
        return 'We automatically saved this card for you. You won’t be able to share this resource card until you have saved.';
      } else {
        return 'We automatically saved the last time you or a collaborator was editing this resource card. Once this card has been saved, the new version will be available to all users with whom it has been shared.';
      }
    }
  },
  watch: {
    resourceDetails: {
      immediate: true,
      handler(resourceDetails) {
        // first render a created resource
        if (resourceDetails) {
          this.handleDisplayedVersion();
        } else {
          this.document = null;
        }

        if (this.isDraft && !this.$route.query.isDraft) {
          // update the route with query
          let query = Object.assign({}, this.$route.query);
          query.draft = 'true';
          this.$router.replace({query}).catch(() => {
          });
        }
      }
    },
    $route(val) {
      if (val.name === 'MyResourceDetailsPage') {
        this.handleDisplayedVersion();
      }
    },
    document() {
      if (!this.isNewResource) {
        this.modifiedContent = this.trackChanges();
      }
    },
    link(val) {
      if (!this.isNewResource && !isEqual(this.resourceDetails.links?.path, val) && !this.modifiedContent.includes('source')) {
        this.modifiedContent.push('source');
      }
    },
    file() {
      if (!this.isNewResource && !this.modifiedContent.includes('source')) {
        this.modifiedContent.push('source');
      }
    },
    tokenWillExpire(val) {
      if (val && this.completedAtLeastOneField) {
        if (this.isNewResource) {
          this.handleSave(true);
        }
      }
    }
  },
  methods: {
    getFormInputs(formInputs) {
      this.document = formInputs.document;
      this.link = formInputs.link;
      this.fileName = formInputs.fileName;
      this.file = formInputs.file;
      this.rules = formInputs.rules;
    },
    shareThisCardDialog() {
      this.dialogShare = true;
    },
    handleDisplayedVersion() {
      let displayedVersion;
      if (this.isViewer && this.storeVersionHistory[0] && !this.$route.query.version) {
        const lastSavedVersion = this.storeVersionHistory[0]?.versionNum;
        const archivedVersion = this.resourceDetails.archive.find(item => item.record.version === lastSavedVersion);
        if (archivedVersion !== undefined) {
          displayedVersion = archivedVersion;
        } else {
          displayedVersion = this.resourceDetails;
        }
      } else if (this.$route.query.version) {
        const displayedVersionDetails = this.storeVersionHistory.find(item => item._id === this.$route.query.version);
        const archivedVersion = this.resourceDetails.archive.find(item => item.record.version === displayedVersionDetails?.versionNum);
        if (displayedVersionDetails !== undefined && archivedVersion !== undefined) {
          displayedVersion = archivedVersion;
          this.hideEditToggle = true;
        } else {
          let query = Object.assign({}, this.$route.query);
          delete query.version;
          this.$router.replace({query}).catch(() => {
          });
          displayedVersion = this.resourceDetails;
          this.hideEditToggle = false;
        }
      } else {
        displayedVersion = this.resourceDetails;
        this.hideEditToggle = false;
      }

      //update local version
      this.document = clone(displayedVersion.document);

      if (displayedVersion.links) {
        this.link = displayedVersion.links.path ?? '';
      }

      this.misc = displayedVersion.misc;

      // if the resource is a file
      if (displayedVersion.files && displayedVersion.files[0]) {
        this.fileName = displayedVersion.files[0].FileName;
      }
    },
    handleDiscardDraft() {
      this.deleteDraftDialog = true;
    },
    async handleAction(actionId) {
      switch (actionId) {
        case 'inviteCollaborators':
          this.dialogCollaborators = true;
          break;
        case 'shareCard':
          if (!this.copyrightCompleted) {
            this.$eventHub.$emit('show:copyright', 'shareCard');
          } else {
            this.dialogShare = true;
          }
          break;
        case 'copyCard':
          await this.handleCopyResource();
          break;
        case 'versionHistory':
          this.versionHistory = await this.$store.dispatch('myResources/getVersionHistory');
          this.versionHistoryDrawer = true;
          break;
        case 'info':
          this.informationDrawer = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    },
    trackChanges() {
      if(this.document) {
        const editedFields = Object.keys(this.document).filter(field => !isEqual(this.document[field], this.resourceDetails?.document[field]));
        const modifiedContent = editedFields.map(f => {
          switch (f) {
            case 'resourceImage':
              return 'image';
            case 'resourceType':
              return 'resource type';
            case 'resourceFormat':
              return 'format';
            case 'name':
              return 'resource title';
            case 'stage':
              return 'stages';
            case 'year':
              return 'years';
            case 'learningArea':
              return 'learning areas';
            case 'outcomes':
              return 'syllabus outcome';
            case 'progressions':
              return 'progression';
            case 'professionalLearningLink':
              return 'professional learning';
            case 'rights':
              return 'copyright statement';
            default:
              return f;
          }
        });
        return [...new Set(modifiedContent)];
      }
    },
    async updateCopyright(payload) {
      const updatedResource = await this.$store.dispatch('myResources/updateResource', payload);
      if (updatedResource) {
        this.$eventHub.$emit('show:dialog');
      }
    },
    async handleSave(isDraft) {
      this.loading = true;
      const modifiedContent = clone(this.modifiedContent);
      if (this.isNewResource) {
        let document = clone(this.document);
        const itemID = uuid.v4()

        // insert draft field on document whether it's being saved as draft, or remove it if it's the user saving it
        if (isDraft) {
          document = {
            ...this.document,
            draft: true
          }
        } else if (document.draft) {
          delete document.draft;
        }

        let requestPayload = {
          document: {
            ...document,
            DateCreated: new Date().toISOString().substring(0, 19), // remove micro seconds
            DateModified: new Date().toISOString().substring(0, 19),
            DateResCreated: new Date().toISOString().substring(0, 19),
            ResourceURL: this.link,
            ItemID: itemID
          }
        }

        if (this.link) {
          requestPayload.links = {path: this.link};
        }
        if (this.file) {
          requestPayload.files = [this.file];
        }
        let payload = {
          requestPayload,
          displayAlert: !isDraft
        }

        const createdResource = await this.$store.dispatch('myResources/createResource', payload);

        if (createdResource) {
          // after creating the resource, replace the local state with the structure returned from the backend
          this.document = createdResource.document;

          // handle link
          this.link = createdResource.links?.path ?? '';

          // handle file
          this.fileName = createdResource.files[0]?.FileName;
          this.file = null;

          // update the route with the id and turn edit mode off
          const modifiedRoute = Object.assign({}, this.$route);
          modifiedRoute.params.id = createdResource.record.id;
          this.$router.replace(modifiedRoute).catch(() => {
          });

          await this.$store.dispatch('myResources/sendChanges', {changes: modifiedContent, isDraft: isDraft});
        }
      } else {
        let newDocument = clone(this.document);
        if (isDraft) {
          newDocument = {
            ...this.document,
            ResourceURL: this.link,
            draft: true
          }
        } else if (newDocument.draft) {
          delete newDocument.draft;
        }
        let requestPayload = {
          newDocument: newDocument,
        }
        if (this.link) {
          requestPayload.newLinks = {path: this.link};
        }
        if (this.file) {
          requestPayload.newFiles = [this.file];
        }

        let payload = {
          requestPayload,
          displayAlert: !isDraft
        }

        const updatedResource = await this.$store.dispatch('myResources/updateResource', payload);
        if (updatedResource) {
          // after updating the resource, replace the local state with the structure returned from the backend
          this.document = updatedResource.document;

          // handle link
          this.link = updatedResource.links.path ?? '';

          // handle file
          this.fileName = updatedResource.files[0]?.FileName;
          this.file = null;

          // update the route with the id and turn edit mode off
          const modifiedRoute = Object.assign({}, this.$route);
          modifiedRoute.params.id = updatedResource.record.id;
          modifiedRoute.params.savePosition = 'true';
          this.$router.replace(modifiedRoute).catch(() => {
          });

          if (isDraft) {
            if (!this.isDraft) {
              await this.$store.dispatch('myResources/sendChanges', {changes: modifiedContent, isDraft: true});
            } else {
              await this.$store.dispatch('myResources/editChanges', {changes: modifiedContent, isDraft: true});
            }
          } else {
            if (!this.isDraft) {
              await this.$store.dispatch('myResources/sendChanges', {changes: modifiedContent, isDraft: false});
            } else {
              await this.$store.dispatch('myResources/editChanges', {changes: modifiedContent, isDraft: false});
            }
          }
        }
      }
      if (!isDraft) {
        this.editMode = false;
        if (this.$route.query.draft) {
          // update the route with query
          let query = Object.assign({}, this.$route.query);
          delete query.draft;
          this.$router.replace({query}).catch(() => {
          });
        }
      } else {
        // update the route with query
        let query = Object.assign({}, this.$route.query);
        query.draft = 'true';
        this.$router.replace({query}).catch(() => {
        });
      }
      this.loading = false;
    },
    async handleCopyResource() {
      const copiedResource = await this.$store.dispatch('myResources/copyResource', {action: 'copy'});
      if (copiedResource && copiedResource.document) {
        // after creating the resource, replace the local data with the data returned from the api
        this.document = copiedResource.document;

        // handle file
        if (copiedResource.files) {
          this.fileName = copiedResource.files[0]?.FileName;
        }

        // update the route with the id and turn edit mode off
        const modifiedRoute = Object.assign({}, this.$route);
        modifiedRoute.params.id = copiedResource.record.id;
        modifiedRoute.query = {};
        this.$router.replace(modifiedRoute).catch(() => {
        });
        this.editMode = true;
      }
    },
    async revertToPreviousVersion() {
      this.processing = true;
      const previousVersion = this.versionHistory.find(item => item._id === this.$route.query.version);
      await this.$store.dispatch('myResources/revertToPreviousVersion', previousVersion.versionNum);
      // update the route with the id and turn edit mode off
      const modifiedRoute = Object.assign({}, this.$route);
      modifiedRoute.query = {};
      this.$router.replace(modifiedRoute).catch(() => {
      });
      this.processing = false;
    },
    async copyPreviousVersion(id) {
      this.processing = true;
      id = this.$route.query.version ? this.$route.query.version : id;
      const previousVersion = this.storeVersionHistory.find(item => item._id === id);
      const copiedVersion = await this.$store.dispatch('myResources/copyResource', {
        action: 'copyversion',
        version: previousVersion.versionNum
      });
      this.versionHistoryDrawer = false;
      if (copiedVersion && copiedVersion.document) {
        this.document = copiedVersion.document;

        // handle file
        if (copiedVersion.files) {
          this.fileName = copiedVersion.files[0]?.FileName;
        }

        // update the route with the id and turn edit mode off
        const modifiedRoute = Object.assign({}, this.$route);
        modifiedRoute.params.id = copiedVersion.record.id;
        modifiedRoute.query = {};
        this.$router.replace(modifiedRoute).catch(() => {
        });
        this.editMode = true;
        this.processing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.create-new-resource__wrapper {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $ads-light-10;

  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .content__wrapper {
    max-width: 1240px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 12px;

      .right-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 56px;

        .v-input--switch {
          margin-right: 16px;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }

    .content__container {
      margin: 12px 0 32px 0;
      padding: 48px 0;
      display: flex;
      justify-content: center;
    }
  }
}

::v-deep.theme--light {
  .v-btn {
    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }
}

.appbar-title {
  padding-right: 12px;
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 960px) {
  .create-new-resource__wrapper {
    .content__wrapper {
      .content__container {
        margin: 24px 0;
        padding: 24px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .create-new-resource__wrapper {
    .content__wrapper {
      padding: 0;

      .top {
        margin: 8px;
      }

      .content__container {
        margin: 0;
        padding: 12px;
        display: flex;
        justify-content: center;
        border-radius: 0;
      }
    }
  }
}
</style>
