<template>
  <Dialog
    v-bind="$attrs"
    persistent
    hide-heading
    :fullscreen="$vuetify.breakpoint.width < 740"
    :max-width="$vuetify.breakpoint.mdAndUp ? 800 : '100%'"
    :hide-close-button="$vuetify.breakpoint.width < 740"
    v-on="$listeners"
  >
    <template #content>
      <AppBar
        v-if="$vuetify.breakpoint.width < 740"
        show-back-button
        :color="ADS_Colors.Navy"
      >
        <template #backButton>
          <v-btn
            icon
            class="ml-3 mr-2"
            aria-label="close"
            @click="showOnboardingDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template #title>
          <h1 class="appbar-title">
            {{ pageTitle }}
          </h1>
        </template>
      </AppBar>
      <v-card-text
        :class="{
          'px-0 pt-6 pb-0': $vuetify.breakpoint.width >= 740,
          'pa-0': $vuetify.breakpoint.width < 740
        }"
      >
        <v-carousel
          v-model="carousel"
          class="onboarding-carousel"
          :show-arrows="false"
          light
          :height="$vuetify.breakpoint.width >= 740 ? '70%' : 'calc(100vh - 64px)'"
          touchless
        >
          <v-carousel-item>
            <Initial />
          </v-carousel-item>
          <v-carousel-item>
            <Categories v-model="selectedCategories" />
          </v-carousel-item>
          <v-carousel-item v-if="displayedSteps.includes('teachingAndLearning') && $vuetify.breakpoint.width >= 740">
            <TeachingAndLearning
              v-model="selectedTeachingAndLearning"
              :carousel="carousel"
            />
          </v-carousel-item>
          <v-carousel-item v-if="displayedSteps.includes('teachingAndLearning') && $vuetify.breakpoint.width < 740">
            <TeachingAndLearning
              v-model="selectedTeachingAndLearning"
              :carousel="carousel"
            />
          </v-carousel-item>
          <v-carousel-item v-if="displayedSteps.includes('teachingAndLearning') && $vuetify.breakpoint.width < 740">
            <TeachingAndLearning
              v-model="selectedTeachingAndLearning"
              :carousel="carousel"
            />
          </v-carousel-item>
          <v-carousel-item v-if="displayedSteps.includes('schoolImprovement')">
            <SchoolImprovement v-model="selectedSchoolImprovement" />
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <v-card-actions :class="{'onboarding-footer': true, 'not-first-step': carousel > 0}">
        <AdsButton
          v-if="carousel > 0"
          tertiary
          icon="mdi-arrow-left"
          button-text="Previous"
          class="mr-2"
          @click="carousel--"
          @keyup.enter="carousel--"
        />
        <AdsButton
          v-if="carousel < lastStep || carousel === 1"
          :disabled="carousel === 1 && !selectedCategories.length"
          icon="mdi-arrow-right"
          button-text="Next"
          class="preferences-next-btn"
          :class="{'ml-2': carousel > 0}"
          @click="carousel++"
          @keyup.enter="carousel++"
        />
        <AdsButton
          v-if="carousel === lastStep && carousel !== 1"
          button-text="Complete"
          class="ml-2"
          @click="submitPreferences"
          @keyup.enter="submitPreferences"
        />
        <v-checkbox
          v-if="carousel === 0"
          v-model="dontShowAgain"
          class="checkbox"
          hide-details
          dense
        >
          <template #label>
            Don't show again
          </template>
        </v-checkbox>
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import {AdsButton, AppBar, ADS_Colors} from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog';
import Initial from '@/components/Dialogs/OnboardingDialog/Initial';
import Categories from '@/components/Dialogs/OnboardingDialog/Categories/Categories';
import TeachingAndLearning from '@/components/Dialogs/OnboardingDialog/TeachingAndLearning';
import SchoolImprovement from '@/components/Dialogs/OnboardingDialog/SchoolImprovement';
import {mapGetters} from 'vuex';

export default {
  name: 'OnboardingDialog',
  components: {
    Dialog,
    Initial,
    Categories,
    TeachingAndLearning,
    SchoolImprovement,
    AdsButton,
    AppBar
  },
  data() {
    return {
      ADS_Colors,
      carousel: 0,
      dontShowAgain: false,
      selectedCategories: [],
      displayedSteps: [],
      selectedTeachingAndLearning: {
        learningAreas: [],
        years: []
      },
      selectedSchoolImprovement: []
    }
  },
  mounted(){
    /**
     * issue could be deeply nested checkbox within the dialog,
     * but other than that, focus is working fine.
     */
    const dialog = document.querySelector('.v-dialog')
    const checkbox = dialog?.querySelector('input')

    dialog?.addEventListener('transitionend', ()=>{
      checkbox?.focus();
    })
  },
  computed: {
    ...mapGetters({
      metadataKlas: 'metadata/klas',
      metadataYears: 'metadata/years',
      preferences: 'users/preferences',
    }),
    showOnboardingDialog: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value);
      }
    },
    pageTitle() {
      switch (this.carousel) {
        case 0:
          return 'Welcome';
        case 1:
          return 'Resource category';
        case 2:
          if (this.displayedSteps.includes('teachingAndLearning')) {
            return 'Teaching and learning';
          } else {
            return 'School improvement';
          }
        case 3:
          if (this.displayedSteps.includes('teachingAndLearning')) {
            return 'Teaching and learning';
          } else {
            return 'School improvement';
          }
        case 4:
          return 'School improvement';
        default:
          return '';
      }
    },
    lastStep() {
      let count = 1;
      if (this.$vuetify.breakpoint.width < 740 && this.selectedCategories.includes('teachingAndLearning')) {
        count += 2;
      }
      if (this.$vuetify.breakpoint.width >= 740 && this.selectedCategories.includes('teachingAndLearning')) {
        count += 1;
      }
      if (this.selectedCategories.includes('schoolImprovement')) {
        count += 1;
      }
      return count;
    }
  },
  watch: {
    showOnboardingDialog(val) {
      // set focus inside the modal when shown
      if (val) {
        this.focusOnFirstInput();
      }

      // if false (not to show the dialog) + dontShowAgain, clear the preferences and send
      if (!val && this.dontShowAgain) {
        this.selectedTeachingAndLearning = {
          learningAreas: [],
          years: []
        };
        this.selectedSchoolImprovement = [];
        this.submitPreferences();
      }
    },
    selectedCategories: {
      immediate: true,
      handler(val) {
        /**
         * v-carousel would change the steps order depending on the order the boxes were selected,
         * the timeout here allows time to clean up the array and restart it with the correct order
         * */
        this.displayedSteps = [];
        setTimeout(() => {
          if (val.length === 2) {
            this.displayedSteps = ['teachingAndLearning', 'schoolImprovement'];
          } else {
            this.displayedSteps = val;
          }
        }, 0);
      }
    },
    preferences: {
      immediate: true,
      handler(val) {
        if (val) {
          this.selectedTeachingAndLearning.years = val.years ? val.years : [];
          this.selectedTeachingAndLearning.learningAreas = val.learningAreas ? val.learningAreas : [];
        }
      }
    },
    metadataKlas(val) {
      if (val.length && !this.preferences) {
        this.selectedTeachingAndLearning.learningAreas = val.map(kla => kla.id);
      }
    },
    metadataYears(val) {
      if (val.length && !this.preferences) {
        this.selectedTeachingAndLearning.years = val.map(y => y.id);
      }
    },
    carousel() {
      // watch active carousel element to set focus inside the carousel content
      this.focusOnFirstInput();
    }
  },
  methods: {
    async submitPreferences() {
      const preferences = {
        years: this.selectedTeachingAndLearning.years,
        learningAreas: this.selectedTeachingAndLearning.learningAreas,
        schoolImprovementSources: this.selectedSchoolImprovement
      };

      if (!this.displayedSteps.includes('teachingAndLearning')) {
        preferences.learningAreas = [];
        preferences.years = [];
      }
      if (!this.displayedSteps.includes('schoolImprovement')) {
        preferences.schoolImprovementSources = [];
      }

      // calculate resourceCategory from preferences and call the preference api
      let resourceCategory = [];
      if (preferences.years.length > 0 || preferences.learningAreas.length > 0) {
        resourceCategory.push('tlr');
      }
      if (preferences.schoolImprovementSources.length > 0) {
        resourceCategory.push('sir');
      }

      if (!this.preferences) {
        await this.$store.dispatch('users/sendPreferences', {...preferences, resourceCategory});
      } else {
        await this.$store.dispatch('users/updatePreferences', {...preferences, resourceCategory});
      }

      // close the dialog
      if (this.showOnboardingDialog) {
        this.showOnboardingDialog = false;
      }
    },
    focusElement(selector, index, ) {
      // Need a timeout to allow the carousel events to be completed
      // Based on current vuetify carousel implementation
      // all .parent-selector elements are present in dom
      // hence we have to do parse the correct index of the element
      setTimeout(function() {
        document.querySelectorAll(selector)[index].focus();
      }, 200);
    },
    focusOnFirstInput () {
      switch(this.carousel) {
        case 0:
          this.focusElement('.preferences-next-btn',0);
          break;
        case 1:
          this.focusElement('.selectable-card__container',0);
          break;
        case 2:
          this.focusElement('.parent-selector input',0);
          break;
        case 3:
          this.focusElement('.parent-selector input',2);
          break;
        default:
        // do nothing
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

::v-deep.onboarding-carousel {
  min-height: 600px;
  max-height: 600px;


  .v-window__container {
    max-height: inherit;

    .v-window-item {
      max-height: inherit;

      .v-image {
        max-height: inherit;

        .v-responsive__content {
          max-height: inherit;
        }
      }
    }
  }

  .body-1 {
    letter-spacing: 0 !important;
  }

  .v-carousel__controls {
    padding-top: 20px;
    background: white;

    .v-carousel__controls__item {
      margin: 0 !important;

      .v-icon {
        font-size: 12px !important;
      }
    }
  }
}

.onboarding-footer {
  padding: 24px;
  display: flex;
  justify-content: center;
  position: relative;
  background: transparent;

  ::v-deep.checkbox {
    position: absolute;
    right: 24px;

    .v-label {
      color: $ads-dark;
    }

    &.v-input--is-focused {
      .v-input--selection-controls__ripple {
        &:before {
          border: 2px solid $ads-navy;
          background-color: rgba(0, 0, 0, 0.2);
          opacity: 1;
        }
      }
    }
  }

  .v-btn {
    min-width: 120px;
  }
}

@media only screen and (max-width: 740px) {
  .appbar-title {
    padding-right: 12px;
    font-size: 1.25rem;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::v-deep.onboarding-carousel {
    min-height: 300px;
    max-height: none;

    .v-carousel__controls {
      border-top: 1px solid $ads-light-20;
      background: white;
      padding-top: 0;
    }
  }

  .onboarding-footer {
    padding: 0 12px 12px 12px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    justify-content: flex-end;
    background-color: white;

    ::v-deep.checkbox {
      left: 12px;
      width: 50%;

      .v-label {
        font-size: 14px;
      }
    }

    &.not-first-step {
      .v-btn {
        flex: 1;
      }
    }
  }
}
</style>
