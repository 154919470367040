<template>
  <span>
    <v-row
      v-if="title"
      class="mt-2"
    >
      <v-col
        cols="12"
        class="pa-0"
      >
        <h5 class="syllabus-item__title">
          {{ title }}
        </h5>
      </v-col>
    </v-row>
    <template v-if="items && items.length > 0">
      <span v-if="displayInline">
        <v-chip
          v-for="(item, i) of items"
          :key="i"
          :close="isAllowedToClear"
          color="primary"
          class="mr-2 mb-2"
          outlined
          @click:close="handleClear(item)"
        >
          {{ item.level }}
          <template v-if="version">
            ({{ version }})
          </template>
        </v-chip>
      </span>
      <v-row
        v-else
        class="ml-n6"
      >
        <v-col
          cols="12"
          class="pa-0"
        >
          <v-row
            class="chips__row"
          >
            <v-col
              cols="auto"
            >
              <v-chip
                v-for="(item, i) of items"
                :key="i"
                :close="isAllowedToClear"
                color="primary"
                class="mr-2 mb-3"
                outlined
                @click:close="handleClear(item)"
              >
                {{ item.level }}
                <template v-if="version">
                  ({{ version }})
                </template>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <p
      v-else-if="!displayInline"
      class="mt-4"
    >
      None selected
    </p>
  </span>
</template>
<script>
export default {
  name: 'ProgressionItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    isAllowedToClear: {
      type: Boolean,
      default: true
    },
    version: {
      type: String,
      default: ''
    },
    displayInline: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClear: function (item) {
      const progressions = this.items.filter(i => i.level !== item.level);
      // This will be handled in the Curriculum.vue
      console.log('new progressions', progressions);
      this.$emit('confirmProgressions', {
        literacy: progressions.filter(progression => progression.type === 'literacy' ),
        numeracy: progressions.filter(progression => progression.type === 'numeracy' )
      })
    }
  }
}

</script>
<style lang="scss" scoped>
@import "@/scss/variables";

.syllabus-item__title {
  font-size: 16px;
  color: $ads-navy;
  font-weight: bold;
  margin: 4px 0;
}

.chips__row {
  margin: 0 0;
  min-height: 56px;

  .chip__col {
    padding-left: 0;
    padding-top: 4px;
  }
}

.paragraph {
  margin-bottom: 0;
  min-height: 56px;
}
</style>
