<template>
  <AdsDrawer
    v-bind="$attrs"
    v-model="versionHistoryDrawer"
    heading="Version history"
    icon="mdi-history"
    v-on="$listeners"
  >
    <template #content>
      <v-list class="py-0">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          class="pa-0"
        >
          <v-list-item-content>
            <div class="actions-menu">
              <ActionsMenu
                class="actions-menu"
                icon
                bottom
                offset-x
                :nudge-bottom="12"
                :nudge-right="24"
                left
                :items="getMoreActionsItemsEditor(item.versionNum)"
                :external-data="item._id"
                @clickedAction="handleAction"
              />
            </div>
            <a
              :class="{'version-link': true, '--active': paramVersion ? item._id === paramVersion : i === 0 }"
              @click="changeVersion(item._id)"
              @keyup.enter="changeVersion(item._id)"
            >
              <h2 class="version-title">
                Version {{ items.length - i }}.0
                <span
                  v-if="item.versionNum === currentVersion"
                  class="version-title__label"
                >
                  {{ item.isDraft ? '(draft)' : '(current)' }}
                </span>
              </h2>
              <p v-if="item.change.length > 0">
                <strong>{{ $store.state.uid === item.userId ? 'You' : item.userName }}</strong>
                {{ formatChanges(item.change) }}.
              </p>
              <p v-else>
                <strong>{{ $store.state.uid === item.userId ? 'You' : item.userName }}</strong>
                created the resource.
              </p>
              <span class="date">{{ formatDate(item.updatedAt) }}</span>
            </a>
            <v-divider />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </AdsDrawer>
</template>

<script>
import {AdsDrawer} from '@nswdoe/doe-ui-core';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu';
import moment from 'moment';
import {mapState} from 'vuex';

export default {
  name: 'VersionHistoryDrawer',
  components: {
    AdsDrawer,
    ActionsMenu
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      versionClicked: null,
      moment,
      moreActionsItemsEditor: [
        {
          id: 'newTab',
          name: 'Open in new tab',
          tag: 'a',
          icon: 'mdi-open-in-new'
        },
        {
          id: 'useVersion',
          name: 'Use this version',
          tag: 'button',
          icon: 'mdi-undo'
        }
      ],
      moreActionsItemsViewer: [
        {
          id: 'newTab',
          name: 'Open in new tab',
          tag: 'a',
          icon: 'mdi-open-in-new'
        },
        {
          id: 'copyVersion',
          name: 'Copy this version',
          tag: 'button',
          icon: 'mdi-content-copy'
        }
      ],
      paramVersion: null
    }
  },
  computed: {
    ...mapState({
      storeMyResourceDetails: state => state.myResources.resourceDetails
    }),
    versionHistoryDrawer: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value);
      }
    },
    currentVersion() {
      return this.storeMyResourceDetails.record.version;
    },
    isEditor() {
      const loggedUser = this.$store.getters['users/userProfile'].userId;
      return !this.isNewResource && (this.storeMyResourceDetails?.userAccess?.owner.includes(loggedUser) || this.storeMyResourceDetails?.userAccess?.editor.includes(loggedUser));
    }
  },
  watch: {
    versionHistoryDrawer(val) {
      if (val) { // true, open the drawer
        const versionIds = this.items.map(i => i._id);
        const paramVersion = this.$route.query.version;
        if (versionIds.includes(paramVersion)) {
          this.paramVersion = paramVersion;
        } else {
          this.paramVersion = null;
        }
      }
    }
  },
  methods: {
    changeVersion(versionId) {
      if (this.items.findIndex(i => i._id === versionId) === 0) {
        if (!this.$route.query.version) {
          this.versionHistoryDrawer = false;
        } else {
          let query = Object.assign({}, this.$route.query);
          delete query.version;
          this.$router.replace({query}).catch(() => {
          });
        }
      } else {
        let query = Object.assign({}, this.$route.query);
        query.version = versionId;
        this.$router.replace({query}).catch(() => {
        });
      }
    },
    formatChanges(arr) {
      if (arr.length === 1) {
        if (arr[0].includes('copying') || arr[0].includes('version')) {
          return arr[0];
        }
        return ' updated ' + arr[0];
      }
      const lastItem = 'and ' + arr[arr.length - 1];
      if (arr.length === 2) {
        return ' updated ' + arr[0] + ' ' + lastItem;
      }
      if (arr.length > 2 && arr.length <= 5) {
        let allButLast = arr.slice(0, arr.length - 1);
        allButLast.push(lastItem);
        return ' updated ' + allButLast.join(', ');
      }
      if (arr.length > 5) {
        const lastWords = 'and more';
        let firstFive = arr.slice(0, 5);
        firstFive.push(lastWords);
        return ' updated ' + firstFive.join(', ');
      }
    },
    formatDate(str) {
      return this.moment(str).format('D MMM YYYY, h:mm A');
    },
    getMoreActionsItemsEditor(version) {
      let items = [
        {
          id: 'newTab',
          name: 'Open in new tab',
          tag: 'a',
          icon: 'mdi-open-in-new'
        }
      ]
      if (this.isEditor && version !== this.currentVersion) {
        items.push({
          id: 'useVersion',
          name: 'Use this version',
          tag: 'button',
          icon: 'mdi-undo'
        });
      }
      if (!this.isEditor) {
        items.push({
          id: 'copyVersion',
          name: 'Copy this version',
          tag: 'button',
          icon: 'mdi-content-copy'
        })
      }
      return items;
    },
    handleAction({externalData, action}) {
      const resourceId = this.$store.state.myResources.resourceDetails.record.id;
      let routeData;
      switch (action) {
        case 'newTab':
          routeData = this.$router.resolve({name: 'MyResourceDetailsPage', params: {id: resourceId}, query: {version: externalData}});
          window.open(routeData.href, '_blank');
          break;
        case 'useVersion':
          this.revertToPreviousVersion(externalData);
          break;
        case 'copyVersion':
          this.$emit('copy:version', externalData);
          break;
      }
    },
    async revertToPreviousVersion(version) {
      const previousVersion = this.items.find(item => item._id === version);
      await this.$store.dispatch('myResources/revertToPreviousVersion', previousVersion.versionNum);
      const modifiedRoute = Object.assign({}, this.$route);
      modifiedRoute.query = {};
      this.$router.replace(modifiedRoute).catch(() => {
      });
      this.versionHistoryDrawer = false;

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-list-item__content {
  padding: 0;
  position: relative;

  .actions-menu {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 12px;
    top: 12px;
  }

  .version-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 2px solid transparent;
    padding: 24px 12px;
    margin-bottom: 4px;
    margin-right: 4px;
    border-radius: 12px;
    box-sizing: border-box;
    height: 160px;

    &:focus-visible {
      border: 2px solid $ads-navy;
      outline: none;
    }

    &.--active {
      background-color: $ads-custom-navy-2;
    }

    .version-title {
      margin-bottom: 12px;
      font-size: 16px;
      color: $ads-blue-1;

      .version-title__label {
        font-weight: normal;
        color: $ads-dark-80;
      }
    }

    p {
      color: $ads-dark;
      line-height: 1.3rem;
      font-size: 16px;
      min-height: 63px;
    }

    .date {
      color: $ads-dark-70;
    }
  }
}

::v-deep.v-navigation-drawer {
  .v-list {
    .v-list-item {
      min-height: 36px !important;
    }
  }
}
</style>
