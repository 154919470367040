<template>
  <div class="side-column__wrapper" >
    <h2 class="heading ads-navy" >
      {{ heading }}
    </h2>

    <v-row v-if="$vuetify.breakpoint.width > 960">
      <v-col
        v-for="(item, index) in resources"
        :key="index"
        class="card__wrapper"
      >
        <ResourceCollectionsCard
          v-if="isCollection(item)"
          :item="item"
          :size="cardWidth"
          :height="cardHeight"
        />
        <ResourceCard
          v-else
          :item="item"
          :size="cardWidth"
          :height="cardHeight"
        />
      </v-col>
    </v-row>

    <v-row v-else-if="$vuetify.breakpoint.width <= 960 && $vuetify.breakpoint.width > 450">
      <v-slide-group
        show-arrows
      >
        <v-slide-item
          v-for="(item, index) in resources"
          :key="index"
          class="pa-3"
        >
          <ResourceCollectionsCard
            v-if="isCollection(item)"
            :item="item"
            :size="cardWidth"
            :height="cardHeight"
          />
          <ResourceCard
            v-else
            :item="item"
            :size="cardWidth"
            :height="cardHeight"
          />
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-row
      v-else
      class="carousel__wrapper"
    >
      <v-carousel
        :show-arrows="false"
        light
        hide-delimiter-background
        :height="$vuetify.breakpoint.width + 48"
      >
        <v-carousel-item
          v-for="(item, index) in resources"
          :key="index"
        >
          <div class="d-flex justify-center">
            <ResourceCollectionsCard
              v-if="isCollection(item)"
              :item="item"
              :size="cardWidth"
              :height="cardHeight"
            />
            <ResourceCard
              v-else
              :item="item.document"
              :files="item.files"
              :size="$vuetify.breakpoint.width"
              :height="cardHeight"
              :rounded="false"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </div>
</template>

<script>
import ResourceCard from '../HubResourcesPage/ResourceCard'
import ResourceCollectionsCard from '@/components/Collection/CollectionCard.vue';

export default {
  name: 'OtherResources',
  components: {
    ResourceCollectionsCard,
    ResourceCard
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    cardWidth: {
      type: [Number, String],
      default: 350
    },
    cardHeight: {
      type: [Number, String],
      default: 380
    },
    resources: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isCollection(r) {
      return r?.record?.isResourceCollection;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.side-column__wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .heading {
    color: $ads-blue-1;
    font-size: 20px;
    padding-bottom: 12px;
  }

  .row {
    margin: 0;
    padding: 0;

    .card__wrapper {
      padding: 12px 0;
    }
  }
}

@media only screen and (max-width: 960px) {
  .side-column__wrapper {
    padding-left: 0;
    padding-right: 0;

    .heading {
      padding-right: 24px;
      padding-left: 24px;
    }

    .row {
      .v-slide-group {
        position: relative;

        &.v-item-group {
          ::v-deep.v-slide-group__prev {
            position: absolute;
            top: 45%;
            left: 12px;
            z-index: 1;
          }

          ::v-deep.v-slide-group__wrapper {
            .v-slide-group__content {
              padding-left: 36px;
              padding-right: 60px;
            }
          }

          ::v-deep.v-slide-group__next {
            position: absolute;
            top: 45%;
            right: 12px;
          }

          ::v-deep.v-slide-group__prev, ::v-deep.v-slide-group__next {
            .v-icon {
              background-color: $ads-navy;
              border-radius: 50%;
              padding: 12px;
              color: $ads-white !important;
              box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
            }
          }

          ::v-deep.v-slide-group__next--disabled, ::v-deep.v-slide-group__prev--disabled {
            display: none !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 669px) {
  .side-column__wrapper {
    padding-bottom: 12px;

    .heading {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}
</style>
