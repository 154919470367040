<template>
  <HorizontalScrollablePage @get:negativeMargin="getNegativeMargin">
    <template #scrollableContent>
      <ScrollingCardsView
        row="createdbyme"
        heading="Created by me"
        :count="$store.getters['myResources/createdByMeCount']"
        :resources="$store.getters['myResources/resourcesCreatedByMe'].slice(0, 8)"
        :loading="loading"
        :negativeMargin="negativeMargin"
        :displayCreateButton="$vuetify.breakpoint.smAndUp"
        :displaySeeAll="$store.getters['myResources/createdByMeCount'] > 8"
        @see:all="handleSeeAll"
      />
      <ScrollingCardsView
        v-if="$store.getters['myResources/sharedWithMeCount']"
        row="sharedwithme"
        heading="Shared with me"
        :count="$store.getters['myResources/sharedWithMeCount']"
        :resources="$store.getters['myResources/resourcesSharedWithMe'].slice(0, 8)"
        :loading="loading"
        :negativeMargin="negativeMargin"
        :displaySeeAll="$store.getters['myResources/sharedWithMeCount'] > 8"
        @see:all="handleSeeAll"
      />
      <ScrollingCardsView
        v-if="$store.getters['myResources/sharedByMeCount']"
        row="sharedbyme"
        heading="Shared by me"
        :count="$store.getters['myResources/sharedByMeCount']"
        :resources="$store.getters['myResources/resourcesSharedByMe'].slice(0, 8)"
        :loading="loading"
        :negativeMargin="negativeMargin"
        :displaySeeAll="$store.getters['myResources/sharedByMeCount'] > 8"
        @see:all="handleSeeAll"
      />
    </template>
  </HorizontalScrollablePage>
</template>

<script>
import ScrollingCardsView from '@/views/MyResourcesPage/ScrollingCardsView';
import HorizontalScrollablePage from '@/components/HorizontalScrollablePage';

export default {
  name: 'LandingView',
  components: {
    HorizontalScrollablePage,
    ScrollingCardsView,
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      negativeMargin: ''
    }
  },
  methods: {
    getNegativeMargin(val) {
      this.negativeMargin = val;
    },
    handleSeeAll(row) {
      const newFilters = {...this.$store.state.myResources.filters, myResourcesLibraries: row};
      this.$store.commit('myResources/SET_FILTERS', newFilters);
      this.$store.commit('myResources/SET_SEARCH_MODE', true);
      this.$store.dispatch('myResources/fetchResources', row);
    }
  }
}
</script>
