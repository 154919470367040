<template>
  <v-infinite-scroll
    :loading="loading"
    :offset="20"
    class="collection-scroll-bar"
    @bottom="loadMoreMyCollections"
  >
    <v-row
      v-if="gridView || $vuetify.breakpoint.xsOnly"
      :class="{'ma-0': $vuetify.breakpoint.xsOnly}"
      align="center"
      justify="start"
    >
      <v-col
        v-for="(item, index) in collections"
        :key="index"
        cols="auto"
        :class="{'px-0': $vuetify.breakpoint.width < 620}"
      >
        <v-responsive
          v-if="$store.state.isLoading && !$store.state.userScroll"
          :min-width="cardSize"
          :min-height="cardSize"
        >
          <v-skeleton-loader
            boilerplate
            type="image, list-item-three-line, actions"
          />
        </v-responsive>
        <ResourceCollectionsCard
          :num="index"
          :item="item"
          :size="cardSize"
          :height="400"
          :rounded="$vuetify.breakpoint.width >= 620"
          v-on="$listeners"
        />
      </v-col>
    </v-row>
  </v-infinite-scroll>
</template>

<script>
import ResourceCollectionsCard from '@/components/Collection/CollectionCard.vue'
import {SEARCH_CONTEXT} from '@/constants';

export default {
  name: 'CollectionsResults',
  components: {
    ResourceCollectionsCard
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    collections: {
      type: Array,
      default: () => []
    },
    metadata: {
      type: Object,
      default: () => {}
    },
    gridView: {
      type: Boolean,
      default: true
    },
    cardSize: {
      type: Number,
      default: 350
    },

  },
  data() {
    return {
      infiniteScrollBusy: false,
      viewportHeight: null,
    }
  },
  methods: {
    loadMoreOnResize() {
      if (!this.viewportHeight) {
        this.viewportHeight = window.innerHeight;
      } else {
        if (this.viewportHeight < window.innerHeight) {
          this.loadMoreMyCollections();
          this.viewportHeight = window.innerHeight;
        }
      }
    },
    async loadMoreMyCollections() {
      console.log('scrolling...')
      console.log('this.metadata.totalPages ' + this.metadata.totalPages)
      console.log('this.metadata.page ' + parseInt(this.metadata.page, 10))
      if (this.metadata.totalPages && parseInt(this.metadata.page, 10) < this.metadata.totalPages) {
        this.infiniteScrollBusy = true;
        await this.$store.commit('myCollections/SET_USER_SCROLL');
        await this.$store.commit('myCollections/INCREASE_PAGE_NUM');
        await this.$store.dispatch('myCollections/fetchCollections')
        this.infiniteScrollBusy = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.v-skeleton-loader {
  height: 100%;

  ::v-deep.v-skeleton-loader__image {
    height: 50% !important;
  }
}
.collection-scroll-bar{
  max-height: 80vh;
  overflow-x: hidden;
  margin-bottom: 50px;
  & {
    scrollbar-color: $ads-light-40!important;
  }
  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    background: $ads-white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $ads-light-40;
    border-radius: 20px;
  }
}
</style>
