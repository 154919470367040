import axios from 'axios';
import qs from 'qs';

/**
 * Create an axios instance and configure THIS instance.
 * If directly import axios and use, settings here won't take effect.
 * Default headers is 'Content-Type': 'application/json'
 */
const requestConfig = {
  // API base URL is configurable and set up in .env.* files
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000,
}
const axiosInstance = axios.create(requestConfig);

/**
 * GET method
 * @param url
 * @param config
 * @returns {Promise}
 */
export function get(url, config = {}) {
  return axiosInstance.get(url, config);
}

/**
 * POST method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export function post(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = data => qs.stringify(data);
  } else { // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.post(url, JSON.stringify(data), config);
}

/**
 * PUT method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export function put(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = data => qs.stringify(data);
  } else { // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.put(url, JSON.stringify(data), config);
}

/**
 * PATCH method
 * @param url
 * @param data
 * @param config
 * @returns {Promise<AxiosResponse<any>>}
 */
export function patch(url, data = {}, config = {}) {
  // for some reason data is null, and this is what is sent to the API.
  // API can't handle this and can't parse the null JSON being sent
  // The error is below:
  /*
  SyntaxError: Unexpected token n in JSON at position 0
    at JSON.parse (<anonymous>)
    at createStrictSyntaxError (C:\Dev\DLRH\elastic-search-service\node_modules\body-parser\lib\types\json.js:158:10)
    at parse (C:\Dev\DLRH\elastic-search-service\node_modules\body-parser\lib\types\json.js:83:15)
    at C:\Dev\DLRH\elastic-search-service\node_modules\body-parser\lib\read.js:121:18
    at invokeCallback (C:\Dev\DLRH\elastic-search-service\node_modules\raw-body\users.ts:224:16)
    at done (C:\Dev\DLRH\elastic-search-service\node_modules\raw-body\users.ts:213:7)
    at IncomingMessage.onEnd (C:\Dev\DLRH\elastic-search-service\node_modules\raw-body\users.ts:273:7)
    at IncomingMessage.emit (events.ts:327:22)
    at endReadableNT (internal/streams/readable.js:1327:12)
    at processTicksAndRejections (internal/process/task_queues.js:80:21)
  */
  // Not sure if we require data at all.
  // Workaround is to instantiate data again below and send it through
  data = {};
  return axiosInstance.patch(url, JSON.stringify(data), config);
}

/**
 * PATCH method
 * @param url
 * @param data
 * @param config
 * @param isForm
 */
export function patchSearch(url, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = data => qs.stringify(data);
  } else { // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.patch(url, JSON.stringify(data), config);
}

/**
 * DELETE method
 * @param url
 * @param data
 * @param isForm
 * @returns {Promise}
 */
export function deleteSearch(url, data, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = data => qs.stringify(data);
  } else { // axios default
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.delete(
    url,
    {
      data: JSON.stringify(data)
    });
}

// Can add JWT token here for each request
/*axiosInstance.interceptors.request.use(
  function () {
  },
  function () {
  }
);*/

// Can check HTTP response code here and jump to different page (e.g. Offline Page)
/*axiosInstance.interceptors.request.use(
  function () {
  },
  function () {
  }
);*/

export default axiosInstance;
















