<template>
  <Dialog
    v-bind="$attrs"
    title="Endorse this resource for publishing"
    subTitle="Do you want to add any comments to be sent to the publisher(s)?"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <div class="share-card__wrapper mt-6">
        <v-card-text>
          <AdsSelect
            v-model="assignee"
            label="Assignee"
            :items="publishers"
            item-text="name"
            item-value="userId"
            return-object
          />
          <AdsTextArea
            v-model="comments"
            label="Comments"
          />
        </v-card-text>
        <v-card-actions class="pa-4 pt-0">
          <v-spacer />
          <CancelButton @click="$downwardBroadcast('close:dialog')" />
          <EndorseButton
            :disabled="!assignee"
            @click="handleEndorse"
          />
        </v-card-actions>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {AdsSelect, AdsTextArea} from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapGetters, mapState} from 'vuex';
import {EDPUSH_ACTION, ELASTIC_STATUS} from '@/constants';
import CancelButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/CancelButton.vue';
import EndorseButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/EndorseButton.vue';

export default {
  name: 'EndorseDialog',
  components: {
    EndorseButton,
    CancelButton,
    Dialog,
    AdsSelect,
    AdsTextArea
  },
  props:{
    hubCollection: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: null
    },
    recordId: {
      type: String,
      default: null
    },
    application: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      assignee: null,
      comments: '',
      reviewSubmitted: false,
    };
  },
  methods: {
    async handleEndorse() {
      // 1. if edit mode, update the resource first
      if (this.editMode) {
        await this.$parent.handleUpdate(undefined, this.assignee.userId, ELASTIC_STATUS.ENDORSED);
        await this.postEdPushAndElastic();
      } else {
        // preview mode
        await this.postEdPushAndElastic();
      }
    },
    async postEdPushAndElastic() {
      const {application, source, recordId} = this;

      // 2. EdPush Post
      const edPushResult = await this.$store.dispatch(
        'editor/declineEndorsePendingResource',
        {
          type: this.assignee.userId === this.userIdFirstApprover ? 'group' : 'user',
          assignee: this.assignee.name,
          assigneeId: this.assignee.userId,
          comments: this.comments,
          action: EDPUSH_ACTION.ENDORSE,
          application,
          source,
          resourceId: recordId,
          name: this.title
        });
      if (edPushResult) {
        this.$downwardBroadcast('close:dialog');

        // 3. Post Elastic for status Update in Elastic
        const elasticResult = await this.$store.dispatch(
          'editor/updateResourceStatus',
          {action: ELASTIC_STATUS.ENDORSED, assigneeId: this.assignee.userId, application, source, recordId}
        );
        if (elasticResult) {
          this.$store.commit('editor/SET_ENDORSE_SUBMITTED', true);
          if (this.hubCollection) {
            this.$emit('onSubmit', elasticResult);
          } else {
            await this.$router.push({name: 'ResourceEditorPage'});
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      publishers: 'editor/publishers',
      userProfile: 'users/userProfile',
      userIdFirstApprover: 'editor/userIdFirstApprover'
    }),
    ...mapState({
      editMode: state => state.editor.editMode
    })
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.share-card__wrapper {
  display: block;
  min-width: 240px;

  .v-divider {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 43.75em) {
    // 700px === 43.75em
    & {
      width: 100%;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  & .v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .v-btn {
    min-height: 2.5rem;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    border-color: $ads-light-40;
    background-color: $ads-white;
    color: $ads-white;

    &:focus {
      border: 2px solid $ads-navy;
      outline: none !important;
    }
  }

  button.v-btn > .v-btn__content {
    justify-content: center;
  }

  .btnText {
    color: white;
  }

  .v-divider {
    border-color: $ads-light-40;
  }
}
</style>
