<template>
  <div class="collection-editor-table-wrapper">
    <div class="mt-5">
      <v-expansion-panels
        v-model="panels"
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            class="pl-4"
            :disabled="showAll"
            :hide-actions="showAll"
          >
            <v-list-item-title
              class="ml-2 "
            >
              <h3>{{ showAll? 'Search results' : type }} ({{ metadata.count }})</h3>
            </v-list-item-title>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mb-1 data-table-wrapper">
            <AdsDataTable
              :page.sync="pagination.page"
              :options.sync="pagination"
              :items-per-page.sync="$store.state.editor.perPage"
              :headers="myCollectionHeaders"
              :items="collections"
              class="hub-collections-table"
              loading-text="Searching... Please wait"
              :group-by="showAll ? 'accessGroup': ''"
              :server-items-length="metadata.count"
              :footer-props="{
                'items-per-page-options': [ 12, 24],
                'page-text': '{0} to {1} out of {2}'
              }"
              @click:row="goToCollection"
            >
              <!--        hide the search-->
              <template #top />
              <template
                v-if="$vuetify.breakpoint.smAndUp"
                #item.Resources="{ item }"
              >
                <div
                  aria-label="no of resources"
                >
                  {{ item.subResources?.length || 0 }}
                </div>
              </template>
              <template
                #group.header="{ group, groupBy, items, isOpen, toggle }"
              >
                <template v-if="showAll">
                  <td
                    :colspan="$vuetify.breakpoint.xsOnly ? '12': '6'"
                    class="text-start pl-6 pr-11"
                    @click="toggle"
                  >
                    <div
                      class="d-flex justify-space-between "
                      role="button"
                      tabindex="0"
                      :aria-expanded="isOpen"
                    >
                      <h4>
                        {{ group }}<span
                          v-if="!showAll"
                          class="font-weight-regular"
                        > ({{ items.length }})</span>
                      </h4>

                      <v-icon small>
                        {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                      </v-icon>
                    </div>
                  </td>
                </template>
              </template>
              <template #item.KLA="{ item }">
                <div aria-label="learning areas">
                  {{ item.KLA && item.KLA.join(', ') }}
                </div>
              </template>
              <template #item.Stages="{ item }">
                <span
                  v-if="$vuetify.breakpoint.smAndDown"
                  aria-label="learning areas"
                >
                  {{ item.KLA && item.KLA.join(', ') }}
                  <span v-if="item.KLA && item.KLA.length > 0 && item.Stages"> | Stage </span>
                </span>
                <span
                  v-if="item.Stages"
                  aria-label="learning stages"
                >{{ getStages(item.Stages).sort().join(', ').replace('Early Stage ','ES').replace(/Stage/g, '').replace('Early', '') }}</span>
              </template>
              <template #item.CreatedBy="{ item }">
                <span
                  aria-label="created by"
                  class="text-bold"
                >{{ item.userAccess.owner[0]?.split('.').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ') }}</span>
              </template>
              <template #item.Members="{ item }">
                <div
                  v-if="item.isMyCollection"
                  aria-label="members"
                  class="d-flex justify-space-between"
                >
                  <AvatarList
                    class="mr-4"
                    :class="{'mt-3': item.members && item.members.length < 1}"
                    :items="item.members"
                    :show-only="3"
                  />
                  <ActionsMenu
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    top
                    offset-y
                    left
                    :items="item.isOwner ? ownerActions : item.isEditor ? editorActions : defaultActions"
                    :externalData="item"
                    @clickedAction="handleAction"
                  />
                </div>
              </template>
            </AdsDataTable>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import {AdsDataTable} from '@nswdoe/doe-ui-core';
import moment from 'moment';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu'
import _isEqual from 'lodash/isEqual'
import AvatarList from '@/components/AvatarList'
import { mapGetters } from 'vuex'

export default {
  name: 'CollectionDataTable',
  components: {
    AvatarList,
    ActionsMenu,
    AdsDataTable
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    metadata: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      moment,
      pagination: {
        page:1,
        itemsPerPage:12,
      },
      panels: [0],
      currentResource: {},
      myCollectionHeaders: [
        {
          text: 'Collection Title',
          value: 'Name',
          width: '300px',
          sortable: false,
          cellClass: 'pl-6',
          class: 'pl-6'
        },
        {
          text: 'Resources',
          value: 'Resources',
          width: '50px',
          sortable: false,
          class: 'resource-data',
          cellClass: 'resource-data-cell'
        },
        {
          text: 'Stages',
          value: 'Stages',
          width: '120px',
          sortable: false
        },
        {
          text: 'Learning Areas',
          value: 'KLA',
          sortable: false
        },
        {
          text: 'Created by',
          value: 'CreatedBy',
          width: '150px',
          sortable: false
        },
        {
          text: '',
          value: 'Members',
          width: '250px',
          sortable: false,
        }
      ],
      ownerActions: [
        {
          id: 'inviteMembers',
          name: 'Invite Members',
          tag: 'a',
          icon: 'group_add'
        },
        {
          id: 'editCollection',
          name: 'Edit Details',
          tag: 'a',
          icon: 'mdi-pencil-outline'
        },
        {
          id: 'copyCollection',
          name: 'Make a copy',
          tag: 'a',
          icon: 'mdi-content-copy'
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'a',
          icon: 'mdi-information-outline'
        },
        {
          id: 'delete',
          name: 'Delete collection for everyone',
          tag: 'a',
          icon: 'mdi-delete-outline',
        }
      ],
      editorActions: [
        {
          id: 'inviteMembers',
          name: 'Invite Members',
          tag: 'a',
          icon: 'group_add'
        },
        {
          id: 'editCollection',
          name: 'Edit Details',
          tag: 'a',
          icon: 'mdi-pencil-outline'
        },
        {
          id: 'copyCollection',
          name: 'Make a copy',
          tag: 'a',
          icon: 'mdi-content-copy'
        },
        {
          id: 'showInfo',
          name: 'Information',
          tag: 'a',
          icon: 'mdi-information-outline'
        },
        {
          id: 'leaveCollection',
          name: 'Leave Collection',
          tag: 'a',
          icon: 'mdi-information-outline',
        }
      ],
      defaultActions: [
        {
          id: 'inviteMembers',
          name: 'Invite Members',
          tag: 'a',
          icon: 'group_add'
        },
        {
          id: 'copyCollection',
          name: 'Make a copy',
          tag: 'a',
          icon: 'mdi-content-copy'
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'a',
          icon: 'mdi-information-outline'
        },
        {
          id: 'leaveCollection',
          name: 'Leave Collection',
          tag: 'a',
          icon: 'mdi-information-outline'
        }
      ],
    }
  },
  watch: {
    pagination: {
      async handler(newVal, oldVal) {
        if (newVal && oldVal && _isEqual(newVal, oldVal)) {
          // don't clear the filters ( back to list view button)
          return
        }
        console.log('state.pageNum', this.$store.state.myCollections.pageNum)
        // await this.$store.commit('myCollections/SET_PAGE_NUM', 1);
        this.$store.dispatch('myCollections/fetchCollections', {
          page: newVal.page,
          perPage: this.pagination.itemsPerPage,
          access: this.seeAllQuery === '' ? this.groupId : this.seeAllQuery // fetch related category in see all mode
        });
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      allStages: 'metadata/stages'
    }),
    seeAllQuery() {
      return this.$store.state.myCollections.queryAction
    },
    page: {
      get() {
        return parseInt(this.metadata.page)
      },
      set(val) {
        console.log('SET current page... ', val);
      }
    },
    showAll () {
      return this.type === 'all'
    },
    groupId() {
      return this.type.toLowerCase().replaceAll(' ', '')
    },
    userId() {
      return this.$store.getters['users/userProfile']?.userId?.toLowerCase() || '';
    },
    collections() {
      return this.items?.map(item => {
        const isOwner = item.userAccess.owner.includes(this.userId)
        const isEditor = item.userAccess.editor.includes(this.userId)
        const scope = item.userAccess.scope === 1
        const isHub = item.record.collection !== 'collections'
        return {
          ...item.document,
          isOwner,
          isEditor,
          isMyCollection: item.record.collection === 'collections',
          document: item.document,
          record: item.record,
          subResources: item.subResources,
          userAccess: item.userAccess,
          accessGroup: isHub ? 'Followed by me' : isOwner? 'Created by me' : isOwner && scope ? 'Shared by me': 'Shared with me',
          members: [ ...item.userAccess.editor, ...item.userAccess.viewer]
        }
      }).sort((a,b)=> (a.accessGroup < b.accessGroup ? -1 : 1))
    }
  },
  methods: {
    getStages(stageIds) {
      return stageIds.map(stage => this.allStages.find(el => el.id === stage)?.name) || []
    },
    goToCollection(collection) {
      console.log('collection details...', collection)
      this.$router.push({name: 'CollectionBoardPage', params: { id: collection.ItemID, source: collection.Source }});
    },
    //@TODO emit to the parent function
    fetchCollections() {
      console.log('fetching new data')
    },
    handleAction({action, externalData}) {
      this.$emit('action', {action, externalData});
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/mixins';
::v-deep .hub-collections-table .v-row-group__header {
  background: none;
  cursor: pointer;

  td {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    @include respond(phone){
      display: block;
      padding: 20px;
    }
  }
}
::v-deep .hub-collections-table {
  th:last-child {
    button {
      display: none;
    }
  }
}

::v-deep .v-data-table__mobile-table-row {
  // style collection name for mobile view
  .v-data-table__mobile-row:nth-of-type(2){
    color: #002664;
    font-size: 16px;
    font-weight: bold;
  }
  // hide no of resources, KLA, Owner columns for mobile view
  .v-data-table__mobile-row:nth-of-type(3){
    display: none;
  }
  .v-data-table__mobile-row:nth-of-type(5){
    display: none;
  }
  .v-data-table__mobile-row:nth-of-type(6){
    display: none;
  }
}
::v-deep .v-data-table__mobile-row {
  .v-data-table__mobile-row__cell {
    flex: 1;

    button.v-btn{
      display: none;
    }
  }
}

::v-deep .data-table-wrapper .v-expansion-panel-content__wrap {
  padding: 0;
  .v-data-table {
    box-shadow: none;
  }
}

</style>
