<template>
  <div class="card--wrapper">
    <v-btn
      v-if="showCheckbox"
      class="btn-select-resource"
      small
      fab
      aria-label="select resource"
      @click="cardClickHandler"
      @keyup.enter="cardClickHandler"
    >
      <v-icon>{{ selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
    </v-btn>
    <router-link
      :id="itemID"
      :to="{
        name: 'HubResourceDetailsPage',
        params: {
          id: itemID,
          collection: 'collections'
        }}"
      tabindex="0"
      class="primary-control"
    >
      <v-card
        v-on="$listeners"
      >
        <v-row class="ma-0">
          <v-col
            cols="4"
            class="image__container"
          >
            <v-img
              :src="imageUrl"
              :lazy-src="resourceDefaultImage"
              :width="350"
              :aspect-ratio="1.85"
              :contain="false"
              :eager="true"
              alt=""
              class="rounded-2"
            >
              <template v-if="showRecentlyAddedBadge">
                <v-chip
                  v-if="isRecentlyAdded"
                  small
                  label
                  class="status-chip"
                >
                  <v-badge
                    dot
                    inline
                    left
                    color="#EE3C48"
                  />
                  Recently Added
                </v-chip>
              </template>

              <v-chip
                v-if="sequenceOrder"
                small
                label
                class="order-chip"
              >
                {{ itemIndex + 1 }} / {{ itemLength }}
              </v-chip>
            </v-img>
          </v-col>
          <v-col>
            <v-card-title class="pb-0 pt-2">
              <span
                class="resourceType"
                :aria-label="'resource type '+ item.ResourceType"
              >
                {{ resourceType || 'Resource' }}
              </span>
            </v-card-title>
            <v-card-title class="pt-0 pb-0 primary-control">
              <h2 class="card-title">
                {{ item.Name || 'No title created' }}
              </h2>
            </v-card-title>
            <v-card-subtitle
              v-if="!hideUpdatedDate"
              class="pr-6 pt-2 pb-1"
            >
              <span>Updated: {{ moment(item.dateAdded).format('DD MMM YYYY') }}</span>
            </v-card-subtitle>
            <v-card-text
              v-if="isTLR"
              class="pt-0 pb-4"
              :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
            >
              <span
                style="font-weight: bold;"
                class="mr-2"
              >Years:</span>
              <span>{{ item.Year.join(', ') }}</span>
              <span
                v-if="!hideLearningAreas"
                style="font-weight: bold;"
                class="ml-9 mr-2"
              >Learning Area:</span>
              <span>{{ item.KLA.join(', ') }}</span>
            </v-card-text>
            <v-card-text
              v-if="isSIR"
              class="pt-0 pb-4"
            >
              <span
                class="font-weight-bold mr-2"
              >Focus Area:</span>
              <span>{{ focusAreas }}</span>
            </v-card-text>
            <v-card-text
              v-if="!hideDescription"
              class="pt-0 pb-0 mb-4"
            >
              <span v-html="item.Description ? item.Description : ''" />
            </v-card-text>
            <v-card-actions
              v-if="!hideAction"
              class="actions-wrapper"
            >
              <div class="mr-3">
                <GoToResource
                  :id="itemID"
                  :item-url="item.ResourceURL"
                />
              </div>
              <div>
                <Dialog
                  v-model="dialogCopyLink"
                  title="Share this link"
                  dark-heading
                  :max-width="$vuetify.breakpoint.smAndUp ? 500 : 360"
                >
                  <template #activator="{ on, attrs}">
                    <AdsButton
                      secondary
                      icon="share"
                      button-text="Share"
                      aria-label="copy content"
                      class="button-icon copy-button"
                      @click.stop="dialogCopyLink = true"
                    />
                  </template>
                  <template #content>
                    <CopyClipboardMenu
                      :is-open="dialogCopyLink"
                      description="Copy and paste this link:"
                      :text-to-copy="item.ResourceURL"
                    />
                  </template>
                </Dialog>
              </div>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </router-link>
  </div>
</template>

<script>
import moment, { defaultFormat } from 'moment';
import {AdsButton} from '@nswdoe/doe-ui-core';
import GoToResource from '@/components/GoToResource';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import { mapGetters } from 'vuex'
import Dialog from '@/components/Dialogs/Dialog';
import CopyClipboardMenu from '@/components/CopyClipboardMenu';

export default {
  name: 'ResourceListCard',
  components: {
    AdsButton,
    CopyClipboardMenu,
    Dialog,
    GoToResource
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    hideAction:{
      type: Boolean,
      default: false
    },
    hideDescription:{
      type: Boolean,
      default: false
    },
    hideLearningAreas:{
      type: Boolean,
      default: false
    },
    hideUpdatedDate:{
      type: Boolean,
      default: false
    },
    showCheckbox:{
      type: Boolean,
      default: false
    },
    statusChip:{
      type: Boolean,
      default: false
    },
    itemLength:{
      type: Number,
      default: 0
    },
    itemIndex:{
      type: Number,
      default: 0
    },
    sequenceOrder:{
      type: Boolean,
      default: false
    },
    showRecentlyAddedBadge:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moment,
      resourceDefaultImage,
      dialogCopyLink: false,
      selected: this.isSelected
    }
  },
  created(){
    console.log(this.item)
  },
  computed: {
    ...mapGetters({
      allHubResourceTypes: 'metadata/allHubResourceTypes'
    }),
    isSIR() {
      return this.item.ResourceCategory?.includes('sir');
    },
    isTLR() {
      return this.item.ResourceCategory?.includes('tlr');
    },
    focusAreas() {
      return this.item.FocusArea?.map(e => e.focusArea).join(', ');
    },
    resourceType() {
      // Lookup in metadata for the resourceType label
      return this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.find(type => {
        return type.id === this.item.ResourceType
      })?.name || 'Resource';
    },
    itemID() {
      return this.item.resourceId.id;
    },
    imageUrl() {
      if (this.item.ThumbnailImage || this.item.image) {
        return this.item.ThumbnailImage || this.item.image;
      } else {
        return resourceDefaultImage;
      }
    },
    isRecentlyAdded() {
      const dateSevenDaysAgo = new Date()
      dateSevenDaysAgo.setDate(dateSevenDaysAgo.getDate() - 7)
      return !moment(this.item.DateAdded).isBefore(dateSevenDaysAgo);
    }
  },
  watch: {
    isSelected(val) {
      this.selected = val
    }
  },
  methods: {
    cardClickHandler() {
      this.$emit('onSelect', { select: !this.selected, item: this.item });
      console.log('selection ' + this.selected + ' item ' + this.item.ItemID)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/mixins';
.card--wrapper {
  position: relative;
  .btn-select-resource {
    position: absolute;
    right: 20px;
    top: 8px;
    z-index: 1;
    opacity: 0.8;

    &.v-btn:focus {
      outline-color: white !important;
    }
  }
}

.resourceType{
  font-size: 14px;
  font-weight: bold;
  display: block;
  color: $ads-navy;

  @include respond(phone){
    font-weight: normal;
  }
}

.image__container {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .v-image {
    position: relative;
    border-radius: 4px;
    .status-chip {
      background-color: $ads-white;
      font-weight: bold;
      color: $ads-dark-70;
      position: absolute;
      left: 8px;
      top: 8px;
    }
    .order-chip {
      background-color: $ads-dark-70;
      font-weight: bold;
      color: $ads-white;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }
}

.primary-control {
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    word-break: keep-all;
    line-height: 27px;
    min-height: 44px;
    color: #041E42;

    @include respond(phone){
      font-size: 14px;
    }
  }
}
</style>
