<template>
  <div>
    <v-row class="image__row">
      <v-col
        cols="12"
        :class="{'image__col': true, 'image__col--mobile': regularUrl}"
      >
        <v-responsive :aspect-ratio="1.85">
          <v-card
            outlined
            class="image-area"
            :class="{'light-blue-background': !regularUrl}"
          >
            <v-img
              v-if="regularUrl"
              :src="regularUrl"
              :alt="altDescription"
              :aspect-ratio="1.85"
              :eager="true"
              :width="width"
            />
            <v-card-text
              v-else
              class="card-content"
            >
              <v-icon class="add-image--icon">
                mdi-image-off-outline
              </v-icon>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'PreviewNewImage',
  data(){
    return {
      width:'100%'
    }
  },
  computed: {
    ...mapState({
      regularUrl: state => state.myResources.resourceDetails?.document?.ResourceImage?.regularUrl,
      altDescription: state => state.myResources.resourceDetails?.document?.ResourceImage?.altDescription
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.image__row {
  //margin-bottom: 36px;

  .image__col {
    .v-image {
      border-radius: 4px;

      .edit-button {
        margin: 12px;

        &:focus {
          outline: none !important;
        }

        &:focus-visible {
          outline: 10px auto $ads-navy !important;
        }
      }
    }

    .image-area {
      //border: 2px dashed $ads-navy;
      min-height: 100%;
      display: flex;

      &:focus {
        &::before {
          opacity: 0;
        }
      }

      &:focus-visible {
        border-width: 4px;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .add-image--icon {
          font-size: 150px;
        }
      }
    }

    .light-blue-background {
      background-color: $ads-light-blue;
    }
  }
}

.theme--light {
  &.v-sheet--outlined {
    border: none !important;
  }
}


@media only screen and (max-width: 600px) {
  .image__row {
    .image__col {
      padding: 0;

      &.image__col--mobile {
        padding: 0;
      }

      .v-image {
        border-radius: 0;
      }

      .image-area {
        border-radius: 0;

        .card-content {
          .add-image--icon {
            font-size: 48px;
          }
        }
      }
    }
  }
}
</style>
