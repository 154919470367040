<template>
  <div class="search-doe-staff__wrapper">
    <SearchField
      v-model="selections"
      :items="emails"
      :loading="loading"
      :counter="counter"
      :highlighted-item="highlightedItem"
      :display-details="displayDetails"
      @blur="resetState"
      @searchInput="handleInput"
      @onHover="handleHover"
      @onKeyboardNavigation="handleKeyboardNavigation"
      @getRule="handleRule"
      @click:clear="resetState"
    />
    <RecipientDetails
      v-if="highlightedItem && $vuetify.breakpoint.mdAndUp && displayDetails"
      :details="highlightedItem"
      :position="hoverPosition"
    />
  </div>
</template>

<script>
import SearchField from '@/components/SearchDoeStaff/SearchField';
import RecipientDetails from '@/components/SearchDoeStaff/RecipientDetails';
import api from '@/api';
import {capitalize} from '@/plugins/utils';
import throttle from 'lodash.throttle';

export default {
  name: 'SearchDoeStaff',
  components: {
    SearchField,
    RecipientDetails
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    displayDetails: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, Number, String],
      default: false
    },
    notAllowed: {
      type: Array,
      default: () => []
    },
    allowedDomains: {
      type: Array,
      default: () => [
        'det.nsw.edu.au',
        'tst.det.nsw.edu.au',
        'pre.det.nsw.edu.au'
      ]
    }
  },
  data() {
    return {
      searchInput: '',
      results: [],
      emails: [],
      highlightedItem: null,
      hoverPosition: null,
      loading: false,
      loadingCount: 0,
      error: '',
    }
  },
  computed: {
    selections: {
      get() {
        return this.value;
      },
      set(val) {
        this.resetState();
        const filteredItems = this.filterItems(val);
        this.$emit('input', filteredItems);
      }
    },
    loggedUserEmail() {
      return this.$OAuth.profile.email.toLowerCase() ?? null;
    }
  },
  watch: {
    error(val) {
      this.$emit('getError', val);
    }
  },
  methods: {
    handleInput: throttle(async function (val) {
      if (val?.length) {
        const response = await this.searchStaffAPICall(val);
        if (response && document.activeElement.id === 'search-staff') {
          this.results = response.map(i => this.parseRecipientData(i)).filter(i => !!i);
          let resultsEmail = response.map(i => i.email).filter(i => !!i);
          resultsEmail = this.filterItems(resultsEmail);
          resultsEmail = resultsEmail.filter(email => !this.selections.includes(email));
          this.emails = resultsEmail.map(e => e.toLowerCase());
        }
      } else {
        this.resetState();
      }
    }, 500),
    async searchStaffAPICall(val) {
      try {
        this.loading = true;
        let res = await api.staff.searchStaff(val);
        this.error = '';
        return res.data;
      } catch (e) {
        console.error(e);
        if (this.searchInput && !this.results) {
          this.error = 'There was an issue when retrieving results for you search';
        }
        return this.results;
      } finally {
        this.loading = false;
      }
    },
    handleHover({item, hoverPosition}) {
      this.highlightedItem = this.results.find(r => r.email === item);
      this.hoverPosition = hoverPosition;
    },
    handleKeyboardNavigation() {
      this.$nextTick(() => {
        let itemSelected = document.querySelector('.v-list-item--highlighted');
        if (itemSelected) {
          this.highlightedItem = this.results.find(r => r.email === itemSelected.id);
          let rect = itemSelected.getBoundingClientRect();
          this.hoverPosition = {x: rect.x + rect.width, y: rect.top};
        } else {
          this.highlightedItem = null;
        }
      });
    },
    handleRule(val) {
      this.$emit('getRule', val);
    },
    resetState() {
      this.results = [];
      this.emails = [];
      this.highlightedItem = null;
      this.hoverPosition = null;
      this.loading = false;
    },
    parseRecipientData(item) {
      if (item) {
        let staffDetails = item;
        let email = item.email;
        // name
        let firstName = staffDetails.givenName;
        let surname = staffDetails.familyName;
        let name = capitalize(firstName) + ' ' + capitalize(surname);
        // title
        let title = staffDetails.title;
        // work phone
        let phone = ''
        // school - only for people with some SCHOOL._ role
        let school = null;
        if (staffDetails.roles && staffDetails.roles[0].includes('SCHOOL.')) {
          school = null;
        }
        staffDetails = {email, name, title, phone, school}
        // clean object from null values
        for (let [key, value] of Object.entries(staffDetails)) {
          if (!value) delete staffDetails[key];
        }
        return staffDetails;
      }
      return null;
    },
    filterItems(emails) {
      emails = emails.map(email => email.toLowerCase());
      const notAllowerCaseInsensitive = this.notAllowed.map(i => i.toLowerCase());
      return emails.filter(email => {
        const userId = email.split('@')[0];
        const emailDomain = email.split('@')[1];
        if (this.allowedDomains.length) {
          return !notAllowerCaseInsensitive.includes(email) && !notAllowerCaseInsensitive.includes(userId) && email !== this.loggedUserEmail && this.allowedDomains.includes(emailDomain);
        } else {
          return !notAllowerCaseInsensitive.includes(email) && !notAllowerCaseInsensitive.includes(userId) && email !== this.loggedUserEmail;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
