<template>
  <div>
    <AdsDrawer
      v-bind="$attrs"
      heading="Overview"
      v-on="$listeners"
    >
      <template #content>
        <v-list>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Assigned to
            </v-list-item-content>
            <v-list-item-content>
              {{ assignedTo }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Owner
            </v-list-item-content>
            <v-list-item-content>
              {{ owner }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Created by
            </v-list-item-content>
            <v-list-item-content>
              {{ createdBy }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Date created
            </v-list-item-content>
            <v-list-item-content>
              {{ moment(createdDate).format('DD MMM YYYY') }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Status
            </v-list-item-content>
            <v-list-item-content>
              {{ status }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Endorsed by
            </v-list-item-content>
            <v-list-item-content>
              {{ endorsedBy && capitalise(endorsedBy) }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="subtitle-1">
              Published by
            </v-list-item-content>
            <v-list-item-content>
              {{ publishedBy && capitalise(publishedBy) }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <Activity
          :loading="loadingAuditResources"
          :activities="activities"
        />
      </template>
    </AdsDrawer>
  </div>
</template>

<script>
import {AdsDrawer} from '@nswdoe/doe-ui-core';
import moment from 'moment';
import {mapGetters} from 'vuex';

import {capitalise} from '@/transforms';
import Activity from './Activity.vue';

export default {
  name: 'OverviewDrawer',
  components: {
    AdsDrawer,
    Activity
  },
  data() {
    return {
      moment,
      capitalise,
    };
  },
  computed: {
    ...mapGetters({
      owner: 'editor/contentOwnerName',
      assignedTo: 'editor/assignedTo',
      createdBy: 'editor/createdBy',
      createdDate: 'editor/createdDate',
      status: 'editor/resourceStatus',
      endorsedBy: 'editor/endorsedBy',
      publishedBy: 'editor/publishedBy',
      activeResourceId: 'editor/activeResourceId',
      loadingAuditResources: 'editor/loadingAuditResources',
      auditDataByResourceId: 'editor/auditDataByResourceId',
    }),
    activities() {
      return this.auditDataByResourceId(this.activeResourceId);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.v-navigation-drawer {
  .v-list {
    .v-list-item {
      min-height: 48px !important;
    }
  }
}
</style>
