<template>
  <BasePage
    :banner="banner"
    :card="{maxSize: 424, minSize: 324}"
    :mobile-breakpoint="mobileBreakpoint"
    @get:cardSize="getCardSize"
  >
    <div class="back-button__container">
      <AdsButton
        tertiary
        icon="mdi-arrow-left"
        button-text="Back"
        @click="handleBackToResourcesPage"
        @keyup.enter="handleBackToResourcesPage"
      />
    </div>
    <h2 class="saved-searches__heading">
      Saved Searches
    </h2>
    <div class="cards__header">
      <p class="mb-0">
        <strong>{{ $store.getters["saveSearches/savedSearches"].length }}/50</strong> total
      </p>
      <div class="sorting__wrapper">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="pr-3"
        >
          Sort by:
        </span>
        <AdsSelect
          v-model="sortSelect"
          hide-details
          :items="sortOptions"
          dense
          style="max-width: 180px;"
        />
      </div>
    </div>
    <v-row
      v-if="cardSize > 50"
      align="center"
      :justify="$vuetify.breakpoint.smAndUp? 'start': 'center'"
    >
      <v-col
        v-for="(savedSearch, index) in $store.getters['saveSearches/savedSearches']"
        :key="index"
        cols="auto"
      >
        <SavedSearchesCard
          :saved-search="savedSearch"
          :size="cardSize"
          :mobile-breakpoint="mobileBreakpoint"
        />
      </v-col>
    </v-row>
  </BasePage>
</template>

<script>
import {AdsSelect, AdsButton} from '@nswdoe/doe-ui-core';
import {SORT_OPTIONS_SAVED_SEARCHES} from '../../constants';
import SavedSearchesCard from './SavedSearchesCard';
import BasePage from '@/components/BasePage';

export default {
  name: 'SavedSearchesPage',
  components: {
    BasePage,
    SavedSearchesCard,
    AdsSelect,
    AdsButton,
  },
  data() {
    return {
      banner: {
        heading: 'Saved Searches',
        subtitle: 'View your saved searches',
        icon: 'mdi-content-save-outline'
      },
      cardSize: 350,
      cardsContainerMaxWidth: '',
      mobileBreakpoint: 690,
      sortOptions: SORT_OPTIONS_SAVED_SEARCHES,
    }
  },
  computed: {
    sortSelect: {
      get() {
        switch (this.$store.state.saveSearches.sortSavedSearches) {
          case 'updatedAt:desc':
            return {
              text: 'Last Updated',
              value: 'updatedAt:desc',
            };
          case 'lastRun:desc':
            return {
              text: 'Last Run',
              value: 'lastRun:desc',
            };
          case 'searchName:asc':
            return {
              text: 'Name',
              value: 'searchName:asc',
            };
          default:
            return {
              text: 'Last Updated',
              value: 'updatedAt:desc',
            };
        }
      },
      set(value) {
        this.$store.commit('saveSearches/SET_SORT_SAVED_SEARCHES', value);
        this.$store.dispatch('saveSearches/getSavedSearchesById', this.$store.state.uid);
      },
    },
  },
  mounted() {
    this.$store.dispatch('saveSearches/getSavedSearchesById', this.$store.state.uid);
  },
  methods: {
    getCardSize(val) {
      this.cardSize = val;
    },
    handleBackToResourcesPage: function () {
      this.$router.push({name: 'HubResourcesPage'});
    },
  }
}
</script>

<style lang="scss" scoped>
.back-button__container {
  margin-left: -24px;

  .v-btn {
    &:before {
      opacity: 0;
    }
  }
}

.saved-searches__heading {
  padding-bottom: 24px;
}

.cards__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 12px;

  .sorting__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (max-width: 690px) {
  .back-button__container {
    margin-left: -12px;
  }

  .saved-searches__heading {
    padding-bottom: 12px;
    padding-left: 12px;
  }

  .cards__header {
    padding: 0 12px 12px 12px;
    align-items: flex-end;
  }
}
</style>
