<template>
  <AdsTextField
    v-bind="$attrs"
    v-model="ItemID"
    label="Identifier *"
    aria-label="Identifier"
    aria-required="true"
    :rules="[specialIDCharsRule]"
    :loading="loading"
    :error-messages="errorMessage"
    :success-messages="successMessage"
    v-on="$listeners"
    @blur="handleCheckIdentifier"
    @focus="handleClearMessages"
    @input="handleInputChange"
  />
</template>

<script>
import {AdsTextField} from '@nswdoe/doe-ui-core';

export default {
  name: 'IdentifierTextField',
  components: {
    AdsTextField
  },
  data() {
    return {
      valid: false,
      loading: false,
      successMessage: null,
      errorMessage: null,
      specialIDCharsRule: value => {
        const validCharacters = /^[a-zA-Z0-9_-]*$/;
        const valid = validCharacters.test(value);
        return valid || 'Cannot include special characters or spaces. Only "- and _" with alphanumeric is allowed.';
      }
    }
  },
  computed: {
    ItemID: {
      get() {
        return this.$attrs.value;
      },
      set(val) {
        this.$listeners.input && this.$listeners.input(val);
      }
    }
  },
  methods: {
    async checkValidity(value) {
      const validInput = /^[a-zA-Z0-9_-]+$/;
      let valid = validInput.test(value);
      let message = value?.length > 0 ? undefined : 'Identifier Field is required.';

      if (valid) {
        this.loading = true;
        // call this end point /resourceeditor/`${resourceId}`?checkId=true
        ({data: {status: valid, message}} =
          await this.$store.dispatch('editor/getResourceId', {resourceId: value}));
        this.loading = false;
      }

      return {valid, message};
    },
    async handleInputChange(value) {
      const {valid} = await this.checkValidity(value);
      if (this.valid !== valid) {
        this.valid = valid;
        this.$emit('validityChange', valid);
      }
      this.$emit('change', value);
    },
    async handleCheckIdentifier() {
      const {valid, message} = await this.checkValidity(this.ItemID);

      if (valid) {
        this.successMessage = message;

        setTimeout(() => {
          this.successMessage = null;
        }, 5000);
      } else {
        this.errorMessage = message;
      }
    },
    handleClearMessages() {
      this.errorMessage = null;
      this.successMessage = null;
    }
  },
}
</script>
