<template>
  <v-row align="center">
    <v-col
      cols="12"
      sm="3"
      lg="3"
      md="3"
      class="pl-3"
      :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
    >
      <span class="font-weight-bold">{{ label }}:</span>
    </v-col>
    <v-col
      cols="12"
      sm="9"
      md="9"
      lg="9"
      :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
    >
      <ul
        v-if="items.length > 0"
        class="linkList pa-0"
      >
        <li
          v-for="(item, index) in items"
          :key="`${item.id}-${index}`"
        >
          <a
            :href="getUrl(item)"
            target="_blank"
            style="color: #1D428A;"
          >{{ item.name }}</a>
          <v-icon
            v-if="getUrl(item) !== ''"
            class="pl-1"
            alt="opens in a new window"
            color="#1D428A"
            small
          >
            open_in_new
          </v-icon>
          <span v-else>
            None
          </span>
        </li>
      </ul>
      <span v-else>None</span>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: 'FieldRowWithLinks',
  props: {
    label: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getUrl(item) {
      return item.id?`/detail/${item.id}`:item.url.startsWith('{envURL}')?item.url.replaceWith('{envURL}',''): item.url
    }
  }
}
</script>

<style lang="scss" scoped>
.linkList {

  li {
    list-style: none;
  }
}
</style>
