<template>
  <v-app-bar
    id="app-bar"
    app
    absolute
    clipped-left
    flat
    dark
    :color="color"
    extension-height="56px"
  >
    <v-app-bar-nav-icon
      v-if="showNavIcon && !showBackButton"
      tabindex="1"
      class="focus--dark--button ml-2 mr-2"
      aria-label="Toggle navigation menu"
      :aria-expanded="$store.state.drawer ? true : 'false'"
      @click.stop="$eventHub.$emit('toggle:navigation')"
    />
    <v-toolbar-title
      class="pl-0"
      style="font-weight: bold;"
    >
      <slot name="appIcon" />
      {{ $vuetify.breakpoint.xs ? title.split(" ").map(word => word[0]).join("") : '' }}
    </v-toolbar-title>
    <a
      v-if="$vuetify.breakpoint.smAndUp"
      class="homeLink"
      style="text-decoration: none; color: inherit; display: flex; justify-content: center; align-items: center; height: 50px;"
      aria-label="Universal Resources Hub"
      :aria-hidden="$store.getters.dialogCopyLink || $store.getters.dialogEmbedCode || $store.getters.dialogEmail"
      @click="onClickTitle"
    >
      <slot name="title">
        <v-toolbar-title
          class="pl-0"
          @click="resetFiltersAndSearch"
        >
          <h2
            class="ml-0 pl-2"
            :class="{'mr-3': $vuetify.breakpoint.xs, 'mr-5': $vuetify.breakpoint.smAndUp, 'app-resource-title-mobile':$vuetify.breakpoint.xs}"
            style="vertical-align: middle; line-height: 40px; font-weight: bold; font-size: 20px"
          >
            {{ title }}
          </h2>
        </v-toolbar-title>
      </slot>
    </a>

    <v-spacer id="appBarSpacer" />

    <v-toolbar-title v-if="showFullScreen">
      <FullScreen />
    </v-toolbar-title>

    <div
      v-if="showNotifications"
      class="mr-2"
    >
      <span role="button">
        <v-btn
          aria-label="Toggle notifications"
          icon
          @click.stop="$eventHub.$emit('toggle:notifications')"
        >
          <v-badge
            :content="displayNotificationIcon"
            :value="displayNotificationIcon"
            dot
            color="error"
            overlap
          >
            <v-icon class="material-icons-outlined">notifications</v-icon>
          </v-badge>
        </v-btn>
      </span>
    </div>

    <div
      v-if="$vuetify.breakpoint.smAndUp"
      class="mr-2"
    >
      <slot name="myEssentials" />
    </div>

    <div class="mr-3">
      <slot name="profile" />
    </div>

    <template
      v-if="extended"
      #extension
    >
      <slot name="extension" />
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  props: {
    color: {
      type: String,
      default: '#0A1F40'
    },
    title: String,
    showFullScreen: Boolean,
    showNavIcon: {
      type: Boolean,
      default: true
    },
    showBackButton: Boolean,
    extended: Boolean,
    showNotifications: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      searchString: '',
      showSearch: false,
      show: false
    }
  },
  computed: {
    displayNotificationIcon() {
      return this.$store.state.myResources.notifications.length;
    }
  },
  methods: {
    resetFiltersAndSearch() {
      if (this.$store.state.searchMode) {
        this.$store.commit('CLEAR_SEARCH');
        this.$store.commit('SET_KEYWORDS', '');
        this.$store.commit('CLEAR_ALL_FILTERS');
        this.$store.commit('SET_SEARCH_MODE', false);
      } else if (this.$store.getters.preferencesMode) {
        this.$store.commit('SET_PREFERENCES_MODE', false);
      }
    },
    async onClickTitle() {
      window.location.href = '/';
    }
  }
};
</script>

<style lang="scss" scoped>
#app-bar {
  position: fixed;
  z-index: 3;
  width: 100vw;
}

::v-deep .v-toolbar__content, ::v-deep .v-toolbar__extension {
  padding: 0;
}

::v-deep #schoolPickerContainer .v-input__control .v-input__slot {
  background-color: #1A3B69;
}

.homeLink:focus {
  outline: 2px solid white;
}


.focus--dark--button:focus {
  border: 2px solid white !important;
  outline: none !important;
}

.chip--filters:focus {
  border: 2px solid #1D428A !important;
  outline: none !important;
}
</style>
