var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card--wrapper",class:{ 'dense' : _vm.dense}},[(_vm.enableSelection)?_c('v-btn',{staticClass:"btn-select-resource",attrs:{"small":"","white":"","fab":"","aria-label":"select resource"},on:{"click":_vm.cardClickHandler,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.cardClickHandler.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'))])],1):_vm._e(),_c('v-card',_vm._g({class:{'rounded-0': !_vm.rounded},attrs:{"width":_vm.size,"height":_vm.cardHeight,"title":_vm.resource.Name}},_vm.$listeners),[_c('v-img',{attrs:{"src":_vm.imageUrl,"lazy-src":_vm.resourceDefaultImage,"width":_vm.size,"aspect-ratio":1.85,"contain":false,"eager":true,"alt":_vm.altDescription}},[(!_vm.dense && _vm.isRecentlyAdded && !_vm.sequenceOrder)?_c('v-chip',{staticClass:"status-chip pl-1",attrs:{"small":"","label":"","aria-label":"recently added resource"}},[_c('v-badge',{attrs:{"dot":"","inline":"","left":"","color":"#EE3C48"}}),_vm._v(" Recently Added ")],1):_vm._e(),(_vm.sequenceOrder)?_c('v-chip',{staticClass:"order-chip",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.itemIndex + 1)+" / "+_vm._s(_vm.itemLength)+" ")]):_vm._e()],1),_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"card-info--heading"},[_c('v-card-title',{class:{
            'd-flex justify-space-between': true,
            'px-2 pt-0 pb-0 mt-0': _vm.size < 350,
            'pb-0 pt-1 mt-0' : _vm.size >= 350
          }},[_c('span',{class:{
              'resource-type': true,
              'resource-type-xs': _vm.size < 280
            },attrs:{"aria-label":'resource type: '+ _vm.resourceType}},[_vm._v(" "+_vm._s(_vm.resourceType)+" ")]),_c('span',{staticClass:"d-flex"},_vm._l((_vm.resourceCategory),function(cat){return _c('FeatureIcon',{key:cat.id,staticClass:"ml-2",attrs:{"size":"20","aria-label":cat.label,"color":cat.iconColor,"backgroundColor":cat.iconBg,"icon":cat.icon}})}),1)]),_c('v-card-title',{staticClass:"pt-0 pb-0",class:{'px-2': _vm.size < 350 }},[_c('router-link',{staticClass:"primary-control",class:{ disabled: _vm.enableNavigation },attrs:{"id":_vm.resource.resourceId.id,"to":{
              name: _vm.editorPage ? 'ResourceEditorDetailsPage' : 'HubResourceDetailsPage',
              params: {
                id: _vm.resource.resourceId.id,
                collection: 'collections'
              }},"tabindex":"0"}},[_c('h3',{staticClass:"card-title",class:{'card-title-xl': _vm.size > 450, 'card-title-md': _vm.size < 320, 'card-title-sm': _vm.dense}},[_c('div',{class:{'truncated': _vm.dense}},[_vm._v(" "+_vm._s(_vm.resource.Name || 'No title created')+" ")])])])],1)],1),_c('v-card-actions',{class:{ 'd-flex align-end': true }},[_c('v-card-subtitle',{staticClass:"card-footer",class:{'pt-1 pr-6': _vm.size >= 350, 'px-2 pt-0 pb-2': _vm.size < 350, 'pb-1' : true}},[(_vm.isSIRCategory)?_c('div',[_vm._v(" Focus Area: "),_c('span',{class:{
                'years-sm': _vm.size < 300,
                'years-xs': _vm.size < 280
              }},[_vm._v(" "+_vm._s(_vm.focusAreas ? _vm.focusAreas : 'None')+" ")])]):_c('div',[_vm._v(" Years: "),_c('span',{class:{
                'years-sm': _vm.size < 300,
                'years-xs': _vm.size < 280
              }},[_vm._v(" "+_vm._s(_vm.years ? _vm.years : 'None selected')+" ")])]),_c('span',[_vm._v("Updated: "+_vm._s(_vm.moment(_vm.updatedDate).format('DD MMM YYYY')))])]),_c('v-spacer'),(!_vm.dense)?[_c('div',{staticClass:"d-flex mb-1"},[_c('div',[_c('Dialog',{attrs:{"title":"Share this link","dark-heading":"","max-width":_vm.$vuetify.breakpoint.smAndUp ? 500 : 360},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs}){return [_c('button',{staticClass:"button-icon copy-button",attrs:{"aria-label":"copy content"},on:{"click":function($event){$event.stopPropagation();_vm.dialogCopyLink = true}}},[_c('v-icon',[_vm._v("share")])],1)]}},{key:"content",fn:function(){return [_c('CopyClipboardMenu',{attrs:{"is-open":_vm.dialogCopyLink,"description":"Copy and paste this link:","text-to-copy":_vm.itemUrl}})]},proxy:true}],null,false,2391654965),model:{value:(_vm.dialogCopyLink),callback:function ($$v) {_vm.dialogCopyLink=$$v},expression:"dialogCopyLink"}})],1),_c('button',{staticClass:"button-icon go-to-resource-button",attrs:{"aria-label":"go to resource"},on:{"click":function($event){$event.stopPropagation();return _vm.handleGoToResource.apply(null, arguments)}}},[_c('v-icon',[_vm._v("open_in_new")])],1)])]:_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }