import { render, staticRenderFns } from "./SchoolImprovement.vue?vue&type=template&id=4a01a168&scoped=true&"
import script from "./SchoolImprovement.vue?vue&type=script&lang=js&"
export * from "./SchoolImprovement.vue?vue&type=script&lang=js&"
import style0 from "./SchoolImprovement.vue?vue&type=style&index=0&id=4a01a168&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a01a168",
  null
  
)

export default component.exports