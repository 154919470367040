import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { IeBlocker, OAuth } from '@nswdoe/doe-ui-core';

// import api from './api';
import infiniteScroll from 'vue-infinite-scroll';
import VueClipboard from 'vue-clipboard2';
import VueGtm from 'vue-gtm';
import VInfiniteScroll from 'v-infinite-scroll'

import 'v-infinite-scroll/dist/v-infinite-scroll.css'

// Enhance the event system on Vue.prototype
import {useDownwardBroadcast, useEventHub, useUpwardBroadcast} from './hooks/events';
useDownwardBroadcast(Vue);
useEventHub(Vue);
useUpwardBroadcast(Vue);

// Rich Text editor
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

// eslint-disable-next-line @typescript-eslint/no-var-requires
global.Buffer = global.Buffer || require('buffer').Buffer

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike', 'link'],        // toggled buttons
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  ['blockquote'],
  [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['clean']                                         // remove formatting button
];
Vue.use(VueQuillEditor, {
  modules: {
    toolbar: toolbarOptions
  },
});


let renderComponent, renderProps;

if (IeBlocker.isIe()) {
  // render IeBlocker
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: 'URH'
    }
  };
} else {
  // render App
  renderComponent = App;
  renderProps = {};

  // Config the App root instance (oAuth plugin, etc.)
  Vue.config.productionTip = false;

  // Unified interface for API requests
  // Vue.prototype.$api = api;

  // DLRH config
  if (!(window as any).dlrhConfig) {
    // you need to add/override assets/config.ts that sets a "dlrhConfig" global object
    throw 'assets/config.ts file not found';
  }

  const CONFIG = (window as any).dlrhConfig;

  // DLRH oAuth plugin settings
  const oAuthConfig: any = {
    forceProdMode: CONFIG.FORCEPRODMODE,
    pkce: true,
    router: router,
    authoriseUri: CONFIG.VUE_APP_OAUTH_URL,
    logoutUri: CONFIG.VUE_APP_OAUTH_LOGOUT_URL,
    // authorise url params
    params: {
      client_id: CONFIG.VUE_APP_OAUTH_CLIENT_ID,
      redirect_uri: CONFIG.VUE_APP_OAUTH_REDIRECT_URL,
      scope: encodeURIComponent(CONFIG.VUE_APP_OAUTH_SCOPES),
      response_type: encodeURIComponent('token id_token')
    }
  };
  if(CONFIG.VUE_APP_OAUTH_TOKEN_URL) {
    oAuthConfig.tokenUri = CONFIG.VUE_APP_OAUTH_TOKEN_URL;
    delete oAuthConfig.params.response_type
  }

  Vue.use(OAuth, oAuthConfig);

  // LOAD service worker if mocking is enabled in dev environment
  if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_MOCK === 'MSW') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./api/mocks/browser')
    worker.start()
  }

  let GtmOption;
  switch (CONFIG.envUI) {
    case 'dev':
      GtmOption = {
        id: 'GTM-MHL32XR', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
        queryParams: {
          // Add url query string when load gtm.js with GTM ID (optional)
          gtm_auth: 'dFMCdpL7769LBRyII-Rpmw',
          gtm_preview: 'env-15',
          gtm_cookies_win: 'x'
        },
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
      };
      break;
    case 'test':
      GtmOption = {
        id: 'GTM-MHL32XR', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
        queryParams: {
          // Add url query string when load gtm.js with GTM ID (optional)
          gtm_auth: 'SneVDStQzUc5eMCze-T7iA',
          gtm_preview: 'env-5',
          gtm_cookies_win: 'x'
        },
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
      };
      break;
    case 'pre':
      GtmOption = {
        id: 'GTM-MHL32XR', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
        queryParams: {
          // Add url query string when load gtm.js with GTM ID (optional)
          gtm_auth: 'k0eUVVUzDSSfzI-LLn9CLg',
          gtm_preview: 'env-29',
          gtm_cookies_win: 'x'
        },
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
      };
      break;
    case 'prod':
      GtmOption = {
        id: 'GTM-MHL32XR', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
        queryParams: {
          // Add url query string when load gtm.js with GTM ID (optional)
          gtm_auth: 'T6cMvFudBz2fav9IMaoINg',
          gtm_preview: 'env-30',
          gtm_cookies_win: 'x'
        },
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
      };
      break;
  }

  Vue.use(VueGtm, GtmOption);

  Vue.use(infiniteScroll);
  Vue.use(VInfiniteScroll)

  VueClipboard.config.autoSetContainer = true;
  Vue.use(VueClipboard);
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(renderComponent, renderProps)
}).$mount('#app');
