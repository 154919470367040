<template>
  <FullscreenDialog
    id="error-page"
    v-model="showDialog"
    :transition="false"
    :showCloseButton="false"
    title=""
    class="error-page"
  >
    <template #content>
      <v-row
        v-if="errorDetails"
        class="error-page__wrapper"
      >
        <v-col
          class="error-page__col"
          cols="12"
          lg="6"
          md="8"
          sm="12"
        >
          <v-card class="error-page__card">
            <FeatureIcon
              icon="info_outline"
              size="160"
            />
            <h2 class="error-page__title">
              {{ errorDetails.title }}
            </h2>
            <p class="error-page__subtitle">
              {{ errorDetails.subtitle }}
            </p>
            <template v-if="errorDetails.items">
              <v-list-item
                v-for="(item, i) in errorDetails.items"
                :key="i"
              >
                {{ item }}
              </v-list-item>
            </template>
            <div class="error-page--actions__wrapper">
              <AdsButton
                button-text="Back to Home"
                @click="$router.push({ name: 'HubResourcesPage' })"
                @keyup.enter="$router.push({ name: 'HubResourcesPage' })"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </FullscreenDialog>
</template>

<script>
import { FeatureIcon, AdsButton, FullscreenDialog } from '@nswdoe/doe-ui-core';
import { ERROR_LOOKUP } from '@/constants';

export default {
  name: 'GenericError',
  components: {
    FullscreenDialog,
    FeatureIcon,
    AdsButton
  },
  data() {
    return {
      showDialog: false,
      defaultErrorCode: '500'
    }
  },
  computed: {
    errorDetails() {
      const decodedError = this.$router.currentRoute.params?.id && atob(this.$router.currentRoute.params?.id)
      return {
        ...(decodedError),
        ...ERROR_LOOKUP.find(e => e.code === decodedError.error || this.defaultErrorCode)
      }
    }
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.$router.back();
      }
    },
  },
  created() {
    this.showDialog = true;
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-dialog {
  .v-card {
    .contentWrapper {
      .error-page__wrapper {
        height: calc(100vh - 64px);
        display: flex;
        justify-content: center;
        padding-top: 72px;
        background-color: $ads-light-10;

        &.row {
          margin: 0;
        }

        .error-page__card {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px;

          .error-page__title {
            padding: 12px;
            color: $ads-navy;
            text-align: center;
          }

          .error-page__subtitle {
            font-size: 18px;
            text-align: center;
          }

          .error-page--actions__wrapper {
            padding-top: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .v-dialog {
    .v-card {
      .contentWrapper {
        .error-page__wrapper {
          padding-top: 0;
          height: calc(100vh - 56px);

          .error-page__col {
            padding: 0;
          }

          .error-page__card {
            justify-content: start;
            border-radius: 0;
            height: 100%;
            padding: 40px 16px;

            .error-page__title {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
