<template>
  <span v-if="valueInternal.length > 0">
    <span
      :class="{'chips-preview__wrapper': true, '--mobile': $vuetify.breakpoint.mdAndDown}"
    >
      <span
        id="chips__row"
        class="chips__row"
        @wheel="$event.stopImmediatePropagation()"
      >
        <span
          v-for="(item, i) of valueInternal"
          :key="i"
          class="chip__col"
        >
          <v-chip
            :close="isAllowedToClear.includes(item)"
            color="primary"
            outlined
            @click:close="handleClose(item)"
          >
            {{ item }}
          </v-chip>
        </span>
      </span>
    </span>
  </span>
</template>
<script>
export default  {
  name: 'ChipsPreview',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isAllowedToClear: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      icon: ''
    }
  },
  computed: {
    valueInternal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleClose: function (item) {
      this.valueInternal = this.valueInternal.filter(i => i !== item);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../../scss/variables";

.chips-preview__wrapper {

  & span {
    font-size: 14px;
    margin-bottom: 4px;
  }

  & h3 {
    font-size: 16px;
    color: #22272B;
  }

  .chips__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 30px;

    .chip__col {
      padding: 0 5px 0 0;

      ::v-deep.v-chip {
        background: $ads-white;
        border: 1px solid $ads-navy;

        .v-chip__content {
          button {
            &:focus {
              border: none !important;
              outline: 2px auto $ads-navy;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .chips-preview__wrapper {
    padding: 0;

    .title {
      padding-left: 12px;
    }

    .chips__row {
      margin: 8px 0;
      min-height: 30px;

      .chip__col {

        &:first-child {
          margin-left: 12px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

</style>
