<template>
  <v-dialog
    v-bind="$attrs"
    v-model="show"
    v-on="$listeners"
    @close:dialog="closeDialog"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-card class="dialog-card">
      <div class="content__wrapper">
        <v-card-title
          :class="{ 'dark': darkHeading, 'pa-0': hideHeading }"
        >
          <div
            v-if="!hideHeading"
            class="heading__wrapper d-flex align-start"
          >
            <FeatureIcon
              v-if="icon"
              size="40"
              :icon="icon"
              class="icon"
            />
            <div class="heading__content">
              <h1
                ref="dialog-heading"
                tabindex="-1"
              >
                {{ title }}
              </h1>
              <div
                v-if="$slots.subTitle"
                class="dialog-subtitle"
              >
                <slot name="subTitle" />
              </div>
              <span v-if="subTitle">{{ subTitle }}</span>
            </div>
          </div>
          <v-btn
            v-if="!hideCloseButton"
            aria-label="close button"
            small
            icon
            :dark="darkHeading"
            @click.prevent="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div :class="{'pa-0': hideHeading, 'px-0 pb-0 pt-15': !hideHeading}">
          <slot name="content" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {FeatureIcon} from '@nswdoe/doe-ui-core';

export default {
  name: 'Dialog',
  components: {
    FeatureIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    darkHeading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    hideHeading: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actionsHeight: '0'
    };
  },
  computed: {
    show: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value);
      }
    }
  },
  updated() {
    // v-dialog adds tabindex=0 in its root element. When the user clicks anywhere in the dialog,
    // this element gets focused, and the scroll fired by arrow down key stops working.
    // This code removes tabindex attribute from the root element
    this.$nextTick(() => {
      const dialog = document.querySelector('.v-dialog__content--active');
      if (dialog && dialog.getAttributeNames().includes('tabindex')) {
        dialog.removeAttribute('tabindex');
      }
      if (this.$refs['actions-container']) {
        this.actionsHeight = this.$refs['actions-container'].offsetHeight;
      }
    });
  },
  methods: {
    closeDialog() {
      this.show = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/mixins";

.dialog-card {
  max-height: 90%;
  min-width: 60% !important;

  .content__wrapper {
    position: relative;
    overflow: hidden;

    @include respond(phone) {
      overflow: auto
    }

    // close button
    .v-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;

      @include respond(phone) {
        top: 20px;
        right: 15px;
      }

      &:focus {
        border: 2px solid $ads-navy;
        outline: none !important;
      }

      &.theme--dark {
        &:focus {
          border-color: $ads-white;
        }
      }
    }

    .v-card__title {
      height: 60px;
      display: flex;
      color: $ads-navy;
      flex-direction: row;
      justify-content: flex-start;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      max-width: 100%;

      @include respond(phone) {
        padding-left: 12px;
        padding-right: 12px;
      }

      &.dark {
        background-color: $ads-navy;
        color: $ads-white;
      }

      .heading__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0 0 0 10px;
        //color: $ads-dark;
        .icon {
          margin-right: 12px;
        }

        .heading__content {
          align-self: center;
        }
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        word-break: normal;
        max-width: 100%;

        @include respond(phone) {
          font-size: 18px;
          line-height: 24px;
          max-width: 190px;
        }

        &:focus {
          outline: none;
        }
      }

      span {
        margin-top: 0;
        font-weight: 400;
        font-size: 16px;
        color: $ads-dark
      }

    }

    .dialog__content {
      overflow-y: auto;
      padding: 0;
    }

    .v-card__actions {
      min-height: 60px;
      background-color: white;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}

@media only screen and (max-width: 620px) {
  .dialog-card {
    .content__wrapper {
      .v-card__title {
        .heading__wrapper {
          h1 {
            max-width: 220px;
          }
        }
      }
    }
  }
}

.v-dialog--fullscreen {
  .dialog-card {
    position: fixed;
    bottom: 0;
    border-radius: 0 !important;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;

    .content__wrapper {
      height: 100%;

      .dialog__content {
        height: 100%;
      }
    }
  }
}


</style>
