<template>
  <ul class="checkbox-list__wrapper">
    <li
      v-for="(syllabus, i) of $store.getters['metadata/nSyllabus']"
      :key="i"
    >
      <v-checkbox
        v-model="syllabusInternal"
        :label="syllabus"
        :value="syllabus"
        multiple
        hide-details
      />
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Syllabus',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {}
  },
  computed: {
    syllabusInternal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../../scss/variables";

.checkbox-list__wrapper {
  list-style: none;
  padding: 0;
  //height: 360px;

  li {
    margin-left: 12px;
    margin-bottom: 28px;

    ::v-deep.v-input--selection-controls__input {
      margin-right: 12px;
    }

    ::v-deep.v-label {
      color: $ads-dark;
    }
  }
}

@media only screen and (max-width: 600px) {
  .checkbox-list__wrapper {
    height: inherit;

    li {
      margin-left: 12px;
      margin-bottom: 12px;

      ::v-deep.v-input--selection-controls__input {
        margin-right: 12px;
      }

      ::v-deep.v-label {
        color: $ads-dark;
      }
    }
  }
}
</style>
