<template>
  <Dialog
    v-bind="$attrs"
    icon="mdi-upload-outline"
    title="Upload"
    :subTitle="subtitle"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <div class="share-card__wrapper mt-6">
        <v-card-text>
          <div
            class="pa-4 file-color"
          >
            <div>
              <strong class="file-name-color">{{ name }}</strong>
            </div>
            <div class="file-size">
              Zip Archive, {{ size }}
            </div>
          </div>
        </v-card-text>
        <!--        <v-card-text>-->
        <!--          <div>-->
        <!--            <div class="mb-4">-->
        <!--              <strong>Edit attachment</strong>-->
        <!--            </div>-->
        <!--            <AdsTextField-->
        <!--              label="Title"-->
        <!--              :placeholder="name"-->
        <!--              v-model="zipFileAlias"-->
        <!--              hint="This title will be seen on your resource"-->
        <!--              persistent-hint-->
        <!--            />-->
        <!--          </div>-->
        <!--        </v-card-text>-->
        <v-card-text v-if="!isScorm">
          <div>
            <div>
              <strong>Files to make visible</strong>
            </div>
            <div class="file-size">
              {{ zipFileContents.length }} files
            </div>
            <v-checkbox
              v-model="isSelectAll"
              label="Select all"
              color="primary"
              class="select-all"
              @click="selectAll()"
            />
            <v-checkbox
              v-for="(content, index) in zipFileContents"
              :key="index"
              v-model="selected"
              :label="content"
              :value="content"
              hide-details
              @change="handleChange"
            />
          </div>
        </v-card-text>

        <v-card-actions class="pa-4 pt-0">
          <v-spacer />
          <CancelButton @click="handleCancel" />
          <AdsButton
            v-bind="$attrs"
            buttonText="Confirm"
            v-on="$listeners"
            @click="handleConfirm"
          />
        </v-card-actions>
      </div>
    </template>
  </Dialog>
</template>

<script>
import CancelButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/CancelButton.vue';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {AdsButton, ADS_Colors} from '@nswdoe/doe-ui-core';

export default {
  name: 'UnzipDialog',
  components: {
    CancelButton,
    Dialog,
    AdsButton
  },
  props: {
    name: String,
    size: String,
    zipFileContents: Array,
    isScorm: Boolean
  },
  data() {
    return {
      ADS_Colors,
      isSelectAll: true,
      // indeterminate: false,
      selected: [...this.zipFileContents], // a copy of the prop array,
      zipFileAlias: this.name
    }
  },
  computed: {
    subtitle() {
      return this.isScorm ? 'We’ve detected a zip file.' : 'We’ve detected a zip file. You can select files to be visible.'
    }
  },
  watch: {
    zipFileContents(val) {
      this.selected = [...val]
    }
  },
  methods: {
    selectAll() {
      if (this.isSelectAll === false) {
        this.selected = [];
      } else {
        this.selected = [...this.zipFileContents];
      }
    },
    handleChange() {
      if (this.selected.length === this.zipFileContents.length) {
        this.isSelectAll = true;
      } else {
        this.isSelectAll = false;
        // this.indeterminate = true;
      }

      /*      if (this.selected.length === 0) {
              this.indeterminate = false;
            }*/
    },
    handleCancel() {
      this.selected = [...this.zipFileContents];
      this.$downwardBroadcast('close:dialog');
    },
    handleConfirm() {
      this.$store.commit('editor/SET_ZIP_FILE_CHILD_COUNT', this.zipFileContents.length);
      this.$store.commit('editor/SET_ZIP_FILE_ORIGINAL_NAME', this.name);
      this.$store.commit('editor/SET_FILES_TO_INCLUDE', this.selected);
      this.$store.commit('editor/SET_ZIP_FILE_ALIAS', this.zipFileAlias);
      this.$store.commit('editor/UPDATE_ZIP_FILES', {
        original: this.name,
        new: this.zipFileAlias,
        unpackOnly: this.selected
      });
      console.log({
        original: this.name,
        new: this.zipFileAlias || this.name,
        unpackOnly: this.selected
      })
      this.$downwardBroadcast('close:dialog');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.share-card__wrapper {
  display: block;
  min-width: 240px;

  .file-color {
    background-color: $ads-light-20;
  }

  .file-name-color {
    color: $ads-primary-blue;
  }

  .file-size {
    color: $ads-grey-02;
  }

  .v-divider {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 43.75em) {
    // 700px === 43.75em
    & {
      width: 100%;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  & .v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .v-btn {
    min-height: 2.5rem;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    border-color: $ads-light-40;
    background-color: $ads-white;
    color: $ads-white;

    &:focus {
      border: 2px solid $ads-navy;
      outline: none !important;
    }
  }

  button.v-btn > .v-btn__content {
    justify-content: center;
  }

  .btnText {
    color: white;
  }

  .v-divider {
    border-color: $ads-light-40;
  }
}

::v-deep .theme--light.v-label {
  color: $ads-primary-blue;
}

::v-deep .select-all .theme--light.v-label {
  font-weight: bold;
}
</style>
