<template>
  <div class="email-menu__wrapper">
    <v-card-text class="header-text">
      <span>
        Please fill in the form below and send.
      </span>
    </v-card-text>
    <v-card-text>
      <SearchDoeStaff
        v-model="recipients"
        display-details
        counter="30"
        :allowed-domains="[]"
        @getRule="handleRule"
        @getError="handleError"
      />
      <v-textarea
        v-model="comments"
        outlined
        maxlength="200"
        counter="200"
        label="Comments"
        height="240"
      />
    </v-card-text>
    <v-divider />
    <v-card-actions class="pa-4 pt-0">
      <v-spacer />
      <AdsButton
        width="120px"
        :loading="$store.getters.sendingEmail"
        icon="send"
        :disabled="recipients.length === 0 || rule[0] !== true"
        button-text="Send"
        aria-label="Send email"
        @click="sendEmail"
      />
    </v-card-actions>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
import {AdsButton} from '@nswdoe/doe-ui-core';
import SearchDoeStaff from '@/components/SearchDoeStaff/SearchDoeStaff';
import {mapState} from 'vuex';

export default {
  name: 'ShareEmailMenu',
  components: {
    SearchDoeStaff,
    AdsButton
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    resourceLinks: {
      type: Array,
      default: () => []
    },
    context: {
      validator(value) {
        // The value must match one of these strings
        return ['hub', 'editor'].includes(value)
      },
      default: 'hub'
    }
  },
  data() {
    return {
      valueInternal: false,
      recipients: [],
      comments: '',
      senderEmail: this.$OAuth.profile.email,
      senderName: this.$OAuth.profile.givenName + ' ' + this.$OAuth.data.profile.sn,
      emailBody: '',
      error: '',
      rule: [true]
    }
  },
  methods: {
    async sendEmail() {
      const data = {
        to: this.recipients,
        from: this.senderEmail,
        subject: `${this.senderName} shared a resource with you`,
        body: this.emailBody,
      }
      try {
        await this.$store.dispatch('sendResourceByEmail', data);
        this.valueInternal = false;
        //notify email has been sent.
        const resource = this.context === 'editor' ? this.editorResourceDetails: this.resourceDetails
        const resourceName = resource?.document?.Name || this.resourceLinks.map(link => link.name).join(',')
        let alert = {
          type: 'success',
          text: `Successfully sent ${resourceName} to email.`
        };
        this.$store.commit('SET_ALERT', alert);
        this.$store.commit('SET_DISPLAY_ALERT', true);
        setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false), 20000);
        this.$store.commit('SET_IS_LOADING', false);
        await this.$downwardBroadcast('close:dialog');
      } catch (e) {
        console.error(e);
        this.error = 'There was an issue with your action. Try again or contact support.';
      }
    },
    handleRule(val) {
      this.rule = val;
    },
    handleError(val) {
      this.error = val;
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.resourceDetails,
      editorResourceDetails: state => state.resourceDetails
    }),
    resourceUrls () {
      let emailText = '';
      this.resourceLinks && this.resourceLinks.forEach(link => {
        emailText += `<p>Resource : <a href="${link.url}" target="_blank">${link.name}</a></p>`;
      })
      return emailText;
    },
    fileUrls() {
      let downloadUrls = [];
      let emailText = '';
      const resource = this.context === 'editor' ? this.editorResourceDetails: this.resourceDetails
      if (resource?.files) {
        resource.files.forEach(file => downloadUrls.push(file.DownloadUrl));
      }

      if (downloadUrls.length > 0) {
        downloadUrls.forEach(url => {
          emailText += `<p>Resource : <a href="${url}" target="_blank">${url}</a></p>`;
        })
      }
      console.log(emailText)
      return emailText;
    },
    linkUrls() {
      let urls = [];
      let emailText = '';
      const resource = this.context === 'editor' ? this.editorResourceDetails: this.resourceDetails

      if (resource?.links) {
        resource?.links.forEach(link => urls.push(link.url));
      }
      if (urls.length > 0) {
        urls.forEach(url => {
          emailText += `<p>Resource : <a href="${url}" target="_blank">${url}</a></p>`;
        })
      }
      console.log(emailText)
      return emailText;
    }
  },
  watch: {
    comments: {
      immediate: true,
      handler(val) {
        let userComments = sanitizeHtml(val);
        if (val) {
          this.emailBody = (`
<p>This resource was sent from the <a href="https://resources.education.nsw.gov.au/">URH</a> by: ${this.senderName} (${this.senderEmail})</p>
<p>Comments: ${userComments}</p>
${this.fileUrls}
${this.linkUrls}
${this.resourceUrls}
`);
        } else {
          this.emailBody = (`
<p>This resource was sent from the <a href="https://resources.education.nsw.gov.au/">URH</a> by: ${this.senderName} (${this.senderEmail})</p>
${this.fileUrls}
${this.linkUrls}
${this.resourceUrls}
`);
        }
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.valueInternal = val;
        if (!val) {
          this.comments = '';
          this.recipients = [];
        }
      }
    },
    valueInternal(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.email-menu__wrapper {
  .v-divider {
    margin-bottom: 16px;
  }
}

.v-autocomplete {
  padding-top: 16px;
  padding-bottom: 16px;

  ::v-deep &.v-select {
    &.v-select--chips {
      .v-select__selections {
        max-height: 120px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

::v-deep.v-textarea {
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        label {
          font-size: 16px;
        }
      }
    }
  }
}

::v-deep.v-card__text {
  .v-select {
    &.v-select--is-menu-active {
      .v-input__icon--append {
        .v-icon {
          transform: none !important;
        }
      }
    }
  }
}

.v-menu__content {
  &.v-autocomplete__content {
    .v-list {
      .v-list-item--link {
        &.v-list-item {
          &.theme--light {
            &:hover {
              &::before {
                opacity: 0;
              }
            }
          }
        }

        .recipients--item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        &.v-list-item--highlighted {
          background-color: $ads-navy;
          color: $ads-white !important;
          outline: none !important;

          &:focus {
            outline: none;
          }

          .recipients--item {
            .v-btn {
              color: $ads-white;
            }
          }
        }
      }
    }
  }
}

.theme--light {
  ::v-deep.v-icon {
    &:focus {
      border: 2px solid $ads-navy;
      border-radius: 50%;

      &::after {
        opacity: 0;
      }
    }
  }

  ::v-deep.v-chip {
    background: $ads-white;
    border: 1px solid $ads-navy;

    .v-chip__content {
      button {
        &:focus {
          border: none !important;
          outline: 2px auto $ads-navy;
        }
      }
    }
  }
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

</style>
