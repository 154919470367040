<template>
  <div>
    <Dialog
      v-bind="$attrs"
      title="Add to collection"
      :sub-title="subTitle"
      :hide-heading="false"
      icon="mdi-bookmark-plus-outline"
      :width="$vuetify.breakpoint.xsOnly ? $vuetify.breakpoint.width : showResources ? 1000 : 1000"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <slot
        slot="subTitle"
        name="subTitle"
      />
      <template #content>
        <v-container class="mt-6">
          <v-card-text v-if="showResources">
            <v-row>
              <v-col
                v-for="(item, index) in selectedCards"
                :key="index"
                lg="3"
                sm="12"
              >
                <ResourceCard
                  dense
                  :height="260"
                  :item="item"
                  :isSelected="true"
                  :rounded="$vuetify.breakpoint.width >= 620"
                  v-on="$listeners"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <EditableCollections
              v-model="selectedCollections"
              :hubCollection="hubCollection"
              @onCollectionSelect="onCollectionSelectHandler"
            />
          </v-card-text>

          <v-card-actions :class="{'d-flex justify-end px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
            <AdsButton
              tertiary
              button-text="Cancel"
              @click="$downwardBroadcast('close:dialog');"
              @keyup.enter="$downwardBroadcast('close:dialog');"
            />
            <!-- if no selected cards disable the New collection button -->
            <AdsButton
              secondary
              icon="add_circle_outline"
              button-text="New collection"
              class="mr-4"
              :disabled="selectedCards?.length < 1"
              @click="showNewCollectionDialog = true"
              @keyup.enter="showNewCollectionDialog = true"
            />
            <CreateCollectionDialog
              v-model="showNewCollectionDialog"
              :hubCollection="hubCollection"
              @collections:updated="handleCollectionsUpdated"
            />

            <!-- if no selected cards or no selected collections disable the Save button -->
            <AdsButton
              primary
              button-text="Save"
              :disabled="selectedCollections.length < 1"
              @click="handleSave"
              @keyup.enter="handleSave"
            />
          </v-card-actions>
        </v-container>
      </template>
    </Dialog>
    <SubmitThroughWorkflowDialog
      v-if="workflow"
      v-model="showSubmitThroughWorkflow"
      :application="application"
      :source="source"
      :recordId="recordId"
      :hubCollection="hubCollection"
      @onCancel="showCollectionDetails"
      @onReviewSubmitted="$emit('onReviewSubmitted')"
    />
  </div>
</template>
<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapState} from 'vuex';
import ResourceCard from '@/components/Resource/ResourceCard'
import EditableCollections from '@/components/Collection/EditableCollections'
import CreateCollectionDialog from '@/components/Dialogs/Collections/NewCollection'
import { SubmitThroughWorkflowDialog } from '@/components/Dialogs/Collections';
import {DATATABLE_ITEMS_STATUS, ResourceStatus} from '@/constants';

export default {
  name: 'SaveToCollectionDialog',
  components: {
    EditableCollections,
    AdsButton,
    Dialog,
    ResourceCard,
    CreateCollectionDialog,
    SubmitThroughWorkflowDialog
  },
  props: {
    items: { // all resources in the collection
      type: Array,
      default: () => []
    },
    selectedCards: { // all selected resources in the collection
      type: Array,
      default: () => []
    },
    subTitle: {
      type: String,
      default: 'Save resource into a collection'
    },
    hubCollection: {
      type: Boolean,
      default: false
    },
    workflow: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      showNewCollectionDialog: false,
      showResources: false, // can move this to prop if required
      selectedCollections: [],
      showSubmitThroughWorkflow: false,
      application: null,
      source: null,
      recordId: null,
      status: '' // show workflow dialog if only draft
    };
  },
  computed: mapState({collection: state => state.myCollections.collectionDetails || ''}),
  methods: {
    // add selected resources to selected collections
    async handleSave() {
      this.$downwardBroadcast('close:dialog');
      // create two arrays of valid and invalid resources selected within the collection
      const invalidResources = []
      const validUpdates = []

      try {

        // this is to show the hub collection details page when added to a hub collection
        const selectedHubCollection = this.selectedCollections?.[0] && {
          id: this.selectedCollections?.[0].ItemID,
          application: this.selectedCollections?.[0].Application,
          source: this.selectedCollections?.[0].Source
        }

        // step 1 call addResources api with a 1s delay
        let promises = this.selectedCollections.map(async (collection, index) => {

          const collectionSubResources = collection.subResources?.flatMap(subs => subs.resourceId?.id) || []

          const [invalid, valid] = this.selectedCards.reduce(([pass, fail], elem) => {
            // push to the invalid list if it is already exist in the collection
            return collectionSubResources.includes(elem.ItemID) ? [[...pass, elem.ItemID], fail] : [pass, [...fail, elem]];
          }, [[], []]);

          invalid?.length > 0 && invalidResources.push(`<li>The resource(s) ${invalid.join(', ')} already exists in the Collection ${collection.Name}</li>`)
          valid.length > 0 && new Promise(() => setTimeout(async () => {
            validUpdates.push(collection) // saving updated collection details
            return  this.$store.dispatch('myCollections/addResources', {
              collection: collection,
              subResources: valid // only add valid resources to the collection
            })
          }, 1000 * index));
        })

        await Promise.all(promises).then(async () => {
          // Step 3: fetch all my collections
          setTimeout(() => {
            console.log('Delayed for 1 second.');
            this.$store.dispatch('myCollections/fetchMyEditableCollections', {hubCollection: this.hubCollection})

            const alert = validUpdates.length < 1 ? {
              type: 'error',
              text: `The following error(s) occured while trying to add resource[s]: <ul> ${invalidResources.join('')}</ul>`
            }: (invalidResources.length > 0) ?  {
              type: 'warning',
              text: `The following error(s) occured while trying to add resource[s]: <ul> ${invalidResources.join('')}</ul>`
            } : {
              type: 'success',
              text: 'Resource successfully added to your collection(s)'
            }

            this.$store.commit('SET_ALERT', alert, {root: true});
            // show workflow dialog for hub collections and for authors/reviewers
            if (this.hubCollection) {
              if (this.workflow && (this.status === ResourceStatus.draft || this.status === DATATABLE_ITEMS_STATUS.DRAFT)) {
                this.showSubmitThroughWorkflow = true
              } else {
                // route to the collection details page
                setTimeout(() => {
                  this.showCollectionDetails(selectedHubCollection)
                }, 200 * this.selectedCards.length);
              }
            }
          }, 1000 * this.selectedCollections.length );

        })
      } catch (err) {
        console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error saving your resource.'
        }
        this.$store.commit('SET_ALERT', alert, {root: true});
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
        this.$store.commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => {
          this.$store.commit('SET_DISPLAY_ALERT', false, {root: true})
          this.selectedCollections = []
        }, 20000);
      }
    },
    async showCollectionDetails(collection) {
      await this.$router.push({
        name: 'CollectionBoardPage',
        params: collection,
        query: {
          rce: true
        }
      });
    },
    onCollectionSelectHandler(val) {
      /**
       * val: {
       *   select: type Boolean, // selected or not
       *   item: type Object<ResourceItem>
       * }
       * **/
      if(val.select) {
        if (this.hubCollection) {
          // Single select mode
          this.selectedCollections = [val.item];
          this.source = this.selectedCollections?.[0].Source
          this.recordId = this.selectedCollections?.[0].ItemID
          this.application = this.selectedCollections?.[0].Application
          this.status = this.selectedCollections?.[0].status
        } else {
          // add object to array
          this.selectedCollections.push(val.item)
        }
      } else {
        //delete from list
        this.selectedCollections = this.selectedCollections.filter(card => card.ItemID !== val.item.ItemID)
      }
    },
    handleCollectionsUpdated(document) {
      this.selectedCollections = [document];
      // when newly created collection is selected
      if (this.hubCollection) {
        this.source = this.selectedCollections?.[0].Source
        this.recordId = this.selectedCollections?.[0].ItemID
        this.application = this.selectedCollections?.[0].Application || 'urh'
        this.status = this.selectedCollections?.[0].status
      }
    }
  }
};
</script>
