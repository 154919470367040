<template>
  <Dialog
    v-bind="$attrs"
    icon="share"
    title="Share"
    sub-title="Multiple files detected in this resource card."
    :hide-heading="false"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="new-collection-content">
        <v-card-text
          class="mt-3 pb-0 data-content"
          :style="{'color': ADS_Colors.Dark}"
        >
          <h4 class="mb-1 stong">
            Select which file you'd like to share
          </h4>
          <p>
            <strong>Note:</strong> Google Classroom and Teams only allow sharing one link at a time.
          </p>
        </v-card-text>
        <v-sheet
          outlined
          rounded
          class="ma-4 pa-5"
        >
          <v-radio-group
            v-model="fileToShare"
            hide-details
          >
            <template v-for="(resource, i) in goToResourceList">
              <v-radio
                v-if="resource.type === 'link'"
                :key="i"
                :label="resource.label"
                :value="resource"
              />
              <v-radio
                v-else
                :key="i"
                :label="resource.FileName"
                :value="resource"
              />
            </template>
          </v-radio-group>
        </v-sheet>
        <v-divider v-if="!$vuetify.breakpoint.smAndUp" />
        <v-card-actions :class="{'d-flex justify-end px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
          <AdsButton
            button-text="Cancel"
            secondary
            @click="$downwardBroadcast('close:dialog')"
            @keyup.enter="$downwardBroadcast('close:dialog')"
          />
          <AdsButton
            :loading="loading"
            primary
            icon="share"
            :disabled="!(fileToShare.DownloadUrl || fileToShare.url)"
            button-text="Share"
            @click="showShareListDialog"
            @keyup.enter="showShareListDialog"
          />
        </v-card-actions>
        <ShareListDialog
          v-if="showShareList"
          v-model="showShareList"
          :item="{ url: fileToShare.type === 'link' ? fileToShare.url : fileToShare.DownloadUrl, id: fileToShare.type === 'link' ? fileToShare.label : fileToShare.FileName }"
        />
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import { ADS_Colors, AdsButton } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
import ShareListDialog from '@/components/Dialogs/Editor/ShareListDialog';

export default {
  name: 'ShareResourcesDialog',
  components: {
    ShareListDialog,
    Dialog,
    AdsButton
  },
  props: {
    resources: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
      loading: false,
      fileToShare: '',
      showShareList: false
    }
  },
  computed: {
    ...mapGetters({
      goToResourceList: 'editor/goToResourceList'
    })
  },
  created() {
    console.log(this.collectionName)
  },
  methods: {
    showShareListDialog: async function () {
      await this.$downwardBroadcast('close:dialog');
      this.showShareList = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../scss/mixins';

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 80vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }
}
</style>
