const moduleErrors = {
  namespaced: true,
  state: {
    type: '',
    errorDetails: {
      heading: '',
      title: '',
      subtitle: '',
      actionText: '',
      actionHandler: () => {},
    }
  },
  mutations: {
    SET_ERROR_DETAILS(state, errorDetails) {
      state.errorDetails = errorDetails;
    },
    SET_ERROR_TYPE(state, type) {
      state.type = type;
    }
  },
  actions: {}
};

export default moduleErrors;
