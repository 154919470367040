<template>
  <!-- From here I need to start Disscussion Drawer -->
  <div class="discussion-header">
    <v-container
      class="d-flex justify-start align-center"
    >
      <div class="discussion-header__members mr-auto">
        <h3 class="p-2 mr-2">
          Members
        </h3>
        <span>({{ members.length }})</span>
      </div>

      <a
        class="discussion-header__viewall d-block"
        @click="$emit('show:inviteMember')"
      >
        View all
      </a>
    </v-container>
    <v-spacer />
    <div class="avatar-container">
      <AvatarList
        :stacked="false"
        :items="members"
        :show-only="6"
      />
    </div>
  </div>
</template>
<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core';
import AvatarList from '@/components/AvatarList'
export default {
  name: 'DiscussionHeader',
  components: {
    AvatarList
  },
  props:{
    members:{
      type: Array,
      default: () => []
    },
    initials: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      ADS_Colors,
    }
  },
  methods:{
    viewAllMembers(){
      console.log('view all members...');
      this.inviteMembersDialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/variables";
.discussion-header {
  &__members{
    width: 80%;
    display: flex;
    padding:10px 20px;

    @media only screen and (max-width: 1200px) {
      width: 70%
    }
  }

  &__viewall {
    display: flex;
    font-weight: 700;
    margin-right: auto;
  }

  .avatar-container{
    padding: 10px 0 0 30px;
  }
  .avatar{
    position: relative;
    display: inline-block;

    .circle{
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: red;
      border: 2px solid white;
      bottom: -1px;
      right: 12px;
    }
  }


  @media only screen and ( max-width: 1200px){

    ::v-deep.v-avatar span.initials {
      font-size: 16px;
    }
  }
}
</style>
