<template>
  <v-row
    dense
    align="center"
    justify="start"
    class="chip-filters__wrapper"
  >
    <v-col
      sm="auto"
      class="mr-3"
    >
      Filter by:
    </v-col>

    <!-- 1. Owner -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="Source"
        v-model="checkboxOwner"
        name="Owner"
        heading="Owner"
        :multiLevel="false"
        :alignRight="$vuetify.breakpoint.smOnly"
        :items="sources"
      />
    </v-col>

    <!-- 2. Category = Hub-Type (the new Resource Category) -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="editorRoles"
        v-model="checkboxEditorRole"
        name="Roles"
        heading="Roles"
        :alignRight="$vuetify.breakpoint.smOnly"
        :items="editorRoles"
      />
    </v-col>
  </v-row>
</template>

<script>
import {ChipFilter} from '@nswdoe/doe-ui-core';
import {mapGetters} from 'vuex'
import _debounce from 'lodash/debounce'
import {SortField} from 'resource_store_vue_client/index';
import sortBy from 'lodash/sortBy';

export default {
  name: 'FiltersForEditor',
  components: {
    ChipFilter
  },
  computed: {
    ...mapGetters({
      editorRoles: 'metadata/editorRoles',
      sources: 'metadata/sources'
    }),
    checkboxOwner: {
      get() {
        return this.$store.state.admin.filtersSelection.checkboxOwner;
      },
      set(value) {
        const filtersSelection = {...this.$store.state.admin.filtersSelection};
        filtersSelection.checkboxOwner = value;
        this.$store.commit('admin/SET_FILTERS_SELECTION', filtersSelection);

        const newFilters = {...this.$store.state.admin.filters};
        newFilters.owner = value.join();
        this.$store.commit('admin/SET_FILTERS', newFilters);
        this.debouncedFetchUsers()
      }
    },
    checkboxEditorRole: {
      get() {
        return this.$store.state.admin.filtersSelection.checkboxEditorRole
      },
      set(value) {
        this.$store.commit('admin/SET_FILTERS_SELECTION', {
          ...this.$store.state.admin.filtersSelection,
          checkboxEditorRole: value
        });
        this.$store.commit('admin/SET_FILTERS', {
          ...this.$store.state.admin.filters,
          checkboxEditorRole: value.join
        });
        this.debouncedFetchUsers()
      }
    }
  },
  created() {
    // to avoid multiple api calls on each state update
    this.debouncedFetchUsers = _debounce(this.fetchUsers, 500)
  },
  methods: {
    fetchUsers() {
      const pagination = {
        itemsPerPage: this.$store.state.admin.perPage,
        page: this.$store.state.admin.pageNum
      }
      this.$store.dispatch('admin/fetchUsersByGroup', pagination);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.chip-filters__wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% + 12px);
  overflow-x: scroll;
}

.chip-filters__wrapper::-webkit-scrollbar {
  display: none;
}

.clear-all {
  &:hover {
    &:before {
      opacity: 0 !important;
    }
  }

  &:focus {
    outline-color: $ads-navy !important;
  }
}

.v-menu {
  &__content {
    max-width: 290px;
  }
}

::v-deep .v-card {
  &__title {
    .heading {
      width: 100px;
      word-break: keep-all;
      line-height: 1.5;
      margin-right: 5px;
    }

    button.close-button {
      align-self: flex-start;
    }
  }
}

@media only screen and (max-width: 620px) {
  .chip-filters__wrapper {
    .col {
      &:first-child {
        margin-left: 12px;
      }

      &:last-child {
        margin-right: 12px;
      }
    }
  }
}
</style>
