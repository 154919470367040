<template>
  <div class="checkbox-group__wrapper">
    <fieldset>
      <legend>
        <h3 class="checkbox-group__heading">
          {{ heading }}
        </h3>
      </legend>
      <v-checkbox
        v-model="fullSelection"
        :label="parentCheckboxLabel"
        dense
        hide-details
        :indeterminate="partialSelection"
        class="parent-selector"
        @change="handleParentCheckbox"
      />
      <v-divider />
      <ul
        v-if="!treeview"
        class="children__container"
      >
        <li
          v-for="item in items"
          :key="item.id"
        >
          <v-checkbox
            v-model="valueInternal"
            hide-details
            :label="item.name"
            :value="item.id"
            dense
          />
        </li>
      </ul>
      <Treeview
        v-else
        v-model="valueInternal"
        :items="items"
        class="preferences-treeview"
      />
    </fieldset>
  </div>
</template>

<script>
import Treeview from '@/components/Dialogs/Preferences/Treeview';

export default {
  name: 'PreferencesCheckboxGroup',
  components: {
    Treeview
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    parentCheckboxLabel: {
      type: String,
      default: ''
    },
    treeview: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {}
  },
  computed: {
    valueInternal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    partialSelection: {
      get: function () {
        if (!this.treeview) {
          return this.valueInternal.length !== 0 && this.valueInternal.length !== this.items.length;
        } else {
          let count = 0;
          this.items.forEach(item => {
            if (item.children) {
              count += item.children.length;
            } else {
              count += 1;
            }
          });
          return this.valueInternal.length !== 0 && this.valueInternal.length !== count;
        }
      },
      set: function (val) {
        return val;
      }
    },
    fullSelection: {
      get: function () {
        if (!this.treeview) {
          return this.valueInternal.length !== 0 && this.valueInternal.length === this.items.length;
        } else {
          let count = 0;
          this.items.forEach(item => {
            if (item.children) {
              count += item.children.length;
            } else {
              count += 1;
            }
          });
          return this.valueInternal.length !== 0 && this.valueInternal.length === count;
        }

      },
      set: function (val) {
        return val;
      }
    }
  },
  methods: {
    handleParentCheckbox(val) {
      if (val && !this.treeview) {
        this.valueInternal = this.items.map(i => i.id);
      } else if (val && this.treeview) {
        this.items.forEach(item => this.selectAll(item));
      } else {
        this.valueInternal = [];
      }
    },
    selectAll(item) {
      if (item.children) {
        item.children.forEach(c => this.selectAll(c));
      } else {
        if (!this.valueInternal.includes(item.id)) {
          this.valueInternal.push(item.id);
        }
      }
    },
  }
}

</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

fieldset {
  border: none;
}
.checkbox-group__wrapper {
  min-width: 240px;
  margin-right: 36px;

  .v-input--checkbox {
    min-height: 36px;

    &:focus {
      border: 2px solid $ads-navy;
    }

    ::v-deep.v-input__control {
      .v-input__slot {
        .v-label {
          &.theme--light {
            color: $ads-dark !important;
          }
        }
      }
    }
  }

  .v-divider {
    margin-top: 4px;
    padding-bottom: 4px;
  }

  .checkbox-group__heading {
    font-size: 16px;
    color: $ads-navy;
    margin-bottom: 12px;
  }

  .children__container {
    list-style: none;
    padding-left: 0;

    .v-input--checkbox {
      min-height: 38px;
    }
  }

  .preferences-treeview {
    margin-left: -14px;
    min-width: 220px;
  }
}

::v-deep.v-input--selection-controls {
  &.v-input--is-focused {
    .v-input--selection-controls__ripple {
      &:before {
        border: 2px solid $ads-navy;
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 740px) {
  .checkbox-group__wrapper {
    margin-bottom: 24px;
    margin-right: 0;
  }
}

</style>
