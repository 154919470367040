<template>
  <v-menu
    v-model="menu"
    bottom
  >
    <template #activator="{ on, attrs }">
      <AdsButton
        v-bind="attrs"
        tertiary
        class=" pl-2 pr-3 ml-n2 mb-5"
        button-text="More filters"
        icon="mdi-filter-variant"
        v-on="on"
      />
    </template>
    <v-card>
      <v-container class="filter-menu">
        <div class="d-flex justify-space-between">
          <h4 class="pt-4 pl-2">
            More filters
          </h4>
          <AdsButton
            class="clear-all mt-2"
            tertiary
            button-text="Clear all"
            aria-label="clear all"
            @click="$emit('clear')"
          />
        </div>
        <v-divider />
        <div class="filter-list mt-5">
          <FilterContent
            v-model="checkboxResourceCategory"
            :items="$store.getters['metadata/resourcecategories']"
            heading="Category"
            multi-level
            hide-arrows
          />

          <FilterContent
            id="Phase"
            v-model="checkboxPhase"
            heading="Phase"
            name="Phase"
            multi-level
            hide-arrows
            :items="phases"
          />

          <FilterContent
            id="Owner"
            v-model="checkboxSource"
            heading="Owner"
            name="Owner"
            multi-level
            hide-arrows
            :items="sources"
          />

          <FilterContent
            v-if="$store.state.showRandNFilter"
            id="L&N Topics"
            v-model="checkboxRandN"
            heading="L&N Topics"
            name="L&N Topics"
            multi-level
            hide-arrows
            :items="$store.getters['metadata/filterRAndNTopics']"
          />
          <FilterContent
            v-if="isSIR || showAllFilterCategories"
            id="SEF Alignment"
            v-model="checkboxSefAlignment"
            heading="SEF Alignment"
            name="SEF Alignment"
            multi-level
            hide-arrows
            :items="$store.getters['metadata/sefAlignment']"
          />

          <FilterContent
            v-if="isSIR || showAllFilterCategories"
            v-model="checkboxFocusArea"
            :items="$store.getters['metadata/focusArea']"
            heading="Focus Area"
            multi-level
            hide-arrows
          />
        </div>
        <v-divider />
        <div class="d-flex justify-end">
          <AdsButton
            class="clear-all mt-2"
            primary
            button-text="Close"
            aria-label="close"
            @click="menu = false"
          />
        </div>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import {AdsButton, ChipFilter} from '@nswdoe/doe-ui-core';
import isEqual from 'lodash.isequal';
import FilterContent from '@/components/Menus/FilterContent';
import {mapGetters} from 'vuex';

export default {
  name: 'FilterMenu',
  components: {
    FilterContent,
    AdsButton
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    ...mapGetters({
      storeResourceCategory: 'resourceCategory',
      allPhases: 'metadata/phases',
      allSources: 'metadata/sourcesForHub'
    }),
    checkboxResourceCategory: {
      get() {
        return this.$store.state.filtersSelection.checkboxResourceCategory;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxResourceCategory) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxResourceCategory: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            'resource-category': newVal.join()
          });
          this.$emit('update') // manual update is required
        }
      }
    },
    checkboxSource: {
      get() {
        return this.$store.state.filtersSelection.checkboxSource;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxSource) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxSource: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            'resource-sources': newVal.join()
          });

          // additional logic for checkboxSource to enble RnR filters
          if (newVal && newVal.includes('readingandnumeracy') || newVal.includes('literacyandnumeracypackages')) {
            this.$store.commit('SET_SHOW_RANDN_FILTER', true);
          } else {
            this.$store.commit('SET_SHOW_RANDN_FILTER', false);
            this.checkboxRandN = [];
          }
          this.$emit('update')
        }
      }
    },
    checkboxPhase: {
      get() {
        return this.$store.state.filtersSelection.checkboxPhase;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxPhase) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxPhase: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            phase: newVal.join()
          });

          this.$emit('update') // manual update is required when filters are multi level
        }
      }
    },
    checkboxSefAlignment: {
      get() {
        return this.$store.state.filtersSelection.checkboxSefAlignment;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxSefAlignment) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxSefAlignment: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            sefalignment: newVal.join()
          });

          this.$emit('update') // manual update is required when filters are multi level
        }
      }
    },
    checkboxFocusArea: {
      get() {
        return this.$store.state.filtersSelection.checkboxFocusArea;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxFocusArea) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxFocusArea: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            focusarea: newVal.join()
          });
          this.$emit('update') // manual update is required when filters are multi level
        }
      }
    },
    checkboxRandN: {
      get() {
        return this.$store.state.filtersSelection.checkboxRandN;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxRandN) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxRandN: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            'resource-randntopics': newVal.join()
          });

          this.$emit('update')
        }
      }
    },
    showAllFilterCategories() {
      return this.checkboxResourceCategory?.length === 0 || this.checkboxResourceCategory?.length === this.$store.getters['metadata/resourcecategories'].length;
    },
    phases() {
      //show all when no category is selected
      if (this.showAllFilterCategories) {
        return this.allPhases.filter(p => p.resourcecatid.join(',').match(/sir|tlr/)); // only allow sir and tlr for filtering
      }

      // highly depend on the order of the source.resourcecatid list when BE required to send multiple categories
      // otherwise need to loop the resourceCategory items if the order is unpredictable
      return this.allPhases.filter(phase => {
        return this.checkboxResourceCategory?.join(',').includes(phase.resourcecatid.join(','))
      })
    },
    sources() {
      //show all when no category is selected
      if (this.showAllFilterCategories) {
        return this.allSources;
      }

      // highly depend on the order of the source.resourcecatid list when BE required to send multiple categories
      // otherwise need to loop the resourceCategory items if the order is unpredictable
      return this.allSources.filter(source => {
        return this.checkboxResourceCategory?.join(',').includes(source.resourcecatid.join(','))
      })
    },
    isSIR() {
      console.log('isSIR', this.checkboxResourceCategory?.includes('sir'))
      return this.checkboxResourceCategory?.includes('sir')
    }
  }
}
</script>
<style scoped>
.filter-menu {
  min-width: 500px;
}
.filter-list {
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
