<template>
  <v-card :class="{'rounded-0': $vuetify.breakpoint.xsOnly}">
    <v-card-title
      v-if="$vuetify.breakpoint.xsOnly"
      class="pb-0"
    >
      <v-spacer />
      <v-btn
        aria-label="close button"
        small
        icon
        @click.prevent="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="more-actions__wrapper">
      <v-list class="more-actions__list">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :class="{'more-actions__item': true, '--delete': item.id === 'delete'}"
          :tag="item.tag"
          :href="item.href ? item.href : null"
          @click.stop="$emit('clickedAction', item.id); $emit('close')"
        >
          <v-icon>
            {{ item.icon }}
          </v-icon>
          <span>
            {{ item.name }}
          </span>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.more-actions__wrapper {
  min-width: 200px;
  padding: 0;

  .more-actions__list {
    padding: 12px 0;

    .more-actions__item {
      box-sizing: border-box;
      width: 100%;
      padding: 0 16px;

      &:before {
        opacity: 1;
      }

      .v-icon {
        margin-right: 20px;
      }
    }
  }
}

.theme--light.v-list-item {
  &:not(.v-list-item--active):not(.v-list-item--highlighted) {
    color: transparent !important;

    span {
      color: $ads-dark-70;
    }
  }

  &.--delete {
    &:not(.v-list-item--active):not(.v-list-item--highlighted) {
      color: transparent !important;

      .v-icon {
        color: $ads-error-red-dark !important;
      }

      span {
        color: $ads-error-red-dark !important;
      }
    }

    &.v-list-item--active, &.v-list-item--highlighted {
      color: $ads-error-red-dark !important;

      .v-icon {
        color: $ads-white !important;
      }

      span {
        z-index: 1;
        color: $ads-white !important;
      }
    }
  }

  &.v-list-item--active, &.v-list-item--highlighted {
    color: $ads-navy !important;

    .v-icon {
      color: $ads-white !important;
    }

    span {
      z-index: 1;
      color: $ads-white !important;
    }
  }
}

@media only screen and (max-width: 600px) {

  .more-actions__wrapper {
    max-width: 100%;
    padding: 0 !important;

    .more-actions__list {
      padding: 0 0 12px 0;

      .more-actions__item {

      }
    }
  }
}
</style>
