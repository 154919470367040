import {DATATABLE_ITEMS_STATUS} from '@/constants';

const mapStatusCounts = {
  'Declined': 'Declined',
  'Pending review': 'Pending'
}

export const extractAndMapStatusCounts = (responses: Array<{data: {status: string, count: number}}>) =>
  Object.entries(mapStatusCounts).map(([key, value]) => ({
    status: key,
    count: responses
      .map(({data: {status, count}}) => ({status, count}))
      .find(({status}) => status === value)?.count
  }));

  export const mapStatus = (status: number) => Object.values(DATATABLE_ITEMS_STATUS)[status];
  