<template>
  <v-row>
    <v-col
      cols="12"
      class="py-0 wrapper"
    >
      <div class="card-list-wrapper">
        <div
          v-for="(item, index) in items"
          :key="index"
        >
          <CollectionCard
            v-if="exclude !== item.ItemID"
            v-bind="$attrs"
            :class="{'first': index ===0}"
            :item="item"
            :hubCollection="hubCollection"
            :isSelected="isSelected(item.ItemID)"
            :imageCollage="$vuetify.breakpoint.smAndUp ? true: false"
            :disabled="item.disable"
            v-on="$listeners"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CollectionCard from '@/components/Collection/CollectionListCard'
import resourceDefaultImage from '@/assets/default_myresources_image.png';

export default {
  name: 'CollectionList',
  components: {
    CollectionCard
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    hubCollection: {
      type: Boolean,
      default: false
    },
    selectedCollections: {
      type: Array,
      default: () => []
    },
    exclude: { // collection to exclude from rendering
      type: String,
      default: ''
    }
  },
  data() {
    return {
      resourceDefaultImage
    }
  },
  methods: {
    isSelected(id) {
      return this.selectedCollections.find(item => item.ItemID === id)?true:false
    }
  }
}
</script>
<style lang="scss" scoped>

@import "src/scss/variables";
@import "src/scss/mixins";

.wrapper .card-list-wrapper {
  padding: 3px 0;
  @include customScrollbar(phone, $ads-light-40, $ads-white, $ads-light-40);
}
</style>
