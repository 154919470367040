<template>
  <div class="wrapper">
    <v-card-text>
      <p class="description">
        {{ description }}
      </p>
    </v-card-text>
    <v-card-text class="code">
      <code>{{ textToCopy }}</code>
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-spacer />
      <AdsButton
        v-clipboard:copy="textToCopy"
        v-clipboard:success="() => copySucceeded = true"
        :button-text="copySucceeded ? 'Copied!' : 'Copy to clipboard'"
        :icon="copySucceeded ? 'check' : ''"
        :aria-label="copySucceeded ? 'copied' : 'copy to clipboard'"
      />
    </v-card-actions>
  </div>
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'CopyClipboardMenu',
  components: {
    AdsButton
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: ''
    },
    textToCopy: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      copySucceeded: false,
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.copySucceeded = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.wrapper {
  .description {
    color: $ads-dark;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 16px;
  }

  .v-card__actions {
    padding: 16px;

    .v-btn {
      min-width: 160px;
    }
  }

  .v-divider {
    margin-top: 60px;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 43.75em) {
    // 700px === 43.75em
    & {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    & .v-card__text.code {
      min-height: 50%;
    }
  }
}
</style>
