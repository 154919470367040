import axios from 'axios';
import Vue from 'vue';

const CONFIG = (window as any).dlrhConfig;

function getUrl(userId) {
  let url;
  switch(CONFIG.envUI) {
    case 'local':
      url = `${CONFIG.apiStaffProfileDev}/urhstaff?pageSize=10&searchStr=${userId}*`;
      break;
    case 'dev':
      url = `${CONFIG.apiStaffProfileDev}/urhstaff?pageSize=10&searchStr=${userId}*`;
      break;
    case 'test':
      url = `${CONFIG.apiStaffProfileTest}/urhstaff?pageSize=10&searchStr=${userId}*`;
      break;
    case 'pre':
      url = `${CONFIG.apiStaffProfilePre}/urhstaff?pageSize=10&searchStr=${userId}*`;
      break;
    case 'prod':
      url = `${CONFIG.apiStaffProfileProd}/urhstaff?pageSize=10&searchStr=${userId}*`;
      break;
    default:
      url = `${CONFIG.apiStaffProfileDev}/urhstaff?pageSize=10&searchStr=${userId}*`;
  }
  return url;
}

export default {
  /* this request only is succeed locally if envUI is 'test' (src/public/assets/config.ts:1)
 * and forceProdMode is true (src/main.ts:53)
 */
  searchStaff(userId) {
    const url = getUrl(userId);
    return axios.get(url, {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          ADFS: true,
          'Content-Type': 'application/json; charset=UTF-8',
        }),
      }
    })
      .then((response) => {
        return response;
      })
      .catch(err => {
        console.error(err);
        throw new Error('It was not possible to display your search results.')
      })
  },
}
