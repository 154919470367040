<template>
  <div
    class="carousel-item__wrapper"
    aria-live="polite"
  >
    <v-row class="ma-0">
      <v-col
        cols="12"
        :class="{'px-6': $vuetify.breakpoint.width >= 740, 'px-3': $vuetify.breakpoint.width < 740 }"
      >
        <h1
          v-if="$vuetify.breakpoint.width >= 740"
          class="mb-3"
        >
          School improvement
        </h1>
        <p :class="{'body-1': $vuetify.breakpoint.width >= 740, 'body-2': $vuetify.breakpoint.width < 740}">
          {{ subtitle }}
        </p>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col
        cols="12"
        lg="5"
        md="5"
        :sm="$vuetify.breakpoint.width < 740 ? '12' : '5'"
        :class="{'pl-6 pr-0 py-0': $vuetify.breakpoint.width >= 740, 'px-3': $vuetify.breakpoint.width < 740}"
      >
        <PreferencesCheckboxGroup
          v-model="selectedSchoolImprovement"
          :items="metadataSchoolImprovementSources"
          parent-checkbox-label="All"
        />
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.width >= 740"
        class="pa-0"
        cols="12"
        lg="7"
        md="7"
        sm="7"
      >
        <v-img
          :max-height="500"
          :src="onboardingVector"
          style="margin-top: -40px;"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import onboardingVector from '@/assets/onboarding-vector-2.svg';
import PreferencesCheckboxGroup from '@/components/Dialogs/Preferences/PreferencesCheckboxGroup';
import {mapGetters} from 'vuex';

export default {
  name: 'SchoolImprovement',
  components: {
    PreferencesCheckboxGroup
  },
  data() {
    return {
      onboardingVector
    }
  },
  computed: {
    ...mapGetters({
      metadataSchoolImprovementSources: 'metadata/schoolImprovementSources',
      preferences: 'users/preferences',
    }),
    selectedSchoolImprovement: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value);
      }
    },
    subtitle() {
      return this.$vuetify.breakpoint.width < 740 ? 'Choose the type of school improvement resources' : 'Choose the type of school improvement resources you are interested in';
    }
  },
  mounted() {
    if (this.metadataSchoolImprovementSources.length) {
      this.selectedSchoolImprovement = this.metadataSchoolImprovementSources.map(sis => sis.id);
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 740px) {
  .carousel-item__wrapper {
    overflow: auto;
    height: calc(100vh - 182px);
    margin: 56px 0 126px 0;
  }
}
</style>
