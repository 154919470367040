<template>
  <div :class="{'px-3 pt-2 pb-4': $vuetify.breakpoint.xs, 'px-6 pt-6 pb-6': $vuetify.breakpoint.smAndUp }">
    <template v-if="(progressionsLiteracy && progressionsLiteracy.length > 0) || (progressionsNumeracy && progressionsNumeracy.length > 0)">
      <v-row
        v-if="document.LitLearnProgLevelV3 && document.LitLearnProgLevelV3.length"
        align="center"
      >
        <v-col
          cols="12"
          class="pb-4"
        >
          <h3 style="font-size: 16px;">
            <span>
              <a
                :href="progressionV3Urls"
                target="_blank"
                class="font-weight-bold"
              >
                National Literacy Learning Progression V3
              </a>
              <v-icon
                class="pl-2"
                alt="opens in a new window"
                :color="ADS_Colors.Blue_1"
                small
              >
                open_in_new
              </v-icon>
            </span>
          </h3>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          lg="9"
          md="9"
        >
          <ul class="pa-0">
            <li
              v-for="(elements, key) in literacyProgressionsV3"
              :key="key"
              class="px-0 mb-4 d-flex flex-column align-items-start"
            >
              <h4
                style="font-size: 16px; display: block;"
                class="mb-1"
              >
                {{ key }}
              </h4>
              <ul class="pa-0 list">
                <li
                  v-for="(levels, subelement, subindex) in elements"
                  :key="subindex"
                >
                  <p class="mb-0">
                    {{ subelement }} - {{ levels?.map(obj => obj.level).join(", ") }}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row
        v-if="document.NumLearnProgLevelV3 && document.NumLearnProgLevelV3.length"
        align="center"
      >
        <v-col
          cols="12"
          class="pb-4"
        >
          <h3 style="font-size: 16px;">
            <span>
              <a
                :href="progressionV3Urls"
                target="_blank"
                class="font-weight-bold"
              >
                National Numeracy Learning Progression V3
              </a>
              <v-icon
                class="pl-2"
                alt="opens in a new window"
                :color="ADS_Colors.Blue_1"
                small
              >
                open_in_new
              </v-icon>
            </span>
          </h3>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          lg="9"
          md="9"
        >
          <ul class="pa-0">
            <li
              v-for="(i, key) in numeracyProgressionsV3"
              :key="key"
              class="px-0 mb-4 d-flex flex-column align-items-start"
            >
              <h4
                style="font-size: 16px; display: block;"
                class="mb-1"
              >
                {{ key }}
              </h4>
              <ul class="pa-0 list">
                <li
                  v-for="(levels, subelement, subindex) in i"
                  :key="subindex"
                >
                  <p class="mb-0">
                    {{ subelement }} - {{ levels?.map(obj => obj.level).join(", ") }}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row
        v-if="progressionsLiteracy.length > 0"
        align="center"
      >
        <v-col
          cols="12"
          class="pb-4"
        >
          <h3 style="font-size: 16px;">
            <span>
              <a
                :href="literacyUrl"
                target="_blank"
                class="font-weight-bold"
              >
                National Literacy Learning Progression (PDF)
              </a>
              <v-icon
                class="pl-2"
                alt="opens in a new window"
                :color="ADS_Colors.Blue_1"
                small
              >
                open_in_new
              </v-icon>
            </span>
          </h3>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          lg="9"
          md="9"
          :class="{'pt-0': $vuetify.breakpoint.xsOnly, 'pl-1': true}"
        >
          <p
            v-for="(progression, index) in progressionsLiteracy"
            :key="index"
            class="pl-2"
          >
            {{ progression }}
          </p>
        </v-col>
      </v-row>

      <v-row
        v-if="progressionsNumeracy.length > 0"
        align="center"
      >
        <v-col
          cols="12"
          class="pb-4"
        >
          <h3 style="font-size: 16px;">
            <span>
              <a
                :href="numeracyUrl"
                target="_blank"
                class="font-weight-bold"
              >
                National Numeracy Learning Progression (PDF)
              </a>
              <v-icon
                class="pl-2"
                alt="opens in a new window"
                :color="ADS_Colors.Blue_1"
                small
              >
                open_in_new
              </v-icon>
            </span>
          </h3>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          lg="9"
          md="9"
          :class="{'pt-0': $vuetify.breakpoint.xsOnly, 'pl-1': true}"
        >
          <p
            v-for="(progression, index) in progressionsNumeracy"
            :key="index"
            class="pl-2"
          >
            {{ progression }}
          </p>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row
        class="ma-0"
        align="center"
      >
        <v-col
          cols="12"
          sm="9"
          md="9"
          lg="9"
          :class="{'px-0': $vuetify.breakpoint.xsOnly}"
        >
          <span>None selected</span>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import {mapState} from 'vuex';
import {URLS} from '@/constants';

export default {
  name: 'PreviewProgressions',
  data() {
    return {
      numeracyProgressions: null,
      literacyProgressions: null,
      numeracyProgressionsV3: null,
      literacyProgressionsV3: null,
      numeracyUrl: 'https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=',
      literacyUrl: 'https://educationstandards.nsw.edu.au/wps/wcm/connect/32837681-1ffc-49b3-8069-c756611ff054/national-literacy-learning-progression.pdf?MOD=AJPERES&CVID=',
      ADS_Colors
    }
  },
  computed: {
    ...mapState({
      document: state => state.myResources.resourceDetails?.document
    }),
    progressionV3Urls() {
      return URLS.PROGRESIONS.LITERACY_NUMERACY_V3
    },
    progressions() {
      return this.document?.progressions?.length > 0 ? this.document.progressions : '';
    },
    progressionsLiteracy() {
      return this.getLiteracyProgressionsResult(this.document.LitLearnProgLevel);
    },
    progressionsNumeracy() {
      return this.getNumeracyProgressionsResult(this.document.NumLearnProgLevel)
    }
  },
  watch: {
    document: {
      immediate: true,
      handler() {
        if (this.document.NumLearnProgLevel) {
          this.numeracyProgressions = this.groupByKey(this.document.NumLearnProgLevel, 'subElement');
          for (let element of Object.keys(this.numeracyProgressions)) {
            this.numeracyProgressions[element] = this.groupByKey(this.numeracyProgressions[element], 'subElement');
          }
        }
        if (this.document.LitLearnProgLevel) {
          this.literacyProgressions = this.groupByKey(this.document.LitLearnProgLevel, 'subElement');
          for (let element of Object.keys(this.literacyProgressions)) {
            this.literacyProgressions[element] = this.groupByKey(this.literacyProgressions[element], 'subElement');
          }
        }
        if (this.document.NumLearnProgLevelV3) {
          this.numeracyProgressionsV3 = this.groupByKey(this.document.NumLearnProgLevelV3, 'element');
          for (let element of Object.keys(this.numeracyProgressionsV3)) {
            this.numeracyProgressionsV3[element] = this.groupByKey(this.numeracyProgressionsV3[element], 'subElement');
          }
        }
        if (this.document.LitLearnProgLevelV3) {
          this.literacyProgressionsV3 = this.groupByKey(this.document.LitLearnProgLevelV3, 'element');
          for (let element of Object.keys(this.literacyProgressionsV3)) {
            this.literacyProgressionsV3[element] = this.groupByKey(this.literacyProgressionsV3[element], 'subElement');
          }
        }
      }
    }
  },
  methods: {
    groupByKey(arr, key) {
      return arr.reduce((r, a) => {
        r[a[key]] = [...r[a[key]] || [], a];
        return r;
      }, {});
    },
    getNumeracyProgressionsResult(arr) {
      /**
       * Since the api returns an Object for NumeracyProgression
       */
      let subelements = [];
      let result = [];

      Object.values(arr).forEach( items => {
        subelements.push(items.subElement)
      });
      // avoid duplication
      [...new Set(subelements)].forEach(subelement => {
        result.push(`${subelement} - ${this.getLevelsForSubelement(subelement, this.document.NumLearnProgLevel).join(', ')}`);
      });
      return result;
    },
    getLiteracyProgressionsResult(arr) {
      /**
       * LiteracyProgression
       */
      let subelements = [];
      let result = [];

      Object.values(arr).forEach( items => {
        subelements.push(items.subElement)
      });
      // avoid duplication
      [...new Set(subelements)].forEach(subelement => {
        result.push(`${subelement} - ${this.getLevelsForSubelement(subelement, this.document.LitLearnProgLevel).join(', ')}`);
      });
      return result;
    },
    getLevelsForSubelement: function (subele, arr) {
      let levels = [];
      Object.values(arr).forEach(el => {
        if (el.subElement === subele) {
          levels.push(el.level)
        }
      });
      return levels;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

a {
  color: $ads-blue-1 !important;
}

.list {
  list-style: none;
}
</style>
