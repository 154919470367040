<template>
  <div>
    <Dialog
      v-bind="$attrs"
      title="Remove from collection"
      :hide-heading="false"
      icon="mdi-image-plus"
      :width="$vuetify.breakpoint.xsOnly ? $vuetify.breakpoint.width : showResources? 1000 : 600"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #subTitle>
        <span class="subtitle-2 font-weight-regular ">{{ selectedCards.length }} resources selected</span>
        <AdsButton
          tertiary
          dense
          small
          :button-text="showResources ? 'Hide': 'Show'"
          @click="showResources = !showResources"
        />
      </template>
      <template #content>
        <v-container class="mt-8 new-collection-content">
          <v-card-text
            v-if="showResources"
            class="data-content"
          >
            <v-row>
              <v-col
                v-for="(item, index) in selectedCards"
                :key="index"
                lg="3"
                sm="12"
              >
                <ResourceCard
                  dense
                  :height="260"
                  :item="item"
                  :isSelected="true"
                  :enableNavigation="false"
                  :rounded="$vuetify.breakpoint.width >= 620"
                  v-on="$listeners"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions :class="{'d-flex justify-space-between px-6 py-6': true, 'footer-mobile mt-auto': $vuetify.breakpoint.xsOnly}">
            <AdsButton
              tertiary
              button-text="Cancel"
              @click="$downwardBroadcast('close:dialog');"
              @keyup.enter="$downwardBroadcast('close:dialog');"
            />
            <!-- if no selected cards, disable the Save button -->
            <AdsButton
              primary
              button-text="Save changes"
              :disabled="selectedCards?.length < 1"
              @click="removeResources(collection)"
              @keyup.enter="removeResources(collection)"
            />
          </v-card-actions>
        </v-container>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapState} from 'vuex';
import ResourceCard from '@/components/Resource/ResourceCard'
import store from '@/store'

export default {
  name: 'RemoveResourcesDialog',
  components: {
    AdsButton,
    Dialog,
    ResourceCard
  },
  props: {
    items: { // all resources in the collection
      type: Array,
      default: () => []
    },
    selectedCards: { // all selected resources in the collection
      type: Array,
      default: () => []
    },
  },
  data: function () {
    return {
      showDialog: false,
      showResources: true,
    };
  },
  computed: {
    ...mapState({
      collection: state => state.myCollections.collectionDetails || ''
    }),
  },
  methods: {
    async removeResources() {
      this.$downwardBroadcast('close:dialog');
      try {
        // Step 2: remove resources api
        await this.$store.dispatch('myCollections/removeResources', {
          collection: this.collection,
          subResources: this.selectedCards
        });
        const alert = {
          type: 'success',
          text: 'Your collection is successfully updated.'
        }

        // Step 2: Clear selections in parent
        this.$emit('onComplete');

        await store.dispatch('myCollections/fetchCollection', {
          id: this.collection.record.id,
          source: this.collection.record.collection,
          application: this.collection.audit.iss,
          rce: true,
          hubCollection: this.collection.record.collection !== 'collections'
        });
        this.$store.commit('SET_ALERT', alert, {root: true});
      } catch (err) {
        console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error saving your resource.'
        }
        this.$store.commit('SET_ALERT', alert, {root: true});
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
        this.$store.commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
      //@TODO fetch collection
      this.selectedCollections = []
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../scss/mixins";

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    min-height: calc(100vh - 80px);

    .data-content{
      min-height: 100vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }
}
</style>
