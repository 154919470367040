<template>
  <BasePage
    :banner="banner"
    :card="{maxSize: 424, minSize: $vuetify.breakpoint.mdAndDown ? 288 : 310}"
    applyMaxWidth
  >
    <div
      v-if="isSuperAdmin"
      class="resource-editor-page"
    >
      <v-row
        justify="space-between"
        align="center"
        class="resource-editor-page__header mb-2"
      >
        <v-col class="resource-editor-page__filters">
          <FiltersForAdmin />
        </v-col>
        <v-col
          class="resource-editor-page__selected-resources"
          align="right"
        >
          <template>
            <AdsButton
              class="pa-5 ml-3"
              button-text="Add User"
              depressed
              icon="mdi-account-plus-outline"
              @click="showAddUser = true"
            />
          </template>
        </v-col>
      </v-row>
      <v-row
        justify="space-between"
        align="center"
      >
        <v-container>
          <UserTable
            v-model="selectedUsers"
            @onPaginationChange="handlePaginationChange"
            @onChange="fetchUsers"
          />
        </v-container>
      </v-row>
    </div>
    <AddUserDialog
      v-model="showAddUser"
      @onSuccess="fetchUsers"
    />
  </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage.vue';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';
import {QUERIES, SORT_OPTIONS, DATATABLE_ITEMS_STATUS, RESOURCE_APPLICATION} from '@/constants';
import UserTable from '@/views/RCEAdminPage/UserTable'
import FiltersForAdmin from '@/views/RCEAdminPage/FiltersForAdmin';
import {mapGetters} from 'vuex';
import {pluralise} from '@/transforms';
import {delay} from '@/store/helpers';
import AddUserDialog from '@/components/Dialogs/Admin/AddUser';

export default {
  name: 'RCEAdminPage',
  components: {
    AddUserDialog,
    BasePage,
    FiltersForAdmin,
    AdsButton,
    UserTable
  },
  data() {
    return {
      ADS_Colors,
      banner: {
        heading: 'Resource Editor Access',
        subtitle: 'Manage resource editor users',
        icon: 'vertical_split',
        iconSize: '3.5rem',
        showStatusCounts: false,
      },
      showAddUser: false,
      selectedUsers: [],
      searchFieldError: false,
      queriesOptions: QUERIES,
      sortOptions: SORT_OPTIONS,
      validCharacters: v => !v || /^[a-zA-Z0-9 '.\-+=&_/"]*$/.test(v) || 'Search parameters cannot include the special characters used',
      showSaveToCollectionDialog: false,
      pagination: {}
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/userProfile',
      isSuperAdmin: 'users/isSuperAdmin',
      scope: 'scope',
    })
  },
  methods: {
    fetchUsers() {
      this.$store.dispatch('admin/fetchUsersByGroup', { pagination: this.pagination });
    },
    handlePaginationChange(pagination) {
      this.pagination = pagination;
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-editor-page {
  &__header {
    display: grid;
    grid-template-columns: repeat(auto-fit, 164px);
  }

  &__filters {
    grid-column: span 2;
  }

  &__selected-resources {
    grid-column: span 4;
    font-size: 14px;
  }
}
</style>
