<template>
  <Dialog
    v-bind="$attrs"
    hideCloseButton
    :maxWidth="800"
    :hideHeading="$vuetify.breakpoint.smAndUp"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="!$vuetify.breakpoint.smAndUp"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="under-review">
        <v-card-text class="under-review__banner">
          <v-img :src="image" />
        </v-card-text>
        <v-card-text>
          <h2>
            Your collection is under review
          </h2>
          <p>
            Your collection is currently under review. If endorsed, an approver will publish it to the hub.
            We’ll let you know the progress of this request.
          </p>
        </v-card-text>
        <v-card-actions class="under-review__actions">
          <AdsButton
            primary
            button-text="Close"
            icon="close"
            @click="$downwardBroadcast('close:dialog')"
            @keyup.enter="$downwardBroadcast('close:dialog')"
          />
        </v-card-actions>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import { AdsButton} from '@nswdoe/doe-ui-core';
import image from '@/assets/workflow_illustration.svg';
import Dialog from '@/components/Dialogs/Dialog';

export default {
  name: 'UnderReviewDialog',
  components:{
    Dialog,
    AdsButton
  },
  data() {
    return {
      image
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";
@import "../../../scss/mixins";

.under-review {
  text-align: center;

  &__banner {
    .v-image {
      width: 142px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h2 {
    font-size: 24px;
    color: $ads-primary-blue;
  }

  p {
    padding: 17px 26px 0;
    font-size: 18px;
    line-height: 27px;
    color: $ads-grey-01;
  }

  &__actions {
    justify-content: center;
    margin-bottom: 43px;
  }
}
</style>
