<template>
  <div>
    <Dialog
      v-bind="$attrs"
      hideCloseButton
      :maxWidth="800"
      :hideHeading="$vuetify.breakpoint.smAndUp"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="!$vuetify.breakpoint.smAndUp"
      v-on="$listeners"
    >
      <template #content>
        <v-container class="submit-for-review">
          <v-card-text class="submit-for-review__banner">
            <v-img :src="image" />
          </v-card-text>
          <v-card-text>
            <h2>
              Success! Do you want to submit this collection through the workflow?
            </h2>
            <p>
              Your collection will not be visible in the Universal Resource Hub until it has been approved
              and published.
            </p>
          </v-card-text>
          <v-card-actions class="submit-for-review__actions">
            <AdsButton
              secondary
              button-text="Not now"
              @click="handleDoNotSubmitForReview"
              @keyup.enter="handleDoNotSubmitForReview"
            />
            <AdsButton
              primary
              button-text="Submit for review"
              @click="handleSubmitForReview"
              @keyup.enter="handleSubmitForReview"
            />
          </v-card-actions>
        </v-container>
      </template>
    </Dialog>
    <SubmitForReviewDialog
      v-model="showSubmitForReview"
      hubCollection
      :application="application"
      :source="source"
      :recordId="recordId"
      @onReviewSubmitted="handleReviewSubmitted"
    />
  </div>
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';
import {mapGetters} from 'vuex';
import Dialog from '@/components/Dialogs/Dialog';
import SubmitForReviewDialog from '@/components/Dialogs/Editor/SubmitForReviewDialog';
import image from '@/assets/submit-for-review.svg';

export default {
  name: 'SubmitThroughWorkflowDialog',
  components: {
    Dialog,
    AdsButton,
    SubmitForReviewDialog
  },
  props: {
    source: {
      type: String,
      default: null
    },
    application: {
      type: String,
      default: null
    },
    recordId: {
      type: String,
      default: null
    },
    newCollections: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      image,
      showSubmitForReview: false,
      showUnderReview: false
    }
  },
  computed: mapGetters({newCreatedCollections: 'myCollections/newCreatedCollection'}),
  methods: {
    handleDoNotSubmitForReview() {
      // this.$store.dispatch('users/displaySuccessToastr', {text: 'Saved as draft'});
      this.$emit('onCancel', {
        id: this.recordId,
        application: this.application,
        source: this.source
      })
      this.$downwardBroadcast('close:dialog');
    },
    handleSubmitForReview() {
      this.$store.dispatch('editor/getReviewersList', this.source);
      this.$downwardBroadcast('close:dialog');
      this.showSubmitForReview = true;
    },
    handleReviewSubmitted() {
      this.showSubmitForReview = false;
      this.showUnderReview = true;
      this.$emit('onReviewSubmitted');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";
@import "../../../scss/mixins";

.submit-for-review {
  text-align: center;

  &__banner {
    .v-image {
      width: 142px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h2 {
    font-size: 20px;
    color: $ads-primary-blue;
  }

  p {
    padding: 28px 77px 0;
    font-size: 18px;
    line-height: 27px;
    color: $ads-grey-01;
  }

  &__actions {
    justify-content: center;
    margin-bottom: 43px;
  }
}



  .alert-dialog{
    &-container {
      text-align: center;
      margin-top: 24px;

      &__icon{
        width: 140px;
        height: 140px;
        border-radius: 100%;
        background-color: $ads-blue-3-dark;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto 20px auto;
        padding: 20px;
        text-align: center;
        position: relative;
        & i {
          display: block;
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }

      h2 {
        font-size: 20px;
        color: $ads-navy;
      }

      p {
        padding: 10px;
        font-size: 16px;
      }
    }
  }
</style>
