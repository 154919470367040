<template>
  <BasePage
    :banner="banner"
    :card="{maxSize: 424, minSize: $vuetify.breakpoint.mdAndDown ? 300 : 310}"
    :applyMaxWidth="!showGridView"
    :class="$vuetify.breakpoint.mdAndDown ? 'pa-4': ''"
    @get:cardSize="getCardSize"
  >
    <v-btn
      v-show="queryAction !== ''"
      depressed
      text
      class="pl-0 mb-3"
      color="primary"
      @click="handleResetSearch"
      @keyup.enter="handleResetSearch"
    >
      <v-icon class="pr-2">
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <AdsTextField
      v-model="searchString"
      solo
      label="Search within My Collections"
      class="search-bar mb-6"
      aria-label="Search within My Collections"
      placeholder="Search within My Collections"
      prepend-inner-icon="search"
      clearable
      flat
      :rules="[ validCharacters ]"
      :hide-details="!searchFieldError"
      @update:error="handleError"
    />
    <!--
      CollectionControls Componenet contains Filters for Collection and sort, create new collection controls
     -->
    <CollectionControls @collections:updated="updateCollections" />

    <!-- Results Header  -->
    <ResultsHeader
      v-if="searchMode && showGridView"
      :count="$store.state.myCollections.searchResults.meta.count"
    />
    <template>
      <!-- GRID view -->
      <template v-if="showGridView">
        <template v-if="searchMode">
          <!-- SEARCH MODE -->
          <CollectionResults
            v-if="showGridView"
            :loading="$store.state.isLoading"
            :collections="$store.state.myCollections.searchResults.resources"
            :card-size="cardSize"
            :metadata="$store.state.myCollections.searchResults.meta"
            @action="actionHandler"
          />
        </template>
        <!-- DEFAULT MODE -->
        <LandingView
          v-else
          :loading="$store.state.isLoading"
          :collectionCreatedByMe="collectionCreatedByMe"
          :collectionSharedByMe="collectionSharedByMe"
          :collectionSharedWithMe="collectionSharedWithMe"
          :collection-followed-by-me="collectionFollowedByMe"
          @action="actionHandler"
        />
      </template>
      <template v-else>
        <!-- Show one data table for search results including see all view -->
        <CollectionDataTable
          v-if="searchMode"
          :items="$store.state.myCollections.searchResults.resources"
          pagination="{}"
          type="all"
          :metadata="$store.state.myCollections.searchResults.meta"
          @action="actionHandler"
        />
        <!-- Show three data tables for list view-->
        <template v-else>
          <CollectionDataTable
            :items="collectionCreatedByMe.resources"
            pagination="{}"
            type="Created by me"
            :metadata="collectionCreatedByMe.meta"
            @action="actionHandler"
          />
          <CollectionDataTable
            :items="collectionFollowedByMe.resources"
            pagination="{}"
            type="Followed by me"
            :metadata="collectionFollowedByMe.meta"
            @action="actionHandler"
          />
          <CollectionDataTable
            :items="collectionSharedByMe.resources"
            pagination="{}"
            type="Shared by me"
            :metadata="collectionSharedByMe.meta"
            @action="actionHandler"
          />
          <CollectionDataTable
            :items="collectionSharedWithMe.resources"
            pagination="{}"
            type="Shared with me"
            :metadata="collectionSharedWithMe.meta"
            @action="actionHandler"
          />
        </template>
      </template>
    </template>

    <!-- Edit collection dialog -->
    <EditMetaData
      v-if="collection"
      v-model="showEditMetaDataDialog"
      :collection="collection"
      @collections:updated="updateCollections"
    />

    <!-- Invite members dialog -->
    <InviteMembers
      v-model="inviteMembersDialog"
      :collection="collection"
      @update:collection="updateCollections"
      @remove:member="updateCollections"
    />

    <!-- Delete collection dialog -->
    <!-- @collections:updated="updateCollections" will be called inside handleDelate -->
    <DeleteCollectionDialog
      v-model="deleteCollectinDialog"
      :collectionId="collection.ItemID"
      :collectionName="collection.Name"
      @confirmDeletion="handleDelete"
    />

    <!-- Leave Collections -->
    <!-- @collections:updated="updateCollections" will be called inside handleLeaveCollection -->
    <LeaveCollection
      v-model="leaveCollection"
      :collectionId="collection.ItemID"
      :collectionName="collection.Name"
      @confirm="handleLeaveCollection"
    />

    <!-- Collection information drawer -->
    <CollectionInformation
      v-model="showInformationDrawer"
      :collection="collection"
      @invite="inviteMembersDialog = true"
    />
  </BasePage>
</template>
<script>
import BasePage from '@/components/BasePage.vue';
import ResultsHeader from '@/views/MyCollectionsPage/ResultsHeader/ResultsHeader.vue';
import CollectionResults from '@/views/MyCollectionsPage/CollectionResults.vue'
import { AdsTextField } from '@nswdoe/doe-ui-core';
import CollectionDataTable from '@/views/MyCollectionsPage/CollectionDataTable'
import CollectionControls from './CollectionControls/CollectionControls.vue';

import LandingView from './LandingView.vue';
import { mapGetters, mapState } from 'vuex'
import {EditMetaData, InviteMembers, DeleteCollectionDialog, LeaveCollection, SuccessDialog, ErrorDialog} from '@/components/Dialogs/Collections'
import CollectionInformation from '@/views/CollectionBoardPage/InformationDrawer/CollectionInformation'
import _debounce from 'lodash/debounce'
const uuid = require('uuid');

export default {
  name: 'MyCollections',
  components:{
    CollectionInformation,
    DeleteCollectionDialog,
    InviteMembers,
    EditMetaData,
    CollectionDataTable,
    BasePage,
    AdsTextField,
    ResultsHeader,
    CollectionControls,
    LandingView,
    CollectionResults,
    LeaveCollection,
  },
  data(){
    return{
      banner: {
        heading: 'My Collections',
        subtitle: 'Use collections to group resources in order to share with students and colleagues',
        icon: 'bookmarks'
      },
      collection: {},
      inviteMembersDialog: false,
      showEditMetaDataDialog: false,
      showInformationDrawer: false,
      deleteCollectinDialog: false,
      searchFieldError: false,
      leaveCollection: false,
      cardSize: 350,
      validCharacters: v => !v || /^[a-zA-Z0-9 '.\-+=&_/"]*$/.test(v) || 'Search parameters cannot include the special characters used'
    }
  },
  watch: {
    filters: {
      deep: true,
        async handler (val, oldVal) {
        if (val !== oldVal) {
          console.log(val, oldVal)
        }
      }
    },
    isSearching(val) {
      console.log('setting search mode', val)
      this.$store.commit('myCollections/SET_SEARCH_MODE', val);
    },
    async showGridView() {
      await this.$store.commit('myCollections/SET_PAGE_NUM', 1);
      await this.$store.commit('myCollections/RESET_USER_SCROLL');
      this.debouncedFetchCollections();
    }
  },
  created() {
    // no more than once every 0.5 seconds on change of keyword
    this.debouncedFetchCollections = _debounce(this.updateCollections, 500);
  },
  methods: {
    handleSuccessData(data){
      this.collection = data
      this.inviteMembersDialog = true
    },
    async fetchInitialCollections(page) {
      console.log('loading page data', page)
      await this.$store.dispatch('myCollections/fetchCollections', {
        access: 'followedbyme',
        page
      });
      await this.$store.dispatch('myCollections/fetchCollections', {
        access: 'createdbyme',
        page
      });
      await this.$store.dispatch('myCollections/fetchCollections', {
        access: 'sharedbyme',
        page
      });
      await this.$store.dispatch('myCollections/fetchCollections', {
        access: 'sharedwithme',
        page
      });
    },
    async fetchCollections() {
      await this.$store.dispatch('myCollections/fetchCollections', {
        page: 1,
        perPage: 12
      });
    },
    handleResetSearch() {
      this.$store.commit('myCollections/RESET_SEARCH')
      this.$store.commit('myCollections/SET_QUERY_ACTION', '');
    },
    handleError(val) {
      this.searchFieldError = val;
    },
    actionHandler({action, externalData}) {
      this.collection = externalData
      console.log('collection => ',this.collection , ' action: ' + action)
      switch (action) {
        case 'inviteMembers':
          this.inviteMembersDialog = true
          break;
        case 'editCollection':
          this.showEditMetaDataDialog = true
          break;
        case 'copyCollection':
          this.handleCopyCollection()
          break;
        case 'info':
          this.showInformationDrawer = true
          break;
        case 'leaveCollection':
          this.leaveCollection = true
          break;
        case 'delete':
          this.deleteCollectinDialog = true
          break;
      }
    },
    async handleDelete() {
      console.log('deletion ', this.collection)
      if (this.collection) {
        let payload = {
          collectionId: this.collection.record.id,
          subResources: this.collection.subResources,
          source: this.collection.record.collection,
          displayAlert: true
        }
        const response = await this.$store.dispatch('myCollections/deleteCollection', payload);
        if (response) {
          this.updateCollections();
        }
      }
    },
    async handleLeaveCollection() {
      const action = 'removeaccess'
      const userId = this.$store.state.uid
      const alert= {
        type: 'success',
        text: 'Your preference is successfully updated'
      }

      const isEditor = this.collection.userAccess.editor.includes(userId)

      const scope = isEditor?'editor':'viewer'

      let payload = {
        action,
        alert,
        collection: this.collection.document ? this.collection.document.ItemID : this.collection.ItemID,
        userAccess:{
          accessFor: userId,
          accessScope: scope
        }
      };
      const response = await this.$store.dispatch('myCollections/shareCollection', payload)
      if(response) {
        this.updateCollections()
      }
    },
    async handleCopyCollection() {
      const copiedCollection = await this.$store.dispatch('myCollections/copyCollection', {action: 'copy', collection: this.collection});
      if (copiedCollection && copiedCollection.document) {
        await this.$router.push({name: 'CollectionBoardPage', params: { id: copiedCollection.record.id, source: copiedCollection.record.collection }});
        await this.updateCollections()
        this.collection = this.$store.getters['myCollections/getCollection']
        this.showEditMetaDataDialog = true;
      }
    },
    getCardSize(val) {
      this.cardSize = val;
    },
    updateCollections() {
      console.log('collections updated...')
      if (this.searchMode) {
        this.fetchCollections()
      } else {
        // this.fetchCollections()
        this.fetchInitialCollections()
      }
    }
  },
  computed: {
    ...mapState({
      isLoading : state => state.isLoading,
      searchResults: 'myCollections/searchResults',
      filters: state => state.myCollections.filters,
      showGridView: state => state.myCollections.showCards
    }),
    ...mapGetters({
      newCreatedCollections: 'myCollections/newCreatedCollection',
      searchMode: 'myCollections/searchMode',
      myCollections: 'myCollections/myCollections',
      collectionFollowedByMe: 'myCollections/collectionFollowedByMe',
      collectionSharedWithMe: 'myCollections/collectionSharedWithMe',
      collectionCreatedByMe: 'myCollections/collectionCreatedByMe',
      collectionSharedByMe: 'myCollections/collectionSharedByMe',
      isFiltersEmpty: 'myCollections/isFiltersEmpty'
    }),
    userId() {
      return this.$store.getters['users/userProfile']?.userId?.toLowerCase() || '';
    },
    queryAction() {
      return this.$store.state.myCollections.queryAction // 'createdbyme' || 'sharedbyme' || 'sharedwithme'
    },
    searchString: {
      get() {
        return this.$store.state.myCollections.keywords;
      },
      set(value, old) {
        if (value === old || (value?.length < 3 && value?.length !== 0 )) {
          return
        }
        this.$store.commit('myCollections/SET_KEYWORDS', value);
        this.debouncedFetchCollections();
      }
    },
    /*
    The isSearching computed property is in charge of the <template> conditional rendering.
    For landing view, searchMode = false. For searching view, searchMode = true.
     */
    isSearching() {
      console.log('this.isFiltersEmpty', this.isFiltersEmpty)
      return (this.searchString && this.searchString.length >= 3) || !this.isFiltersEmpty
    },
  }
}
</script>
