<template>
  <v-row class="mb-6">
    <h4 class="title pb-3">
      Curriculum alignment
    </h4>
    <v-col cols="12">
      <SyllabusItem
        v-model="syllabusInternal"
        title="NSW Syllabus"
      />
      <SyllabusItem
        v-model="outcomesInternal"
        title="NSW Syllabus outcome"
      />
      <ProgressionItem
        title="National Literacy & Numeracy Progressions V2"
        :items="[...literacyV2, ...numeracyV2]"
        @confirmProgressions="confirmV2Progressions"
      />
      <ProgressionItem
        title="National Literacy & Numeracy Progressions V3"
        :items="[...literacyV3, ...numeracyV3]"
        @confirmProgressions="confirmV3Progressions"
      />
    </v-col>
    <v-col class="px-0 pt-0">
      <Dialog
        v-model="curriculumDialog"
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :width="$vuetify.breakpoint.mdAndUp ? 800 : 600"
        :hide-heading="true"
      >
        <template #activator>
          <AdsButton
            :disabled="disableCurriculumDialog"
            icon="mdi-plus-circle-outline"
            button-text="Add"
            @click="openCurriculumDialog"
          />
        </template>
        <template #content>
          <div class="curriculum-dialog-content__wrapper">
            <AppBar
              v-if="$vuetify.breakpoint.xsOnly"
              show-back-button
              :color="ADS_Colors.Navy"
            >
              <template #backButton>
                <v-btn
                  icon
                  class="ml-3 mr-2"
                  aria-label="close"
                  @click="curriculumDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <template #title>
                <h1 class="appbar-title">
                  Curriculum Alignment
                </h1>
              </template>
            </AppBar>
            <CurriculumSteps
              :syllabus="syllabus"
              :outcomes="outcomes"
              :v2-progressions="[ ...literacyV2,...numeracyV2 ]"
              :v3-progressions="[ ...literacyV3,...numeracyV3 ]"
              :curriculum-input="curriculumInput"
              @confirm="handleConfirm"
              @confirmV2Progressions="confirmV2Progressions"
              @confirmV3Progressions="confirmV3Progressions"
              @cancel="curriculumDialog = false"
            />
          </div>
        </template>
      </Dialog>
    </v-col>
  </v-row>
</template>
<script>
import {AdsButton, AppBar, ADS_Colors} from '@nswdoe/doe-ui-core';
import SyllabusItem from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/SyllabusItem.vue';
import Dialog from '@/components/Dialogs/Dialog';
import CurriculumSteps from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps.vue'
import {mapGetters} from 'vuex';
import isEqual from 'lodash.isequal';
import _ from 'lodash';
import ProgressionItem
  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/CurriculumSteps/Progressions/ProgressionItem'

export default {
  name: 'Curriculum',
  components: {
    ProgressionItem,
    SyllabusItem,
    AdsButton,
    Dialog,
    AppBar,
    CurriculumSteps
  },
  props: {
    syllabus: {
      type: Array,
      default: () => []
    },
    outcomes: {
      type: Array,
      default: () => []
    },
    progressions: {
      type: Array,
      default: () => []
    },
    literacyV2: {
      type: Array,
      default: () => []
    },
    numeracyV2: {
      type: Array,
      default: () => []
    },
    literacyV3: {
      type: Array,
      default: () => []
    },
    numeracyV3: {
      type: Array,
      default: () => []
    },
    curriculumInput: {
      type: Object,
      default: () => {
      }
    }
  },
  data: function () {
    return {
      ADS_Colors,
      curriculumDialog: false,
      syllabusInternal: [],
      outcomesInternal: [],
      progressionsInternal: [],
      isUpdatingAfterConfirm: false
    }
  },
  computed: {
    ...mapGetters({
      storeSyllabuses: 'metadata/nSyllabus',
      clearProgressions: 'editor/clearProgressions',
      removeProgressionsDialog: 'editor/removeProgressionsDialog'
    }),
    disableCurriculumDialog: function () {
      if (this.curriculumInput.kla && this.curriculumInput.kla.length === 0) {
        return true;
      }
      if (this.curriculumInput.stage && this.curriculumInput.stage.length === 0) {
        return true;
      }
      if (this.storeSyllabuses && this.storeSyllabuses.length === 0) {
        return true;
      }
      return false;
    }
  },

  watch: {
    storeSyllabuses: function (val) {
      if (this.syllabusInternal.length > 0) {
        this.syllabusInternal = this.syllabusInternal.filter(s => val.includes(s));
      }
    },
    clearProgressions: function (val) {
      if (val && !this.curriculumDialog) {
        if (this.progressionsInternal.length > 0) {
          this.progressionsInternal = [];
        }
        this.$store.commit('editor/SET_CLEAR_PROGRESSIONS', false);
      }
    },
    curriculumInput: function (val) {
      if (val.kla.length === 0 || val.stage.length === 0) {
        if (this.syllabusInternal.length > 0) {
          this.syllabusInternal = [];
        }
        if (this.outcomesInternal.length > 0) {
          this.outcomesInternal = [];
        }
      }
    },
    syllabus: {
      immediate: true,
      handler: function (val) {
        if (!isEqual(val, this.syllabusInternal)) {
          this.syllabusInternal = val;
        }
      }
    },
    outcomes: {
      immediate: true,
      handler: function (val) {
        const outcomesInternal = val.map(o => o.id);
        if (!isEqual(val, outcomesInternal)) {
          this.outcomesInternal = outcomesInternal;
        }
      },
    },
    syllabusInternal: function (val, oldVal) {
      const removedSyllabus = oldVal.find(i => !val.includes(i));
      let remainingOutcomes = this.outcomes
      if (removedSyllabus) {
        // if (this.progressionsInternal.length > 0 && !this.removeProgressionsDialog && !this.isUpdatingAfterConfirm) {
        //   this.$store.commit('editor/SET_REMOVE_PROGRESSIONS_DIALOG', true);
        // }
        // remove  outcomes related to removed syllabus and updates internal state
        remainingOutcomes = this.outcomes.filter(o => o.syllabus !== removedSyllabus)
        this.outcomesInternal = remainingOutcomes.map(o => o.id);
        if (val.length === 0) {
          this.outcomesInternal = [];
        }
      }
      // emit to Edit.vue if prop value is different than internal value
      if (val.length !== this.syllabus.length) {
        this.$emit('curriculumSelection', {
          syllabus: this.getSyllabusOutcomes(remainingOutcomes, val)
        });
      }
    },
    outcomesInternal: function (val, oldVal) {
      const removedOutcome = oldVal.find(i => !val.includes(i)); // this.outcomes.find(o => o.id === oldVal.find(i => !val.includes(i)));
      if (removedOutcome && this.progressionsInternal.length > 0 && !this.removeProgressionsDialog && !this.isUpdatingAfterConfirm) {
        this.$store.commit('editor/SET_REMOVE_PROGRESSIONS_DIALOG', true);
      }

      // updated outcomes after removing
      const newOutcomes = this.outcomes.filter(o => o.id !== removedOutcome)

      // emit to Edit.vue if prop value is different than internal value
      if (val.length !== this.outcomes.length) {
        this.$emit('curriculumSelection', {
          syllabus: this.getSyllabusOutcomes(newOutcomes, this.syllabusInternal)
        })
      }
    },
  },
  methods: {
    openCurriculumDialog: async function () {
      this.curriculumDialog = true;
      await this.$store.dispatch('metadata/fetchProgressions');
      await this.$store.dispatch('metadata/fetchProgressionsV3');
    },
    getSyllabusOutcomes: function (outcomes, currentSyllabus) {

      // api friendly syllabus model
      const nswSyllabusWithOutcomes = _(outcomes)
        .groupBy(x => x.syllabus) // group by syllabus
        .map((value, key) => ({ // map the name to syllabus key and values to outcomes
          name: key,
          outcomes: value.map(o => {
            return {
              name: o.name,
              id: o.id,
              url: 'http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=' + o.id,
              syllabus: o.syllabus
            }
          })
        })).value();

      // syllabus with outcomes
      const syllabusIdsWithOutcomes = nswSyllabusWithOutcomes.map(s => s.name)

      // sylabus without outcomes
      const removedSyllabuses = currentSyllabus.filter(i => !syllabusIdsWithOutcomes.includes(i))

      // appending removed syllabuses back and save
      const nswSyllabus = [...nswSyllabusWithOutcomes, ...removedSyllabuses?.map(rs => {
        return {
          name: rs,
          outcomes: []
        }
      })]

      return nswSyllabus;
    },
    confirmV3Progressions(val) {
      console.log('updating v3 object', val)
      // This will be handled in the Edit.vue
      this.$emit('confirmProgressions', {
        literacyV3: val.literacy,
        numeracyV3: val.numeracy
      });
    },
    confirmV2Progressions(val) {
      // This will be handled in the Edit.vue
      console.log('updating v2 object', val)
      this.$emit('confirmProgressions', {
        literacyV2: val.literacy,
        numeracyV2: val.numeracy
      });
    },
    handleConfirm: function (val) {

      // api friendly syllabus model
      const nswSyllabusWithOutcomes = this.getSyllabusOutcomes(val.outcomes, val.syllabus)

      this.$emit('curriculumSelection', {
        syllabus: nswSyllabusWithOutcomes,
        outcomes: val.outcomes
      });

      this.isUpdatingAfterConfirm = true;
      this.syllabusInternal = val.syllabus;
      this.outcomesInternal = val.outcomes.map(o => o.id);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/ads";

.curriculum__heading {
  font-size: 20px;
  padding-bottom: 12px;
}

.curriculum-dialog-content__wrapper {
  display: flex;
}

.appbar-title {
  padding-right: 12px;
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  .curriculum-dialog-content__wrapper {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    position: relative;
  }
}
</style>
