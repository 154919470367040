<template>
  <v-card>
    <v-row class="ma-0">
      <v-col
        cols="4"
        class="image__container"
      >
        <v-img
          :src="item.cardImageUrl"
          :lazy-src="resourceDefaultImage"
          :width="350"
          :aspect-ratio="1.85"
          :contain="false"
          :eager="true"
          alt=""
          class="rounded-2"
        />
      </v-col>
      <v-col>
        <v-card-title class="pb-0 pt-2">
          <span
            style="font-size: 14px; font-weight: bold; display: block; color: #1D428A;"
            class="fontColorResourceType"
            :aria-label="'resource type '+ item.resourceType"
          >
            {{ resourceType }}
          </span>
        </v-card-title>
        <v-card-title class="pt-0 pb-2">
          <router-link
            :id="item.id"
            :to="'/detail/' + item.id"
            tabindex="0"
            class="primary-control"
          >
            <h2 class="card-title">
              {{ item.name }}
            </h2>
          </router-link>
        </v-card-title>
        <v-card-subtitle class="pr-6 pt-2 pb-1">
          <span>Updated: {{ moment(item.updated).format('DD MMM YYYY') }}</span>
        </v-card-subtitle>
        <v-card-text
          v-if="isTLR"
          class="pt-0 pb-4"
        >
          <span
            style="font-weight: bold;"
            class="mr-2"
          >Years:</span>
          <span>{{ item.years.join(', ') }}</span>
          <span
            style="font-weight: bold;"
            class="ml-9 mr-2"
          >Learning Area:</span>
          <span>{{ item.klas.join(', ') }}</span>
        </v-card-text>
        <v-card-text
          v-if="isSIR"
          class="pt-0 pb-4"
        >
          <span
            class="font-weight-bold mr-2"
          >Focus Area:</span>
          <span>{{ focusAreas }}</span>
        </v-card-text>
        <v-card-text
          class="pt-0 pb-0 mb-4"
          style="min-height:110px;"
        >
          <ShowMore
            :text="item.introText ? item.introText : ''"
            max-height="89px"
          />
        </v-card-text>
        <v-card-actions class="actions-wrapper">
          <div class="mr-3">
            <GoToResource
              :id="item.id"
              :item-url="item.url"
            />
          </div>
          <div>
            <Share
              :item="item"
              align-right
            />
          </div>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import * as prettyBytes from 'pretty-bytes';
import Share from '@/components/Share';
import GoToResource from '@/components/GoToResource';
import ShowMore from '@/components/ShowMore';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import { mapGetters } from 'vuex'

export default {
  name: 'ResourceCardList',
  components: {
    Share,
    GoToResource,
    ShowMore,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      moment,
      prettyBytes,
      resourceDefaultImage
    }
  },
  computed: {
    ...mapGetters({
      allHubResourceTypes: 'metadata/allHubResourceTypes'
    }),
    isSIR() {
      return this.item.resourceCategories?.includes('sir');
    },
    isTLR() {
      return this.item.resourceCategories?.includes('tlr');
    },
    focusAreas() {
      return this.item.focusArea?.map(e => e.focusArea).join(', ');
    },
    resourceType() {
      // Lookup in metadata for the resourceType label
      return this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.find(type => {
        return type.id === this.item.resourceType
      }).name || 'Resource';
    }
  }
}
</script>

<style lang="scss" scoped>
.image__container {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .v-image {
    border-radius: 4px;
  }
}

.primary-control {
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    word-break: keep-all;
    line-height: 27px;
    min-height: 44px;
    color: #041E42;
  }
}
</style>
