<template>
  <div>
    <v-card
      :width="size"
      :height="size"
      role="button"
      aria-label="Create new"
      outlined
      class="v-card--create"
      @click="openCreateNewResourcePage"
      @keyup.enter="openCreateNewResourcePage"
    >
      <div class="card-info">
        <div class="card-info--heading">
          <v-card-title
            class="pt-0 pb-0"
            :class="{'px-2': size < 350 }"
            style="justify-content: center;"
          >
            <h3
              class="card-title"
              :class="{'card-title-xl': size > 450, 'card-title-md': size < 320}"
            >
              Create new
            </h3>
          </v-card-title>
          <div style="display: flex; justify-content: center;">
            <button
              tabindex="-1"
              aria-hidden="true"
              class="button-icon go-to-resource-button"
            >
              <v-icon
                :color="ADS_Colors.Blue_1"
                x-large
              >
                mdi-plus-circle-outline
              </v-icon>
            </button>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {ADS_Colors} from '@nswdoe/doe-ui-core';

export default {
  name: 'CreateNewResourceCard',
  components: {},
  props: {
    size: {
      type: [Number, String],
      default: 350
    }
  },
  data(){
    return {
      ADS_Colors
    }
  },
  methods: {
    openCreateNewResourcePage() {
      this.$router.push('/myresources/new');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";


.v-card {
  display: flex;
  flex-direction: column;
  border: 2px dashed $ads-navy;
  background-color: transparent;
  color: $ads-blue-1;
  &.v-card--create:focus {
    outline: 2px solid $ads-navy;
  }
  .card-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .primary-control {
      text-decoration: none;

      &:focus {
        outline-color: $ads-navy;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .card-title {
        font-size: 24px;
        color: $ads-blue-1;
        font-weight: bold;
        height: 50px;
      }
    }
  }
}
</style>
