<template>
  <v-row align="center">
    <v-col
      cols="12"
      sm="3"
      lg="3"
      md="3"
      class="pl-1"
      :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
    >
      <span class="font-weight-bold">{{ label }}:</span>
    </v-col>
    <v-col
      cols="12"
      sm="9"
      md="9"
      lg="9"
      :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
    >
      <span>{{ value }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FieldRowWithChips',
  props: {
    label: {
      type: String
    },
    value: {
      type: String,
      default: 'None'
    }
  }
}
</script>
