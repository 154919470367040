<template>
  <v-row
    class="mb-0"
  >
    <h3 :id="groupId">
      {{ label }}
    </h3>
    <v-col
      cols="12"
      class="pl-0 pb-0 pt-0"
    >
      <v-radio-group
        v-model="selectedOption"
        :aria-labelledby="groupId"
        class="mt-0"
        :rules="[v => !!v || 'Selection is required']"
      >
        <div class="d-flex option-group">
          <v-radio
            v-for="(option, i) in options"
            :key="i"
            :label="option"
            :value="option"
            class="mb-3 mr-4"
          />
          <v-radio
            label="Other"
            value="Other"
            class="mb-3 mr-4"
          />
          <AdsTextField
            v-if="isOther"
            v-model="otherOption"
            class="mt-0 pt-0"
            :rules="[v => !!v || 'This is required']"
            :label="`Please specify other ${label.toLowerCase()}`"
            aria-required
            :placeholder="`Please specify the ${label.toLowerCase()}`"
            @change="handleRadioGroupChange"
          />
        </div>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { AdsTextField } from '@nswdoe/doe-ui-core';

export default {
  name: 'InputRadioGroup',
  components: {
    AdsTextField
  },
  props: {
    selected: String,
    label: {
      type: String,
      default: 'test'
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      groupId: this._uid,
      otherOption: ''
    }
  },
  computed: {
    isOther() {
      return this.options.indexOf(this.selected) === -1
    },
    selectedOption: {
      get() {
        return this.isOther? 'Other': this.selected
      },
      set(val) {
        this.$emit('change', (val === 'Other')? this.otherOption: val)
      }
    }
  },
  watch: {
    selected() {
      if(this.isOther) {
        this.otherOption= this.selected
      }
    }
  },
  methods: {
    handleRadioGroupChange() {
      this.$emit('change', this.selectedOption === 'Other'? this.otherOption: this.selectedOption)
    }
  }
}
</script>

<style lang="scss" scoped>

.theme--light {
  ::v-deep label.v-label {
    color: $ads-dark;
  }

  .option-group {
    height: 80px;
  }
}
</style>
