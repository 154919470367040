<template>
  <div
    id="page__wrapper"
    class="page__wrapper"
  >
    <NotificationCarousel
      v-if="showNotifications && notifications.length > 0"
      :notifications="notifications"
    />
    <Banner
      v-else-if="$vuetify.breakpoint.mdAndUp && showBanner"
      id="main-content"
      :heading="banner.heading"
      :subtitle="banner.subtitle"
      :icon="banner.icon"
      :icon-size="banner.iconSize"
      tabindex="-1"
    >
      <template #rightPannel>
        <StatusCounts v-if="banner.showStatusCounts" />
      </template>
    </Banner>

    <template v-if="showStatusHeader && $vuetify.breakpoint.mdAndUp">
      <div
        class="status-header"
      >
        <v-card
          v-for="status in statusHeader"
          :key="status.statusState"
        >
          <v-card-text>
            <p class="text-center">
              {{ status.statusNumber }}
            </p>
            <span>{{ status.statusState }}</span>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <v-container
      id="page-content__wrapper"
      v-resize="handleResize"
      fluid
      class="page-content__container"
      :style="{ 'max-width': containerMaxWidth }"
    >
      <slot />
    </v-container>
  </div>
</template>

<script>
import {Banner} from '@nswdoe/doe-ui-core';
import StatusCounts from './StatusCounts';
import {mapGetters} from 'vuex';

let timeoutId = null;
import NotificationCarousel from '@/components/Notifications/NotificationCarousel.vue';

export default {
  name: 'BasePage',
  components: {
    NotificationCarousel,
    Banner,
    StatusCounts,
  },
  props: {
    showStatusHeader: {
      type: Boolean,
      default: false
    },
    statusHeader:{
      type: Array,
      default: () => []
    },
    showBanner: {
      type: Boolean,
      default: true
    },
    showNotifications: {
      type: Boolean,
      default: false
    },
    banner: {
      type: Object,
      default: () => ({
        heading: '',
        subtitle: '',
        icon: '',
        iconSize: '3.5rem',
        showStatusCounts: false
      })
    },
    applyMaxWidth: {
      type: Boolean,
      default: false
    },
    card: {
      type: Object,
      default: () => ({
        maxSize: 424, // card actual size is 400
        minSize: 374 // card actual size is 350
      })
    },
    mobileBreakpoint: {
      type: Number,
      default: 620
    }
  },
  data() {
    return {
      containerMaxWidth: '',
      cardSize: 350,
      notifications: []
    };
  },
  computed: {
    ...mapGetters({
      allNotifications: 'metadata/notifications',
      userProfile: 'users/userProfile',
    }),
  },
  watch: {
    $route() {
      this.getContainerMaxWidth();
    },
    userProfile(val) { // set user specific messages
      const allDismissed = val?.dismissedNotifications || []
      const preferredResourceCategories = val?.preferences?.resourceCategory || []
      this.notifications = this.allNotifications.filter(n => {
        // if no category defined just return undismissed notifications
        if (!n.category || n.category === '') {
          return !allDismissed.includes(n.id)
        } else {
          return !allDismissed.includes(n.id) && preferredResourceCategories.includes(n.category)
        }
      })
    },
    cardSize(val) {
      if (val !== 0) {
        this.$emit('get:cardSize', val);
      }
    },
    applyMaxWidth: {
      immediate: true,
      handler(val) {
        let maxWidth;
        if (this.containerMaxWidth) {
          maxWidth = this.containerMaxWidth.substring(
            0,
            this.containerMaxWidth.length - 2
          );
        }
        // maximum width for list view
        if (val && maxWidth > 1400) {
          this.containerMaxWidth = '1400px';
        }
      }
    }
  },
  mounted() {
    this.$nextTick(this.handleResize);
  },
  methods: {
    handleResize() {
      let timeInMs = 200;
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          this.getContainerMaxWidth();
        }, timeInMs);
      } else {
        timeInMs = 200;
        timeoutId = setTimeout(() => {
          this.getContainerMaxWidth();
        }, timeInMs);
      }
    },
    getContainerMaxWidth() {
      let maxWidth = '';
      let availableWidth = 0;
      const container = document.getElementById('page__wrapper');

      if (container) {
        availableWidth = container.clientWidth;
      }

      const horizontalMarginSummed = this.$vuetify.breakpoint.mdAndDown ? 24 : 48; // 12px and 24px respectively

      if (availableWidth && this.$vuetify.breakpoint.width >= this.mobileBreakpoint
      ) {
        // calculate how many cards fit in a row considering the minimum width
        let cardsInARow = Math.floor((availableWidth - horizontalMarginSummed) / this.card.minSize);
        if ((availableWidth - horizontalMarginSummed) / cardsInARow > this.card.maxSize) {
          // apply maximum card width if the
          this.cardSize = this.card.maxSize - 24; // size of the card minus the padding
        } else {
          this.cardSize = (availableWidth - horizontalMarginSummed) / cardsInARow - 24;
        }

        const cardsWidthSummed = this.cardSize * cardsInARow;
        const cardsGuttersSummed = (cardsInARow * 2 - 2) * 12; // subtract twelve because the first and last cards in a row have their first and last padding ignored

        maxWidth = cardsWidthSummed + cardsGuttersSummed;

        this.containerMaxWidth = maxWidth.toString() + 'px';
      } else {
        this.containerMaxWidth = '100vw';
        this.cardSize = availableWidth;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page__wrapper {
  #main-content {
    margin: 0;
    position: relative;
  }
  .status-header{
    position: absolute;
    top: 10px;
    right: 50px;
    display: flex;
    justify-content: center;
    gap: 15px;

    & .v-card{
      width: 150px;
      height: 120px;
      background: $ads-primary-blue;

      display: flex;
      justify-content: center;
      flex-flow: column;

      p {
        font-weight: bold;
        font-size: 24px;
        color: $ads-white;
      }

      span {
        display: block;
        text-align: center;
        color: $ads-blue-2;
      }
    }

  }

  .page-content__container {
    min-height: 100vh;
    padding: 0;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 960px) {
  .page__wrapper {
    .page-content__container {
      margin-top: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .page__wrapper {
    .page-content__container {
      margin-top: 12px;
    }
  }
}
</style>
