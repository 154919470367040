<template>
  <FullscreenDialog
    id="error-page"
    v-model="showDialog"
    :transition="false"
    :showCloseButton="false"
    :title="heading"
    class="error-page"
  >
    <template #content>
      <v-container>
        <v-row class="error-page__wrapper">
          <v-col
            class="error-page__col"
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="keyword"
              label="Search text"
              outlined
              @change="getResults"
            ></v-text-field>
            <v-list>
              <v-list-item class="d-block" v-for="item in results">
                <v-list-item-title><h4>{{item.name.text}}</h4></v-list-item-title>
                <v-list-item-content>{{ item.description.text }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>

    </template>
  </FullscreenDialog>
</template>

<script>
import { FeatureIcon, AdsButton, FullscreenDialog } from '@nswdoe/doe-ui-core';
import {apiBaseUrlV2} from '@/store/config';

export default {
  name: 'ResourceNotFound',
  components: {
    FullscreenDialog
  },
  data() {
    return {
      showDialog: false,
      heading: 'Semantic Search Demo',
      results: [],
      temp: [
        {
          name: {
            text: 'English Stage 1 - Unit 5 - Perspective and argument'
          },
          description: {
            text: 'Students identify persuasive techniques used in a range of humorous metafiction picture books. These techniques will then be used as students create their own texts to persuade a familiar audience.'
          }
        },
        {
          name: {
            text: 'Main idea teaching strategies for Years 3 to 4'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to identify the main idea.These activities will support students to find the main idea in persuasive, imaginative and informative texts by using strategies of scanning quickly to identify the main ideas in a text, and skimming to find key words.This resource can be used to support students to identify the main idea in texts. This resource might be used when students can recognise topic sentences and supporting ideas.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Text features teaching strategies for Years 7 to 8'
          },
          description: {
            text: 'A series of learning activities that explicitly teach identifying and analysing the features of a range of types of text.These activities support students in identifying and analysing the features of a range of types of text. Students examine how layout, punctuation and key features shape imaginative, informative and persuasive texts.This resource can be used to develop student skill in identifying and analysing text features across a range of types of texts and subject areas. Students consider punctuation in context and the effect of different fonts before identifying and analysing key features of imaginative, informative and persuasive texts. These activities can be easily used and adapted across KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying and analysing text features across types of text and KLAs.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Main idea teaching strategies for Years 5 to 6'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to identify and interpret the main idea.These activities will support students to find the main idea in persuasive, imaginative and informative texts by using strategies of scanning quickly to identify the main ideas in a text, and skimming to find key words.This resource can be used to support students to identify the main idea in texts. This resource might be used when students can recognise topic sentences and supporting ideas.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'English Stage 1 - Unit 24 - Perspective and argument'
          },
          description: {
            text: 'Students build on their understanding of perspective and argument through exploring animal characters with human characteristics. They will have opportunities to engage with informative texts and explore how information can be used to support opinions in persuasive texts.'
          }
        },
        {
          name: {
            text: 'Main idea teaching strategies for years 7 to 8'
          },
          description: {
            text: 'Description:A series of learning tasks to explicitly teach students to identify and interpret the main idea.These activities will support students to identify the main idea using the GIST process with increasingly complex imaginative, persuasive and informative texts. Students learn to identify the difference between main idea and main argument in persuasive texts, and to identify and explain themes. They will also learn to identify generalisations in texts and how to avoid making assumptions. These activities can be easily used and adapted across all KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Text structure teaching strategies for Years 7 to 8'
          },
          description: {
            text: 'A series of learning activities to explicitly teach students to identify and critically analyse key structural features of a range of texts.These activities support students to develop skill in identifying and critically analysing the structural features of imaginative, informative and persuasive texts.This resource can be used to further develop student skill in identifying and analysing the key structural elements of imaginative, informative and persuasive texts. These activities can be easily used and adapted across KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying and analysing text features across types of text and KLAs.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Audience and purpose teaching strategies for Years 5 to 6'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach audience and purpose.These tasks will support students to identify a text\'s intended audience and purpose, and how authors use language and text structure to craft a text for a particular audience and purpose.This resource can be used to develop student understanding of how authors construct texts for a particular audience and purpose. This resource might be used when students are learning about the structural and text features of imaginative, persuasive and informative types of text, and are learning about why and how texts are constructed.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying audience and purpose and how these are established. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Audience and purpose teaching strategies for Years 3 to 4'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach audience and purpose.These tasks will support students to identify a text\'s intended audience and purpose, and how authors use language and text structure to craft a text for a particular audience and purpose. This resource can be used to develop student understanding of how authors construct texts for a particular audience and purpose.This resource might be used when students are learning about the structural and textual features of imaginative, persuasive and informative types of text, and are learning about why and how texts are constructed.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying audience and purpose and how these are established. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Literary devices teaching strategies for Years 9 to 10'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to interpret and analyse a range of literary devices.These tasks will support students to interpret and analyse a range of literary devices. Students examine how literary devices are used to make meaning across a range of texts.This resource can be used to develop student skill in recognising, interpreting and analysing a range of literary devices. Students identify and analyse literary devices and figurative language, including irony and satire. While these devices are most often studied in the context of the English KLA, they are used across a wide range of texts, and used heavily in both imaginative and persuasive texts. As such, the activities can be easily used and adapted across all KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in how to interpret and analyse a range of literary devices.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: ' Main idea and theme teaching strategies for years 9 to 10'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to identify and interpret the main idea.These activities will support students to identify the main idea using the GIST process with increasingly complex imaginative, persuasive and informative texts. Students learn to identify the difference between main idea and theme in a range of texts. They will learn to compare and contrast�information across texts and synthesize information. These activities can be easily used and adapted across all KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Audience and purpose teaching strategies for Years 7 to 8'
          },
          description: {
            text: 'A series of learning activities to explicitly teach audience and purpose.These tasks will support students to identify the intended audience and purpose of a text. Students consider how language choices and text structure are used to craft texts for a particular audience and purpose.This resource can be used to strengthen student understanding of how composers construct texts for particular audiences and purposes. Teachers might use these activities when considering the structural and language features of imaginative, persuasive and informative types of text. They may also use them when students are considering what a given text is for and why it was constructed in a given way.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying and analysing audience and purpose and how these are established.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Evaluating sources teaching strategies for Years 5 to 6'
          },
          description: {
            text: 'A series of learning activities to explicitly teach how to evaluate sources.These activities will support students to develop skills in evaluating the accuracy of statements and sources. Students examine statements and sources across types of text, learning how to evaluate accuracy and objectivity.This resource can be used to develop student understanding of how to evaluate sources.This resource might be used when students have explored author perspective, and audience and purpose in informative, imaginative and persuasive types of text.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in evaluating sources. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Text structure and features teaching strategies for Years 9 to 10'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to critically analyse the structural and language features of a variety of texts.These tasks will support students to critically analyse the structural and language features of a variety of texts, and how authors use language and text structure to craft a text for a particular audience and purpose.This resource can be used to further develop student understanding of the structural and text features of imaginative, persuasive and informative types of text, and about why and how texts are constructed. These activities can be easily used and adapted across KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in the structure of texts and the textual features of a variety of text types. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Making meaning - revise'
          },
          description: {
            text: 'Practise using images, shapes and colours to add meaning to your writing.For example: using dark colours to represent a stormy day.'
          }
        },
        {
          name: {
            text: 'Text structure teaching strategies for Years 5 to 6'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to analyse structural features of texts.These activities will support students to analyse the structure of different types of text. Students learn to identify structural and language features, such as headings and sub-headings, in different genres.This resource can be used to support students to recognise different structures of texts. This resource might be used when students are familiar with the purpose of imaginative, informative and persuasive types of text.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in recognising structural features of texts.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Fact and opinion teaching strategies for Years 3 to 4'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to recognise what is fact and what is opinion.These activities will support student understanding on the difference between fact and opinion and to learn to recognise both within a range of texts.This resource can be used to support students to recognise what is fact and what is opinion to evaluate texts.This resource might be used when students have explored persuasive text language and features and author perspective.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying fact and opinion. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Fact and opinion teaching strategies for Years 5 to 6'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to recognise what is fact and what is opinion.These activities will support student understanding on the difference between fact and opinion and to learn to recognise both within a range of texts.This resource can be used to support students to recognise what is fact and what is opinion to evaluate texts.This resource might be used when students have explored persuasive text language and features and author perspective.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying fact and opinion. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        },
        {
          name: {
            text: 'Years 7 to 8 Audience and purpose'
          },
          description: {
            text: 'A series of explicit teaching activities to enable students to understand, identify and analyse audience and purpose in a variety of texts. This series of explicit teaching activities supports students to apply their skills in identifying audience and purpose, and to be able to justify their reasoning. This resource supports students to understand and identify audience and purpose. Students might explore audience and purpose after learning about text structure and language features particular to informative, imaginative and persuasive texts. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation suggestions are included. Students are challenged to find or create their on texts for analysis by peers of audience and purpose.'
          }
        },
        {
          name: {
            text: 'Stage 5 reading - Vocabulary in context'
          },
          description: {
            text: 'A series of learning tasks to explicitly teach students to understand and use precise vocabulary in a wide range of academic contexts.Students examine morphology, nuance and other elements of precise vocabulary use. These tasks will support students to understand and use precise vocabulary in a wide range of academic contexts, and how authors use language and text structure to craft a text for a particular audience and purpose.This resource can be used to develop student understanding of how authors use precise vocabulary in a wide range of academic contexts. This resource might be used when students are learning about the structural and text features of imaginative, persuasive and informative types of text, and are learning about why and how texts are constructed. The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in understanding and using precise vocabulary in a wide range of academic contexts.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
          }
        }
      ],
      keyword: ''
    }
  },
  watch: {
    // keyword(v) {
    //   console.log('value', v)
    // },
    showDialog(val) {
      if (!val) {
        this.$router.back();
      } else {
        this.$store.commit('errors/SET_ERROR_TYPE', '');
      }
    },
  },
  methods: {
    getResults() {
      console.log(this.keyword)
      if (this.keyword) {
        fetch(`${apiBaseUrlV2}/search-semantic?keyword=${this.keyword}`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'source': 'semantic_embeddings',
            'uid': 'sam.roku',
            'application': 'urh_dlrh'
          },
        })
          .then(res => {
            return res.json();
          }).then(this.setResults);
      }
      console.log('input change', this.keyword)
    },
    setResults(v){
      const results = {
        resources: [
          {
            name: {
              text: 'English Stage 1 - Unit 5 - Perspective and argument'
            },
            description: {
              text: 'Students identify persuasive techniques used in a range of humorous metafiction picture books. These techniques will then be used as students create their own texts to persuade a familiar audience.'
            }
          },
          {
            name: {
              text: 'Main idea teaching strategies for Years 3 to 4'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to identify the main idea.These activities will support students to find the main idea in persuasive, imaginative and informative texts by using strategies of scanning quickly to identify the main ideas in a text, and skimming to find key words.This resource can be used to support students to identify the main idea in texts. This resource might be used when students can recognise topic sentences and supporting ideas.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Text features teaching strategies for Years 7 to 8'
            },
            description: {
              text: 'A series of learning activities that explicitly teach identifying and analysing the features of a range of types of text.These activities support students in identifying and analysing the features of a range of types of text. Students examine how layout, punctuation and key features shape imaginative, informative and persuasive texts.This resource can be used to develop student skill in identifying and analysing text features across a range of types of texts and subject areas. Students consider punctuation in context and the effect of different fonts before identifying and analysing key features of imaginative, informative and persuasive texts. These activities can be easily used and adapted across KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying and analysing text features across types of text and KLAs.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Main idea teaching strategies for Years 5 to 6'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to identify and interpret the main idea.These activities will support students to find the main idea in persuasive, imaginative and informative texts by using strategies of scanning quickly to identify the main ideas in a text, and skimming to find key words.This resource can be used to support students to identify the main idea in texts. This resource might be used when students can recognise topic sentences and supporting ideas.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'English Stage 1 - Unit 24 - Perspective and argument'
            },
            description: {
              text: 'Students build on their understanding of perspective and argument through exploring animal characters with human characteristics. They will have opportunities to engage with informative texts and explore how information can be used to support opinions in persuasive texts.'
            }
          },
          {
            name: {
              text: 'Main idea teaching strategies for years 7 to 8'
            },
            description: {
              text: 'Description:A series of learning tasks to explicitly teach students to identify and interpret the main idea.These activities will support students to identify the main idea using the GIST process with increasingly complex imaginative, persuasive and informative texts. Students learn to identify the difference between main idea and main argument in persuasive texts, and to identify and explain themes. They will also learn to identify generalisations in texts and how to avoid making assumptions. These activities can be easily used and adapted across all KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Text structure teaching strategies for Years 7 to 8'
            },
            description: {
              text: 'A series of learning activities to explicitly teach students to identify and critically analyse key structural features of a range of texts.These activities support students to develop skill in identifying and critically analysing the structural features of imaginative, informative and persuasive texts.This resource can be used to further develop student skill in identifying and analysing the key structural elements of imaginative, informative and persuasive texts. These activities can be easily used and adapted across KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying and analysing text features across types of text and KLAs.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Audience and purpose teaching strategies for Years 5 to 6'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach audience and purpose.These tasks will support students to identify a text\'s intended audience and purpose, and how authors use language and text structure to craft a text for a particular audience and purpose.This resource can be used to develop student understanding of how authors construct texts for a particular audience and purpose. This resource might be used when students are learning about the structural and text features of imaginative, persuasive and informative types of text, and are learning about why and how texts are constructed.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying audience and purpose and how these are established. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Audience and purpose teaching strategies for Years 3 to 4'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach audience and purpose.These tasks will support students to identify a text\'s intended audience and purpose, and how authors use language and text structure to craft a text for a particular audience and purpose. This resource can be used to develop student understanding of how authors construct texts for a particular audience and purpose.This resource might be used when students are learning about the structural and textual features of imaginative, persuasive and informative types of text, and are learning about why and how texts are constructed.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying audience and purpose and how these are established. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Literary devices teaching strategies for Years 9 to 10'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to interpret and analyse a range of literary devices.These tasks will support students to interpret and analyse a range of literary devices. Students examine how literary devices are used to make meaning across a range of texts.This resource can be used to develop student skill in recognising, interpreting and analysing a range of literary devices. Students identify and analyse literary devices and figurative language, including irony and satire. While these devices are most often studied in the context of the English KLA, they are used across a wide range of texts, and used heavily in both imaginative and persuasive texts. As such, the activities can be easily used and adapted across all KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in how to interpret and analyse a range of literary devices.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: ' Main idea and theme teaching strategies for years 9 to 10'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to identify and interpret the main idea.These activities will support students to identify the main idea using the GIST process with increasingly complex imaginative, persuasive and informative texts. Students learn to identify the difference between main idea and theme in a range of texts. They will learn to compare and contrast�information across texts and synthesize information. These activities can be easily used and adapted across all KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in finding the main idea.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Audience and purpose teaching strategies for Years 7 to 8'
            },
            description: {
              text: 'A series of learning activities to explicitly teach audience and purpose.These tasks will support students to identify the intended audience and purpose of a text. Students consider how language choices and text structure are used to craft texts for a particular audience and purpose.This resource can be used to strengthen student understanding of how composers construct texts for particular audiences and purposes. Teachers might use these activities when considering the structural and language features of imaginative, persuasive and informative types of text. They may also use them when students are considering what a given text is for and why it was constructed in a given way.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying and analysing audience and purpose and how these are established.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Evaluating sources teaching strategies for Years 5 to 6'
            },
            description: {
              text: 'A series of learning activities to explicitly teach how to evaluate sources.These activities will support students to develop skills in evaluating the accuracy of statements and sources. Students examine statements and sources across types of text, learning how to evaluate accuracy and objectivity.This resource can be used to develop student understanding of how to evaluate sources.This resource might be used when students have explored author perspective, and audience and purpose in informative, imaginative and persuasive types of text.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in evaluating sources. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Text structure and features teaching strategies for Years 9 to 10'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to critically analyse the structural and language features of a variety of texts.These tasks will support students to critically analyse the structural and language features of a variety of texts, and how authors use language and text structure to craft a text for a particular audience and purpose.This resource can be used to further develop student understanding of the structural and text features of imaginative, persuasive and informative types of text, and about why and how texts are constructed. These activities can be easily used and adapted across KLAs.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in the structure of texts and the textual features of a variety of text types. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Making meaning - revise'
            },
            description: {
              text: 'Practise using images, shapes and colours to add meaning to your writing.For example: using dark colours to represent a stormy day.'
            }
          },
          {
            name: {
              text: 'Text structure teaching strategies for Years 5 to 6'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to analyse structural features of texts.These activities will support students to analyse the structure of different types of text. Students learn to identify structural and language features, such as headings and sub-headings, in different genres.This resource can be used to support students to recognise different structures of texts. This resource might be used when students are familiar with the purpose of imaginative, informative and persuasive types of text.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in recognising structural features of texts.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Fact and opinion teaching strategies for Years 3 to 4'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to recognise what is fact and what is opinion.These activities will support student understanding on the difference between fact and opinion and to learn to recognise both within a range of texts.This resource can be used to support students to recognise what is fact and what is opinion to evaluate texts.This resource might be used when students have explored persuasive text language and features and author perspective.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying fact and opinion. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Fact and opinion teaching strategies for Years 5 to 6'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to recognise what is fact and what is opinion.These activities will support student understanding on the difference between fact and opinion and to learn to recognise both within a range of texts.This resource can be used to support students to recognise what is fact and what is opinion to evaluate texts.This resource might be used when students have explored persuasive text language and features and author perspective.The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in identifying fact and opinion. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          },
          {
            name: {
              text: 'Years 7 to 8 Audience and purpose'
            },
            description: {
              text: 'A series of explicit teaching activities to enable students to understand, identify and analyse audience and purpose in a variety of texts. This series of explicit teaching activities supports students to apply their skills in identifying audience and purpose, and to be able to justify their reasoning. This resource supports students to understand and identify audience and purpose. Students might explore audience and purpose after learning about text structure and language features particular to informative, imaginative and persuasive texts. Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation suggestions are included. Students are challenged to find or create their on texts for analysis by peers of audience and purpose.'
            }
          },
          {
            name: {
              text: 'Stage 5 reading - Vocabulary in context'
            },
            description: {
              text: 'A series of learning tasks to explicitly teach students to understand and use precise vocabulary in a wide range of academic contexts.Students examine morphology, nuance and other elements of precise vocabulary use. These tasks will support students to understand and use precise vocabulary in a wide range of academic contexts, and how authors use language and text structure to craft a text for a particular audience and purpose.This resource can be used to develop student understanding of how authors use precise vocabulary in a wide range of academic contexts. This resource might be used when students are learning about the structural and text features of imaginative, persuasive and informative types of text, and are learning about why and how texts are constructed. The activities in this resource can be used as a learning sequence or as standalone activities targeting specific skills in understanding and using precise vocabulary in a wide range of academic contexts.Teachers can adapt the activities to current units of learning. There are opportunities for variations to learning and differentiation within most tasks. This includes the use of pair and small group structures, offering opportunities for peer support as well as oral practice and experimentation with target language and ideas.'
            }
          }
        ]
      }
      this.results = v?.resources
      //this.results= v
    }
  },
  created() {
    this.showDialog = true;
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-dialog {
  .v-card {
    .contentWrapper {
      .error-page__wrapper {
        height: calc(100vh - 64px);
        display: flex;
        justify-content: center;
        padding-top: 72px;

        &.row {
          margin: 0;
        }

        .error-page__card {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px;

          .error-page__title {
            padding: 12px;
            color: $ads-navy;
            text-align: center;
          }

          .error-page__subtitle {
            font-size: 18px;
            text-align: center;
          }

          .error-page--actions__wrapper {
            padding-top: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .v-dialog {
    .v-card {
      .contentWrapper {
        .error-page__wrapper {
          padding-top: 0;
          height: calc(100vh - 56px);

          .error-page__col {
            padding: 0;
          }

          .error-page__card {
            justify-content: start;
            border-radius: 0;
            height: 100%;
            padding: 40px 16px;

            .error-page__title {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
