<template>
  <BasePage
    :banner="banner"
    :card="{maxSize: 424, minSize: $vuetify.breakpoint.mdAndDown ? 288 : 310}"
    :applyMaxWidth="!showGridView"
    @get:cardSize="getCardSize"
  >
    <AdsTextField
      v-model="searchString"
      solo
      label="Search within My Resources"
      prepend-inner-icon="search"
      clearable
      flat
      :rules="[ validCharacters ]"
      :hide-details="!searchFieldError"
      class="search-bar"
      @update:error="handleError"
    />
    <FiltersMyResources />
    <template v-if="isSearching">
      <ResultsHeader
        :count="$store.getters['myResources/searchResultsCount']"
        :sorting="$store.getters['myResources/sorting']"
        isMyResources
        @toggle:view="getToggleView"
      />
      <ResourcesResults
        :loading="$store.getters['users/loading']"
        :resources="$store.getters['myResources/searchResults']"
        :gridView="showGridView"
        :cardSize="cardSize"
        isMyResources
      />
    </template>
    <template v-if="!isSearching">
      <LandingView :loading="$store.state.isLoading" />
    </template>
    <router-link
      v-if="$vuetify.breakpoint.xsOnly"
      to="/myresources/new"
    >
      <FeatureIcon
        size="60"
        icon-size="36"
        :color="ADS_Colors.White"
        :background-color="ADS_Colors.Navy"
        icon="add"
        class="create-new-button"
      />
    </router-link>
  </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage';
import ResultsHeader from '@/components/ResultsHeader/ResultsHeader';
import ResourcesResults from '@/views/MyResourcesPage/ResourcesResults/ResourcesResults';
import FiltersMyResources from '@/views/MyResourcesPage/FiltersMyResources';
import LandingView from '@/views/MyResourcesPage/LandingView';
import {AdsTextField, FeatureIcon, ADS_Colors} from '@nswdoe/doe-ui-core';

export default {
  name: 'MyResourcesPage',
  components: {
    AdsTextField,
    LandingView,
    FiltersMyResources,
    ResourcesResults,
    ResultsHeader,
    BasePage,
    FeatureIcon
  },
  data() {
    return {
      ADS_Colors,
      banner: {
        heading: 'My Resources',
        subtitle: 'A space where you can create, store, and collaborate with peers',
        icon: 'collections_bookmark'
      },
      searchFieldError: false,
      showGridView: true,
      cardSize: 350,
      validCharacters: v => !v || /^[a-zA-Z0-9 '.\-+=&_/"]*$/.test(v) || 'Search parameters cannot include the special characters used'
    };
  },
  computed: {
    searchString: {
      get() {
        return this.$store.state.myResources.keywords;
      },
      set(value) {
        // when click the X button in the search bar, value becomes null
        if (value === null || value === '') {
          this.$store.commit('myResources/SET_KEYWORDS', '');
          this.$store.commit('myResources/SET_SEARCH_MODE', false);
        }
        if (value?.length >= 3) {
          this.$store.commit('myResources/SET_KEYWORDS', value);
          this.$store.commit('myResources/SET_SEARCH_MODE', true);
          this.$store.dispatch('myResources/fetchResources');
        }
      }
    },
    /*
    The isSearching computed property is in charge of the <template> conditional rendering.
    For landing view, searchMode = false. For searching view, searchMode = true.
     */
    isSearching() {
      if (this.$store.state.myResources.searchMode ||
        this.searchString && this.searchString.length >= 3 ||
        !this.$store.getters['myResources/isFiltersEmpty']) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    /*
    Watch if the computed property's value changes.
    If becomes false, the watcher performs RESET_SEARCH and call getScrollableViewResources() for the landing view.
     */
    async isSearching(val) { // true -> false, or false -> true
      this.$store.commit('myResources/SET_SEARCH_MODE', val);
      if (!val) {
        this.$store.commit('myResources/RESET_SEARCH');
        await this.getScrollableViewResources();
      }
    }
  },
  mounted() {
    this.getScrollableViewResources();
  },
  methods: {
    handleError(val) {
      this.searchFieldError = val;
    },
    async getScrollableViewResources() {
      this.$store.commit('SET_IS_LOADING', true);
      let createdByMePromise = this.$store.dispatch('myResources/fetchResources', 'createdByMe');
      let sharedWithMePromise = this.$store.dispatch('myResources/fetchResources', 'sharedWithMe');
      let sharedByMePromise = this.$store.dispatch('myResources/fetchResources', 'sharedByMe');

      try {
        await Promise.all([createdByMePromise, sharedByMePromise, sharedWithMePromise]);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
      }
    },
    getCardSize(val) {
      this.cardSize = val;
    },
    getToggleView(val) {
      if (val === 0) {
        this.showGridView = true;
      }
      if (val === 1) {
        this.showGridView = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-new-button {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 25%);
  position: fixed;
  bottom: 12px;
  right: 12px;
}

@media only screen and (max-width: 620px) {
  .search-bar {
    padding: 0 12px;
  }
}
</style>
