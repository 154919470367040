<template>
  <Dialog
    v-bind="$attrs"
    icon="delete_outline"
    title="Are you sure you want to delete this resource card?"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        class="mt-3 pb-0"
        :style="{'color': ADS_Colors.Dark}"
      >
        <p class="mb-1">
          You will not be able to undo this action.
        </p>
        <p>
          <strong>Note:</strong> If you are the creator, this action will delete the resource card for every user that
          it was shared with.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between px-6 pb-6">
        <v-spacer />
        <AdsButton
          button-text="Cancel"
          @click="$downwardBroadcast('close:dialog')"
          @keyup.enter="$downwardBroadcast('close:dialog')"
        />
        <AdsButton
          :loading="loading"
          :color="ADS_Colors.Red_Dark"
          secondary
          button-text="Delete"
          @click="handleDelete"
          @keyup.enter="handleDelete"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'DeleteResourceDialog',
  components: {
    Dialog,
    AdsButton
  },
  props: {
    resourceId: {
      type: String,
      default: ''
    },
    resourceName: {
      type: String,
      default: ''
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isEditor: {
      type: Boolean,
      default: false
    },
    isViewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
      loading: false
    }
  },
  methods: {
    handleDelete: async function () {
      this.loading = true;
      if (this.isOwner) {
        await this.$store.dispatch('myResources/deleteResource', this.resourceId);
      } else {
        const payload = {
          action: 'removeaccess',
          resourceId: this.resourceId,
          resourceName: this.resourceName,
          userAccess: {
            accessFor: this.$store.state.uid.toLowerCase(),
            accessScope: this.isEditor ? 'editor' : 'viewer'
          }
        };
        await this.$store.dispatch('myResources/shareThisResource', payload);
        await this.$store.dispatch('myResources/fetchResources', 'sharedWithMe');
      }

      this.loading = false;
      this.$downwardBroadcast('close:dialog');
      if (this.$route.name === 'MyResourceDetailsPage') {
        await this.$router.push('/myresources');
      }
    }
  }
}
</script>
