<template>
  <div
    class="carousel-item__wrapper"
    aria-live="polite"
  >
    <v-row class="ma-0">
      <v-col
        cols="12"
        :class="{'px-6': $vuetify.breakpoint.width >= 740, 'px-3 pt-6': $vuetify.breakpoint.width < 740 }"
      >
        <h1 class="mb-3">
          Welcome {{ $store.getters['users/userProfile'] && $store.getters['users/userProfile'].firstName }}
        </h1>
        <p :class="{'body-1': $vuetify.breakpoint.width >= 740, 'body-2': $vuetify.breakpoint.width < 740}">
          Welcome to the Universal Resources Hub!
        </p>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col
        cols="12"
        lg="5"
        md="5"
        :sm="$vuetify.breakpoint.width < 740 ? '12' : '5'"
        :class="{'pl-6 pr-0 py-0': $vuetify.breakpoint.width >= 740, 'px-3': $vuetify.breakpoint.width < 740}"
      >
        <p :class="{'body-1': $vuetify.breakpoint.width >= 740, 'body-2': $vuetify.breakpoint.width < 740}">
          The Universal Resources Hub (previously known as Digital Learning Resources Hub) supports both
          the
          teaching and learning cycle and now the school improvement cycle through the provision of quality assured
          resources.
        </p>
        <p :class="{'body-1': $vuetify.breakpoint.width >= 740, 'body-2': $vuetify.breakpoint.width < 740}">
          You are invited to choose or update your areas of interest. These include both those associated with the
          teaching and learning and school improvement cycles.
        </p>
        <p :class="{'body-1': $vuetify.breakpoint.width >= 740, 'body-2': $vuetify.breakpoint.width < 740}">
          Making selections on the following pages will help us to suggest relevant resources. You can update this in
          your
          settings at any time.
        </p>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.width >= 740"
        class="pa-0"
        cols="12"
        lg="7"
        md="7"
        sm="7"
      >
        <v-img
          :max-height="500"
          :src="onboardingVector"
          style="margin-top: -40px;"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import onboardingVector from '@/assets/onboarding-vector.svg';

export default {
  name: 'Initial',
  data() {
    return {
      onboardingVector,
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 740px) {
  .carousel-item__wrapper {
    overflow: auto;
    height: calc(100vh - 182px);
    margin: 56px 0 126px 0;
  }
}
</style>
