<template>
  <v-btn-toggle
    v-model="toggleCardView"
    active-class="active"
    color="primary"
    mandatory
    class="ml-4"
  >
    <v-btn
      aria-label="grid"
      :aria-pressed="String(toggleCardView === 0)"
    >
      <v-icon>grid_on</v-icon>
    </v-btn>
    <v-btn
      aria-label="list"
      :aria-pressed="String(toggleCardView === 1)"
    >
      <v-icon>list</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'GridOrListToggle',
  computed: {
    toggleCardView: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables.scss';

.v-btn-toggle {
  max-height: 48px;
}

.active {
  background-color: $ads-navy !important;

  .v-icon {
    color: $ads-white !important;
  }

  &:focus {
    outline: 3px solid $ads-navy !important;
  }
}
</style>
