<template>
  <v-combobox
    v-bind="$attrs"
    v-model="value"
    class="select-access"
    outlined
    v-on="$listeners"
  >
    <template
      #item="{ item }"
    >
      <v-list-item-content>
        <v-list-item-title v-html="item.text" />
      </v-list-item-content>
    </template>
  </v-combobox>
</template>
<script>
export default {
  name: 'SetAccess',
  computed: {
    value: {
      get() {
        return this.$attrs.value
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .v-input.select-access{
    flex: 0 1 18%;
  }
</style>
