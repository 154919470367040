<template>
  <div class="main-content d-flex flex-column mt-10">
    <div>
      <AdsButton
        :class="{'mr-6 ml-4': $vuetify.breakpoint.smAndUp, 'fullwidth': $vuetify.breakpoint.xsOnly}"
        class="mb-6"
        secondary
        buttonText="Done"
        @click="confirmSequence"
      />
    </div>
    <draggable
      v-model="resources"
      class="draggabble"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <v-col
        v-for="(item, index) in resources"
        :key="index"
        :class="{'px-0': $vuetify.breakpoint.width < 620}"
        class="draggabble-container"
      >
        <ResourceCard
          v-if="$vuetify.breakpoint.smAndUp"
          :sm="$vuetify.breakpoint.width < 1700 ? '4': '3'"
          :enableSelection="false"
          :enableNavigation="false"
          :item="item"
          :rounded="$vuetify.breakpoint.width >= 620"
          :class=" startSequence ? 'draggabble-card': ''"
          :sequenceOrder="true"
          :itemLength="resources.length"
          :itemIndex="index"
        />

        <ResourceList
          v-else
          :item="item"
          :enableSelection="false"
          :enableNavigation="false"
          :hideAction="true"
          :show-recently-added-badge="false"
          :hideDescription="true"
          :hideLearningAreas="true"
          :hideUpdatedDate="true"
          :showCheckbox="false"
          :statusChip="true"
          :sequenceOrder="true"
          :itemLength="resources.length"
          :itemIndex="index"
        />
      </v-col>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { ADS_Colors, AdsButton } from '@nswdoe/doe-ui-core';
import { mapState } from 'vuex';
import ResourceCard from '@/components/Resource/ResourceCard.vue';
import ResourceList from '@/components/Resource/ResourceListCard.vue'
import {ResourceStatus} from '@/constants';
export default {
  name: 'LearningSequence',
  components:{
    draggable,
    AdsButton,
    ResourceCard,
    ResourceList
  },
  props:{
    items: {
      type: Array,
      default: null
    },
  },
  data(){
    return{
      startSequence: true,
      ADS_Colors,
      cardSize: 350,
      containerMaxWidth: '',
      showResources: false,
      heading: 'Click and drag resources into an order of sequence. This will help your colleagues understand the recommended order resource use',
      // sequenceResources: null
    }
  },

  computed: {
    ...mapState({
      collection: state => state.myCollections.collectionDetails || '',
    }),
    subResources () {
      return this.$store.state.myCollections.subResources
    },
    unpublishedResources () {
      return this.subResources?.map(item => {
        const resourceDetails = this.getSubResource(item.record.id)
        return !resourceDetails && {
          resourceId: {
            id: item.record.id,
            source: item.record.collection,
            application: item.audit.iss
          }
        }
      }).filter(Boolean)
    },
    resources: {
      get() {
        return this.collection.subResources?.map(item => {
          const resourceDetails = this.getSubResource(item.resourceId.id)
          return resourceDetails && {
            resourceId: item.resourceId,
            ThumbnailImage: item.image,
            DateAdded: item.dateAdded,
            ...resourceDetails?.document,
            file: resourceDetails?.files?.[0]
          }
        }).filter(Boolean)
      },
      set(v) {
        this.collection.subResources = v
      }
    },
    dragOptions(){
       return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
  },
  mounted(){
    this.getContainerMaxWidth()
  },
  methods:{
    getSubResource(id) {
      return this.subResources.find(resource => resource.document.ItemID === id)
    },
    checkWriteAccess(userAccess) {
      if (!userAccess) return false
      const userId = this.$store.getters['users/userProfile'].userId.toLowerCase();
      return this.isEditor(userAccess.editor, userId) || this.isOwner(userAccess.owner, userId)
    },
    async confirmSequence(){
      await this.$store.dispatch('myCollections/sequenceOrder', {
        ...this.collection,
        subResources: [...this.collection.subResources, ...this.unpublishedResources]
      })
      this.$emit('confirmSequence')
      if (this.$vuetify.breakpoint.xsOnly){
        this.$emit('modalClose')
      } else {
        this.$emit('go:back')
      }
      // this.startSequence = false
    },

    getContainerMaxWidth() {
      let maxWidth = '';
      let availableWidth = 0;
      const container = document.querySelector('.draggabble');

      if (container) {
        availableWidth = container.clientWidth;
      }

      const horizontalMarginSummed = this.$vuetify.breakpoint.mdAndDown ? 24 : 48; // 12px and 24px respectively

      if (availableWidth && this.$vuetify.breakpoint.width >= this.mobileBreakpoint
      ) {
        // calculate how many cards fit in a row considering the minimum width
        let cardsInARow = Math.floor((availableWidth - horizontalMarginSummed) / this.card.minSize);
        console.log(cardsInARow);
        if ((availableWidth - horizontalMarginSummed) / cardsInARow > this.card.maxSize) {
          // apply maximum card width if the
          this.cardSize = this.card.maxSize - 24; // size of the card minus the padding
        } else {
          this.cardSize = (availableWidth - horizontalMarginSummed) / cardsInARow - 24;
        }

        const cardsWidthSummed = this.cardSize * cardsInARow;
        const cardsGuttersSummed = (cardsInARow * 2 - 2) * 12; // subtract twelve because the first and last cards in a row have their first and last padding ignored

        maxWidth = cardsWidthSummed + cardsGuttersSummed;

        this.containerMaxWidth = maxWidth.toString() + 'px';
      } else {
        this.containerMaxWidth = '100vw';
        this.cardSize = availableWidth;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../scss/variables';
  .draggabble{
    display: flex;
    flex-wrap: wrap;
    // padding: 25px;
    // row-gap: 20px;
    // column-gap: 20px;

    &-container{
      max-width: 400px;
    }
    //&-card{
    //  animation: shake 0.2s ease-in-out infinite;
    //  transform: translate3d(0, 0, 0);
    //  backface-visibility: hidden;
    //  perspective: 2000px;
    //
    //  @keyframes shake {
    //    0% {
    //      transform: rotate(-1.5deg);
    //      animation-timing-function: ease-in;
    //    }
    //
    //    50% {
    //      transform: rotate(1deg);
    //      animation-timing-function: ease-out;
    //    }
    //  }
    //}
  }

  .ghost {
    opacity: 1;
    background: $ads-light-20;
  }
  .fullwidth{
    width: 100%;
  }
</style>
