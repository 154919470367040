<template>
  <v-menu
    v-if="$vuetify.breakpoint.smAndUp"
    v-bind="$attrs"
    v-model="isExpanded"
    attach
    v-on="$listeners"
  >
    <template #activator="{on, attrs}">
      <AdsButton
        v-if="!icon"
        v-bind="attrs"
        :class="{'ml-3': true, 'button-icon': icon}"
        :color="button.color"
        :secondary="button.secondary"
        :tertiary="button.tertiary"
        :icon="button.icon"
        :small="!!icon"
        :button-text="button.text"
        :disabled="disabled"
        v-on="on"
        @click="isExpanded = !isExpanded"
      />
      <v-btn
        v-else
        v-bind="attrs"
        aria-label="open menu"
        icon
        v-on="on"
        @click="isExpanded = !isExpanded"
      >
        <v-icon>
          {{ typeof icon === 'string' ? icon : 'mdi-dots-vertical' }}
        </v-icon>
      </v-btn>
    </template>
    <Menu
      :items="items"
      @clickedAction="handleClickedAction"
      @close="isExpanded = false"
    />
  </v-menu>
  <v-dialog
    v-else
    v-model="isExpanded"
  >
    <template #activator="{on, attrs}">
      <AdsButton
        v-if="!icon"
        v-bind="attrs"
        class="ml-3"
        :secondary="button.secondary"
        :tertiary="button.tertiary"
        :icon="button.icon"
        :button-text="button.text"
        :disabled="disabled"
        v-on="on"
        @click="isExpanded = !isExpanded"
      />
      <v-btn
        v-else
        aria-label="open menu"
        icon
        @click="isExpanded = !isExpanded"
      >
        <v-icon>
          {{ typeof icon === 'string' ? icon : 'mdi-dots-vertical' }}
        </v-icon>
      </v-btn>
    </template>
    <Menu
      :items="items"
      @clickedAction="handleClickedAction"
      @close="isExpanded = false"
    />
  </v-dialog>
</template>

<script>
import {AdsButton} from '@nswdoe/doe-ui-core';
import Menu from '@/views/MyResourceDetailsPage/ActionsMenu/Menu';

export default {
  name: 'ActionsMenu',
  components: {
    AdsButton,
    Menu
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    button: {
      type: Object,
      default: () => ({
        text: 'Actions',
        icon: 'mdi-dots-vertical',
        secondary: false,
        tertiary: false,
        disabled: false
      })
    },
    icon: {
      type: [Boolean, String],
      default: false
    },
    externalData: {
      type: [String, Number, Object, Array],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    handleClickedAction(id) {
      if (this.externalData) {
        this.$emit('clickedAction', {externalData: this.externalData, action: id});
      } else {
        this.$emit('clickedAction', id);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

// dialog open at the top, full screen width on mobile portrait
::v-deep.v-dialog__content {
  align-items: start;

  &.v-dialog__content--active {
    .v-dialog {
      &.v-dialog--active {
        margin: 0;
        border-radius: 0;
      }
    }
  }
}

.v-btn {
  padding: 0;

  &.v-btn--icon.v-size--default {
    height: 42px !important;
    width: 42px !important;
  }

  &:focus {
    outline: none !important;

    &::before {
      opacity: 0 !important;
    }
  }

  &:focus-visible {
    border: 2px solid $ads-navy;
  }

  &:hover {
    &::before {
      opacity: 0 !important;
    }
  }
}
</style>
