<template>
  <Dialog
    v-bind="$attrs"
    icon="undo"
    title="Unpublish"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        class="mt-3 pb-0"
        :style="{'color': ADS_Colors.Dark}"
      >
        <h3 class="ml-4">
          Are you sure you want to unpublish this resource card?
        </h3>
        <p class="ma-4 pb-10">
          Any unsaved changes will be discarded. Once unpublished, this resource card will need to go back through the workflow to be published again.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between px-6 pb-6">
        <v-spacer />
        <AdsButton
          :loading="loading"
          :color="ADS_Colors.Red_Dark"
          secondary
          button-text="Unpublish"
          @click="$emit('onConfirm')"
          @keyup.enter="$emit('onConfirm')"
        />
        <AdsButton
          button-text="Cancel"
          @click="$downwardBroadcast('close:dialog')"
          @keyup.enter="$downwardBroadcast('close:dialog')"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'ConfirmUnpublishDialog',
  components: {
    Dialog,
    AdsButton
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
      loading: false
    }
  }
}
</script>
