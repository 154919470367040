<template>
  <AdsDrawer
    v-if="collection"
    v-bind="$attrs"
    heading="Information"
    class="info-drawer"
    icon="info_outline"
    v-on="$listeners"
  >
    <template #content>
      <v-container v-if="Object.keys(collection).length > 0">
        <!-- Collection Detail -->
        <CollectionDetails
          :item="collection"
          @invite:member="$emit('invite')"
        />
        <v-divider />
      </v-container>
      <v-container v-if="Object.keys(collection).length > 0">
        <ActivityInformation :rce="rce" />
      </v-container>
    </template>
  </AdsDrawer>
</template>
<script>
import { AdsDrawer } from '@nswdoe/doe-ui-core';
import CollectionDetails from './CollectionDetails.vue';
import ActivityInformation from './Activity.vue'
export default {
  name: 'CollectionInformation',
  components:{
    AdsDrawer,
    ActivityInformation,
    CollectionDetails
  },
  props:{
    collection: {
      type: Object,
      default: () => {}
    },
    rce: {
      type: Boolean,
      default: false
    }
  }
}
</script>

