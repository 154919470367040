<template>
  <div :class="{'px-0 pt-2 pb-4': $vuetify.breakpoint.xs, 'px-6 pt-6 pb-6': $vuetify.breakpoint.smAndUp } ">
    <v-row
      class="mt-0"
      align="center"
    >
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">Topics:</span>
      </v-col>
      <v-col
        v-if="topics && topics.length > 0"
        cols="12"
        sm="9"
        lg="9"
        md="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly, 'pl-1': true}"
      >
        <v-chip
          v-for="(topic, index) in topics"
          :key="index"
          tabindex="0"
          :color="ADS_Colors.Navy"
          outlined
          :text-color="ADS_Colors.Navy"
          class="chip ma-2"
        >
          {{ topic }}
        </v-chip>
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <span>None</span>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">NSW Syllabus:</span>
      </v-col>
      <v-col
        v-if="syllabus && syllabus.length > 0"
        cols="12"
        sm="9"
        lg="9"
        md="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly, 'pl-1': true}"
      >
        <v-chip
          v-for="(syl, index) in syllabus"
          :key="index"
          tabindex="0"
          :color="ADS_Colors.Navy"
          outlined
          :text-color="ADS_Colors.Navy"
          class="chip ma-2"
        >
          {{ syl }}
        </v-chip>
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="9"
        md="9"
        lg="9"
      >
        <span>None</span>
      </v-col>
    </v-row>

    <FieldRowWithChips
      label="Syllabus Outcome(s)"
      :items="outcomes"
    />

    <FieldRowWithChips
      label="Progression V2"
      :items="progressions"
    />

    <FieldRowWithChips
      label="Progression V3"
      :items="progressionsV3"
    />

    <FieldRowWithLinks
      label="Professional Learning"
      :items="professionalLearning"
    />

    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">Publisher:</span>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <span>{{ publisher }}</span>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">Author:</span>
      </v-col>
      <v-col
        v-if="author"
        cols="12"
        sm="9"
        md="9"
        lg="9"
      >
        <span>{{ author }}</span>
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="9"
        md="9"
        lg="9"
      >
        <span>None</span>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">Resource:</span>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <span class="text-capitalize">{{ resourceType }}</span>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">Created Date:</span>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <span>{{ dateCreated }}</span>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">Updated Date:</span>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <span>{{ dateModified }}</span>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
        lg="3"
        md="3"
        :class="{'pb-1': $vuetify.breakpoint.xsOnly}"
      >
        <span style="font-weight: bold; font-size: 16px;">Rights:</span>
      </v-col>
      <v-col
        v-if="rights || imageRights"
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <p
          v-if="rights"
          class="mb-0"
        >
          {{ rights }}
        </p>
        <p
          v-if="imageRights"
          class="mb-0"
        >
          Image courtesy of {{ imageRights }}.
        </p>
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'pt-0': $vuetify.breakpoint.xsOnly}"
      >
        <span>None</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import { mapGetters, mapState } from 'vuex'
import FieldRowWithChips from '@/components/FieldRowWithChips'
import FieldRowWithLinks from '@/components/FieldRowWithLinks'

export default {
  name: 'PreviewCardOverview',
  components: {
    FieldRowWithChips,
    FieldRowWithLinks
  },
  data() {
    return {
      moment,
      ADS_Colors
    }
  },
  computed: {
    ...mapState({
      document: state => state.myResources.resourceDetails?.document,
      resourceDetails: state => state.myResources.resourceDetails
    }),
    ...mapGetters({
      allResourceTypes: 'metadata/myResourcesTypes'
    }),
    topics() {
      return this.document?.Topic?.length > 0 ? this.document.Topic : '';
    },
    syllabus() {
      return this.document?.NSWSyllabus?.map(syllabus => syllabus.name) || [];
    },
    outcomes() {
      return this.document?.NSWSyllabus?.reduce(function (outcomes, syllabus) {
        return [...outcomes, ...syllabus.outcomes?.map(outcome => outcome.id)]
      }, []);
    },
    progressions() {
      const literacyLevels = this.document?.LitLearnProgLevel?.map(progression => progression.level) || [];
      const numeracyLevels = this.document?.NumLearnProgLevel?.map(progression => progression.level) || [];
      return literacyLevels.concat(numeracyLevels);
    },
    progressionsV3() {
      const literacyLevels = this.document?.LitLearnProgLevelV3?.map(progression => progression.level) || [];
      const numeracyLevels = this.document?.NumLearnProgLevelV3?.map(progression => progression.level) || [];
      return literacyLevels.concat(numeracyLevels);
    },
    professionalLearning() {
      return this.document?.PLLinks ? this.document.PLLinks : []
    },
    publisher() {
      return this.document?.Publisher ? this.document.Publisher : 'None';
    },
    author() {
      // @TODO check with BE why this is String for School Improvement Resources
      return Array.isArray(this.document?.Author) && this.document?.Author.join(', ') || this.document?.Author;
    },
    resourceType() {
      const type = this.allResourceTypes.filter(el => el.id === this.document?.ResourceType)
      return type[0] ? type[0].name : this.document?.ResourceType || 'None'
    },
    rights() {
      return this.document?.Rights ? this.document.Rights : 'Copyright not yet defined. Note: Resource cannot be shared until provided.';
    },
    imageRights() {
      return this.document?.ResourceImage?.author ? this.document.ResourceImage.author : '';
    },
    dateCreated() {
      if (this.resourceDetails?.record?.created) {
        return this.moment(this.resourceDetails.record.created).format('DD MMM YYYY');
      } else {
        return this.moment(new Date()).format('DD MMM YYYY');
      }
    },
    dateModified() {
      if (this.resourceDetails?.record?.modified) {
        return this.moment(this.resourceDetails.record.modified).format('DD MMM YYYY');
      } else {
        return this.moment(new Date()).format('DD MMM YYYY');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.row {
  margin-left: 0;
  margin-right: 0;
}

.pllink {
  color: $ads-blue-1;
}

.chip:focus {
  color: $ads-white !important;
}

::v-deep.v-chip.v-chip--outlined.v-chip.v-chip:focus {
  background-color: $ads-navy !important;
}

.linkList {
  padding: 0 !important;

  li {
    list-style: none;
  }
}
</style>
