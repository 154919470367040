<template>
  <div>
    <Dialog
      v-bind="$attrs"
      title="Create a new collection"
      hideCloseButton
      icon="mdi-plus-circle-outline"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #content>
        <v-container class="new-collection-content">
          <v-form ref="newCollectionForm">
            <v-card-subtitle class="mb-4">
              You are about to create a new collection. Use collections as a way to group resources you find useful for students. You can share and discuss these with other teachers.
            </v-card-subtitle>
            <v-card-text class="data-content">
              <AddNewImage
                v-model="collection.ThumbnailImage"
                text="Click to add a cover image for your collection. Leaving this blank will create a collated image from the resources selected." />
              <AdsTextField
                ref="collectionTitle"
                v-model="collection.Name"
                class="input-container"
                label="Collection title *"
                aria-required="true"
                placeholder="Collection Name"
                persistent-placeholder
                :rules="[rules.required('Collection title'), rules.specialChars]"
                :aria-invalid="rules.specialChars || rules.required() ? 'true':'false'"
                aria-describedby="Name"
              />
              <v-select
                v-if="hubCollection"
                v-model="collection.source"
                class="input-container"
                aria-label="content group"
                :rules="[rules.requiredId('Content group')]"
                aria-required
                :aria-invalid="rules.required()"
                label="Content group *"
                outlined
                item-text="name"
                placeholder="Select"
                persistent-placeholder
                return-object
                :items="supportedContentGroups"
              />
              <template v-if="hubCollection">
                <div class="section">
                  <fieldset>
                    <legend>
                      <h4 class="checkbox-group__heading">
                        Resource Category *
                      </h4>
                      <label class="body-2">
                        Which category best describes this resource?
                      </label>
                    </legend>

                    <!-- Resource Category -->
                    <v-row class="mb-7">
                      <v-col
                        cols="6"
                      >
                        <v-checkbox
                          v-model="collection.resourceCategory"
                          label="Teaching and learning resource"
                          hide-details
                          :rules="[rules.required('Resource Category')]"
                          value="tlr"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-checkbox
                          v-model="collection.resourceCategory"
                          label="School Improvement resource"
                          :rules="[rules.required('Resource Category')]"
                          hide-details
                          value="sir"
                        />
                      </v-col>
                    </v-row>
                  </fieldset>
                </div>
              </template>
              <v-textarea
                v-model="collection.Description"
                class="input-container optional"
                label="Description"
                aria-required="true"
                placeholder="Description about this collection"
                persistent-placeholder
                outlined
              />

              <AdsButton
                text
                class="pl-1 mb-4"
                tertiary
                :aria-expanded="showOtherfields? 'true' : 'false'"
                :ripple="false"
                :icon="showOtherfields?'arrow_drop_down':'arrow_right'"
                button-text="Other fields"
                @click="showOtherfields = !showOtherfields"
              />
              <v-expand-transition
                class="py-0"
                origin="'top center 0"
                mode="in-out"
              >
                <div
                  v-if="showOtherfields"
                  key="row-optionals"
                >
                  <v-row>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                      class="mb-0"
                    >
                      <v-select
                        id="stages-field"
                        v-model="stages"
                        class="input-container optional"
                        aria-label="stages"
                        aria-required="true"
                        label="Stages"
                        multiple
                        outlined
                        item-text="text"
                        item-value="value"
                        chips
                        deletable-chips
                        placeholder="Select"
                        persistent-placeholder
                        :return-object="false"
                        :items="allStages"
                      />
                    </v-col>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <v-select
                        id="years-field"
                        v-model="years"
                        class="input-container optional"
                        aria-label="years"
                        aria-required="true"
                        label="Years"
                        multiple
                        outlined
                        item-text="text"
                        item-value="value"
                        chips
                        deletable-chips
                        placeholder="Select"
                        persistent-placeholder
                        :return-object="false"
                        :items="scholasticYears"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <v-select
                        v-model="collection.KLA"
                        class="input-container optional"
                        label="Learning areas"
                        aria-label="Learning areas"
                        aria-required="true"
                        multiple
                        outlined
                        chips
                        deletable-chips
                        item-text="name"
                        item-value="id"
                        placeholder="Select"
                        persistent-placeholder
                        :items="$store.getters['metadata/klas']"
                      />
                    </v-col>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <Autocomplete
                        v-model="collection.Topic"
                        class="input-container optional"
                        label="Topics"
                        persistent-hint
                        persistent-placeholder
                        placeholder="Select"
                        hint="Select up to 3 topics"
                        :items="topics.map(el => el.name)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                    >
                      <Autocomplete
                        v-model="collection.Keywords"
                        class="input-container optional"
                        allow-user-input
                        label="Keywords"
                        placeholder="Type"
                        persistent-placeholder
                        aria-describedby="Keywords (Optional)"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-card-text>
            <v-divider />
            <v-card-actions :class="{'justify-space-between px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
              <AdsButton
                secondary
                icon="mdi-arrow-left"
                button-text="Back"
                @click="$downwardBroadcast('close:dialog')"
                @keyup.enter="$downwardBroadcast('close:dialog')"
              />
              <div>
                <AdsButton
                  class="mr-6"
                  tertiary
                  button-text="Cancel"
                  @click="$downwardBroadcast('close:dialog')"
                  @keyup.enter="$downwardBroadcast('close:dialog')"
                />
                <AdsButton
                  primary
                  button-text="Create"
                  @click="createCollection"
                  @keyup.enter="createCollection"
                />
              </div>
            </v-card-actions>
          </v-form>
        </v-container>
      </template>
    </Dialog>
    <ErrorDialog
      v-model="showErrorCreateDialog"
      title="Sorry, you cannot create any more collections"
      :resource-id-exist="false"
    >
      <template #message>
        <p>You’ve reached the maximum amount of collections. Manage your collections to clear some space.</p>
      </template>
      <template #buttons>
        <AdsButton
          secondary
          button-text="Close"
          @click="$downwardBroadcast('close:dialog');"
          @keyup.enter="$downwardBroadcast('close:dialog');"
        />
        <!-- if no selected cards, disable the Save button -->
        <AdsButton
          primary
          button-text="Manage collections"
          @click="$downwardBroadcast('close:dialog');$router.push({ name: 'MyCollection' })"
          @keyup.enter="$downwardBroadcast('close:dialog');$router.push({ name: 'MyCollection' })"
        />
      </template>
    </ErrorDialog>
    <SuccessDialog
      v-if="!hubCollection"
      v-model="showSuccessCreateDialog"
    />
  </div>
</template>
<script>
import { AdsButton, AdsTextField } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import topics from '@/views/ResourceEditorDetailsPage/Edit/topics.json'
import Autocomplete from '@/views/ResourceEditorDetailsPage/Edit/Autocomplete';
import {SuccessDialog, ErrorDialog} from '@/components/Dialogs/Collections';
import {mapGetters} from 'vuex';
import store from '@/store';
import {ResourceStatus, RESOURCE_APPLICATION} from '@/constants';
import AddNewImage from '@/views/ResourceEditorDetailsPage/Edit/AddNewImage.vue';
const uuid = require('uuid');

export default  {
  name: 'NewCollectionDialog',
  components: {
    AddNewImage,
    AdsButton,
    AdsTextField,
    Autocomplete,
    Dialog,
    SuccessDialog,
    ErrorDialog,
  },
  props: {
    hubCollection: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      topics,
      showEditMetaDataDialog: false,
      showOtherfields: false,
      collection: {
        Name: '',
        source: [],
        resourceCategory: [],
        Stages: [],
        Years: [],
        KLA: [],
        Keywords: [],
        Topic: [],
        Description: '',
        ThumbnailImage: ''
      },
      rules: {
        specialChars: value => {
          // \xB0C  is for math degree symbol
          const pattern = /^[a-zA-Z0-9_/?,:()!\xB0C+=&' .-]*$/
          const testResult = pattern.test(value)
          return !!testResult || 'Collection name cannot include the special characters used.'
        },
        required: field => v => (!!v && v?.length > 0) || `${field} is required`,
        requiredId: field => v => (!!v && !!v.id && v.id?.length > 0) || `${field} is required`
      }
    };
  },
  watch: {
    '$attrs.value'(val) {
      // reset form validation on close
      !val && this.$refs.newCollectionForm && this.$refs.newCollectionForm.reset()
    },
    'collection.Years': {
      handler(selectedYears) {
        // when element is active or Stage is not defined (to support old resources) need to re calculate stages
        if (document.activeElement && document.activeElement.id === 'years-field' || (selectedYears && !this.collection.Stages)) {
          const stages = selectedYears ? this.stagesAndYears.filter(sy => {
            return selectedYears.includes(sy.year)
          }).map(s => s.stage) : []
          // just removing duplicates
          this.collection.Stages = [ ...new Set(stages) ];
        }
      }
    },
    'collection.Stages': {
      handler(selectedStages) {
        if (document.activeElement && document.activeElement.id === 'stages-field') {
          const years = selectedStages ? this.stagesAndYears.filter(sy => {
            return selectedStages.includes(sy.stage)
          }).map(s => s.year) : []
          this.collection.Years = years;
        }
      }
    },
  },
  mounted() {
    if (!this.hubCollection) {
      // since 0 is the default value in the store we need to make sure we have
      // the createdbyme collections metadata in the store
      this.reloadMyCollections()
    }
  },
  updated() {
    if (this.$attrs.value) {
      this.$nextTick(() => this.$refs.collectionTitle.$el.querySelector('input').focus());
    }
  },
  computed: {
    ...mapGetters({
      upsConfig: 'users/upsConfig',
      errorCreateCollection: 'myCollections/errorCreateCollection',
      successCreateCollection: 'myCollections/successCreateCollection',
      collectionCreatedByMe: 'myCollections/collectionCreatedByMe',
      supportedContentGroups: 'users/collectionSupportedContentGroups',
      stagesAndYears: 'metadata/mappedStagesAndYears'
    }),
    scholasticYears() {
      return this.$store.getters['metadata/years']?.map(y => {
        return {
          value: y.shortName,
          text: y.name
        }
      }) || []
    },
    allStages() {
      return this.$store.getters['metadata/stages'].map(s => {
        return {
          id: s.id,
          value: s.name === 'Early Stage 1' ? 'ES1' : s.name.replace('Stage ', ''),
          text: s.name
        }
      })
    },
    stages: {
      get() {
        return this.collection?.Stages?.map(y => {
          return this.allStages.find(stage => stage.value === y).value
        }) || []
      },
      set(value) {
        this.collection.Stages = value
      }
    },
    years: {
      get() {
        return this.collection.Years?.map(y => {
          return this.scholasticYears.find(sy => sy.value === y)
        }) || []
      },
      set(value) {
        this.collection.Years = value.map(y => y).sort(function (a, b) {
          return a - b
        });
      },
    },
    showErrorCreateDialog:{
      get() {
        return this.errorCreateCollection
      },
      set(value, old) {
        this.$store.commit('myCollections/SET_ERROR_CREATE_DIALOG', false)
      }
    },
    collectionCount() {
      return this.collectionCreatedByMe.meta.count
    },
    showSuccessCreateDialog:{
      get() {
        return this.successCreateCollection
      },
      set(value, old) {
        this.$store.commit('myCollections/SET_SUCCESS_CREATE_DIALOG', false)
      }
    }
  },
  methods: {
    handleSuccessData(data){
      this.collection = data
      // only show member dialog for hub collections
      if (!this.hubCollection) {
        this.inviteMembersDialog = true
      }
    },
    async reloadMyCollections() {
      await store.dispatch('myCollections/fetchCollections', {
        access: 'createdbyme',
        page: 1
      });
    },
    async createCollection() {
      const valid = await this.$refs.newCollectionForm.validate()
      if (!valid) {
        return false
      }
      this.$downwardBroadcast('close:dialog');
      if (this.collectionCreatedByMe.meta.count >= (this.upsConfig?.maxNumCollections)) {
        this.$store.commit('myCollections/SET_ERROR_CREATE_DIALOG', true)
        return;
      } else {
        const {source, resourceCategory, ...document} = this.collection;
        document.ItemID = uuid.v4();

        const newCollection = await this.$store.dispatch('myCollections/createCollection', {
          document,
          ...(this.hubCollection && {
            hubCollection: this.hubCollection,
            application: this.hubCollection ? source?.application?.id : RESOURCE_APPLICATION.URH,
            source: source.id,
            resourceCategory,
          })
        });
        // update collections with editor access
        await this.$store.dispatch('myCollections/fetchMyEditableCollections', {
          hubCollection: this.hubCollection,
          newCollectionId: document.ItemID
        });
        // only show the invite members dialog to my collection
        !this.hubCollection && this.$store.commit('myCollections/SET_SUCCESS_CREATE_DIALOG', true)

        const newDocument = newCollection.record.status === ResourceStatus.published ? newCollection.document : newCollection.draft;
        this.$emit('collections:updated', {
          ...newDocument,
          status: newCollection.record.status,
          Source: newCollection.record.collection,
          Application: newCollection.audit.iss
        });
        // clear collections once created...
        this.$refs.newCollectionForm.reset()
        this.collection = {}
      }
    },
  }
};
</script>
<style lang="scss" scoped>

@import "../../../scss/mixins";

fieldset {
  border: none;
  legend {
    h4 {
      color: #002664
    }
    label {
      color: $ads-grey-02 !important;
    }
  }
}

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 65vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }

  .input-container {
    ::v-deep .v-label {
      color: $ads-primary-blue;
    }

    &.optional ::v-deep .v-label {
      &::after {
        content: ' (optional)';
        font-weight: normal;
      }
    }
  }
}
</style>
