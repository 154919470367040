<template>
  <v-btn
    v-bind="$attrs"
    type="button"
    color="#041E42"
    dark
    class="pl-3 pr-3"
    :class="$vuetify.breakpoint.xs ? 'go--to--resources--button buttonMobile' : 'go--to--resources--button'"
    @click="handleGoToResource"
  >
    <v-icon
      class="pr-2"
      alt="opens in a new window"
    >
      open_in_new
    </v-icon>
    Go to resource
  </v-btn>
</template>

<script>
export default {
  name: 'GoToResource',
  props: {
    itemUrl: String,
    id: String,
    name: String,
  },
  methods: {
    handleGoToResource() {
      this.$store.dispatch('captureGoToResource', {id: this.id, name: this.name});
      const withHttp = (url) => url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, scheama, nonScheamaUrl) => scheama ? match : `https://${nonScheamaUrl}`);
      window.open(withHttp(this.itemUrl), '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.buttonMobile {
  width: 100% !important;
}

.go--to--resources--button {
  width: 100%;

  &.v-size--default {
    height: 43px;
  }
  &:focus {
    background-color: #ffffff !important;
    border: 2px solid #041E42 !important;
    color: #041E42 !important;
    outline: none !important;
  }
}
</style>
