<template>
  <div
    class="discussion-drawer"
    :class="!show ? 'show': ''"
  >
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      icon
      @click.stop="collapseDiscussionPanel"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <div
      class="drawer-container"
      :class="!show ? 'collapse-panel': ''"
    >
      <DiscussionHeader
        :members="members"
        :initials="initials"
        class="discussion__header"
        @show:inviteMember="showInviteMemberDialog"
      />
      <v-spacer />
      <DiscussionPanel
        :discussion="discussion"
        class="discussion__panel"
      />
    </div>
    <InviteMembers v-model="inviteMembersDialog" />
  </div>
</template>
<script>
import {InviteMembers} from '@/components/Dialogs/Collections'
import DiscussionHeader from './DiscussionHeader.vue';
import DiscussionPanel from './DiscussionPanel.vue'
import { mapGetters } from 'vuex';
export default {
  name: 'DiscussionDrawer',
  components:{
    DiscussionHeader,
    DiscussionPanel,
    InviteMembers
  },
  props: {
    members: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      show: true,
      mini: true,
      inviteMembersDialog: false
    }
  },
  created(){
    console.log(this.collectionDiscussion)
  },
  computed:{
    ...mapGetters({
      collectionDiscussion: 'myCollections/discussion'
    }),
    discussion(){
      return this.collectionDiscussion?.at(0).discussion ? this.collectionDiscussion?.at(0).discussion : []
    },
    initials(){
      let username = [];
      this.discussion.forEach(element => {
        username.push(element.userId.split('.').map(item => item.charAt(0).toUpperCase()).join(''))
      });

      return username
    }
  },
  methods:{
    collapseDiscussionPanel(){
      this.show = !this.show
    },
    showInviteMemberDialog(){
      this.inviteMembersDialog = true;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../scss/variables";
  aside.v-navigation-drawer{
    width: 100%!important;
  }
  .discussion-drawer {
    width: 25%;
    height: 100vh;
    background: white;
    position: relative;
    transition: width .3s ease-out;
    float: right;

    @media only screen and (max-width: 600px){
       width: 100%;
      background: $ads-light-10;
    }

    &__header,
    &__panel{
      position: absolute;
      width: 100%;
      height: 100%;
    }


    @media only screen and (max-width: 600px){
      width: 100%;
    }
  }

  .show {
    width: 50px;
    transition: all .2s ease-in-out;
  }

  .drawer-container{
    opacity: 1;
    transform: translateX(0);
    transition: all .4s ease-in-out;
  }

  .collapse-panel {
    opacity: 0;
    transform: translateX(450px);
    transition: all .4s ease-out;
  }

</style>
