<template>
  <Dialog
    v-bind="$attrs"
    title="Share this card"
    icon="mdi-export-variant"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <div class="share-card__wrapper">
        <v-card-text>
          <span>
            {{ heading }}
          </span>
          <span v-if="subHeading"><strong>Note: </strong>{{ subHeading }} </span>
        </v-card-text>
        <v-card-text>
          <SearchDoeStaff
            v-model="recipients"
            display-details
            counter="30"
            :not-allowed="[...viewers, ...editors, ...owners]"
            @getRule="handleRule"
            @getError="handleError"
          />
          <v-textarea
            v-model="comments"
            outlined
            maxlength="200"
            counter="200"
            label="Comments"
            height="240"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4 pt-0">
          <v-spacer />
          <AdsButton
            button-text="Cancel"
            tertiary
            @click="$downwardBroadcast('close:dialog');"
          />
          <AdsButton
            button-text="Send"
            icon="send"
            class="btn-dark"
            :disabled="recipients.length === 0 || rule[0] !== true"
            :loading="$store.getters.sendingEmail"
            @click="shareResource('addaccess', 'viewer')"
          />
        </v-card-actions>
      </div>
    </template>
  </Dialog>
</template>
<script>
import sanitizeHtml from 'sanitize-html';
import AdsButton from '@nswdoe/doe-ui-core/src/AdsButton';
import Dialog from '../Dialogs/Dialog.vue';
import SearchDoeStaff from '@/components/SearchDoeStaff/SearchDoeStaff';
import {mapMutations, mapState} from 'vuex';
import {apiBaseUrl} from '@/store/config';

export default {
  name: 'ShareThisCardDialog',
  components: {
    AdsButton,
    Dialog,
    SearchDoeStaff
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    subHeading: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      recipients: [],
      comments: '',
      dialogShare: false,
      rule: [true],
      senderEmail: this.$OAuth.profile.email,
      senderName: this.$OAuth.profile.givenName + ' ' + this.$OAuth.data.profile.sn,
      error: '',
    };
  },
  watch: {
    /**
     * while sending body with html, we need to sanitize it
     */
    comments: {
      immediate: true,
      handler(val) {
        let userComments = sanitizeHtml(val);
        val ? userComments : ''
      }
    }
  },
  computed: {
    ...mapState({
      hubResourceDetails: state => state.itemDetail,
      myResourceDetails: state => state.myResources.resourceDetails
    }),
    emailBody() {
      let rname;
      this.$route.path.includes(`detail/${this.$route.params.id}`) ? rname = this.hubResourceName || 'Click here to go to card shared with you.' : rname = this.resourceName
      if (this.comments) {
        return `<p>This resource was sent from the <a href="https://resources.education.nsw.gov.au/">DLRH</a> by: ${this.senderName} (${this.senderEmail})</p><p>Comments: ${this.comments}</p><p>Resource Card: <a href="${this.resourceUrl}" target="_blank">${rname}</a></p>`
      } else {
        return (
          `<p>This resource was sent from the <a href="https://resources.education.nsw.gov.au/">DLRH</a> by: ${this.senderName} (${this.senderEmail})</p><p>Resource Card: <a href="${this.resourceUrl}">${rname}</a></p>`
        );
      }
    },
    hubResourceName() {
      return this.hubResourceDetails?.Name;
    },
    resourceName() {
      return this.myResourceDetails?.document.Name || ''
    },
    resourceUrl() {
      let url = apiBaseUrl.replace('/api/v1', this.$route.path)
      return (url || '')
    },
    viewers() {
      return this.myResourceDetails?.userAccess?.viewer ? this.myResourceDetails.userAccess?.viewer : [];
    },
    editors() {
      return this.myResourceDetails?.userAccess?.editor ? this.myResourceDetails.userAccess?.editor : [];
    },
    owners() {
      return this.myResourceDetails?.userAccess?.owner ? this.myResourceDetails.userAccess?.owner : [];
    },
  },
  methods: {
    ...mapMutations({
      SET_LOADING: 'myResources/SET_LOADING',
      SET_DISPLAY_ALERT: 'SET_DISPLAY_ALERT',
      SET_ALERT: 'SET_ALERT'
    }),
    async sendEmail() {
      this.$downwardBroadcast('close:dialog');
      //compose email header and body
      const data = {
        to: this.recipients,
        from: this.senderEmail,
        subject: `${this.senderName} shared a resource with you`,
        body: this.emailBody,
      }
      try {
        await this.$store.dispatch('sendResourceByEmail', data);
        // if email is sent successfully then close dialog
        // Todo implement loading spinner while email is being sent.
        this.recipients = [];
        this.comments = '';
        this.$downwardBroadcast('close:dialog');
        // await this.$store.dispatch('myResources/fetchResourceDetails', {id: this.myResourceDetails.record?.id});
      } catch (e) {
        console.error(e);
        this.error = 'There was an issue with your action. Try again or contact support.';
      }
    },
    handleRule(val) {
      this.rule = val;
    },
    handleError(val) {
      this.error = val;
    },
    async shareResource(action, accessScope) {
      /**
       * this method will allow users to add access to view shared card,
       * the store is designed to receive @address action, we must pass this action via config object as paylod
       * @config<Object> address, body
       */


      const resourceId = this.$route.params.id;
      // configure user name to set access for

      let userAccess = this.recipients.map(item => {
        let userId = item.split('@')[0].toLowerCase();
        return {accessFor: userId, accessScope}
      });

      /**
       * since the alert is variable depending on dialog, we will compose alert message here and send it as a payload
       */
      const resourceCardName = this.resourceName || this.hubResourceName;
      let alert;
      if (action === 'addaccess') {
        alert = {
          type: 'success',
          text: `Successfully shared ${resourceCardName}`
        }
      }

      // prepare payload
      const payload = {
        action,
        alert,
        resourceName: this.resourceName,
        userAccess
      };
      if (this.$route.path === `/myresources/${resourceId}`) {
        await this.$store.dispatch('myResources/shareThisResource', payload);
        //this updates the activitiy under inforfation drawer
        await this.$store.dispatch('myResources/getResourceNotifications', resourceId);
        await this.sendEmail()
      } else {
        try {
          this.$store.commit('SET_IS_LOADING', true);
          await this.sendEmail();
          const alert = {
            type: 'success',
            text: `Successfully shared ${resourceCardName}`
          };
          this.$store.commit('SET_ALERT', alert);
        } catch (err) {
          console.error(err);
          const alert = {
            type: 'error',
            text: `There was an issue sharing ${resourceCardName}`
          };
          this.$store.commit('SET_ALERT', alert);
        } finally {
          this.$store.commit('SET_DISPLAY_ALERT', true);
          setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false), 20000);
          this.$store.commit('SET_IS_LOADING', true);
          this.recipients = [];
          this.comments = '';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.share-card__wrapper {
  display: block;
  min-width: 240px;

  .v-divider {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 43.75em) {
    // 700px === 43.75em
    & {
      width: 100%;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  & .v-card__text span {
    display: block;

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .v-btn {
    min-height: 2.5rem;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    border-color: $ads-light-40;
    background-color: $ads-white;
    color: $ads-white;

    &:focus {
      border: 2px solid $ads-navy;
      outline: none !important;
    }
  }

  button.v-btn > .v-btn__content {
    justify-content: center;
  }

  .btnText {
    color: white;
  }

  .v-divider {
    border-color: $ads-light-40;
  }
}
</style>
