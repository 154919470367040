<template>
  <v-row v-if="!isMyCollection">
    <v-col>
      <v-list>
        <v-subheader>
          <h3> Activity </h3>
        </v-subheader>
        <v-list-item-group v-if="activities && activities.length > 0">
          <template v-for="activity in activities">
            <v-list-item
              v-if="getIcon(activity.origin.action)"
              :key="activity._id"
              class="d-flex align-start"
            >
              <v-list-item-icon color="white">
                <v-icon
                  size="30"
                  style="width: 100%"
                  class="mt-0"
                  :color="ADS_Colors.Dark_60"
                >
                  {{ getIcon(activity.origin.action) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>
                  <template v-if="rce || isMyCollection"> {{ activity.userName || activity.userId }} {{ activity.description }} </template>
                  <template v-else>  The content owner, <span class="font-weight-bold">{{ owner }}</span>, {{ activity.description }}  </template>
                </span>
                <a
                  v-if="activity.reference"
                  class="mt-2"
                  :alt="activity.reference.resourceId"
                  :href="`/detail/${activity.reference.resourceId}`"
                >  {{ activity.reference.resourceId }} </a>
                <div class="d-flex pb-0 mb-0">
                  <div class="d-flex grow align-self-center justify-space-between">
                    <span
                      class="caption align-self-center"
                      style="max-width: 90%"
                    >
                      {{ activity?.updatedAt != "" ? timeSince(activity.updatedAt) + " ago" : "" }}
                    </span>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
        <v-list-item-group v-else>
          <v-list-item>
            There are no members activity on this collection so far.
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import { ADS_Colors } from '@nswdoe/doe-ui-core';

export default {
  name: 'ActivityInformation',
  props: {
    rce: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      ADS_Colors,
    }
  },
  computed:{
    ...mapGetters({
      activities: 'myCollections/collectionsNotification',
      collection: 'myCollections/getCollection',
      hubResourceSources: 'metadata/HubResourceSources'
    }),
    owner () {
      // Lookup in metadata for the source name
      return this.hubResourceSources.length > 0 && this.hubResourceSources?.find(source => {
        // console.log(source);
        return source.id === this.collection.record.collection
      })?.name || '';
    },
    isMyCollection () {
      return this.collection?.record?.collection === 'collections'
    }
  },
  methods:{
    getIcon(type) {
      let icon = ''
      switch (type) {
        case 'published_resource':
          icon = 'mdi-publish'
          break;
        case 'endorsed_resource':
          icon = 'mdi-check-circle-outline'
          break;
        case 'submitted_resource':
          icon = 'mdi-clipboard-text-outline'
          break;
        case 'declined_resource':
          icon = 'mdi-cancel'
          break;
        case 'unpublished_resource':
          icon = 'mdi-undo'
          break;
        case 'updated_resource':
          icon = 'mdi-plus-circle-outline'
          break;
        case 'created_collection':
          icon = 'mdi-plus-circle-outline'
          break;
        case 'added_resource_to_collection':
          icon = 'mdi-bookmark-plus-outline'
          break;
        case 'removed_resource_from_collection':
          icon = 'mdi-bookmark-minus-outline'
          break;
        case 'unpublished_resource_in_collection':
          icon = 'mdi-undo'
          break;
      }
      return icon;
    },
    timeSince(timestamp) {
      let date = new Date(timestamp)
      let seconds = Math.floor((new Date() - date) / 1000)
      let interval = Math.floor(seconds / 31536000)
      if (interval > 1) {
        return interval + ' years'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval > 1) {
        return interval + ' months'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval == 1) {
        return interval + ' month'
      }
      interval = Math.floor(seconds / 86400)
      if (interval > 1) {
        return interval + ' days'
      }
      interval = Math.floor(seconds / 3600)
      if (interval > 1) {
        return interval + ' hours'
      }
      interval = Math.floor(seconds / 60)
      if (interval > 1) {
        return interval + ' minutes'
      }
      return Math.floor(seconds) + ' seconds'
    }
  }

}
</script>
<style lang="scss" scoped>
@import "../../../scss/variables";
  .container {
    overflow: hidden;

    h3 {
      color: $ads-dark;
    }
  }
</style>
