<template>
  <div class="add-image-dialog__content">
    <v-card-title class="heading">
      <h1
        ref="dialog-heading"
        tabindex="-1"
        class="title"
      >
        Add an image
      </h1>
      <p class="sub-title">
        Choose an image for your resource card.
      </p>
    </v-card-title>
    <v-card-text class="pb-0 px-0">
      <v-row
        class="ma-0"
        align="center"
      >
        <v-col
          cols="12"
          class="d-flex flex-row justify-space-between px-0"
        >
          <AdsTextField
            v-model="unsplashQuery"
            :class="{'mr-6': $vuetify.breakpoint.smAndUp, 'mr-3': $vuetify.breakpoint.xsOnly }"
            prepend-inner-icon="search"
            label="Search Unsplash.com"
            hide-details
            @keyup.enter="searchUnsplash"
          />
          <AdsButton
            :loading="loading"
            class="search-button"
            :buttonText="$vuetify.breakpoint.xsOnly ? '' : 'Search'"
            icon="search"
            :iconOutlined="true"
            secondary
            @click="searchUnsplash"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-row
        v-if="$store.getters['unsplash/photos'].length > 0"
        ref="images-container"
        class="images__container"
        :style="perPage !== 9 && $vuetify.breakpoint.lgAndUp ? {'overflow-y': 'hidden'} : {'overflow-y': 'auto'}"
      >
        <v-col
          v-for="photo in $store.getters['unsplash/photos']"
          :key="'photo_' + photo.id"
          class="pa-2"
          :cols="colSize.xs"
          :lg="colSize.lg"
          :md="colSize.md"
          :sm="colSize.sm"
        >
          <v-btn
            v-if="!loading"
            :ripple="false"
            class="image__wrapper"
            :aria-label="`${photo.alt_description} - ${selectedPhoto && selectedPhoto.id === photo.id ? 'selected' : 'not selected'}`"
            @click="selectImage(photo)"
            @keyup.enter="selectImage(photo)"
          >
            <v-img
              :aspect-ratio="1.7"
              width="100%"
              :src="photo.urls.small"
            >
              <v-icon
                v-if="selectedPhoto && selectedPhoto.id === photo.id"
                class="selected-photo--icon"
              >
                check
              </v-icon>
            </v-img>
          </v-btn>
          <v-responsive
            v-else
            :aspect-ratio="1.85"
          >
            <v-skeleton-loader
              type="image"
            />
          </v-responsive>
        </v-col>
      </v-row>
      <span v-else>No results.</span>
    </v-card-actions>
    <v-card-actions class="d-flex flex-column align-end actions">
      <div class="images__footer">
        <span
          class="per-page__label"
          aria-hidden="true"
        >
          {{ $vuetify.breakpoint.xs ? 'per page:' : 'Items per page:' }}
        </span>
        <v-select
          v-model="perPage"
          :aria-label="$vuetify.breakpoint.xs ? 'per page:' : 'Items per page:'"
          :height="20"
          hide-details
          :items="photosPerPage"
        />
        <div
          class="pagination-count"
          :class="{'d-flex flex-row': true, 'ml-12': $vuetify.breakpoint.mdAndUp, 'ml-3': $vuetify.breakpoint.smAndDown}"
        >
          <span>
            {{ $store.state.unsplash.page * $store.state.unsplash.perPage }} {{
              $vuetify.breakpoint.smAndUp ? 'out' : null
            }} of {{ $store.state.unsplash.total }}
          </span>
          <v-btn
            class="icon-button"
            small
            icon
            aria-label="Go to previous page"
            :disabled="$store.state.unsplash.page === 1"
            @click="getPreviousPhotos"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            class="icon-button"
            small
            icon
            aria-label="Go to next page"
            @click="getNextPhotos"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div>
        <v-spacer />
        <AdsButton
          button-text="Cancel"
          tertiary
          @click="$upwardBroadcast('close:dialog')"
        />
        <AdsButton
          button-text="Done"
          @click="setResourceImage"
        />
      </div>
    </v-card-actions>
  </div>
</template>

<script>
import {AdsTextField, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'UnsplashDialogContent',
  components: {
    AdsTextField,
    AdsButton,
  },
  data() {
    return {
      loading: false,
      photosPerPage: [6, 12],
      perPage: 6,
      unsplashQuery: '',
      selectedPhoto: '',
      colSize: {
        lg: '4',
        md: '4',
        sm: '6',
        xs: '12'
      }
    }
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.commit('unsplash/SET_PER_PAGE', val);
          switch (val) {
            case 6:
              this.colSize = {
                lg: '4',
                md: '4',
                sm: '6',
                xs: '12'
              }
              break;
            case 12:
              this.colSize = {
                lg: '3',
                md: '3',
                sm: '4',
                xs: '12'
              }
              break;
          }
          this.$store.dispatch('unsplash/getPhotos')
              .then((result) => {
                if (result.type === 'success') {
                  this.$store.commit('unsplash/SET_PHOTOS', result.response.results);
                  this.$store.commit('unsplash/SET_TOTAL', result.response.total);
                }
              });
        }
      }
    }
  },
  methods: {
    async searchUnsplash() {
      if (this.unsplashQuery !== '') {
        this.loading = true;
        const result = await this.$store.dispatch('unsplash/getPhotos', this.unsplashQuery);
        if (result.type === 'success') {
          this.$store.commit('unsplash/SET_PHOTOS', result.response.results);
          this.resetScroll();
        }
        this.loading = false;
      }
    },
    selectImage(photo) {
      this.selectedPhoto = photo;
    },
    setResourceImage() {
      const resourceImage = {
        author: null,
        altDescription: this.selectedPhoto.alt_description,
        regularUrl: this.selectedPhoto.urls.regular,
        thumbnailUrl: this.selectedPhoto.urls.small
      }
      if (this.selectedPhoto.user && this.selectedPhoto.user.name) {
        resourceImage.author = this.selectedPhoto.user.name;
      }
      this.$listeners.input(resourceImage);
      this.$upwardBroadcast('close:dialog');
    },
    async getNextPhotos() {
      this.loading = true;
      this.$store.commit('unsplash/SET_PAGE', this.$store.state.unsplash.page + 1);
      const result = await this.$store.dispatch('unsplash/getPhotos', this.unsplashQuery);
      if (result.type === 'success') {
        this.$store.commit('unsplash/SET_PHOTOS', result.response.results);
        this.resetScroll();
      }
      this.loading = false;
    },
    async getPreviousPhotos() {
      this.loading = true;
      this.$store.commit('unsplash/SET_PAGE', this.$store.state.unsplash.page - 1);
      const result = await this.$store.dispatch('unsplash/getPhotos', this.unsplashQuery)
      if (result.type === 'success') {
        this.$store.commit('unsplash/SET_PHOTOS', result.response.results);
        this.resetScroll();
      }
      this.loading = false;
    },
    resetScroll() {
      const scrollableDiv = this.$refs['images-container'];
      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.add-image-dialog__content {
  padding: 0 40px 24px 40px;
  display: flex;
  flex-direction: column;
  position: relative;

  .heading {
    color: $ads-dark;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 8px 0;

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .sub-title {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .images__container {
    margin: 0 -8px;


    .image__wrapper {
      background: transparent;
      width: 100%;
      height: fit-content;
      padding: 0;

      &:focus {
        outline: none !important;
      }

      &:focus-visible {
        outline: 3px solid $ads-navy !important;
      }

      .v-image {
        border-radius: 4px;

        .v-responsive__content {
          position: relative;

          .selected-photo--icon {
            position: absolute;
            bottom: 6px;
            right: 6px;
            padding: 6px;
            background-color: $ads-white;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .images__footer {
    margin-bottom: 12px;
    max-height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .pagination-count {
      span {
        width: 140px;
        margin-right: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .per-page__label {
      padding-right: 12px;
    }

    .v-select {
      max-width: 50px;
      padding-top: 0;
      padding-bottom: 4px;
    }

    ::v-deep.v-text-field > {
      .v-input__control > {

        .v-input__slot {
          &:before {
            border-color: transparent;
          }
        }
      }
    }

    .v-input {
      input {
        max-height: 20px;
      }
    }
  }
}

.v-skeleton-loader {
  height: 100%;
}

@media only screen and (max-width: 700px) {
  .add-image-dialog__content {
    padding: 0 12px;

    .images__footer {
      font-size: 12px;

      .per-page__label {
        padding-top: 4px;
      }

      .pagination-count {
        span {
          width: 100px;
        }
      }

      .v-select {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .add-image-dialog__content {
    //height: 100% !important;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    position: relative;

    .heading {
      padding: 0 0 12px 0;

      .title {
        font-size: 20px;
      }

      .sub-title {
        font-size: 14px;
      }
    }

    .images__container {
      max-height: calc(100vh - 257px);
      padding-bottom: 130px;
    }

    .actions {
      background-color: $ads-white;
      width: 100%;
      padding: 12px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .images__footer {
      width: 100%;
      font-size: 14px;

      .pagination-count {
        span {
          width: 100px;
        }
      }

      .v-select {
        font-size: 14px;
      }
    }

    .search-button {
      min-width: 40px !important;
      max-width: 40px !important;

      ::v-deep.v-btn__content {
        .buttonText {
          display: none !important;
        }
      }
    }
  }
}
</style>
