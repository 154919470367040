<template>
  <div
    class="carousel-item__wrapper"
    aria-live="polite"
  >
    <v-row class="ma-0">
      <v-col
        cols="12"
        :class="{'px-6': $vuetify.breakpoint.width >= 740, 'px-3': $vuetify.breakpoint.width < 740 }"
      >
        <h1
          v-if="$vuetify.breakpoint.width >= 740"
          class="mb-3"
        >
          Resource category
        </h1>
        <p :class="{'body-1': $vuetify.breakpoint.width >= 740, 'body-2': $vuetify.breakpoint.width < 740}">
          Choose the resource categories most relevant to you
        </p>
      </v-col>
    </v-row>
    <v-row
      :class="{'ma-0 px-3': $vuetify.breakpoint.width >=740, 'ma-0 px-0': $vuetify.breakpoint.width < 740}"
    >
      <v-col
        v-for="item in items"
        :key="item.id"
        cols="12"
        lg="6"
        md="6"
        sm="6"
        :class="{'pa-0': $vuetify.breakpoint.width < 740}"
      >
        <SelectableCard
          :id="item.id"
          v-model="selectedCategories"
          :expansion-panel="$vuetify.breakpoint.width < 740"
          :bottom-border="$vuetify.breakpoint.width < 740 && item.id === 'teachingAndLearning'"
          :text="item.text"
          :icon="item.icon"
        >
          <template
            v-if="item.id === 'schoolImprovement'"
            #flexibleContainer
          >
            <v-chip
              small
              class="chip-new"
              :color="ADS_Colors.Blue_1"
            >
              NEW
            </v-chip>
          </template>
          <template #description>
            <span v-html="item.description" />
          </template>
        </SelectableCard>
      </v-col>
    </v-row>
    <v-row
      :class="{'ma-0 px-3': $vuetify.breakpoint.width >=740, 'ma-0 px-0': $vuetify.breakpoint.width < 740}"
    >
      <v-col cols="12">
        <div class="info-div body-2 pa-6">
          For more information on the teaching & learning cycle and school improvement cycle, please go to the Frequently Asked Questions (FAQs) under the Help & About section.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import onboardingVector from '@/assets/onboarding-vector.svg';
import onboardingVectorMobile from '@/assets/onboarding-vector-mobile.svg';
import SelectableCard from '@/components/Dialogs/OnboardingDialog/Categories/SelectableCard';
import {mapGetters} from 'vuex';

export default {
  name: 'Categories',
  components: {SelectableCard},
  data() {
    return {
      ADS_Colors,
      onboardingVector,
      onboardingVectorMobile,
      items: [
        {
          id: 'teachingAndLearning',
          text: 'Teaching and learning resources',
          icon: 'mdi-school-outline',
          description: `
          <p>Teaching and learning resources are linked to the phases of the teaching and learning cycle:</p>
            <ul>
              <li>Planning and programming</li>
              <li> Classroom practice</li>
              <li>Assessment, feedback and reporting</li>
              <li>Analysis for decision making</li>
            </ul>
          `
        },
        {
          id: 'schoolImprovement',
          text: 'School improvement resources',
          icon: 'mdi-wrench-outline',
          description: `
          <p>School improvement resources are linked to the phases of the school improvement cycle:</p>
            <ul>
              <li>Assess student need</li>
              <li>Plan for improvement</li>
              <li>Implement action</li>
              <li>Evaluate progress</li>
            </ul>
          `
        }
      ]
    }
  },
  computed: {
    // ...mapGetters({
    //   preferences: 'users/preferences',
    // }),
    selectedCategories: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$listeners.input && this.$listeners.input(value);
      }
    }
  },
  // watch: {
  //   preferences: {
  //     immediate: true,
  //     handler(val) {
  //       if (val) {
  //         this.valueInternal.push('teachingAndLearning');
  //       }
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.chip-new {
  font-weight: bold;
  color: $ads-white;
  position: absolute;
  top: 12px;
  right: 12px;
}

.info-div {
  background-color: $ads-light-10;
}

@media only screen and (max-width: 740px) {
  .carousel-item__wrapper {
    overflow: auto;
    //height: calc(100vh - 182px);
    margin: 56px 0 126px 0;
  }
}
</style>
