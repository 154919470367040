<template>
  <v-row
    dense
    align="center"
    justify="start"
    class="chip-filters__wrapper"
  >
    <!-- 1. My Collection Libraries -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="my-collections"
        v-model="checkboxMyCollectionLibraries"
        name="My Collections Libraries"
        heading="My Collections Libraries"
        tabindex="0"
        :alignRight="$vuetify.breakpoint.smOnly"
        :items="$store.getters['metadata/myCollectionLibraries']"
      />
    </v-col>
    <!-- 2. Stages -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <!-- @TODO Confirm with BE about how this needs to be saved in dialog and queried here -->
      <ChipFilter
        id="Stages"
        v-model="checkboxStages"
        name="Stages"
        heading="Stages"
        tabindex="0"
        :alignRight="$vuetify.breakpoint.smOnly"
        :items="$store.getters['metadata/stages']"
      />
    </v-col>

    <!-- 3. Category = Hub-Type (the new Resource Category) -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="LearningArea"
        v-model="checkboxKLAs"
        name="Learning Area"
        heading="Learning Area"
        :items="learningArea"
      />
    </v-col>
  </v-row>
</template>

<script>
import {ChipFilter} from '@nswdoe/doe-ui-core';
import {mapGetters} from 'vuex'

export default {
  name: 'FiltersForMyCollections',
  components: {
    ChipFilter
  },
  data() {
    return {
      checkboxStages: [], // Stages
      checkboxKLAs: [], // Learning Area
      checkboxMyCollectionLibraries: [] // Collection Libraries
    }
  },
  computed: {
    ...mapGetters({
      learningArea: 'metadata/klas',
      stages: 'metadata/stages',
    })
  },
  watch: {
    checkboxMyCollectionLibraries: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const filtersSelection = {
            ...this.$store.state.myCollections.filtersSelection,
            checkboxMyCollectionLibraries: newVal
          };
          this.$store.commit('myCollections/SET_FILTERS_SELECTION', filtersSelection);
          const newFilters = {...this.$store.state.myCollections.filters}
          newFilters.myCollectionLibraries = newVal.join()
          this.$store.commit('myCollections/SET_FILTERS', newFilters);
          this.$emit('collections:updated')
        }
      }
    },
    checkboxStages: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const filtersSelection = {
            ...this.$store.state.myCollections.filtersSelection,
            checkboxStages: newVal
          };

          this.$store.commit('myCollections/SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {
            ...this.$store.state.myCollections.filters,
            'resource-stages': newVal.join()
          };

          this.$store.commit('myCollections/SET_FILTERS', newFilters);

          this.$emit('collections:updated')
        }
      }
    },
    checkboxKLAs: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const filtersSelection = {
            ...this.$store.state.myCollections.filtersSelection,
            checkboxKLAs: newVal
          };

          this.$store.commit('myCollections/SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {
            ...this.$store.state.myCollections.filters,
            'resource-klas': newVal.join()
          };

          this.$store.commit('myCollections/SET_FILTERS', newFilters);

          this.$emit('collections:updated')
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';

.chip-filters__wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% + 12px);
  overflow-x: scroll;
}

.chip-filters__wrapper::-webkit-scrollbar {
  display: none;
}

.clear-all {
  &:hover {
    &:before {
      opacity: 0 !important;
    }
  }

  &:focus {
    outline-color: $ads-navy !important;
  }
}

.v-menu {
  &__content {
    max-width: 290px;
  }
}

::v-deep .v-card {
  &__title {
    .heading {
      width: 100px;
      word-break: keep-all;
      line-height: 1.5;
      margin-right: 5px;
    }

    button.close-button {
      align-self: flex-start;
    }
  }
}

@media only screen and (max-width: 620px) {
  .chip-filters__wrapper {
    .col {
      &:first-child {
        margin-left: 12px;
      }

      &:last-child {
        margin-right: 12px;
      }
    }
  }
}
</style>
