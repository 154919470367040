<template>
  <div class="controls-wrapper">
    <div
      class="controls__content"
    >
      <template>
        <div class="sorting">
          <AdsSelect
            v-model="sortSelect"
            style="width:200px"
            aria-label="Sort by"
            :items="sortOptions"
            item-text="text"
            item-value="value"
            hide-details
          />
        </div>
        <GridOrListToggle v-model="toggleCardView" />

        <!-- block level create collection button -->
        <AdsButton
          v-if="$vuetify.breakpoint.smAndUp"
          class="ml-4"
          button-text="New Collection"
          icon="add_circle_outline"
          @click="showNewCollectionDialog = true"
          @keyup.enter="showNewCollectionDialog = true"
        />

        <!-- Floating create collection button -->
        <div v-else>
          <v-btn
            class="mx-2 floating-btn"
            fab
            dark
            @click="showNewCollectionDialog = true"
            @keyup.enter="showNewCollectionDialog = true"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
        <CreateCollectionDialog
          v-model="showNewCollectionDialog"
          v-on="$listeners"
        />
      </template>
    </div>
  </div>
</template>
<script>
/**
 * @CollectionControls will consist of Filters for my collection on left side and to the right
 * side, it will consist of sorting, toggle view and create new collections.
 *
 */

import {AdsButton, ADS_Color, AdsSelect} from '@nswdoe/doe-ui-core';
import GridOrListToggle from './GridOrListToggle.vue';
import CreateCollectionDialog from '@/components/Dialogs/Collections/NewCollection'
import {MY_COLLECTIONS_SORT_OPTIONS} from '@/constants';

export default {
  name: 'Controls',
  components: {
    AdsButton,
    AdsSelect,
    GridOrListToggle,
    CreateCollectionDialog
  },
  props: {
    count: {
      type: [Number, String],
      default: ''
    },
    showView: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      ADS_Color,
      sortOptions: null,
      saveSearchDialog: false,
      saveUpdateSearchDialogTitle: 'Save this search',
      showNewCollectionDialog: false,
      sortingOptions: [
        {
          text: 'Relevance',
          value: 'relevance'
        },
        {
          text: 'Created Date',
          value: 'created:desc'
        },
        {
          text: 'Updated Date',
          value: 'updated:desc'
        },
        {
          text: 'Name',
          value: 'name:asc'
        },
        {
          text: 'Most viewed',
          value: 'numberViews:desc'
        },
        {
          text: 'Likes',
          value: 'numberLikes:desc'
        }
      ]
    }
  },
  computed: {
    toggleCardView: {
      get() {
        return this.showView || 0
      },
      set(value) {
        this.$emit('toggle:view', value);
      }
    },
    sortSelect: {
      get() {
        return this.$store.state.myCollections.sorting || 'updated:desc'
      },
      set(value, oldValue) {
        if (value !== oldValue) {
          this.$store.commit('myCollections/SET_SORTING', value);
          this.$emit('collections:updated')
        }
      }
    },
  },
  created() {
    /**
     * no need to check if its already in Mycollections jus
     */
    this.sortOptions = MY_COLLECTIONS_SORT_OPTIONS;
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.controls-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 0;
  min-height: 76px;

  .controls__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    flex: 1;
    @media only screen and (max-width: 600px) {
      width: 100%;
      justify-content: space-around;
    }


    .sorting {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      #sorting-label {
        padding-right: 12px;
      }

      .sorting-menu {
        max-width: 180px;
        padding-right: 24px;
      }

    }
  }

  .v-btn.floating-btn {
    position: fixed;
    z-index: 2;
    top: calc(100% - 100px); // calculates the top position depending on the screen width.
    right: 20px;
    background: $ads-navy;
  }
}

::v-deep.v-list {
  .v-list-item {
    transition: background-color 0.3s ease-out;
  }

  .v-list-item.v-list-item--highlighted {
    border-collapse: separate; /* for ie 9-11 */
    box-shadow: 0 0 0 3 $ads-blue-3-dark inset;

    &:before {
      opacity: 0;
    }
  }

  &.theme--light {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy;
      color: $ads-white !important;
    }
  }

  &.theme--dark {
    .v-list-item.v-list-item--active {
      background-color: $ads-navy-dark;
      color: $ads-01dp !important;

      &:before {
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .controls-wrapper {
    align-items: center;
    min-height: 40px;


    .controls-heading__content {
      padding-right: 12px;
      align-items: flex-end;

      .sorting {
        .sorting-menu {
          padding-right: 0;
          max-width: 140px;
        }
      }
    }
  }
}
</style>
