<template>
  <div class="resource-editor-table-wrapper">
    <v-card class="mt-5">
      <AdsDataTable
        v-model="selectedUsers"
        :page.sync="$store.state.admin.users.metadata.page"
        :options.sync="pagination"
        :items-per-page.sync="$store.state.admin.perPage"
        class="hub-users-table"
        :headers="tableHeaders"
        :items="selectableItems"
        item-key="userId"
        :item-value="item => item.userId"
        sort-by="name"
        sort-desc
        :loading="$store.state.isLoading"
        loading-text="Searching... Please wait"
        :server-items-length="count"
        :footer-props="{
          'items-per-page-options': [16, 32, 48],
          'page-text': '{0} to {1} out of {2}'
        }"
        @input="value => $emit('input', value)"
        @click:row="showContentAreas"
      >
        <template #top>
          <v-row
            class="mx-3 mb-1"
            justify="center"
            align="center"
          >
            <v-col md="9">
              <v-text-field
                v-model="searchString"
                class="pt-0"
                clearable
                label="Search"
                aria-label="search"
                prepend-icon="search"
                hide-details
              />
            </v-col>
            <v-col md="3">
              <label>{{ count }} results</label>
            </v-col>
          </v-row>
          <v-divider />
        </template>
        <template #[`header.userName`]>
          <div
            text
            class="headerContent"
          >
            Name
          </div>
        </template>
        <template #[`header.userId`]>
          <div
            text
            class="headerContent"
          >
            Name
          </div>
        </template>
        <template #[`header.accesses`]>
          <div class="d-flex">
            <div
              text
              class="owner headerBtn headerContent"
            >
              Content Owner
            </div>
            <div class="headerContent role">
              Roles
            </div>
          </div>
        </template>
        <template
          #group.header="{ group, items, isOpen, toggle }"
        >
          <template>
            <td
              :colspan="$vuetify.breakpoint.xsOnly ? '12': '6'"
              class="text-start pl-6 pr-11"
              @click="toggle"
            >
              <div
                class="d-flex justify-space-between "
                role="button"
                tabindex="0"
                :aria-expanded="isOpen"
              >
                <span class="d-flex align-baseline">
                  <h4 class="ml-5">{{ group }} <span>({{ items.length }}) </span></h4>
                </span>
              </div>
            </td>
          </template>
        </template>
        <template #[`item.userName`]="{item}">
          <InitialsIcon
            :given-name="item.userId.split('.')[0]"
            :surname="item.userId.split('.')[1]"
          />
          {{ item.userName }}
        </template>
        <template #[`item.userId`]="{item}">
          {{ item.userId }}
        </template>
        <template #[`item.accesses`]="{item}">
          <div
            class="d-flex"
            :class="{'': expanded.includes(item.userId)}"
          >
            <div class="ownerCell">
              <div
                v-if="item.count > 1"
                class="font-weight-bold"
              >
                Multiple
              </div>
              <div
                v-else
                class="font-weight-bold"
              >
                Singular
              </div>
            </div>
            <div class="rolesCell">
              <div
                v-if="item.count > 1"
                class="font-weight-bold"
              >
                Multiple
              </div>
              <div
                v-else
                class="font-weight-bold"
              >
                Singular
              </div>
            </div>
            <div class="d-flex">
              <v-icon
                role="button"
                class="mx-5"
                @click="selectedUser = item, showEditUserDialog = true"
              >
                mdi-account-edit
              </v-icon>
              <v-icon
                role="button"
                :aria-controls="`content-${item.userId}`"
                :aria-expanded="expanded.includes(item.userId)"
                small
              >
                {{ expanded.includes(item.userId) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </div>
          </div>
          <v-expand-transition>
            <div
              v-if="expanded.includes(item.userId)"
              :id="`content-${item.userId}`"
              :aria-hidden="!expanded.includes(item.userId)"
              class="pb-5"
            >
              <div
                v-for="(access, index) in item.accesses"
                :key="index"
                class="d-flex pt-5"
              >
                <div class="ownerCell">
                  {{ getOwners(access.content) }}
                </div>
                <div class="rolesCell">
                  {{ getRoles(access.roles) }}
                </div>
              </div>
            </div>
          </v-expand-transition>
        </template>
      </AdsDataTable>
      <EditUserDialog
        v-if="selectedUser"
        v-model="showEditUserDialog"
        :user="selectedUser"
        @onChange="$emit('onChange')"
      />
    </v-card>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {AdsButton, AdsDataTable, InitialsIcon} from '@nswdoe/doe-ui-core';
import {RESOURCE_CATEGORIES, DATATABLE_ITEMS_STATUS} from '@/constants'
import moment from 'moment';
import _isEqual from 'lodash/isEqual'
import _debounce from 'lodash/debounce'
import statusChipMixin from '@/mixins/statusChipMixin';
import EditUserDialog from '@/components/Dialogs/Admin/EditUser'

export default {
  name: 'UserTable',
  components: {
    AdsDataTable,
    EditUserDialog,
    InitialsIcon
  },
  filters: {
    dateFormat(value) {
      return moment(value).format('DD-MM-YYYY');
    }
  },
  mixins: [statusChipMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      moment,
      showErrorDialog: false,
      showEditUserDialog: false,
      errorMessages: '',
      pagination: {
        'page': 1,
        'itemsPerPage': 16,
        'sortBy': [ 'name' ]
      },
      selectedUser: {},
      selectedUsers: [],
      expanded: [],
      sortedFilteredResources: [],
      currentResource: {},
      singleSelect: true,
      deleteDialog: false,
      outlined: false,
      tableHeaders: [
        {
          text: 'Name',
          value: 'userName',
          sortable: false,
        },
        {
          text: 'User ID',
          value: 'userId',
          sortable: false,
        },
        {
          text: '',
          value: 'accesses',
          width: 600,
          align: 'start',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapState({
      status: state => state.status,
      items: state => state.admin.users.items,
    }),
    ...mapGetters({
      allSources: 'metadata/sources',
      editorRoles: 'metadata/editorRoles',
      count: 'admin/count',
      userProfile: 'users/userProfile',
      profileSourcesIds: 'users/profileSourcesIds',
      resourceStatusList: 'metadata/resourceStatusList',
      accessDetails: 'admin/urhUserAccesses'
    }),
    selectableItems() {
      return this.items?.map(item => ({
        ...item,
        firstName: item.details?.givenName,
        surName: item.details?.familyName,
        email: item.details?.email,
        userName: item.details?.fullName,
        count: item.count,
        userId: item._id,
        accesses: item.accesses,
        role: '',
        owners: []
      }));
    },
    searchString: {
      get() {
        return this.$store.state.admin.keywords;
      },
      set(value) {
        this.$store.commit('admin/SET_KEYWORDS', value);
        this.pagination = {
          ...this.pagination,
          page: 1
        }
        this.debouncedFetchUsers();
      }
    }
  },
  watch: {
    pagination: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && _isEqual(newVal, oldVal)) {
          // don't clear the filters ( back to list view button)
          return
        }
        this.fetchUsers()
        this.$emit('onPaginationChange', this.pagination);
      },
      deep: true
    },
    selectedUser(val) {
      val?.userId && this.$store.dispatch('admin/fetchUser', { userId: val.userId })
    },
    showEditUserDialog(val) {
      if(!val) {
        this.selectedUser = {}
      }
    }
  },
  created() {
    this.fetchUsers()
    // no more than once every 2 seconds on change of keyword
    this.debouncedFetchUsers = _debounce(this.fetchUsers, 500);
  },
  methods: {
    fetchUsers() {
      this.$store.dispatch('admin/fetchUsersByGroup', { pagination: this.pagination });
    },
    toggleUser(userId) {
      this.expanded = this.expanded.includes(userId) ? this.expanded.filter(el => el !== userId) : [...this.expanded, userId];
    },
    showContentAreas(user) {
      this.toggleUser(user.userId)
    },
    getRoles(roles){
      return roles.map(role => this.editorRoles?.find(r => r.id === role)?.name)?.join(', ')
    },
    getOwners(source) {
      // Lookup in metadata for the owner label
      return this.allSources?.find(owner => owner.id === source.id)?.name
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .hub-users-table {
  td:first-child, thead th:first-child {
    min-width: 1px !important;
  }
  .v-data-table-header .headerBtn.owner,
  td .ownerCell {
    width: 300px;
  }
  th .headerContent{
    font-size: 1rem;
    font-weight: normal;
    color: #002664;
  }
  td .rolesCell {
    width: 200px;
  }
  td {
    vertical-align: top;
    padding-top: 20px !important;
    padding-bottom: 15px !important;
  }

  .v-row-group__header {
    background: none;
    font-weight: normal;
    td {
      border-bottom: 1px solid grey;
    }
  }
}
</style>
