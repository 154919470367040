<template>
  <v-row class="collection-details">
    <v-col>
      <v-list>
        <v-subheader>
          <h3> Collection Details </h3>
        </v-subheader>
        <v-list-item-group>
          <!-- Topic -->
          <v-list-item>
            <v-list-item-content>
              <h3>Name: </h3>
            </v-list-item-content>
            <v-list-item-content>
              {{ collectionName ? collectionName : 'None' }}
            </v-list-item-content>
          </v-list-item>

          <!-- Learning Areas -->
          <v-list-item>
            <v-list-item-content>
              <h3>Learning areas: </h3>
            </v-list-item-content>
            <v-list-item-content>
              {{ LearningArea.length > 0 ? LearningArea.join(', ') : 'None' }}
            </v-list-item-content>
          </v-list-item>

          <!-- Stages -->
          <v-list-item>
            <v-list-item-content>
              <h3>Stages: </h3>
            </v-list-item-content>
            <v-list-item-content>
              {{ Stages.length > 0 ? Stages.sort().join(', ').replace('Early Stage','ES').replace('Early', ''): 'None' }}
            </v-list-item-content>
          </v-list-item>

          <!-- Description -->'
          <v-list-item>
            <v-list-item-content class="align-self-start">
              <h3>Description: </h3>
            </v-list-item-content>
            <v-list-item-content>
              {{ Description }}
            </v-list-item-content>
          </v-list-item>
          <!-- Owner -->
          <v-list-item>
            <v-list-item-content>
              <h3>Owner: </h3>
            </v-list-item-content>
            <v-list-item-content v-if="isHubCollection">
              {{ ContentOwner }}
            </v-list-item-content>
            <v-list-item-content v-else>
              <!-- owner needs to be checked on collectin CreatedBy -->
              {{ Owner.split('.').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ') }}
            </v-list-item-content>
          </v-list-item>
          <!-- Learning Areas -->
          <v-list-item>
            <v-list-item-content class="align-start">
              <h3>Date Created: </h3>
            </v-list-item-content>
            <v-list-item-content>
              {{ moment(DateCreated).format('DD MMMM YYYY') }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="align-start">
              <h3>Last Updated: </h3>
            </v-list-item-content>
            <v-list-item-content>
              {{ moment(LastModified).format('DD MMMM YYYY') }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="!isHubCollection"
            @click="handleClick"
          >
            <v-list-item-content class="align-start">
              <h3>Members </h3>
            </v-list-item-content>
            <v-list-item-content class="d-flex justify-center">
              {{ Members.length }}
            </v-list-item-content>
            <span
              class="manage-link"
            >Manage</span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment';
import {mapGetters} from 'vuex';

export default {
  name: 'ItemsDetails',
  props:{
    item:{
      type: Object,
      default : () => {}
    }
  },
  data(){
    return{
      moment,
      showInviteMembersDialog: false
    }
  },
  created() {
    console.log(this.item)
  },
  computed:{
    ...mapGetters({
      allStages: 'metadata/stages',
      hubResourceSources: 'metadata/HubResourceSources'
    }),
    collectionName(){
      return this.item?.document?.Name || this.item.Name || 'None'
    },
    isHubCollection() {
      return this.item?.record?.collection !== 'collections'
    },
    LearningArea(){
      return this.item?.document?.KLA || this.item.KLA ||  []
    },
    Stages(){
      return (this.item?.document || this.item).Stages?.map(stage => this.allStages.find(el => el.id === stage)?.name) || []
    },
    Description(){
      return this.item?.document?.Description || this.item.Description || 'None';
    },
    Owner(){
      return this.item?.userAccess?.owner?.at(0) || ''
    },
    ContentOwner(){
      // Lookup in metadata for the source name
      return this.hubResourceSources.length > 0 && this.hubResourceSources?.find(source => {
        // console.log(source);
        return source.id === this.item.record.collection
      }).name || '';
    },
    DateCreated(){
      return this.item?.document?.DateCreated || this.item.DateCreated
    },
    LastModified(){
      return this.item?.record?.modified
    },
    Members(){
      return [...this.item.userAccess?.owner, ...this.item.userAccess?.editor, ...this.item.userAccess?.viewer] || []
    }
  },
  methods:{
    handleClick(){
      this.$emit('invite:member')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/variables";
  .container {
    overflow: hidden;

    h3{
      color: $ads-dark;
    }
  }

.info-drawer .v-list {
  .v-list-item {
    min-height: 35px;
  }
  .v-list-item__content {
    padding: 0;
  }
  span.manage-link{
    font-weight: 700;
    width: 33.33%;
    color: $ads-blue-1
  }
}

.v-list{
  span.manage-link{
    font-weight: 700;
    width: 33.33%;
    color: $ads-blue-1
  }
}
</style>
