<template>
  <Loader
    :loading="loading"
    type="heading, list-item-two-line@4"
  >
    <div class="subtitle-2 ml-4">
      ACTIVITY
    </div>
    <v-list>
      <v-list-item
        v-for="({origin, userName, description, updatedAt, notes}, index) in activities"
        :key="index"
      >
        <v-list-item-content>
          <v-tooltip bottom>
            <template #activator="{on, attrs}">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>
                  <v-icon
                    class="mr-5"
                    size="20"
                  >
                    {{ origin.action === 'published_resource' ? 'mdi-check-circle-outline' : 'mdi-plus-circle-outline' }}
                  </v-icon>
                  <span class="subtitle-1">{{ userName }}</span>
                  <span class="body-1">{{ ` ${description}` }}</span>
                </v-list-item-title>
              </span>
            </template>
            <span>{{ notes }}</span>
          </v-tooltip>
          <v-list-item-subtitle class="body-1 ml-10">
            {{ moment(updatedAt).fromNow() }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </Loader>
</template>

<script>
import moment from 'moment';
import {Loader} from '@nswdoe/doe-ui-core';

import {capitalise} from '@/transforms';

export default {
  name: 'Activity',
  components: {
    Loader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    activities: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      capitalise,
      moment
    };
  }
}
</script>
