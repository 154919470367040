<template>
  <div>
    <v-combobox
      ref="searchStaff"
      v-model="value"
      :search-input.sync="search"
      label="Name"
      placeholder="Search User"
      persistent-placeholder
      prepend-inner-icon="search"
      :items="items"
      :loading="isLoading"
      :rules="rules"
      chips
      deletable-chips
      multiple
      outlined
      no-filter
      item-text="uniqueId"
      item-value="uniqueId"
      :cache-items="false"
      :menu-props="{closeOnContentClick:true}"
      return-object
      @change="items = [], validate()"
    >
      <template #item="{ item, parent }">
        <InitialsIcon
          :given-name="item.givenName"
          :surname="item.familyName"
        />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ item.fullName }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-spacer />
        <v-list-item-action @click.stop>
          <v-btn
            icon
            @click.stop.prevent="parent.selectItem(item)"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import {InitialsIcon, ADS_Colors} from '@nswdoe/doe-ui-core';
import api from '@/api';

export default {
  name: 'StaffSearch',
  components: {
    InitialsIcon
  },
  props: {
    showSelectedChips: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    ADS_Colors,
    isLoading: false,
    activator: null,
    attach: null,
    items: [],
    value: [],
    nonce: 1,
    menu: false,
    x: 0,
    search: '',
    y: 0,
  }),
  watch: {
    value (val) {
      this.$emit('onSelect', val)
    },
    async search (val) {
      console.log('searching ', val)
      // if search string is empty dont fetch
      if (!val || val?.length < 1) return

      if (this.isLoading) return

      this.isLoading = true

      try {
        this.items = []
        const response = await api.staff.searchStaff(val);

        // this.items = response.data
        this.items = response?.data
        console.log('results ', this.items.length)

        this.error = '';
        return response.data;
      } catch (e) {
        console.error(e);
        if (this.search && !this.items) {
          this.error = 'There was an issue when retrieving results for you search';
        }
        return this.items;
      } finally {
        this.isLoading = false;
      }
    },
  },
  methods: {
    validate() {
      this.value = this.value.filter(user => user.email)
    }
  }
}

</script>
