<template>
  <Dialog
    v-if="resource"
    v-bind="$attrs"
    icon="delete_outline"
    title="Delete Resource"
    :width="$vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.smAndUp ? 600 : 360"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        class="mt-3 pb-0"
        :style="{'color': ADS_Colors.Dark}"
      >
        <span class="mb-2">
          Are you sure you want to delete this resource <strong>
            {{ resource.Name }}
          </strong> ?
        </span>
        <span class="mb-1">
          You will not be able to undo this action.
        </span>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between px-6 pb-6">
        <v-spacer />
        <AdsButton
          button-text="Cancel"
          @click="$downwardBroadcast('close:dialog')"
          @keyup.enter="$downwardBroadcast('close:dialog')"
        />
        <AdsButton
          :loading="loading"
          :color="ADS_Colors.Red_Dark"
          secondary
          button-text="Delete"
          @click="handleDelete"
          @keyup.enter="handleDelete"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'DeleteResource',
  components: {
    Dialog,
    AdsButton
  },
  props: {
    resource: {
      type: Object
    }
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
      loading: false
    }
  },
  methods: {
    handleDelete: async function () {
      this.loading = true;
      this.$emit('confirm', this.resource)
      this.loading = false;
      this.$downwardBroadcast('close:dialog');
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-card__text {

    span {
      display: block;
      font-size: 16px;
    }
  }
</style>
