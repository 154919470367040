<template>
  <Dialog
    v-bind="$attrs"
    icon="delete_outline"
    title="Are you sure you want to discard this draft?"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 600"
    v-on="$listeners"
  >
    <template #content>
      <v-card-text
        class="mt-3 pb-0 content__wrapper"
        :style="{'color': ADS_Colors.Dark, 'min-width': $vuetify.breakpoint.xsOnly ? '360px' : '500px'}"
      >
        <p class="mb-1">
          You will not be able to undo this action.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between px-6 pb-6">
        <v-spacer />
        <AdsButton
          button-text="Cancel"
          @click="$downwardBroadcast('close:dialog')"
        />
        <AdsButton
          :loading="loading"
          :color="ADS_Colors.Red_Dark"
          secondary
          button-text="Discard"
          @click="handleDelete"
          @keyup.enter="handleDelete"
        />
      </v-card-actions>
    </template>
  </Dialog>
</template>

<script>
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog';

export default {
  name: 'DeleteDraftDialog',
  components: {
    Dialog,
    AdsButton
  },
  data() {
    return {
      loading: false,
      ADS_Colors
    }
  },
  computed: {
    isNewResourceDraft: function () {
      const versionHistory = this.$store.getters['myResources/versionHistory'] ?? [];
      return versionHistory.length <= 1;
    },
    title: function () {
      if (this.isNewResourceDraft) {
        return 'Are you sure you want to discard this draft card?'
      } else {
        return 'Are you sure you want to discard?'
      }
    }
  },
  methods: {
    handleDelete: async function () {
      this.loading = true;
      if (this.isNewResourceDraft) {
        await this.$store.dispatch('myResources/deleteResource', this.$route.params.id);
        if (this.$route.name === 'MyResourceDetailsPage') {
          await this.$router.push('/myresources');
        }
      } else {
        await this.$store.dispatch('myResources/deleteChanges');
        // update the route with query
        let query = Object.assign({}, this.$route.query);
        delete query.draft;
        this.$router.replace({query}).catch(() => {
        });
      }
      this.loading = false;
      if (this.$store.getters['myResources/editMode']) {
        this.$store.commit('myResources/SET_EDIT_MODE', false);
      }
      this.$downwardBroadcast('close:dialog');
    }
  }
}
</script>
