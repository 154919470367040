<template>
  <div :class="{'px-3 pt-2 pb-4': $vuetify.breakpoint.xs, 'px-6 pt-6 pb-6': $vuetify.breakpoint.smAndUp }">
    <v-row
      class="ma-0"
      align="center"
    >
      <v-col
        v-if="(keywords && keywords.length > 0)"
        cols="12"
        sm="9"
        lg="9"
        md="9"
        class="pa-0"
      >
        <span>
          <v-chip
            v-for="(keyword, index) in keywords"
            :key="index"
            tabindex="0"
            :color="ADS_Colors.Navy"
            outlined
            :text-color="ADS_Colors.Navy"
            class="chip ma-2"
          >
            {{ keyword }}
          </v-chip>
        </span>
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="9"
        md="9"
        lg="9"
        :class="{'px-0': $vuetify.breakpoint.xsOnly}"
      >
        <span>None selected</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import {mapState} from 'vuex';
import {ResourceStatus} from '@/constants';

export default {
  name: 'PreviewKeywords',
  data() {
    return {
      ADS_Colors
    };
  },
  computed: {
    ...mapState({
      document: state => state.editor.resourceDetails?.record?.status === ResourceStatus.published ? state.editor.resourceDetails?.document : state.editor.resourceDetails?.draft,
    }),
    keywords() {
      return this.document?.Keywords?.length > 0 ? this.document.Keywords : '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.keywords__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px;

  li {
    list-style: none;
  }
}

.chip {
  margin: 8px;

  &:focus {
    color: $ads-white !important;
  }
}

::v-deep.v-chip {
  &.v-chip--outlined {
    &.v-chip {
      &.v-chip {
        &:focus {
          background-color: $ads-navy !important;
        }
      }
    }
  }
}
</style>
