<template>
  <div>
    <v-row>
      <v-col>
        <AdsTextField
          v-model="searchString"
          label=""
          solo
          prepend-inner-icon="search"
          clearable
          flat
          class="search-bar"
          placeholder="Search my collections"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <div class="d-flex justify-space-between">
          <div>
            <span v-if="value.length > 0"> {{ value.length }} of </span> {{ collections.meta.count }} collections <span v-if="value.length > 0"> selected </span>
          </div>
          <div>
            <v-pagination
              v-if="hubCollection && collections.meta.totalPages > 1"
              v-model="page"
              class="editable-pagination mt-n2"
              :length="collections.meta.totalPages"
            />
          </div>
        </div>
        <div class="card-wrapper">
          <CollectionList
            :items="myCollections"
            :hubCollection="hubCollection"
            :selectedCollections="value"
            :exclude="exclude"
            v-on="$listeners"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ResourceStatus} from '@/constants';
import {mapStatus} from '@/transforms';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import CollectionList from '@/components/Collection/CollectionList'
import {mapGetters, mapState} from 'vuex';
import { AdsTextField } from '@nswdoe/doe-ui-core'
import _debounce from 'lodash/debounce';

export default {
  name: 'EditableCollections',
  components: {
    AdsTextField,
    CollectionList,
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: String,
      default: ''
    },
    hubCollection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchString: '',
      resourceDefaultImage
    }
  },
  created() {
    // no more than once every 0.5 seconds on change of keyword
    this.debouncedSearchCollections = _debounce(this.searchCollections, 500);
  },
  mounted () {
    this.$store.dispatch('myCollections/fetchMyEditableCollections', { page: 1, hubCollection: this.hubCollection});
  },
  computed: {
    ...mapState({
      collections: state => state.myCollections.collectionsEditable,
      upsConfig: state => state.users.upsConfig
    }),
    ...mapGetters({
      isPublisher: 'users/isPublisher',
      isAuthor: 'users/isAuthor',
      isReviewer: 'users/isReviewer'
    }),
    page: {
      get() {
        return parseInt(this.collections?.meta.page, 10) || 1
      },
      set(val) {
        this.$store.dispatch('myCollections/fetchMyEditableCollections', {
          page: val,
          hubCollection: this.hubCollection,
          keywords: val.length >= 3 ? val: ''
        });
      }
    },
    maxCollectionCount() {
      return this.upsConfig.maxNumCollections
    },
    myCollections () {
      // return all collections those I have write access to owner/editor/publisher/reviewer
      return this.collections?.resources.map(collection => {
        const status = collection.record.status;
        const isAllowedToEdit = status === ResourceStatus.draft || status === ResourceStatus.declined|| status === ResourceStatus.pending || this.isPublisher || (this.isReviewer && status !== ResourceStatus.published)
        return {
          ...collection.draft,
          ...collection.document,
          Application: collection.audit.iss,
          subResources: collection.subResources,
          disable: this.hubCollection ? !isAllowedToEdit : false,
          Members: [...collection.userAccess.editor, ...collection.userAccess.viewer],
          status: mapStatus(collection.record.status)
        }
      })
    }
  },
  watch: {
    searchString(val) {
      this.debouncedSearchCollections(val, 1)
    }
  },
  methods: {
    searchCollections(val, page) {
      this.$store.dispatch('myCollections/fetchMyEditableCollections', {
        page: 1,
        hubCollection: this.hubCollection,
        keywords: val?.length >= 3 ? val: ''
      });
    },
    checkWriteAccess (userAccess) {
      if ( !userAccess) return false
      const userId = this.$store.getters[ 'users/userProfile' ].userId.toLowerCase();
      return this.isEditor(userAccess.editor, userId) || this.isOwner(userAccess.owner, userId)
    },
    isOwner (owners, userId) {
      return owners.includes(userId);
    },
    isEditor (editors, userId) {
      return editors.includes(userId);
    }
  }
}
</script>
<style lang="scss" scoped>

@import "src/scss/variables";
@import "src/scss/mixins";

::v-deep.editable-pagination {
  .v-pagination__navigation,
  .v-pagination__item {
    padding: 0;
    margin-top: 0px;
    line-height: 1rem;
    font-size: 0.9rem;
    box-shadow: initial;
  }
}
.card-wrapper {
  border: 1px solid #A0A5AE;
  border-radius: 4px;
  max-height: 400px;
  overflow-X: hidden;

  @include customScrollbar(phone, $ads-light-40, $ads-white, $ads-light-40);
}
</style>
