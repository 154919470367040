<template>
  <BasePage
    :banner="banner"
    :card="{
      maxSize: 424,
      minSize: $vuetify.breakpoint.mdAndDown ? 288 : 310
    }"
    :applyMaxWidth="!showGridView"
    @get:cardSize="getCardSize"
  >
    <ResultsHeader
      v-if="!$store.state.isLoading || !$store.getters['tutorials/loading']"
      :count="$store.state.tutorials.tutorials.items.length"
      :sorting="$store.getters['tutorials/sort']"
      @toggleView="getToggleView"
    />
    <ResourcesResults
      :loading="!$store.getters['tutorials/loading']"
      :resources="$store.state.tutorials.tutorials.items"
      :grid-view="showGridView"
      :card-size="cardSize"
    />
  </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage';
import ResultsHeader from '@/components/ResultsHeader/ResultsHeader';
import ResourcesResults from '@/views/Help&About/TutorialsPage/ResourcesResults/ResourcesResults';

export default {
  name: 'TutorialsPage',
  components: {
    BasePage,
    ResourcesResults,
    ResultsHeader
  },
  data() {
    return {
      banner: {
        heading: 'Help & About - Tutorials',
        subtitle: 'Learn about our new features in the hub',
        icon: 'help_outline',
        iconSize: '3.5rem'
      },
      showGridView: true,
      cardSize: 350,
    };
  },
  mounted() {
    this.$store.dispatch('tutorials/fetchTutorials');
  },
  methods: {
    getCardSize(val) {
      this.cardSize = val;
    },
    getToggleView(val) {
      if (val === 0) {
        this.showGridView = true;
      }
      if (val === 1) {
        this.showGridView = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
