<template>
  <v-row>
    <v-col class="px-0">
      <v-form
        :ref="formId"
        v-model="valid"
      >
        <fieldset>
          <legend>
            <h3>{{ title }} </h3>
            <div
              class="mb-6 body-2 subtitle--text"
            >
              {{ description }}
            </div>
          </legend>
          <v-row
            v-for="(item, resourceIndex) in items"
            :key="resourceIndex"
          >
            <v-col
              v-if="show('id')"
            >
              <AdsTextField
                v-model="item.id"
                :label="`${title} id`"
                aria-required="true"
                placeholder="Paste URH ID"
                persistent-placeholder
              />
            </v-col>
            <v-col
              v-if="show('title')"
            >
              <AdsTextField
                v-model="item.name"
                :label="`${title} title`"
                aria-required="true"
                placeholder="Title"
                persistent-placeholder
              />
            </v-col>
            <v-col
              v-if="show('url')"
              sm="5"
            >
              <div class="d-flex">
                <AdsTextField
                  v-model="item.url"
                  :label="`${title} url`"
                  aria-required="true"
                  placeholder="Paste external URL"
                  persistent-placeholder
                />
                <AdsButton
                  class="remove-button"
                  button-text=""
                  aria-label="remove button"
                  tertiary
                  small
                  color="warning"
                  icon="mdi-minus-circle-outline"
                  @click="removeItem(resourceIndex)"
                  @keyup.enter="removeItem(resourceIndex)"
                />
              </div>
            </v-col>
          </v-row>
          <AdsButton
            :button-text="buttonText"
            secondary
            icon="mdi-plus-circle-outline"
            @click="addItem"
            @keyup.enter="addItem"
          />
        </fieldset>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {AdsTextField, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'LinkListIterator',
  components: {
    AdsButton,
    AdsTextField
  },
  props: {
    title: String,
    description: String,
    items: Array,
    buttonText: String,
    skeleton: {
      type: Object,
      default() {
        return {
          id: '',
          name: '',
          url: '',
        };
      }
    },
    displayFields: Array
  },
  data() {
    return {
      valid: true,
      formId: `lli_${this._uid}`,
      rules: {
        required: v => !!v || 'This field is required'
      }
    };
  },

  methods: {
    addItem() {
      // only add a new item if entered values are valid
      // this.$refs[this.formId].validate()
      if (this.items.length === 0 || this.valid) {
        this.items.push({...this.skeleton})
      }
    },
    show(attr) {
      return this.displayFields.includes(attr);
    },
    removeItem(index){
      this.items.splice(index, 1)
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../../../scss/variables.scss';
  fieldset {
    border: none;
  }

  .remove-button{
    margin-top: 15px;
  }
</style>
