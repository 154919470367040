<template>
  <Dialog
    v-bind="$attrs"
    :title="$vuetify.breakpoint.smAndUp ? 'Duplicate existing resource card': 'Duplicate Resource Card'"
    :subTitle="$vuetify.breakpoint.smAndUp ? 'You must specify an ID for new resource cards.' : ''"
    :hide-heading="false"
    icon="mdi-content-copy"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="duplicate-resource-content mt-6">
        <v-card-text class="mb-6">
          <p>Enter a custom ID below <strong>OR</strong> continue with a system generated ID. </p>
        </v-card-text>
        <v-form ref="check-resourceId-field">
          <v-card-text class="data-content">
            <AdsTextField
              v-model="customIdentifier"
              label="Custom identifier (optional)"
              aria-required="true"
              placeholder="Type here"
              :error="error"
              :success="success"
              :error-messages="errorMessage"
              :success-messages="successMessage"
              persistent-placeholder
              :clearable="true"
              :rules="[rules.required, rules.specialChars]"
              :aria-invalid="rules.specialChars || rules.required ? 'true':'false'"
              aria-describedby="Custom identifier( Optional )"
              @focus="handleClearMessages"
              @blur="resourceIdExist"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions :class="{'d-flex justify-end px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
            <AdsButton
              secondary
              :disabled="isValidId"
              button-text="Use custom ID"
              @click="useResourceId"
              @keyup.enter="useResourceId"
            />
            <AdsButton
              primary
              button-text="Use system generated ID"
              @click="closeDialog"
              @keyup.enter="closeDialog"
            />
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton, AdsTextField} from '@nswdoe/doe-ui-core';
export default {
  name: 'CustomResourceIDDialog',
  components:{
    Dialog,
    AdsButton,
    AdsTextField
  },
  props:{
    resourceDetail:{
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      ADS_Colors,
      showCheckId: true,
      isValidId: true,
      loading: false,
      customIdentifier: '',
      error: false, // if id exist show text box in error state
      success: false, // if id is available show text box in success state
      successMessage: '', // show appropriate message either error or success
      errorMessage: '',
      containSpecialChars: false,
      rules: {
        specialChars: value => {
          const pattern = /^[a-zA-Z0-9_-]*$/
          const testResult = pattern.test(value)
          if (testResult) {
            this.containSpecialChars = false;
            return testResult;
          } else {
            this.containSpecialChars = true;
            return 'Cannot include special characters or spaces. Only "- and _" with alphanumeric is allowed.'
          }
        },
        required: v => !!v || 'Custom Id is required.',
      }
    }
  },
  computed:{
      resourceId(){
        return this.resourceDetail?.record?.id;
      }
  },
  methods:{
    async resourceIdExist(){
      this.loading = true
      // 1.) send Resource id as a payload

      //2.) call this end point /resourceeditor/`${resourceId}`?checkId=true
      if(this.customIdentifier ){
        const resourceId = this.customIdentifier.trim()
        const res = await this.$store.dispatch('editor/getResourceId', { resourceId })
        // 3.) Check if id Exist
        if(res.data && res.data.status === false){
          this.error = true;
          this.errorMessage = res.data.message
          this.containSpecialChars = true
          this.loading = false
          this.isValidId = true
          return;
        }
        // 4.) if Id is available
        this.success = true;
        this.successMessage = res.data ? res.data.message : 'Resource ID accepted'
        this.loading = false
        this.showCheckId = false
        this.isValidId = false

        // 5.) clear success message
        setTimeout(() =>{
          this.success = false;
          this.successMessage = ''
          this.loading = false
        }, 5000);
      }
    },
    useResourceId(){
      let payload = {
        originalResourceId: this.resourceDetail.record.id,
        resourceId: this.customIdentifier.trim(),
        repository: this.resourceDetail.record.collection,
        resourceName: this.resourceDetail?.document?.Name || this.resourceDetail?.draft?.Name,
        displayAlert: true,
        appendCustomIdQuery: true
      }

      this.copyResourceAction(payload)
    },
    closeDialog(){
      let payload = {
        originalResourceId: this.resourceDetail.record.id,
        repository: this.resourceDetail.record.collection,
        resourceName: this.resourceDetail?.document?.Name || this.resourceDetail?.draft?.Name,
        displayAlert: true,
        appendCustomIdQuery: false
      }
      this.copyResourceAction(payload)
    },
    /**
     * this methods only clears error and success message.
     * upon clearing isValid should be set to true so that 'Continue' button is diabled.
     */
    handleClearMessages(){
      this.customIdentifier = ''
      this.isValidId = true
      if(this.error){
        this.errorMessage = ''
        this.error = false
      }else{
        this.successMessage = ''
        this.success= false
      }
    },
    /**
     * this method should copy resource either way, even if user clicks cancel button.
     * if 'Continue' is clicked, its payload should use custom id that was entered
     * else 'Cancel' is clicked, the system generated id should be used.
     * @param payload
     * @returns {Promise<void>}
     */
    async copyResourceAction(payload){
      const newResource = await this.$store.dispatch('editor/copyResource', payload);
      if (newResource) {
        // update the route with the id and turn edit mode off
        const modifiedRoute = Object.assign({}, this.$route);
        modifiedRoute.params.id = newResource.record.id;
        modifiedRoute.query = {};
        this.$router.replace(modifiedRoute).catch(() => {
        });
        await this.$store.commit('editor/SET_EDIT_MODE', true)
      }
      this.$downwardBroadcast('close:dialog')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../scss/mixins';

.duplicate-resource-content{

  @include respond(phone){
    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 80vh;
    }
    .footer-mobile{
      width: 100%;
    }
  }

  ::v-deep.success--text {
    & .v-messages__wrapper{
    .v-messages__message{
      color: green!important;
    }
    }
  }
}
</style>
