<template>
  <div>
    <Dialog
      v-bind="$attrs"
      title="Add Users"
      hideCloseButton
      icon="mdi-account-plus-outline"
      :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #content>
        <v-container class="new-collection-content">
          <v-form ref="newUserForm">
            <v-card-text class="data-content">
              <StaffSearch
                v-model="users"
                :rules="[ rules.required('User')]"
                @onSelect="v => users = v"
              />
              <v-row
                v-for="(access, index) in accesses"
                :key="index"
                class="mt-2"
              >
                <v-col>
                  <v-autocomplete
                    v-model="access.content"
                    class="input-container"
                    aria-label="content group"
                    aria-required
                    :rules="[ rules.requiredId('Content owner'), rules.isUnique(accesses,'Content owner')]"
                    :aria-invalid="rules.requiredId('Content owner')"
                    label="Content owner *"
                    outlined
                    item-text="name"
                    item-value="id"
                    placeholder="Select"
                    persistent-placeholder
                    :return-object="true"
                    :items="contentOwners"
                  />
                </v-col>
                <v-col>
                  <div class="d-flex">
                    <v-select
                      v-model="access.roles"
                      class="input-container"
                      aria-label="Role"
                      :rules="[rules.required('Role')]"
                      aria-required
                      :aria-invalid="rules.required()"
                      label="Role(s) *"
                      outlined
                      item-text="name"
                      item-value="id"
                      placeholder="Select"
                      persistent-placeholder
                      :return-object="false"
                      :items="editorRoles"
                    />
                    <v-btn
                      text
                      icon
                      :color="ADS_Colors.PRIMARY_RED"
                      @click="removeAccess(access)"
                    >
                      <v-icon>
                        mdi-minus-circle-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <AdsButton
                    tertiary
                    icon="mdi-plus-circle-outline"
                    button-text="New Line"
                    @click="addNewLine"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions :class="{'justify-end px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
              <AdsButton
                class="mr-6"
                tertiary
                button-text="Cancel"
                @click="$downwardBroadcast('close:dialog')"
                @keyup.enter="$downwardBroadcast('close:dialog')"
              />
              <AdsButton
                primary
                button-text="Confirm"
                @click="createUser"
                @keyup.enter="createUser"
              />
            </v-card-actions>
          </v-form>
        </v-container>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { AdsButton, ADS_Colors } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapGetters} from 'vuex';
import StaffSearch from '@/components/StaffSearch';
const uuid = require('uuid');

export default  {
  name: 'AddUserDialog',
  components: {
    StaffSearch,
    AdsButton,
    Dialog,
  },
  data: function () {
    return {
      ADS_Colors,
      accessModel: {
        content: {},
        accessId: '',
        roles: ''
      },
      userSearch: [],
      roles: [],
      owners: [],
      users: [],
      accesses: [{
        content: {},
        accessId: '',
        roles: []
      }],
      rules: {
        required: field => v => {
          return (!!v && v?.length > 0) || `${field} is required`
        },
        requiredId: field => v => {
          return (!!v?.id) || `${field} is required`
        },
        isUnique: (accesses, field) => v => {
          return (!!v?.id && accesses?.filter(a=> a.content?.id === v?.id).length < 2) || `${field} duplicated`
        }
      }
    };
  },
  watch: {
    '$attrs.value'(val) {
      // reset form validation on close
      !val && this.$refs.newUserForm && this.$refs.newUserForm.reset()
    }
  },
  updated() {
    if (this.$attrs.value) {
      // this.$nextTick(() => this.$refs.userSearchInput.$el.querySelector('input').focus());
    }
  },
  computed: {
    ...mapGetters({
      editorRoles: 'metadata/editorRoles',
      upsConfig: 'users/upsConfig',
      errorCreateCollection: 'myCollections/errorCreateCollection',
      successCreateCollection: 'myCollections/successCreateCollection',
      collectionCreatedByMe: 'myCollections/collectionCreatedByMe',
      contentOwners: 'metadata/sources',
    }),
    allStages() {
      return this.$store.getters['metadata/stages']
    }
  },
  methods: {
    addNewLine(){
      this.accesses.push({
        ...this.accessModel,
        status: 'new',
        accessId: uuid.v4()
      })
    },
    handleError() {
      console.log('error')
    },
    removeAccess(access) {
      const removeIndex = this.accesses.map(item => item.id).indexOf(access.id);

      if (removeIndex >= 0) {
        this.accesses.splice(removeIndex, 1)
      }
      console.log('removing access...', this.accesses)
    },
    async createUser() {
      const valid = await this.$refs.newUserForm.validate()
      if (!valid) {
        return false
      }
      this.$downwardBroadcast('close:dialog');
      console.log(this.users)
      console.log(this.accesses)
      const urs = this.users
      const accesses = this.accesses.reduce(function (prev, curr) {
        const children = urs.map(u => {
          return {
            userId: u.uniqueId,
            userDetails: {
              userId: u.uniqueId,
              familyName: u.familyName,
              fullName: u.fullName,
              givenName:u.givenName,
              title: u.title
            },
            content: {
              application: curr.content.application,
              id: curr.content.id
            },
            roles: [ curr.roles ]
          }
        });
        return [...prev, ...children]
      }, []);

      const response = await this.$store.dispatch('admin/patchUserAccess', {
        accesses
      });

      if (response?.message?.toLowerCase() === 'success') {
        const alert = {
          type: 'success',
          text: 'Access successfully created.'
        }
        this.$store.commit('SET_ALERT', alert, {root: true});
        this.$store.commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
      console.log('creating accesses', accesses, response)
      this.$emit('onSuccess')
    },
  }
};
</script>
<style lang="scss" scoped>

@import "../../../scss/mixins";

fieldset {
  border: none;
  legend {
    h4 {
      color: #002664
    }
    label {
      color: $ads-grey-02 !important;
    }
  }
}

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 65vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }

  .input-container {
    ::v-deep .v-label {
      color: $ads-primary-blue;
    }

    &.optional ::v-deep .v-label {
      &::after {
        content: ' (optional)';
        font-weight: normal;
      }
    }
  }
}
</style>
