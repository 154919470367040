<template>
  <v-row class="upload-or-link__wrapper">
    <v-col
      cols="12"
      lg="6"
      md="6"
      sm="12"
    >
      <AdsTextField
        v-if="type === 'link'"
        v-model="linkInternal"
        label="Source*"
        aria-label="Source"
        aria-required="true"
        placeholder="Paste a link"
      />
      <v-file-input
        v-else
        id="file-input"
        :key="ckey"
        class="file-input"
        prepend-icon=""
        prepend-inner-icon="$file"
        placeholder="Select a file"
        aria-required="true"
        outlined
        :rules="rules"
        :value="mockFile"
        label="Source*"
        :clearable="false"
        @change="handleFileUpload"
      >
        <template #selection="{File, index, multiple, text}">
          <v-chip
            v-if="fileName"
            label
            small
            :close="true"
            close-icon="mdi-close-circle"
            @click:close="handleRemoveFile"
          >
            {{ fileName }}
          </v-chip>
        </template>
      </v-file-input>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="6"
      sm="12"
      class="action-button__wrapper"
    >
      <v-radio-group
        v-model="type"
        hide-details
      >
        <v-radio
          label="Link to resource"
          value="link"
        />
        <v-radio
          label="Upload (max 50MB)"
          value="file"
        />
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import {AdsTextField} from '@nswdoe/doe-ui-core';

export default {
  name: 'UploadFileOrLink',
  components: {
    AdsTextField,
  },
  props: {
    value: {
      type: [File, String, Object],
      default: () => {
      }
    }
  },
  data() {
    return {
      fileInternal: null,
      mockFile: null,
      fileName: '',
      linkInternal: '',
      type: 'link',
      rules: [],
      ckey: 0,
      maxSize: 52428800,
    }
  },
  computed: {
    fileSize() {
      return this.fileInternal?.size;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.linkInternal = val.link;
        this.fileName = val.file;
        if (val.link) {
          this.type = 'link';
          this.mockFile = null;
        }
        if (val.file) {
          this.type = 'file';
          this.mockFile = new File(['mockFile'], val.file);
        }
      }
    },
    fileInternal: {
      handler: function (file) {
        this.$emit('fileOrLink', {file: file, link: ''});
      }
    },
    linkInternal: {
      handler: function (link) {
        // what if the user clears the filed? Now the empty string value should still be passed back.
        if (link) {
          this.$emit('fileOrLink', {file: null, link: link});
        }
      }
    },
    fileSize(val) {
      if (val > this.maxSize) {
        this.rules = ['Maximum file size is 50MB'];
      } else {
        this.rules = [true];
      }
    },
    rules(rules) {
      if (typeof rules[0] === 'string') {
        this.$emit('exceedFileSize', true);
      }
      if (rules[0] === true) {
        this.$emit('exceedFileSize', false);
      }
    }
  },
  methods: {
    handleFileUpload(val) {
      if (val) {
        if (this.fileInternal && this.fileInternal.name === val.name) {
          return;
        }
        this.fileInternal = val;
      } else if (this.fileSize > this.maxSize) {
        this.fileInternal = null;
        this.mockFile = null;
        this.fileName = '';
        this.ckey++;
      } else {
        this.ckey++;
      }
    },
    handleRemoveFile() {
      this.fileInternal = null;
      this.mockFile = null;
      this.fileName = '';
      this.ckey++;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.upload-or-link__wrapper {
  &.row {
    margin-top: 0;
    margin-bottom: 0;

    .col-12 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .action-button__wrapper {
    display: flex;
    align-items: center;
    max-height: 56px;

    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
      width: 100%;

      ::v-deep.v-input--radio-group__input {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
    }
  }

  .v-input--radio-group--column {
    .v-radio:not(:last-child):not(:only-child) {
      margin-bottom: 0;
    }
  }

  .file-input {
    ::v-deep.v-chip:not(.v-chip--active) {
      background: $ads-blue-2;
      color: $ads-white;
      border-radius: 4px;

      .v-icon {
        color: $ads-white;

        &:focus-visible {
          outline: 2px solid $ads-white !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .upload-or-link__wrapper {
    &.row {
      display: flex;
      flex-direction: column-reverse !important;
      margin-bottom: 0;
    }

    .action-button__wrapper {
      max-width: 400px;
      margin-bottom: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .upload-or-link__wrapper {
    .row {
      .col-12 {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .action-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      span:not(.maximum-size-text) {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .maximum-size-text {
        padding-top: 8px;
      }

      .v-btn {
        width: 100%;
      }
    }
  }
}
</style>
