<template>
  <div>
    <div
      ref="startParagraph"
      class="startParagraph"
      tabindex="-1"
    >
      <p
        v-for="(paragraph, index) in paragraphs"
        :key="`${index}-first`"
        :class="{'mb-0': true, 'mb-0 d-inline': index === paragraphs.length - 1}"
        v-html="paragraph"
      />
    </div>
    <span
      v-if="!isShowingMoreText && text.length > maxChars"
      style="margin-left: -3px;"
    >... </span>
    <div
      v-if="isShowingMoreText"
      class="newContent"
      style="margin-left: -4px;"
    >
      <p
        v-for="(paragraph, index) in hiddenParagraphs"
        :key="`${index}-second`"
        :class="{'mb-0': true, 'mb-0 d-inline pl-1': index === 0}"
        v-html="paragraph"
      />
    </div>
    <!--      Note: click.stop is needed to prevent event propagation in the parent component-->
    <button
      v-if="!isShowingMoreText && text.length > maxChars"
      class="show-more--button"
      @click.stop="handleShowMore"
    >
      <span
        class="text--primary font-weight-bold"
        style="cursor: pointer"
      >
        {{ showMoreLabel }}
      </span>
    </button>
    <button
      v-if="isShowingMoreText && text.length > maxChars"
      class="show-more--button"
      @click.stop="isShowingMoreText = false"
    >
      <span
        class="text--primary font-weight-bold"
        style="cursor: pointer"
      >
        {{ showLessLabel }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ShowMore',
  props: {
    showMoreLabel: {
      type: String,
      default: 'show more'
    },
    showLessLabel: {
      type: String,
      default: 'show less'
    },
    text: {
      type: String,
      required: true
    },
    maxChars: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      newText: '',
      paragraphs: [],
      hiddenParagraphs: [],
      isShowingMoreText: false,
    };
  },
  computed: {
    displayedText() {
      let originalText = this.newText;
      if (this.newText.length > this.maxChars) {
        return `${originalText.substring(0, this.maxChars)}`;
      }
      return originalText;
    },
    hiddenText() {
      let originalText = this.newText;
      if (this.newText.length > this.maxChars) {
        return `${originalText.substring(this.maxChars, this.newText.length)}`;
      } else {
        return originalText;
      }
    }
  },
  watch: {
    text: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.newText = val || oldVal
        }
      }
    },
    $route(to, from) {
      this.newText = this.text
    }
  },
  created() {
    this.displayDescription();
  },
  beforeUpdate() {
    this.displayDescription();
  },
  methods: {
    handleShowMore() {
      this.isShowingMoreText = true
      this.setFocus()
    },
    setFocus() {
      this.$refs.startParagraph.focus()
    },
    displayDescription() {
      this.paragraphs = this.newText.split('<br/>');
      if (this.newText.length > this.maxChars) {
        let characterCounter = 0;
        for (let i = 0; i < this.paragraphs.length; i++) {
          characterCounter += this.paragraphs[i].length;
          if (characterCounter > this.maxChars) {
            let breakIndex = this.paragraphs[i].length - (characterCounter - this.maxChars);
            //make sure the break doesn't break a word
            if (this.paragraphs[i][breakIndex] !== ' ') {
              const nextSpace = this.paragraphs[i].substring(breakIndex).search(' ');
              breakIndex = breakIndex + nextSpace;
            }
            const paragraphStart = this.paragraphs[i].substring(0, breakIndex);
            this.hiddenParagraphs[0] = this.paragraphs[i].substring(breakIndex);
            this.hiddenParagraphs = this.hiddenParagraphs.concat(this.paragraphs.splice(i + 1));
            this.paragraphs.pop();
            this.paragraphs.push(paragraphStart);
            break;
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.startParagraph {
  display: inline;
  word-break: break-word;
  min-width: min-content;
}

.startParagraph:focus {
  outline: none;
}

.newContent {
  display: inline;
  word-break: break-word;
  min-width: min-content;
}

.show-more--button {
  background-color: transparent;
  border: none;
}
</style>
