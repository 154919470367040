<template>
  <v-row
    v-if="itemDetail"
    class="resource-info__wrapper"
  >
    <v-col
      cols="12"
      lg="11"
      md="11"
      sm="12"
    >
      <div class="resource-type d-md-flex flex-md-row-reverse justify-end">
        <span
          v-if="resourceCategory"
          class="d-flex mb-3"
        >
          <FeatureIcon
            v-for="cat in resourceCategory"
            :key="cat.id"
            size="20"
            :aria-label="cat.label"
            :color="cat.iconColor"
            :backgroundColor="cat.iconBg"
            :icon="cat.icon"
            class="mr-2"
          />
          <span
            class="text--secondary text-body-2 font-weight-regular"
            aria-hidden="true"
          >{{ resourceCategoryLabel }}</span>
        </span>

        <span class="font-weight-bold mr-2">{{ resourceType }}</span>
      </div>
      <h2 class="page-title">
        {{ itemDetail.Name }}
      </h2>
      <p class="updated-date">
        Updated: {{ moment(resourceDetails.record.modified ? resourceDetails.record.modified : resourceDetails.record.created).format('DD MMM YYYY') }}
      </p>
      <div class="d-md-flex mb-3">
        <div class="label">
          Format:
        </div>
        <div>
          {{ format }}
        </div>
      </div>
      <div
        v-if="isLearningResource"
        class="tags__wrapper mb-4"
      >
        <template>
          <div
            class="tag--group__wrapper"
          >
            <span class="label">Stage: </span>
            <span>
              {{ stages ? stages.join(', ') : null }}
            </span>
          </div>
          <div
            class="tag--group__wrapper px-2"
          >
            <span class="label">Year: </span>
            <span>
              {{
                itemDetail.Year ? itemDetail.Year.join(', ') : itemDetail.Year
              }}
            </span>
          </div>
          <div class="tag--group__wrapper">
            <span class="label">Learning Area: </span>
            <span>{{
              itemDetail.KLA ? itemDetail.KLA.join(', ') : itemDetail.KLA
            }}</span>
          </div>
        </template>
      </div>
      <div
        v-if="focusArea"
        class="mb-4"
      >
        <span class="label">Focus Area: </span>
        <span>{{ focusArea }}</span>
      </div>
      <div class="label">
        Description:
      </div>
      <div v-html="description ? description : ''" />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';
import {FeatureIcon} from '@nswdoe/doe-ui-core';
import {RESOURCE_CATEGORIES} from '@/constants'
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'ResourceInfo',
  components: {
    FeatureIcon
  },
  data() {
    return {
      moment
    }
  },
  computed: {
    ...mapState({
      itemDetail: state => state.itemDetail,
      resourceDetails: state => state.resourceDetails
    }),
    ...mapGetters({
      stages: 'stages',
      allHubResourceTypes: 'metadata/allHubResourceTypes'
    }),
    description() {
      return this.itemDetail.Description;
    },
    focusArea() {
      return this.itemDetail.FocusArea?.map(e => e.focusArea).join(', ');
    },
    format() {
      return this.itemDetail.Format;
    },
    resourceType() {
      // if the filters are not loaded yet
      if (this.allHubResourceTypes.length === 0) {
        return this.itemDetail.ResourceType;
      }
      // Lookup in metadata for the resourceType label
      return this.allHubResourceTypes?.find(type => {
        return type.id === this.itemDetail.ResourceType
      }).name || 'Resource';
    },
    resourceCategory() {
      return this.itemDetail.ResourceCategory?.map(id => this.getCategoryDetails(id));
    },
    resourceCategoryLabel() {
      return this.resourceCategory?.map(e => e.label).join(', ');
    },
    isLearningResource() {
      return this.isInCategory('tlr');
    }
  },
  methods: {
    isInCategory(category) {
      return this.resourceCategory?.find(cat => cat.id === category) || null;
    },
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.resource-info__wrapper {
  padding-left: 12px;
  padding-right: 12px;

  .col-12 {
    padding-left: 0;
    padding-right: 0;

    .resource-type {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: $ads-blue-1;
      margin-bottom: 8px;
    }

    .page-title {
      font-size: 34px;
      font-weight: bold;
      color: $ads-navy;
      word-break: break-word;
      line-height: 40px;
      margin-bottom: 8px;
    }

    .updated-date {
      color: $ads-dark-60;
    }

    .tags__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .tag--group__wrapper {
        display: flex;
      }
    }

    .label {
      color: $ads-dark;
      margin-right: 8px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 960px) {
  .resource-info__wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (max-width: 669px) {
  .resource-info__wrapper {
    .col-12 {
      .page-title {
        font-size: 20px;
        line-height: 28px;
      }

    }
  }
}
</style>
