<script>
  import marked from 'marked'
  import {
    mapGetters,
    mapState,
  } from 'vuex'

  export default {
    name: 'DocMarkdown',
    props: {
      code: {
        type: [Array, String],
        default: '',
      },
      source: {
        type: String,
        default: '',
      },
      tag: {
        type: String,
        default: 'div',
      },
    },
    computed: {
      ...mapGetters('documentation', [
        'namespace',
        'page',
      ]),
      ...mapState('route', ['params']),
    },

    render(h) {

      marked.setOptions({
        gfm: true,
        breaks: true
      });

      const parseLink = (match, text, link) => {
        let attrs = ''
        let linkClass = 'markdown--link'
        let icon = ''

        // External link
        if (link.indexOf('http') > -1) {
          attrs = 'target="_blank" rel="noopener"'
          //icon = 'open-in-new'
          icon = ''
          linkClass += ' markdown--external'
          // Same page internal link
        } else if (link.charAt(0) === '#') {
          linkClass += ' markdown--same-internal'
          icon = 'pound'
          // Different page internal link
        } else {
          linkClass += ' markdown--internal'
          icon = 'page-next'
        }
        return `<a href="${link}" ${attrs} class="${linkClass}">${text}<i class="v-icon mdi mdi-${icon}"></i></a>`
      }

      let code = this.code || this.source

      if (!this.code) {
        if (this.$slots.default) {
          code = this.$slots.default[0].text.trim()
        }

        if (code.indexOf('.') > -1) {
          code = this.$t(code)
        } else if (
          this.namespace &&
          this.page
        ) {
          code = this.$t(
            `${this.namespace}.${this.page}.${code}`
          )
        }
      }

      // Probably wants to make a list
      const wantsList = Array.isArray(code);

      if (wantsList) {
        code = code.map(c => `- ${c}\n`).join('')
      }

      // Convert markdown links
      code = code.replace(/\[([^\]]*)\]\(([^)]*)\)/g, parseLink)

      return h(this.tag, {
        staticClass: 'markdown',
        class: {
          'mb-6': wantsList,
        },
        domProps: {innerHTML: marked(code)},
      })
    },
  }
</script>

<style scoped>
  .markdown:last-child > p {
    margin-bottom: 0 !important
  }

  .markdown:last-child {
    margin-bottom: 0 !important
  }

  .markdown--link {
    text-decoration: none
  }

  .markdown--link :hover {
    opacity: 0.8
  }

  .v-icon {
    font-size: 14px;
    margin-left: 4px;
    vertical-align: baseline;
  }

  .markdown > h4 {
    margin: 8px 0
  }
</style>
