import axios from 'axios';
import Vue from 'vue';

const CONFIG = (window as any).dlrhConfig;

export async function shareCollection(baseUrl, data={}, config = {}) {
  try {
    return await axios.put(baseUrl, data, config);
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function copyCollectionResource(baseUrl, requestData) {
  const document = requestData.collection.document;
  const source = requestData.source || 'collections';
  const application = requestData.application || CONFIG.APPLICATION;
  const { uid, action, index, sessionid } = requestData
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
          application,
          source,
          uid,
          sessionid
        })
      }
    };
    // console.log(typeof index);
    let query = `&action=${action}`;
    if (typeof index === 'number') {
      query += `&index=${index}`;
    }
    const data = {
      draft: document
    };

    return axios.post(
      `${baseUrl}/resources/copy/${document.ItemID}?userId=${uid}${query}`,
      data,
      config
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function followCollection(baseUrl, requestData) {
  const { uid, source, application, follow, id } = requestData;
  const action = follow ? 'follow': 'unfollow'

  const url = `${baseUrl}/resources/${action}/${id}`;
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'multipart/form-data',
          application,
          source,
          uid
        })
      }
    };

    return axios.post(url, {}, config);
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function sequenceOrderCollection (baseUrl, data={}, { uid }){
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
          application: CONFIG.APPLICATION,
          source: 'collections',
          uid: uid
        })
      }
    };

    return axios.put(
      `${baseUrl}/collection/subresource/order`,
      data,
      config
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}
